import React, { useState, useRef, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../axios-instance_baseURL';
import { isSafari } from "react-device-detect";

import userImg from '../assets/images/icons/Mask Group 3.svg';
import InputEditable from '../UI/InputEditable';
import DatePickerSettings from '../UI/DatePickerSettings';
import checkImg from '../assets/images/icons/Check On.svg';
import { update_profile_url, user_profile_url, get_img_url } from '../assets/urls/urls';
import { update_profile_config, user_profile_config, get_img_config } from '../assets/configs/config_calls';
import { setUserData, setUserNameSurname } from '../store/actions/authActions';
import Aux from '../hoc/Auxilary';
import Spinner from '../UI/Spinner';

const VALIDITY_CHANGE = "VALIDITY_CHANGE";
const UPDATE_VALUES = "UPDATE_VALUES";

const reducer = (state, action) => {
    if (action.type === VALIDITY_CHANGE) {
        if (state.validElements[action.elemName] === false && action.elemValidity) {
            const copy = { ...state.validElements };
            copy[action.elemName] = action.elemValidity;
            let formValid = true;
            for (let item in { ...copy }) {

                if (copy[item] === false) {
                    formValid = false;
                    break;
                }
            }
            const values = { ...state.elementsValues };
            values[action.elemName] = action.elemValue;
            return {
                ...state,
                validElements: { ...copy },
                elementsValues: { ...values },
                formValid: formValid
            }
        } else if (state.validElements[action.elemName] && action.elemValidity === false) {
            const copy = { ...state.validElements };
            copy[action.elemName] = action.elemValidity;
            return {
                ...state,
                validElements: { ...copy },
                formValid: false
            }
        } else if (state.validElements[action.elemName] && action.elemValidity) {
            const copy = { ...state.validElements };
            copy[action.elemName] = action.elemValidity;
            let formValid = true;
            for (let item in { ...copy }) {

                if (copy[item] === false) {
                    formValid = false;
                    break;
                }
            }
            const values = { ...state.elementsValues };
            values[action.elemName] = action.elemValue;
            return {
                ...state,
                validElements: { ...copy },
                elementsValues: { ...values },
                formValid: formValid
            }
        } else {
            return state;
        }

    } else if (action.type === UPDATE_VALUES) {
        return {
            ...state,
            elementsValues: {
                ...action.newValues
            }
        }
    }
}

const Profile = props => {

    const { t, i18n } = useTranslation();

    const [userData, setUserData2] = useState(null);
    const [date, setdate] = useState();
    const [file, setFile] = useState(null);
    const [URLImageObject, setURLImageObject] = useState(null);
    const [errMsg, setErrMsg] = useState(null);
    const [loadingOverall, setLoadingOverall] = useState(false);
    const [emailReceive, setEmailReceive] = useState(false);
    const refToInput = useRef();
    const accessToken = useSelector(state => state.auth.accessToken);
    const [messageDispaly, setMessageDisplay] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [displayName, setDisplayName] = useState();
    const [displayEmail, setDisplayEmail] = useState();

    const dispatch = useDispatch();


    const [validations, dispatchValidations] = useReducer(reducer, {
        validElements: {
            name: true,
            surname: true,
            phone: true
        },
        elementsValues: {
            name: "",
            surname: "",
            phone: ""
        },
        formValid: true
    });

    const [formData, setFormData] = useState({
        name: {
            elemType: "input",
            placeholder: 'inputs.placeholders.name',
            desc: 'profile.name',
            type: "input",
            name: "name",
            valid: true,
            value: "",
            validationMessage: 'inputs.errorMessages.name',
            succesMessage: 'inputs.inputValidMessage',
            typing: false,
            rules: {
                required: true,
                minLength: 2
            },
        },
        surname: {
            elemType: "input",
            placeholder: 'inputs.placeholders.last_name',
            desc: 'profile.last_name',
            type: "input",
            valid: true,
            name: "surname",
            value: "",
            validationMessage: 'inputs.errorMessages.last_name',
            succesMessage: 'inputs.inputValidMessage',
            typing: false,
            rules: {
                required: true,
                minLength: 2
            },
        },
        phone: {
            elemType: "input",
            placeholder: '07XXXXXXX',
            desc: 'profile.phone',
            type: "input",
            valid: true,
            name: "phone",
            value: "",
            validationMessage: 'inputs.errorMessages.phone',
            succesMessage: 'inputs.inputValidMessage',
            typing: false,
            rules: {
                required: true,
                isNumeric: true,
                maxLength: 9,
                minLength: 9
            },
        },
    });


    const validChangeHandler = (elemName, elemValidity, elemValue) => {
        dispatchValidations({ type: VALIDITY_CHANGE, elemName: elemName, elemValidity: elemValidity, elemValue: elemValue })
    }


    const setDateFunc = (date) => { setdate(date); }

    const handleFile = (event) => {
        event.preventDefault();
        if (event.target.files[0]) {
            if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif|svg|JPG)$/)) {
                setFile(null);
                setURLImageObject(null);
            } else {
                setFile(event.target.files[0]);
                setURLImageObject(URL.createObjectURL(event.target.files[0]));
            }

        }
    };

    const callReff = () => {
        refToInput.current.click();
    };

    useEffect(() => {
        let configUser = user_profile_config(accessToken, i18n.language);
        setLoadingOverall(true);

        axios.get(user_profile_url, { params: {}, headers: configUser.headers })
            .then(response => {
                if (response.data.status) {
                    dispatch(setUserData({ ...response.data }, accessToken));
                    setUserData2(response.data);
                    setFormData({
                        ...formData,
                        phone: {
                            ...formData.phone,
                            value: response.data.user.phone_number
                        },
                        surname: {
                            ...formData.surname,
                            value: response.data.user.last_name
                        },
                        name: {
                            ...formData.name,
                            value: response.data.user.first_name
                        }
                    });

                    setEmailReceive(response.data.user.marketing);
                    dispatchValidations({
                        type: UPDATE_VALUES, newValues: {
                            name: response.data.user.first_name,
                            surname: response.data.user.last_name,
                            phone: response.data.user.phone_number
                        }
                    });
                    // setURLImageObject(response.data.user.profile_picture_url);

                    if (response.data.user.dob_year && response.data.user.dob_month && response.data.user.dob_day) {
                        let givenDate = new Date(response.data.user.dob_year, response.data.user.dob_month - 1, response.data.user.dob_day, 0, 0, 0, 0)
                        setdate(givenDate);
                    }

                    setDisplayName(response.data.user.first_name);
                    setDisplayEmail(response.data.user.email)
                    let imageConfing = get_img_config(accessToken, i18n.language);
                    if (response.data.user.profile_picture_url === "") {
                        setLoadingOverall(false);
                        return;
                    }
                    axios.get(get_img_url + response.data.user.profile_picture_url, { params: {}, headers: imageConfing.headers })
                        .then(response => {
                            if (response && response.request && response.request.responseURL) {
                                setURLImageObject(response.request.responseURL);
                            }
                            setLoadingOverall(false);
                        })
                        .catch(err => {
                            if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                                props.history.push({
                                    pathname: '/settings/logout',
                                    err: "unauthenticated",
                                });
                                return;
                            }
                            setLoadingOverall(false);
                            if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.statusText === "UNAUTHORIZED") {
                                props.history.push({
                                    pathname: '/settings/logout',
                                    err: "unauthenticated",
                                });
                                return;
                            }

                        })
                } else {
                    setErrMsg('homepage.errorMsg');
                    setLoadingOverall(false);
                }
            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setLoadingOverall(false);
                setErrMsg('homepage.errorMsg');

            });
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        if (validations.formValid) {
            let formDataWithFile = new FormData();
            formDataWithFile.append('first_name', validations.elementsValues.name);
            formDataWithFile.set('last_name', validations.elementsValues.surname);
            formDataWithFile.set('phone_number', validations.elementsValues.phone);
            formDataWithFile.set('marketing', emailReceive);
            if (file) formDataWithFile.set('profile_picture', file);
            let day = null;
            let month = null;
            let year = null;
            if (date) {
                day = date.getDate();
                month = date.getMonth() + 1;
                year = date.getFullYear();
                formDataWithFile.append('dob_day', day);
                formDataWithFile.set('dob_month', month);
                formDataWithFile.set('dob_year', year);
            }

            let config = update_profile_config(accessToken, i18n.language);

            setMessageDisplay(null);
            setSuccess(false);
            setLoading(true);
            axios.put(update_profile_url, formDataWithFile, { headers: { ...config.headers }, })
                .then(response => {
                    if (response.data.status && response.data.code === 1019) {
                        setDisplayName(validations.elementsValues.name);
                        dispatch(setUserNameSurname(validations.elementsValues.name, validations.elementsValues.surname))
                        setMessageDisplay('profile.successfull');
                        setLoading(false);
                        setSuccess(true);
                        setTimeout(() => {
                            setSuccess(false)
                        }, 4000);
                    } else {
                        setErrMsg(response.data.message);
                        setLoading(false);
                        setSuccess(false);
                    }
                }).catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }
                    setErrMsg('homepage.errorMsg');
                    setSuccess(false);
                    setLoading(false);
                })
        }
    };

    const validity = (validations.formValid);
    let content = null;
    if (userData && !loadingOverall) {
        content = (
            <Aux>
                <div className='image-part'>
                    <div onClick={callReff}>
                        <input
                            style={{ display: 'none' }}
                            ref={refToInput}
                            type="file"
                            onChange={(event) => handleFile(event)}
                        />
                        <img alt='profile' style={{ width: "100px", height: "100px", borderRadius: "50%" }} src={URLImageObject ? URLImageObject : userImg}></img>
                    </div>
                    <button onClick={callReff} className="change-image"> {t('profile.change')}</button>
                    <div className='gretings-user'> {t('profile.greetings')} {displayName}</div>
                    <div style={{ height: "35px", width: "150px" }}>
                        {/* {loading ? <Spinner spinnerStyle="SpinnerYellow SpinnerButton SpinnerSettingsButton" /> : */}
                        <button onClick={handleSubmit} disabled={!validity} className='change-button'>
                            {t('profile.save')}
                        </button>

                    </div>

                </div>
                <div className='profile-settings-part'>
                    <hr className='hr-remove-margins'></hr>
                    <div className='user-profile-item'>
                        <div> {t('profile.email')}: <span> {displayEmail}</span></div>
                    </div>
                    <hr className='hr-remove-margins'></hr>
                    <InputEditable data={formData.name} notifyHandler={validChangeHandler} />
                    <hr className='hr-remove-margins'></hr>
                    <InputEditable data={formData.surname} notifyHandler={validChangeHandler} />
                    <hr className='hr-remove-margins'></hr>
                    <InputEditable data={formData.phone} notifyHandler={validChangeHandler} />
                    <hr className='hr-remove-margins'></hr>
                    <div className='user-settings-phone'>
                        <div style={{ display: "inline-block" }}>
                            <span> {t('profile.birthdate')}: &nbsp;  </span>
                            {date ? (String(date.getDate() + 1).length === 1 ? "0" + (date.getDate()) : (date.getDate())) : null}.
                                {date ? (String(date.getMonth() + 1).length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) : null}.
                                {date ? date.getFullYear() : null}

                        </div>
                        <DatePickerSettings
                            date={date}
                            setDateFunc={setDateFunc}
                        />
                    </div>
                    <hr className='hr-remove-margins'></hr>
                    <div className='policy-rules'>
                        {emailReceive ? <img alt='check-icon' src={checkImg} onClick={() => setEmailReceive(false)} />
                            : <button type='button' className='empty-square' onClick={() => setEmailReceive(true)}> </button>}
                        <div className='text'>
                            {t('login.accept_email')}
                        </div>
                    </div>
                    {errMsg ? <div className='error-message'>  {t(errMsg)} </div> : null}
                    <div className={(messageDispaly && success) ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>
                        {t(messageDispaly)}
                    </div>

                </div>
            </Aux>
        )
    } else if (loadingOverall) {
        content = <Spinner logo spinnerStyle="SpinnerYellow" />
    } else if (errMsg) {
        content = t(errMsg);
    }

    return (
        <div className='profile-wrapper'>
            {content}
            {loading ?
                <div className='loading-data-modal' >
                    <Spinner logo spinnerStyle="SpinnerYellow" />;
                         </div> : null
            }
        </div>
    )
}

export default Profile;
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { set_filter_types, clear_filter_modal, filter_applied, set_tag_checked, set_tag_uncheck, set_expanded } from '../store/actions/filterActions';
import arrowDown from '../assets/images/icons/arrow-down.svg';
import arrowUp from '../assets/images/icons/up-arrow.svg';
import filterImg from '../assets/images/icons/filter.svg';
import allImg from '../assets/images/icons/nine-squares.svg';
import deliverImg from '../assets/images/icons/pickup-car.png';
import pickupImg from '../assets/images/icons/Icon.png';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Auxilary from '../hoc/Auxilary';
import Backdrop from '../UI/Backdrop';

const FilterOption = props => {
    // const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation();
    const filters = useSelector(state => state.filters.filter);
    const apiTags = useSelector(state => state.filters.apiTags);
    const expanded = useSelector(state => state.filters.expanded);
    const dispatch = useDispatch();


    const tagsIds = filters.appliedTags.map(item => item.tag_id);
    return (
        <Auxilary>

            <Backdrop address className='menu-backdrop' show={expanded} backdropClicked={() => dispatch(set_expanded(false))} />
            <span className={filters.filterApplied ? "filter-item filter-option active-item" : "filter-item filter-option"}>
                <button className='button' onClick={() => {
                    // if (!filters.filterApplied) {
                    //     dispatch(clear_filter_modal());
                    // }
                    // setExpanded(!expanded);
                    dispatch(set_expanded(!expanded));
                }}>
                    <img src={filterImg} />
                    {t('filters.filter')}
                    <img src={expanded ? arrowDown : arrowUp} />
                </button>
                {expanded ?
                    (
                        <div className='modal-options'>

                            <div className='filter-clear'>
                                <button onClick={() => dispatch(clear_filter_modal())}>{t('filters.clear')}</button>
                            </div>

                            <div className='option-group-1'>
                                <div className='options-group-title'>
                                    <span>
                                        {t('filters.diningOptions')}
                                    </span>
                                </div>

                                <div className='options-delivery-type delivery-types'>
                                    <div>
                                        <button onClick={() => dispatch(set_filter_types("deliveryType", "all"))}
                                            className={filters.deliveryType === 'all' ? 'activeButton' : ''}>
                                            <img alt='pickup-icon' src={allImg} /> {t('filters.all')}
                                        </button>
                                        <button onClick={() => dispatch(set_filter_types("deliveryType", "delivery"))}
                                            className={filters.deliveryType === 'delivery' ? 'activeButton' : ''}>
                                            <img alt='pickup-icon' src={deliverImg} /> {t('filters.delivery')}
                                        </button>
                                        <button onClick={() => dispatch(set_filter_types("deliveryType", "pickup"))}
                                            className={filters.deliveryType === 'pickup' ? 'activeButton' : ''}>
                                            <img alt='pickup-icon' src={pickupImg} /> {t('filters.pickUp')}
                                        </button>
                                    </div>
                                    {/* <div>
                                        <span className={filters.deliveryType === 'all' ? 'radio-button-checked' : 'radio-button-empty'}
                                            onClick={() => dispatch(set_filter_types("deliveryType", "all"))} />
                                        <span onClick={() => dispatch(set_filter_types("deliveryType", "all"))} style={{ cursor: "pointer" }}>
                                            {t('filters.all')}
                                        </span>
                                    </div>
                                    <div>
                                        <span className={filters.deliveryType === 'delivery' ? 'radio-button-checked' : 'radio-button-empty'}
                                            onClick={() => dispatch(set_filter_types("deliveryType", "delivery"))} />
                                        <span onClick={() => dispatch(set_filter_types("deliveryType", "delivery"))} style={{ cursor: "pointer" }}>
                                            {t('filters.delivery')}
                                        </span>
                                    </div>
                                    <div>
                                        <span className={filters.deliveryType === 'pickup' ? 'radio-button-checked' : 'radio-button-empty'}
                                            onClick={() => dispatch(set_filter_types("deliveryType", "pickup"))} />
                                        <span onClick={() => dispatch(set_filter_types("deliveryType", "pickup"))} style={{ cursor: "pointer" }}>
                                            {t('filters.pickUp')}
                                        </span>

                                    </div> */}
                                </div>
                            </div>

                            <div className='option-group-2'>
                                <div className='options-group-title'>
                                    <span>
                                        {t('filters.sort')}
                                    </span>
                                </div>
                                <div className='options-time'>
                                    <Row className='m-0'>
                                        <Col xs='12' sm='5' className='p-0 mt-1 hover-div'
                                            onClick={() => dispatch(set_filter_types("sortType", "LOCATION"))}>
                                            <div className={filters.sortType === 'LOCATION' ? 'sort-item-checked' : 'sort-item'}
                                                onClick={() => dispatch(set_filter_types("sortType", "LOCATION"))}
                                            >
                                                {t('filters.pickedForYou')}

                                            </div>
                                            <div className={filters.sortType === 'LOCATION' ? 'check-mark' : 'check-mark-hover'}></div>

                                            {/* <span className={filters.sortType === 'pickedForYou' ? 'radio-button-checked' : 'radio-button-empty'}
                                                onClick={() => dispatch(set_filter_types("sortType", "pickedForYou"))} />
                                            <span onClick={() => dispatch(set_filter_types("sortType", "pickedForYou"))} style={{ cursor: "pointer" }}>
                                                {t('filters.pickedForYou')}
                                            </span> */}

                                        </Col>
                                        <Col xs='12' sm='5' className='p-0 ml-sm-2 mt-1 hover-div'
                                            onClick={() => dispatch(set_filter_types("sortType", "TIME"))}>
                                            <div className={filters.sortType === 'TIME' ? 'sort-item-checked' : 'sort-item'}
                                                onClick={() => dispatch(set_filter_types("sortType", "TIME"))}
                                            >
                                                {t('filters.deliveryTime')}
                                            </div>
                                            <div className={filters.sortType === 'TIME' ? 'check-mark' : 'check-mark-hover'}></div>
                                            {/* <span className={filters.sortType === 'deliveryTime' ? 'radio-button-checked' : 'radio-button-empty'}
                                                onClick={() => dispatch(set_filter_types("sortType", "deliveryTime"))} />
                                            <span onClick={() => dispatch(set_filter_types("sortType", "deliveryTime"))} style={{ cursor: "pointer" }}>
                                                {t('filters.deliveryTime')}
                                            </span> */}
                                        </Col>
                                        <Col xs='12' sm='5' className='p-0 mt-1 hover-div'
                                            onClick={() => dispatch(set_filter_types("sortType", "MOST_ORDERS"))}>
                                            <div className={filters.sortType === 'MOST_ORDERS' ? 'sort-item-checked' : 'sort-item'}
                                                onClick={() => dispatch(set_filter_types("sortType", "MOST_ORDERS"))}
                                            >
                                                {t('filters.mostOrders')}
                                            </div>
                                            <div className={filters.sortType === 'MOST_ORDERS' ? 'check-mark' : 'check-mark-hover'}></div>
                                            {/* <span className={filters.sortType === 'opened' ? 'radio-button-checked' : 'radio-button-empty'}
                                                onClick={() => dispatch(set_filter_types("sortType", "opened"))} />
                                            <span onClick={() => dispatch(set_filter_types("sortType", "opened"))} style={{ cursor: "pointer" }}>
                                                {t('filters.open')}
                                            </span> */}
                                        </Col>
                                        {/*
                                         <Col xs='12' sm='5' className='p-0 ml-sm-2 mt-1 hover-div'
                                            onClick={() => dispatch(set_filter_types("sortType", "close"))}>
                                            <div className={filters.sortType === 'close' ? 'sort-item-checked' : 'sort-item'}
                                                onClick={() => dispatch(set_filter_types("sortType", "close"))}
                                            >
                                                {t('filters.closed')}
                                            </div>
                                            <div className={filters.sortType === 'close' ? 'check-mark' : 'check-mark-hover'}></div>
                                             <span className={filters.sortType === 'closed' ? 'radio-button-checked' : 'radio-button-empty'}
                                                onClick={() => dispatch(set_filter_types("sortType", "closed"))} />
                                            <span onClick={() => dispatch(set_filter_types("sortType", "closed"))} style={{ cursor: "pointer" }}>
                                                {t('filters.closed')}
                                            </span> 
                                            
                                        </Col> 
                                            */}
                                    </Row>
                                </div>
                            </div>

                            <div className='option-group-3'>
                                <div className='options-group-title'>
                                    <span>{t('filters.tags')}</span>
                                </div>
                                <div className='options-tags'>
                                    <Row className='m-0 pb-2 mb-3'>
                                        {apiTags.map(item => {
                                            return (
                                                <div className='tag-item' key={item.tag_id}>
                                                    <button onClick={() => dispatch(set_tag_checked({ tag_id: item.tag_id, name: item.name }))}
                                                        className={tagsIds.indexOf(item.tag_id) > -1 ? 'tag-button-checked' : 'tag-button'} >
                                                        {item.name}
                                                    </button>
                                                </div>

                                            )
                                            // return <Col xs='6' md='4' className='p-0' key={item.tag_id}>
                                            //     <span
                                            //         onClick={() => dispatch(set_tag_checked({ tag_id: item.tag_id, name: item.name }))}
                                            //         className={tagsIds.indexOf(item.tag_id) > -1 ? 'check-button-checked' : 'check-button-empty'} />
                                            //     <span
                                            //         onClick={() => dispatch(set_tag_checked({ tag_id: item.tag_id, name: item.name }))}
                                            //         style={{ cursor: "pointer" }}>{item.name}</span>
                                            // </Col>

                                        })}
                                    </Row>
                                </div>
                            </div>

                            <div className='filter-apply'>
                                <button onClick={() => dispatch(filter_applied())} >{t('filters.apply')}</button>
                            </div>

                        </div>
                    )
                    : null
                }
            </span>
        </Auxilary>

    )
}


export default FilterOption;


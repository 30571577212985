import React, { useEffect } from 'react';
import axios from '../axios-instance_baseURL';

import { logout_url } from '../assets/urls/urls';
import { logout_confing } from '../assets/configs/config_calls';
import { useSelector, useDispatch } from 'react-redux';
import { logout_call } from '../store/actions/authActions';

const Logout = props => {

    const accessToken = useSelector(state => state.auth.accessToken);
    const dispatch = useDispatch();
    useEffect(() => {
        let showUnAuthenticated = props.history && props.history.location && props.history.location.err && props.history.location.err === "unauthenticated" ? true : false;
        if (showUnAuthenticated) {
            dispatch(logout_call());
            props.history.push({
                pathname: '/',
                err: "unauthenticated",
            });
            return;
        } else {
            const config = logout_confing(accessToken);
            axios.get(logout_url, {}, {
                params: {}, headers: config.headers
            }).then(response => {
                localStorage.setItem("promo", true);
                dispatch(logout_call());
                props.history.push('/');
            }).catch(err => {
                
            });
        }

    }, [])

    return (

        <div>
        </div>
    )

}

export default Logout;
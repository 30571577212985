import React, { useState, useRef } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';


const DeliveryTimesModal = props => {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState();
    const selectRef = useRef(null)
    let options = [];
    let values = props.data.times[0].values;
    let leadTime = 0;
    if (props.leadTime && props.leadTime !== '-1') leadTime = props.leadTime;
    for (let item in values) {
        let date = new Date();
        let time = values[item].time.split(" ")[1].slice(0, 5);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        
        if (parseInt(minutes) + parseInt(leadTime) >= 60) {
            hours = parseInt(hours) + 1;
            minutes = parseInt(leadTime) - (60 - parseInt(minutes));
        } else {
            minutes = parseInt(minutes) + parseInt(leadTime);
        }
     
        if (parseInt(hours) <= parseInt(time.split(":")[0])) {
            if (parseInt(hours) === parseInt(time.split(":")[0])) {
                if (parseInt(time.split(":")[1]) > minutes) {
                    // if (props.selectedTime && (props.selectedTime === values[item].id)) {
                    //     setSelectedOption({
                    //         value: values[item].id,
                    //         label: time
                    //     })
                    // };
                    options.push({
                        value: values[item].id,
                        label: time,
                        fullTime: values[item].time
                    });
                }
            } else {
                options.push({
                    value: values[item].id,
                    label: time,
                    fullTime: values[item].time
                });
            }
        }
    }
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        props.setSelected(selectedOption, "choose",false);
    };


    // Feature of focusing selected option when menu is getting opened
    const onMenuOpen = () => {
        const option = selectRef.current?.select?.state?.selectValue?.[0]
        if (option) {
          setTimeout(() => {
            if (selectRef.current?.select) {
              const selectedIndex = selectRef.current.select.state.menuOptions.focusable.indexOf(option)
              if (selectedIndex >= 0) {
                // Focusing selected option only if it exists
                selectRef.current.select.scrollToFocusedOptionOnUpdate = true
                selectRef.current.select.inputIsHiddenAfterUpdate = false
                selectRef.current.select.setState({
                  focusedValue: null,
                  focusedOption: option,
                })
              }
            }
          })
        }
      }
    

    const customTheme = (theme) => {
        return {
            ...theme,
            colors: {
                ...theme.colors,
                primary25: "#FFC300",
                primary: "#FFC300"
            }
        }
    }
    let chooseTime = t('order-page.choose-time');
    let noOptions = t('order-page.no-available-time');
    return (
        <div className='delivery-times-modal'>
            <button className='close-button' onClick={() => {
                props.handleCloseTimesModal();
            }}>
                <div className="mdiv">
                    <div className="md"></div>
                </div>
            </button>
            <Select
                ref={selectRef}
                value={selectedOption}
                onChange={handleChange}
                options={options}
                menuIsOpen={true}
                placeholder={chooseTime}
                isSearchable={true}
                theme={customTheme}
                onMenuOpen={onMenuOpen}
                noOptionsMessage={()=>noOptions}
             
            />
        </div>
    )

}

export default DeliveryTimesModal;
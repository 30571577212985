import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import Layout from '../hoc/Layout';
import OrderPage from '../components/OrderPage';

const OrderContainer = props => {
    return (
        <Layout settings history={props.history}>
                <Switch>
                    <Route path='/order/main/' component={OrderPage} />
                    <Route path='/order' render={() => <Redirect to='/' />} />
                </Switch>

        </Layout>
    )

}

export default OrderContainer
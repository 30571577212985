import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import defaultImage from '../assets/images/logos/Logo_naracaj_2-1.svg';

const UpsaleItem = (props) => {
    const basketData = useSelector(state => state.basket.basketData);
    let hasPicked = basketData.upsale?.filter(item => item.id === props.data.id)[0];
    const [quantity, setQuantity] = useState(props.quantity ? props.quantity : hasPicked ? hasPicked.quantity : 1);
    const [successUpdate, setSuccessUpdate] = useState(null);
    const [failedUpdae, setFailedUpdate] = useState(null);
    const { t } = useTranslation();
    const updateQuantity = (type) => {
        if (type === 'minus' && quantity > 1)
            setQuantity(quantity - 1);

        if (type === 'plus')
            setQuantity(quantity + 1);
    }
    const handleAddition = () => {
        props.addItem(props.data.id, quantity).then(result => {
            setFailedUpdate(null);
            setSuccessUpdate(null);
            if (result === 1) {
                setFailedUpdate(null);
                setSuccessUpdate(true);
                setTimeout(() => {
                    setSuccessUpdate(null);
                }, 2500);
            } else if (result === 0) {
                setFailedUpdate(true);
                setSuccessUpdate(null);
                setTimeout(() => {
                    setFailedUpdate(null);
                }, 2500);
            }
        })
    }
    return (
        <div className='upsale-item-wrapper'>
            <div className='upsale-price'>
                {props.data.price}
                <span>{t('upsale.currency')}</span>
            </div>
            <div className='title-image-wrapper'>
                <div className='image-wrapper'>
                    <img src={props.data.thumbnail_image ? props.data.thumbnail_image : defaultImage} />
                </div>
                <div className='title-size-wrapper'>
                    <div className='upsale-item-title'>
                        {props.data.name}
                    </div>
                    <div className='upsale-item-size'>
                        {props.data.size}
                    </div>
                </div>
            </div>

            <div className='upsale-item-description'>
                {props.data.description}
            </div>
            <div className='upsale-buttons'>
                <div className='incremental-upsale-buttons'>
                    <button
                        className='minus-sign'
                        onClick={() => updateQuantity('minus')} />
                    <span>{quantity}</span>
                    <button
                        className='plus-sign'
                        onClick={() => updateQuantity('plus')} />
                </div>
                <div className='add-button'>
                    <button
                        onClick={handleAddition}>
                        {t('upsale.add')}
                    </button>
                </div>
            </div>

            <div className="upsale-messages">
                {successUpdate && <div className='upsale-succ-message'>
                    {t('upsale.success')}
                </div>
                }
                {failedUpdae && <div className='upsale-err-message'>
                    {t('upsale.failed')}
                </div>
                }
            </div>
            {props.includeHr && <hr></hr>}
        </div>
    );
};





export default UpsaleItem;

import React, { useEffect, useState, useRef } from 'react';
import { Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from '../axios-instance_baseURL';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Route } from 'react-router';

// import defaultImage from '../assets/images/logos/image1024x375.png';
import defaultImage from '../assets/images/logos/WebCover1.jpg';
import { get_restaurant_menu_config, get_restaurant_details_config, create_basket_dinein_pickup_config } from '../assets/configs/config_calls';
import { get_restaurant_menu_url, restaurant_details_url, create_basket_url } from '../assets/urls/urls';
import { save_basket_data } from '../store/actions/basketActions';
import locationIcon from '../assets/images/icons/Location Icon new.png';
import pickupIcon from '../assets/images/icons/Icon.png';
import dineinIcon from '../assets/images/icons/Group 4887.png';
import deliveryIcon from '../assets/images/icons/pickup-car.png';
import timeIcon from '../assets/images/icons/XMLID_806_.png';
import approxIcon from '../assets/images/icons/is-approximately-equal-to.png';
import MenuItems from './MenuItems';
import Spinner from '../UI/Spinner';
import MapModal from './MapModal';
import Backdrop from '../UI/Backdrop';
import Auxilary from '../hoc/Auxilary';


const RestaurantDetails = props => {
    const { t, i18n } = useTranslation();
    const accessToken = useSelector(state => state.auth.accessToken);
    const long = useSelector(state => state.auth.lat);
    const lat = useSelector(state => state.auth.long);
    const [restDetails, setRestDetails] = useState();
    const [imgError, setImgError] = useState(false);
    // const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errMessage, setErrMessage] = useState();
    const [restBasicData, setRestBasicData] = useState();
    const [showMore, setShowMore] = useState(false);
    const imgRef = useRef();
    const basketData = useSelector(state => state.basket.basketData);
    const [baskeHasData, setBasketHasData] = useState(true);
    const [modalBasketChange, setModalBasketChange] = useState(false);
    const [modalBasketChangeOnClick, setModalBasketChangeOnClick] = useState(false);
    const [changingModeType, setChangingModeType] = useState();
    const [closedRestaurant, setClosedRestaurant] = useState(false);
    const [viewRestClosed, setViewRestClosed] = useState({
        id: null,
        type: null
    });
    const dispatch = useDispatch();


    const createBasketFunction = (id, type) => {
        const config = create_basket_dinein_pickup_config(type, id, accessToken, i18n.language);
        axios.post(create_basket_url, { ...config.body }, { params: {}, headers: config.headers })
            .then(response => {
                if (response.data.status === true) {
                    dispatch(save_basket_data(response.data));
                    // props.history.push({ pathname: '/restaurant/details/' + id, opened: true, mode: "delivery" });
                    getRestInfosFunction(id, type, false);
                } else {
                    setErrMessage(response.data.message);
                    setLoading(false);
                }

            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setErrMessage('homepage.errorMsg');
                setLoading(false);
            });
    }

    const getRestInfosFunction = (id, mode, basic = true) => {
        const config = get_restaurant_menu_config(props.match.params.id, mode, i18n.language, accessToken)
        axios.get(get_restaurant_menu_url, { params: {}, headers: config.headers })
            .then(response => {
                if (response.data.status) {
                    setRestDetails(response.data);
                } else {
                    if (response.data.code === 1001) {
                        setErrMessage(response.data.message);
                    }
                }
                setLoading(false);
            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setLoading(false);
                setErrMessage('homepage.errorMsg')
            });
        if (basic) {
            let configs = get_restaurant_details_config(accessToken ? accessToken : "", i18n.language);
            let callurl = restaurant_details_url + props.match.params.id + '?latitude=' + lat + '&longitude=' + long;
            axios.get(callurl, { params: {}, headers: configs.headers })
                .then((response => {
                    if (response.data.status) {
                        setRestBasicData(response.data.restaurant);
                    }
                    setLoading(false);
                }))
                .catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }
                    setLoading(false);
                });
        }
    }

    const newRestaurantHandler = (type = "delivery") => {
        let configs = get_restaurant_details_config(accessToken ? accessToken : "", i18n.language);
        let callurl = restaurant_details_url + props.match.params.id + '?latitude=' + lat + '&longitude=' + long;
        axios.get(callurl, { params: {}, headers: configs.headers })
            .then((response => {
                if (response.data.status) {
                    let item = response.data.restaurant;

                    if (!item.is_open) {
                        if (!item.today_open_hour || !item.today_open_hour.open_at) {
                            // setOpenClicked(true);
                            // setTimeout(() => {
                            //     setOpenClicked(false);
                            // }, 3000);
                            setLoading(false);
                            setClosedRestaurant(true);
                            setViewRestClosed({
                                id: props.match.params.id,
                                type: type,
                                data: response.data.restaurant
                            });
                            return;
                        }
                        if (item.today_open_hour.open_at === "-") {
                            // setErrMessage('homepage.closed_message')
                            // setLoading(false);
                            setLoading(false);
                            setClosedRestaurant(true);
                            setViewRestClosed({
                                id: props.match.params.id,
                                type: type,
                                data: response.data.restaurant
                            });
                            // setRestBasicData(response.data.restaurant);
                            // createBasketFunction(props.match.params.id, type);
                            return;
                        }
                        const openHour = parseInt(item.today_open_hour.open_at.split(":")[0]);
                        const openMinutes = parseInt(item.today_open_hour.open_at.split(":")[1]);
                        let currentHour = new Date().getHours();
                        let currentMinutes = new Date().getMinutes();
                        if (openHour < currentHour) {
                            // setErrMessage('homepage.closed_message')
                            // setLoading(false);
                            setLoading(false);
                            setClosedRestaurant(true);
                            setViewRestClosed({
                                id: props.match.params.id,
                                type: type,
                                data: response.data.restaurant
                            });
                            // setRestBasicData(response.data.restaurant);
                            // createBasketFunction(props.match.params.id, type);
                        } else if (openHour === currentHour) {
                            if (openMinutes <= currentMinutes) {
                                // setErrMessage('homepage.closed_message')
                                // setLoading(false);
                                setClosedRestaurant(true);
                                setLoading(false);
                                setViewRestClosed({
                                    id: props.match.params.id,
                                    type: type,
                                    data: response.data.restaurant
                                });
                                // setRestBasicData(response.data.restaurant);
                                // createBasketFunction(props.match.params.id, type);
                            } else {
                                setRestBasicData(response.data.restaurant);
                                createBasketFunction(props.match.params.id, type);
                            }
                        } else {
                            setRestBasicData(response.data.restaurant);
                            createBasketFunction(props.match.params.id, type);
                        }
                    } else {
                        setRestBasicData(response.data.restaurant);
                        createBasketFunction(props.match.params.id, type);
                    }
                    setLoading(false);
                    // }

                } else {
                    setErrMessage(response.data.message);
                    setLoading(false);
                }
            }))
            .catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setErrMessage('homepage.errorMsg')
                setLoading(false);
            });
    }
    const changeModeOnButtonClick = (type) => {
        if (type === basketData.order_mode) return;
        if (type !== basketData.order_mode && (basketData.products.length > 0 || basketData.upsale?.length > 0)) {
            setModalBasketChangeOnClick(true);
            setChangingModeType(type);
            return;
        }
        setLoading(true);
        newRestaurantHandler(type);
    }

    useEffect(() => {
        if (props.match.params.id && props.location.mode) {
            setLoading(true);
            getRestInfosFunction(props.match.params.id, basketData.order_mode);
        } else if (!basketData) {
            setLoading(true);
            newRestaurantHandler();
        } else if (basketData && basketData.restaurant_id === props.match.params.id) {
            setLoading(true);
            getRestInfosFunction(props.match.params.id, basketData.order_mode);
        } else if (basketData && basketData.restaurant_id !== props.match.params.id && basketData.products.length === 0 && basketData.upsale?.length === 0) {
            setLoading(true);
            newRestaurantHandler();
        } else if (basketData && basketData.restaurantId !== props.match.params.id && (basketData.products.length > 0 || basketData.upsale?.length > 0)) {
            setModalBasketChange(true);
        }

    }, [i18n.language]);

    let content = closedRestaurant ? <div style={{ minHeight: "800px" }}></div> : <div style={{ minHeight: "100vh" }}><div className='restaurant-detail-wrapper'> <Spinner logo spinnerStyle="SpinnerYellow" /> </div></div>;
    const delivery = t("post.delivery");
    const pickUp = t("post.pickUp");
    const dineIn = t("post.dineIn");
    if (!props.location.mode && !baskeHasData && !basketData) content = <Redirect to={'/restaurant/mode/' + props.match.params.id} />
    if (restDetails && !loading && restBasicData) {
        content = (
            <div className='restaurant-detail-wrapper'>
                <div >
                    <img alt='restaurant-menu-web-offer-backgroud' ref={imgRef} style={{ display: "none" }} src={restDetails.menu.web_offer_image} onError={() => setImgError(true)} />
                    <div
                        className='offer-image'
                        style={{ backgroundImage: "url(" + (imgError ? defaultImage : restDetails.menu.web_offer_image) + ")" }}
                    >
                        <Row className='m-0 restaurant-details-part'>
                            <Col xs='12' className='title'>
                                {restBasicData.name}
                                <div className='tags'>
                                    {
                                        restBasicData.type_tags.split(', ').map((item, index) => {
                                            return <div className='restaurant-details-tags' key={item}>{item} {
                                                restBasicData.type_tags.split(', ').length !== index + 1 ?
                                                    <span className='dot'></span>
                                                    : null
                                            } </div>
                                        })
                                    }
                                </div>
                            </Col>
                            <Col xs='12' >
                                <div className='delivery-time'>
                                    <img alt='time-icon' src={timeIcon} />
                                    {t("post.avrg-time")} <img alt='approximate-icon' src={approxIcon}></img> {restBasicData.average_delivery_time} {t("post.min")}
                                </div>
                            </Col>
                            {/* <Col xs='12' className='delivery-fee'>
                                <div>
                                    {t("post.delivery-fee")}
                                    <span>{props.location.data.delivery_fee} {t("post.currency")}</span>
                                </div>
                            </Col> */}
                            <Col xs='12' className='delivery-types pr-0' >
                                <div>
                                    {restBasicData.is_pickup ?
                                        <button onClick={() => changeModeOnButtonClick("pickup")} className={basketData.order_mode === 'pickup' ? 'activeButton' : ""}>
                                            <img alt='pickup-icon' src={pickupIcon} />{pickUp}
                                        </button> : null}
                                    {restBasicData.is_delivery ?
                                        <button onClick={() => changeModeOnButtonClick("delivery")} className={basketData.order_mode === 'delivery' ? 'activeButton' : ""}>
                                            <img alt='delivery-icon' src={deliveryIcon} />{delivery}
                                        </button> : null}
                                    {restBasicData.is_dinein ?
                                        <button onClick={() => changeModeOnButtonClick("dinein")} className={basketData.order_mode === 'dinein' ? 'activeButton' : ""}>
                                            <img alt='dinein-icon' src={dineinIcon} />{dineIn}
                                        </button> : null}
                                </div>
                            </Col>
                            <Col xs='12' className='address'>
                                <img alt='location-icon' src={locationIcon} />
                                {restBasicData.address} -
                                <button onClick={() => props.history.push(props.match.url + '/more')}>{t("post.more")}</button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='restaurant-menu-wrapper'>
                    <MenuItems
                        match={props.match}
                        history={props.history}
                        restaurantId={props.match.params.id}
                        deliveryTime={restBasicData.average_delivery_time}
                        mode={basketData.order_mode}
                        data={restDetails.menu.menu_categories}
                        menuId={restDetails.menu.id} />
                </div>
                {/* {showMore ? */}
                <Route path={props.match.url + '/more'}
                    component={(props) =>
                        <Auxilary>
                            <Backdrop address className='backdrop' show={true} backdropClicked={() => props.history.replace(props.match.url.replace('/more', ''))} />
                            <MapModal data={restBasicData} handleCloseMenuModal={() => props.history.replace(props.match.url.replace('/more', ''))} />
                        </Auxilary>
                    } />

                {/* : null} */}
                {modalBasketChangeOnClick ?
                    <Auxilary>
                        <div className='modal-basket-change'>
                            <div className="description">
                                {t('restaurant_mode.change_basket')}
                            </div>
                            <div className='buttons'>
                                <button onClick={() => {
                                    setModalBasketChangeOnClick(false);
                                    setChangingModeType();

                                }}> {t('restaurant_mode.cancel')} </button>
                                <button onClick={() => {
                                    setModalBasketChangeOnClick(false);
                                    setLoading(true);
                                    newRestaurantHandler(changingModeType);
                                }}> {t('restaurant_mode.ok')} </button>
                            </div>

                        </div>
                        <Backdrop address className='backdrop' show={modalBasketChangeOnClick} backdropClicked={() => { setModalBasketChangeOnClick(false); }} />
                    </Auxilary>

                    : null
                }

            </div>
        )
    } else if (errMessage) {
        content = <div className='restaurant-detail-wrapper' style={{ textAlign: "center" }}> {t(errMessage)} </div>
    } else if (modalBasketChange) {
        content = <div style={{ height: "100vh" }}>
            <div className='modal-basket-change'>
                <div className="description">
                    {t('restaurant_mode.change_basket')}
                </div>
                <div className='buttons'>
                    <button onClick={() => props.history.replace('/')}> {t('restaurant_mode.cancel')} </button>
                    <button onClick={() => {
                        setModalBasketChange(false);
                        newRestaurantHandler();
                    }}> {t('restaurant_mode.ok')} </button>
                </div>

            </div>
            <Backdrop address className='backdrop' show={modalBasketChange} backdropClicked={() => { }} />

        </div>
    }

    return (
        <Auxilary>
            {closedRestaurant ?
                <Auxilary>
                    <div className='modal-basket-change'>
                        <div className="description">
                            {t('restaurant_mode.closed_restaurant')}
                        </div>
                        <div className='buttons'>
                            <button onClick={() => props.history.push('/')}> {t('restaurant_mode.cancel')} </button>
                            <button onClick={() => {
                                setClosedRestaurant(null);
                                setLoading(true);
                                setRestBasicData(viewRestClosed.data);
                                createBasketFunction(viewRestClosed.id, viewRestClosed.type);
                            }}> {t('restaurant_mode.ok')} </button>
                        </div>

                    </div>
                    <Backdrop address className='backdrop' show={closedRestaurant} backdropClicked={() => { }} />
                </Auxilary>
                : null
            }
            {  content}
        </Auxilary>

    )
}

export default RestaurantDetails;



 // const refToImage = useRef();
    // const [width, setWidth] = useState();
    // const [mounted, setMounted] = useState(false);
    // before removing order mode page
    // useEffect(() => {

    //     if (props.match.params.id && props.location.mode) {
    //         const config = get_restaurant_menu_config(props.match.params.id, props.location.mode, i18n.language, accessToken)
    //         axios.get(get_restaurant_menu_url, { params: {}, headers: config.headers })
    //             .then(response => {
    //                 if (response.data.status) {
    //                     setRestDetails(response.data);
    //                 } else {
    //                     if (response.data.code === 1001) {
    //                         setErrMessage(response.data.message);
    //                     }
    //                 }
    //                 setLoading(false);
    //             }).catch(err => {
    //                 setLoading(false);
    //                 setErrMessage('homepage.errorMsg')
    //             });

    //         let configs = get_restaurant_details_config(accessToken ? accessToken : "", i18n.language);
    //         let callurl = restaurant_details_url + props.match.params.id + '?latitude=' + lat + '&longitude=' + long;
    //         axios.get(callurl, { params: {}, headers: configs.headers })
    //             .then((response => {
    //                 if (response.data.status) {
    //                     setRestBasicData(response.data.restaurant);
    //                 }
    //             }))
    //             .catch(err => {
    //             })

    //     } else {
    //         if (!basketData) {
    //             setTimeout(() => {
    //                 if (!basketData) setBasketHasData(false);
    //             }, 2000);
    //         } else {

    //             if (basketData.restaurant_id !== props.match.params.id) {
    //                 props.history.replace("/restaurant/mode/" + props.match.params.id);
    //                 return;
    //             }
    //             // if (props.history.location.pathname !== '/restaurant/details/' + props.match.params.id) {
    //             //     props.history.replace("/restaurant/details/" + props.match.params.id);
    //             // }

    //             const config = get_restaurant_menu_config(basketData.restaurant_id, basketData.order_mode, i18n.language, accessToken)
    //             axios.get(get_restaurant_menu_url, { params: {}, headers: config.headers })
    //                 .then(response => {
    //                     if (response.data.status) {
    //                         setRestDetails(response.data);
    //                     } else {
    //                         if (response.data.code === 1001) {
    //                             setErrMessage(response.data.message);
    //                         }
    //                     }
    //                     setLoading(false);
    //                 }).catch(err => {
    //                     setLoading(false);
    //                     setErrMessage('homepage.errorMsg')
    //                 });

    //             let configs = get_restaurant_details_config(accessToken ? accessToken : "", i18n.language);
    //             let callurl = restaurant_details_url + props.match.params.id + '?latitude=' + lat + '&longitude=' + long;
    //             axios.get(callurl, { params: {}, headers: configs.headers })
    //                 .then((response => {
    //                     if (response.data.status) {
    //                         setRestBasicData(response.data.restaurant);
    //                     }
    //                 }))
    //                 .catch(err => {
    //                 })
    //         }
    //     }

    // }, [i18n.language, basketData]);



    // const handleScroll = (e) => {
    //     const elem = refToImage;
    //     setWidth(elem.current.clientWidth);
    //     // const lastLioffset = elem.offsetTop + elem.clientHeight;
    //     // const pageOffset = window.pageYOffset + window.innerHeight;
    //     // var bottomoffset = 300;
    //     // if (pageOffset > lastLioffset - bottomoffset) {
    //     //     setScrolling(true);
    //     // }
    // }
    // useEffect(() => {
    //     if (mounted && refToImage.current) {
    //         setWidth(refToImage.current.clientWidth);
    //     } else {
    //         setMounted(true);
    //    }
    // }, [mounted, refToImage.current])
    // useEventListener('resize', handleScroll);
    // const heightStyle = width ? width / (width > 675 ? 2 : 1) : null;
    // let restDetailsImage = width > 675 ? defaultImage : defaultImage2 ;

// function useEventListener(eventName, handler, element = window) {

//     const savedHandler = useRef();

//     useEffect(() => {
//         savedHandler.current = handler;
//     }, [handler]);

//     useEffect(
//         () => {

//             const isSupported = element && element.addEventListener;
//             if (!isSupported) return;

//             const eventListener = event => savedHandler.current(event);

//             element.addEventListener(eventName, eventListener, true);


//             return () => {
//                 element.removeEventListener(eventName, eventListener, true);
//             };
//         },
//         [eventName, element]
//     );
// };
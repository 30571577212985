import React, { useState, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import axios from '../axios-instance_baseURL';
import FacebookLogin from '../components/FacebookLogin';
import { isMobile, osName, osVersion, isAndroid, isWinPhone, isIOS } from "react-device-detect";

import Header from '../components/Header/Header';
import Spinner from '../UI/Spinner';
import Input from '../UI/Input';
import checkImg from '../assets/images/icons/Check On.svg';
import Footer from '../components/Footer';
import { register_url, user_profile_url } from '../assets/urls/urls';
import { register_confing, user_profile_config } from '../assets/configs/config_calls';
import { setUserData } from '../store/actions/authActions';
import BasketButton from '../UI/BasketButton';

const VALIDITY_CHANGE = "VALIDITY_CHANGE"

const reducer = (state, action) => {
    if (action.type === VALIDITY_CHANGE) {
        if (state.validElements[action.elemName] === false && action.elemValidity) {
            const copy = { ...state.validElements };
            copy[action.elemName] = action.elemValidity;
            let formValid = true;
            for (let item in { ...copy }) {

                if (copy[item] === false) {
                    formValid = false;
                    break;
                }
            }
            const values = { ...state.elementsValues };
            values[action.elemName] = action.elemValue;
            return {
                ...state,
                validElements: { ...copy },
                elementsValues: { ...values },
                formValid: formValid
            }
        } else if (state.validElements[action.elemName] && action.elemValidity === false) {
            const copy = { ...state.validElements };
            copy[action.elemName] = action.elemValidity;
            return {
                ...state,
                validElements: { ...copy },
                formValid: false
            }
        } else if (state.validElements[action.elemName] && action.elemValidity) {
            const copy = { ...state.validElements };
            copy[action.elemName] = action.elemValidity;
            let formValid = true;
            for (let item in { ...copy }) {

                if (copy[item] === false) {
                    formValid = false;
                    break;
                }
            }
            const values = { ...state.elementsValues };
            values[action.elemName] = action.elemValue;
            return {
                ...state,
                validElements: { ...copy },
                elementsValues: { ...values },
                formValid: formValid
            }
        } else {
            return state;
        }

    }
}

const Register = (props) => {
    const { t, i18n } = useTranslation();
    const [formData] = useState({
        companyEmail: {
            elemType: "input",
            placeholder: 'inputs.placeholders.email',
            desc: 'inputs.descriptions.email',
            type: "email",
            name: "companyEmail",
            valid: false,
            value: "",
            validationMessage: 'inputs.errorMessages.email',
            succesMessage: 'inputs.inputValidMessage',
            typing: false,
            rules: {
                email: true,
                required: true
            },
        },
        password: {
            elemType: "input",
            placeholder: 'inputs.placeholders.password',
            desc: 'inputs.descriptions.password',
            type: "password",
            name: "password",
            valid: false,
            value: "",
            validationMessage: 'inputs.errorMessages.password',
            succesMessage: 'inputs.inputValidMessage',
            typing: false,
            changeAble: true,
            rules: {
                // passwordTriximi: true,
                minLength: 8,
                required: true
            },
        },
        name: {
            elemType: "input",
            placeholder: 'inputs.placeholders.name',
            desc: 'inputs.descriptions.name',
            type: "input",
            name: "name",
            valid: false,
            value: "",
            validationMessage: 'inputs.errorMessages.name',
            succesMessage: 'inputs.inputValidMessage',
            typing: false,
            rules: {
                required: true,
                minLength: 2
            },
        },
        surname: {
            elemType: "input",
            placeholder: 'inputs.placeholders.last_name',
            desc: 'inputs.descriptions.last_name',
            type: "input",
            valid: false,
            name: "surname",
            value: "",
            validationMessage: 'inputs.errorMessages.last_name',
            succesMessage: 'inputs.inputValidMessage',
            typing: false,
            rules: {
                required: true,
                minLength: 2
            },
        },
        phone: {
            elemType: "input",
            placeholder: '07XXXXXXX',
            desc: 'inputs.descriptions.phone',
            type: "input",
            valid: false,
            name: "phone",
            value: "",
            validationMessage: 'inputs.errorMessages.phone',
            succesMessage: 'inputs.inputValidMessage',
            typing: false,
            rules: {
                required: true,
                isNumeric: true,
                maxLength: 9,
                minLength: 9
            },
        },
        date: {
            elemType: "datePicker",
            placeholder: 'DD/MM/YYYY',
            desc: 'inputs.descriptions.date',
            valid: true,
            value: "",
            rules: {},
        },
    });
    const [emailAccept, setEmailAccept] = useState(false);
    const [termsAccept, setTermAccept] = useState(false);
    const [birthDate, setBirthDate] = useState();
    const long = useSelector(state => state.auth.long);
    const lat = useSelector(state => state.auth.lat);

    const [validations, dispatchValidations] = useReducer(reducer, {
        validElements: {
            companyEmail: false,
            password: false,
            name: false,
            surname: false,
            phone: false
        },
        elementsValues: {
            companyEmail: "",
            password: "",
            name: "",
            surname: "",
            phone: ""
        },
        formValid: false
    });

    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        // if (validations.formValid && termsAccept)
        if (validations.formValid) {
            setLoading(true);
            let day = null;
            let month = null;
            let year = null;
            if (birthDate) {
                day = birthDate.getDate();
                month = birthDate.getMonth() + 1;
                year = birthDate.getFullYear();
            }
            let osType = 'Web'
            if (isMobile) {
                if (isAndroid) osType = 'Android';
                if (isIOS) osType = 'iPhone';
                if (isWinPhone) osType = 'WinPhone';
            }
            let config = register_confing(
                validations.elementsValues.companyEmail,
                validations.elementsValues.password,
                validations.elementsValues.name,
                validations.elementsValues.surname,
                validations.elementsValues.phone,
                day,
                month,
                year,
                emailAccept ? true : false,
                i18n.language,
                parseFloat(lat),
                parseFloat(long),
                osType,
                osName,
                osVersion
            );

            axios.post(register_url, {}, {
                params: {}, headers: config.headers, data: { ...config.body }
            }).then(response => {
                if (response.data.status) {
                    let configUser = user_profile_config(response.data["x-access-token"], i18n.language);
                    const accesToken = response.data["x-access-token"];
                    axios.get(user_profile_url, {
                        params: {}, headers: configUser.headers
                    }).then(response => {
                        if (response.data.status) {
                            dispatch(setUserData(response.data, accesToken));
                            props.history.push("/");
                        } else {
                            setErrMsg(response.data.message);
                        }
                        setLoading(false);
                    }).catch(err => {
                        if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                            props.history.push({
                                pathname: '/settings/logout',
                                err: "unauthenticated",
                            });
                            return;
                        }
                        setErrMsg('homepage.errorMsg');
                        setLoading(false);
                    })
                } else {
                    setErrMsg(response.data.message);
                    setLoading(false);
                }
            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setErrMsg('homepage.errorMsg');
            })
        }
    };

    const validChangeHandler = (elemName, elemValidity, elemValue) => {
        dispatchValidations({ type: VALIDITY_CHANGE, elemName: elemName, elemValidity: elemValidity, elemValue: elemValue })
    }

    // let formValid = (validations.formValid && termsAccept);
    let formValid = (validations.formValid);
    return (
        <div className='wrapper'>
            <Row className="justify-content-center align-content-center text-center m-0 ">
                {/* <Col xs="12" className='p-0 '> <Logo classNames='logo' /> </Col> */}
                <Col xs="12" className='p-0 mb-5'>   <Header settings history={props.history} headerHomepageOnly />   </Col>
                <Col xs="12 register-form p-md-0" lg='12'>
                    <div className='title'>{t("register.lets_start")}</div>
                    <form onSubmit={() => handleSubmit}>

                        <Row className='m-0'>
                            <Input
                                className='p-0 col-height pr-md-4'
                                sm="12"
                                md='6'
                                data={formData.companyEmail}
                                notifyHandler={validChangeHandler}
                            />
                            <Input
                                className='p-0 col-height pl-md-4'
                                sm="12"
                                md='6'
                                data={formData.password}
                                notifyHandler={validChangeHandler}
                            />
                        </Row>
                        <Row className='m-0'>
                            <Input
                                className='p-0 col-height pr-md-4'
                                sm="12"
                                md='6'
                                data={formData.name}
                                notifyHandler={validChangeHandler}
                            />
                            <Input
                                className='p-0 col-height pl-md-4'
                                sm="12"
                                md='6'
                                data={formData.surname}
                                notifyHandler={validChangeHandler}
                            />

                        </Row>
                        <Row className='m-0'>
                            <Input
                                className='p-0 col-height pr-md-4'
                                sm="12"
                                md='6'
                                data={formData.phone}
                                notifyHandler={validChangeHandler}
                            />
                            <Input
                                className='p-0 col-height pl-md-4'
                                sm="12"
                                md='6'
                                data={formData.date}
                                notifyHandler={validChangeHandler}
                                date={birthDate}
                                setDateFunc={(date) => setBirthDate(date)}
                            />
                            {/* <Input
                                        className='p-0 col-height'
                                        sm="12"
                                        data={formData.name}
                                        notifyHandler={validChangeHandler}
                                    /> */}
                        </Row>
                        <div className='policy-rules first-rule'>
                            {/* {termsAccept ? <img alt='check-img' src={checkImg} onClick={() => setTermAccept(false)} />
                                : <button className='empty-square' onClick={() => setTermAccept(true)}> </button>} */}
                            <div className='text'>
                                {t('login.accept_terms')}
                                <a href='https://naracaj.com/terms_of_use/' target="_blank" rel="noopener noreferrer">{t('login.terms')}</a>
                                {t('login.and')}
                                <a href='https://naracaj.com/privacy_policy/' target="_blank" rel="noopener noreferrer">{t('login.privacy')} </a>
                            </div>
                        </div>
                        <div className='policy-rules '>
                            {emailAccept ? <img alt='check-img' src={checkImg} onClick={() => setEmailAccept(false)} />
                                : <button className='empty-square' onClick={() => setEmailAccept(true)}> </button>}
                            <div className='text'>{t('login.accept_email')}</div>
                        </div>
                      
                        {/* {loading ? <div style={{ height: "45px" }}> <Spinner spinnerStyle="SpinnerYellow SpinnerButton" /></div> */}
                        <button className="submit-button" disabled={!formValid} onClick={handleSubmit} >{t('register.register_button')}</button>

                        <div className='facebook-button'><FacebookLogin history={props.history} /></div>
                        {errMsg ? (
                            <Col xs="12">
                                <label className="span-style">{t(errMsg)}</label>
                            </Col>
                        ) : null}
                        <div className='new-create'>
                            <span>{t('register.already')}</span>
                        </div>
                        <div className='register-login-back'>
                            <button onClick={() => props.history.push("/login")}>{t('register.login')}</button>
                        </div>
                    </form>
                </Col>
                {loading ?
                    <div className='loading-data-modal' >
                        <Spinner logo spinnerStyle="SpinnerYellow" />;
                         </div> : null
                }
                {/* <Col xs='12'>
                    <Languages classess="languages" />
                </Col> */}
            </Row>
            <BasketButton history={props.history} />
            <Footer />
        </div>
    );
};

export default Register;

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import mkFlag from "../assets/images/flags/mk-flag.svg";
import ukFlag from "../assets/images/flags/uk-flag.svg";
import alFlag from "../assets/images/flags/albania.svg";
import iconEmail from "../assets/images/icons/mail.svg";
import iconPhone from "../assets/images/icons/iconPhone@1x.svg";
import Auxilary from "../hoc/Auxilary";

const Languages = (props) => {
  const { i18n, t } = useTranslation();
  const bannerText = useSelector((state) => state.auth.bannerText);
  const setLanguage = (lng) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
  };
  return (
    <Auxilary>
      {!props.headerHomepageOnly ? (
        <div
          className={
            bannerText === ""
              ? props.classess
              : props.classess + " languages-banner"
          }
        >
          <div
            className={props.infoOnly ? "header-infos-auth" : "header-infos"}
          >
            <span>
              {" "}
              <a
                href="https://info.naracaj.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                {t("homepage.about-us")}{" "}
              </a>{" "}
            </span>
            <span>
              <img alt="phone-icon" src={iconPhone} /> 075/815-888
            </span>
            <span>
              <img alt="email-icon" src={iconEmail} /> contact@naracaj.com
            </span>
          </div>
          {!props.infoOnly ? (
            <Auxilary>
              <div>
                {" "}
                <img
                  alt="mk-flag"
                  onClick={() => setLanguage("mk")}
                  src={mkFlag}
                />{" "}
              </div>
              <div>
                {" "}
                <img
                  alt="al-flag"
                  onClick={() => setLanguage("sq")}
                  src={alFlag}
                />{" "}
              </div>
              <div>
                {" "}
                <img
                  alt="uk-flag"
                  onClick={() => setLanguage("en")}
                  src={ukFlag}
                />{" "}
              </div>
            </Auxilary>
          ) : null}
          {/* <div> <img onClick={() => setLanguage("mk")} alt='mk flag' src={mkFlag} /> </div>
             <div> <img onClick={() => setLanguage("sq")} alt='al flag' src={alFlag} /> </div>
             <div> <img onClick={() => setLanguage("en")} alt='uk flag' src={ukFlag} /> </div> */}
        </div>
      ) : null}
    </Auxilary>
  );
};

export default Languages;

import React from "react";
const backdrop = (props) => {
    let content = null;
    if (props.show && props.menu) {
        content = <div className={props.className} onClick={props.backdropClicked}></div>
    } else if (props.show && props.address){
        content = <div className={props.className} onClick={props.backdropClicked}></div>
    } else if (props.show) {
        content = <div className={props.className ? props.className + " backdrop desktop-hide" : 'backdrop desktop-hide'} onClick={props.backdropClicked}></div>
    }
    return (
      content
    )
}

export default backdrop;

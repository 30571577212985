import React, { useState, useRef } from 'react';

import DatePicker from 'react-datepicker';
import arrowIcon from '../assets/images/icons/arrow.svg';
import Aux from '../hoc/Auxilary';
import BackDrop from '../UI/Backdrop';
const DatePickerSettings = props => {

    const [focused, setFocused] = useState(false);

    const datepickerRef = useRef();
    const buttonRef = useRef();
    const clickHandler = () => {
        if (!focused) {
            datepickerRef.current.deferFocusInput();
            setFocused(true);
        } else {
            setFocused(false);
        }
    }
    const onSelect = () => {
        setFocused(false);
        datepickerRef.current.cancelFocusInput();
    }
    const outsideClicked = () => {
        setFocused(false);
        datepickerRef.current.cancelFocusInput();
    }
    return (
        <Aux>
            <div className='update-datepicker'>
                
                <DatePicker
                    placeholderText=""
                    selected={props.date}
                    onChange={props.setDateFunc}
                    dateFormat="dd MMMM, yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={new Date()}
                    ref={datepickerRef}
                    onSelect={onSelect}
                    onBlur={outsideClicked}
                />
            </div>
            <button ref={buttonRef} onClick={clickHandler}  >
                <img alt='arrow-icon' src={arrowIcon} />
            </button>
            <BackDrop className='menu-backdrop' menu={true} show={focused} backdropClicked={outsideClicked} />
        </Aux>
    )
}

export default DatePickerSettings
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AddIcon from "../assets/images/icons/plus.svg";
import CloseIcon from "../assets/images/icons/close.svg";
import AddressAdd from "./AddressAdd";
import { AppContext } from "../context/app-context";

const AddressPost = (props) => {
  const { handleZip } = React.useContext(AppContext);
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  return props.adding ? (
    <Col xs="12" className="p-0 mb-4 post-col-wrapper" lg="6" xl="4">
      <Row className="m-0 post-address-row" style={{ cursor: "pointer" }}>
        <div className="add-address" onClick={() => setModalOpen(true)}>
          {" "}
          <img alt="add-icon" src={AddIcon}></img>
        </div>
      </Row>
      <AddressAdd
        orderMode={props.orderMode}
        adding
        refreshAddresses={props.refreshAddresses}
        createBasketDelivery={props.createBasketDelivery}
        setModalOpen={(value) => setModalOpen(value)}
        modalOpen={modalOpen}
      />
    </Col>
  ) : (
    <Col xs="12" className="p-0 mb-4 post-col-wrapper" lg="6" xl="4">
      <Row
        className={
          props.data.is_default
            ? "default-address-class m-0 post-address-row"
            : "m-0 post-address-row"
        }
        style={props.orderMode ? { cursor: "pointer" } : null}
        onClick={
          props.orderMode ? () => props.createBasketDelivery() : () => {}
        }
      >
        <Col className="p-0 address-name" xs="12">
          {props.data.name}
          {props.orderMode ? null : (
            <button
              className="delete-address"
              onClick={() => props.deleteAddress(props.data.id)}
            >
              <img alt="close-icon" src={CloseIcon}></img>
            </button>
          )}
        </Col>
        <Col className="p-0" xs="8">
          {t("inputs.descriptions.street")}
        </Col>
        <Col className="p-0" xs="4">
          {t("inputs.descriptions.number")}
        </Col>
        <Col className="p-0 bolder-text" xs="8">
          {props.data.address_street}
        </Col>
        <Col className="p-0 bolder-text" xs="4">
          {props.data.address_no}
        </Col>
        <Col className="p-0 bolder-text" xs="8">
          <div>{handleZip(props.data.city)}</div>
          {props.data.city}
        </Col>
        <Col className="p-0" xs="4">
          <div>{t("inputs.descriptions.apartment")}</div>
          <div className="bolder-text"> {props.data.address_apt_no}</div>
        </Col>
        {props.orderMode ? null : (
          <Col className="p-0 text-right address-buttons" xs="12">
            <button onClick={() => setModalOpen(true)}>
              {t("profile.change")}
            </button>
            <button
              className={
                props.data.is_default
                  ? "default-address-button"
                  : "default-address-button-normal"
              }
              onClick={() =>
                props.setDefault(props.data.id, !props.data.is_default)
              }
            >
              {t("address.primary")}
            </button>
          </Col>
        )}
      </Row>
      <AddressAdd
        name={props.data.name}
        address_apt_no={props.data.address_apt_no}
        address_street={props.data.address_street}
        address_no={props.data.address_no}
        user_fav_location_id={props.data.id}
        lat={props.data.latitude}
        long={props.data.longitude}
        is_default={props.data.is_default}
        setModalOpen={(value) => setModalOpen(value)}
        modalOpen={modalOpen}
        updateAddressData={props.updateAddressData}
        refreshAddresses={props.refreshAddresses}
        data
      />
    </Col>
  );
};
export default AddressPost;

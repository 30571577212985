import React, { useState, useEffect } from "react";
import axios from '../axios-instance_baseURL';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";

import btnCardBadge from '../assets/images/icons/btnCardBadge.svg';
import { basket_upsales_url, basket_url, restaurant_details_url } from '../assets/urls/urls';
import { getUpsaleConfig, get_basket_config, get_restaurant_details_config, promo_code_config } from '../assets/configs/config_calls';
import { save_basket_data } from '../store/actions/basketActions';
import OpenBasketData from '../components/OpenBasketData';
import Spinner from '../UI/Spinner';
import Auxilary from "../hoc/Auxilary";
import MenuModalOrder from '../components/MenuModalOrder';
import Backdrop from '../UI/Backdrop';
import { Route } from "react-router";
import ModalUpsale from "../components/ModalUpsale";

const BasketButton = props => {
    const basketData = useSelector(state => state.basket.basketData);
    const accessToken = useSelector(state => state.auth.accessToken);
    const [openBasket, setOpenBasket] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const long = useSelector(state => state.auth.lat);
    const lat = useSelector(state => state.auth.long);
    const [closedRestaurant, setClosedRestaurant] = useState(false);
    const [showModalUpsale, setShowModalUpsale] = useState(false);
    const [fetchedUpsaleData, setFetchedUpsaleData] = useState(null);
    const [upsaleContinueLoading, setUpsaleContinueLoading] = useState(false);
    const [upsaleEditing, setUpsaleEditingModal] = useState({
        editing: false,
        itemId: null
    });
    const [modalEditing, setModalEditing] = useState({
        opened: false,
        data: null,
        basketItemDetails: null,
        editingItemId: null
    });
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    let show = false;
    if (basketData && (basketData.products?.length > 0 || basketData.upsale?.length > 0)) {
        show = true;
    } else {
        if (openBasket === true) {
            setOpenBasket(false);
        }
    }
    useEffect(() => {

        if (basketData && basketData.discount_code.trim() !== "") {
            const config = promo_code_config(accessToken, i18n.language, basketData.discount_code);
            axios.delete(basket_url + basketData.id + "/discount", { data: { ...config.body }, headers: { ...config.headers } })
                .then(response => {
                    if (response.data.status) {
                        dispatch(save_basket_data(response.data));
                    } else {

                    }

                }).catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }
                })
        }
        return () => {
        }
    }, [])


    const removeRegularItem = (itemId) => {
        let config = get_basket_config(accessToken, i18n.language);
        setLoadingData(true);
        axios.delete(basket_url + basketData.id + "/products/" + itemId, { headers: config.headers })
            .then(response => {
                if (response.data.status) {
                    dispatch(save_basket_data(response.data));
                }
                setLoadingData(false);
            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setLoadingData(false);
            })
    }

    const removeUpsaleItem = (itemId) => {
        let config = getUpsaleConfig(accessToken, i18n.language);
        setLoadingData(true);
        axios.delete(basket_upsales_url + basketData.id + "/upsale",
            {
                params: {},
                headers: { ...config.headers },
                data: { upsale: [{ id: itemId, quantity: basketData.upsale.filter(item => item.id = itemId)[0].quantity }] }
            })
            .then(response => {
                if (response.data.status) {
                    dispatch(save_basket_data(response.data));
                    setFetchedUpsaleData(response.data?.upsale)
                }
                setLoadingData(false);
            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setLoadingData(false);
            })
    }

    const removeItemHandler = (itemId, upsale = false) => {
        if (!upsale) removeRegularItem(itemId);
        if (upsale) removeUpsaleItem(itemId);

    }

    const editRegularItem = (itemId) => {
        let basketItemDetails;
        for (let item in basketData.products) {
            if (basketData.products[item].id === itemId) {
                basketItemDetails = basketData.products[item];
            }
        }
        let config = get_basket_config(accessToken, i18n.language);
        setLoadingData(true);
        axios.get(basket_url + basketData.id + "/products/" + itemId + "/menu_item", { headers: config.headers })
            .then(response => {
                setModalEditing({
                    opened: true,
                    data: response.data.menu_item,
                    basketItemDetails: { ...basketItemDetails },
                    editingItemId: itemId
                });
                setLoadingData(false);
                // if (props.history.location.pathname !== '/restaurant/details/' + basketData.restaurant_id) {
                //     props.history.replace('/restaurant/details/' + basketData.restaurant_id + '/' + basketItemDetails.id)
                // } else {
                //     props.history.replace({
                //         pathname: '/restaurant/details/' + basketData.restaurant_id + '/' + basketItemDetails.id,
                //         scrollOff: true
                //     })
                // }


            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setLoadingData(false);
            })
    }

    const editUpsaleItem = (itemId) => {
        setUpsaleEditingModal({
            editing: true,
            itemId
        });
    }

    const editItemHandler = (itemId, upsale = false) => {
        if (!upsale) editRegularItem(itemId);
        if (upsale) editUpsaleItem(itemId);
    }

    useEffect(() => {
        if (modalEditing.opened === true) {
            if (props.history.location.pathname.charAt(0) === '/' && props.history.location.pathname.charAt(1) === '/') {
                setModalEditing({
                    opened: false,
                    data: null,
                    basketItemDetails: null,
                    editingItemId: null
                });
                return;
            }
            props.history.push({
                pathname: props.history.location.pathname === '/' ? (modalEditing.basketItemDetails.id) : (props.history.location.pathname + "/" + modalEditing.basketItemDetails.id),
                scrollOff: true
            });
        }

    }, [modalEditing.opened]);

    useEffect(() => {
        window.addEventListener("popstate", (event) => {
            setModalEditing({
                opened: false,
                data: null,
                basketItemDetails: null,
                editingItemId: null
            });
        });
        return () => {
            window.removeEventListener("popstate", (event) => { });
        }
    }, [])

    useEffect(() => {
        if (basketData && (basketData.products.length > 0 || basketData.upsale?.length > 0)) {
            let config = get_basket_config(accessToken, i18n.language);
            axios.get(basket_url + basketData.id, { params: {}, headers: config.headers })
                .then(response => {
                    if (response.data.status) {
                        dispatch(save_basket_data(response.data));
                    } else {
                    }

                }).catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }

                })
        }

    }, [i18n.language]);

    useEffect(() => {
        if (showModalUpsale) handleUpsale();
    }, [i18n.language])

    const handleUpsaleFinished = (upsaleContinue = false) => {
        let configs = get_restaurant_details_config(accessToken ? accessToken : "");
        let callurl = restaurant_details_url + basketData.restaurant_id + '?latitude=' + lat + '&longitude=' + long + '&search=&size=6&page=1';
        upsaleContinue ? setUpsaleContinueLoading(true) : setLoadingData(true);
        axios.get(callurl, { params: {}, headers: configs.headers })
            .then((response => {
                if (response.data.status) {
                    if (response.data.restaurant.is_open) {
                        // setRestaurantData(response.data);
                        //on success upsale
                        upsaleContinue ? setUpsaleContinueLoading(false) : setLoadingData(false);
                        setOpenBasket(false);
                        setShowModalUpsale(null);
                        setFetchedUpsaleData(null);
                        setUpsaleEditingModal({
                            editing: false,
                            itemId: null
                        });
                        if (!accessToken) {
                            props.history.push({
                                pathname: '/login',
                                mode: 'ordering'
                            })
                            return;
                        }
                        props.history.push({
                            pathname: "/order/main",
                            state: {
                                restaurantId: response.data.restaurant.id
                            }
                        });
                    } else {
                        let item = response.data.restaurant;
                        if (!item.is_open) {
                            if (item.today_open_hour.open_at === "-") {
                                setClosedRestaurant(true);
                                upsaleContinue ? setUpsaleContinueLoading(false) : setLoadingData(false);
                                if (upsaleContinue) setShowModalUpsale(false);
                                setTimeout(() => {
                                    setClosedRestaurant(false);
                                }, 3000);

                                return;
                            }
                            const openHour = parseInt(item.today_open_hour.open_at.split(":")[0]);
                            const openMinutes = parseInt(item.today_open_hour.open_at.split(":")[1]);
                            let currentHour = new Date().getHours();
                            let currentMinutes = new Date().getMinutes();
                            if (openHour < currentHour) {
                                setClosedRestaurant(true);
                                upsaleContinue ? setUpsaleContinueLoading(false) : setLoadingData(false);
                                if (upsaleContinue) setShowModalUpsale(false);
                                setTimeout(() => {
                                    setClosedRestaurant(false);
                                }, 3000);
                            } else if (openHour === currentHour) {
                                if (openMinutes <= currentMinutes) {
                                    setClosedRestaurant(true);
                                    upsaleContinue ? setUpsaleContinueLoading(false) : setLoadingData(false);
                                    if (upsaleContinue) setShowModalUpsale(false);
                                    setTimeout(() => {
                                        setClosedRestaurant(false);
                                    }, 3000);
                                } else {
                                    // setRestaurantData(response.data);
                                    setOpenBasket(false);
                                    setShowModalUpsale(null);
                                    setFetchedUpsaleData(null);
                                    setUpsaleEditingModal({
                                        editing: false,
                                        itemId: null
                                    })
                                    if (!accessToken) {
                                        props.history.push({
                                            pathname: '/login',
                                            mode: 'ordering'
                                        })
                                        return;
                                    }
                                    props.history.push({
                                        pathname: "/order/main",
                                        state: {
                                            restaurantId: response.data.restaurant.id
                                        }
                                    });
                                }
                            } else {
                                // setRestaurantData(response.data);
                                setOpenBasket(false);
                                setShowModalUpsale(null);
                                setFetchedUpsaleData(null);
                                setUpsaleEditingModal({
                                    editing: false,
                                    itemId: null
                                })
                                if (!accessToken) {
                                    props.history.push({
                                        pathname: '/login',
                                        mode: 'ordering'
                                    })
                                    return;
                                }
                                props.history.push({
                                    pathname: "/order/main",
                                    state: {
                                        restaurantId: response.data.restaurant.id
                                    }
                                });
                            }
                        } else {
                            // setRestaurantData(response.data);
                            setOpenBasket(false);
                            setShowModalUpsale(null);
                            setFetchedUpsaleData(null);
                            setUpsaleEditingModal({
                                editing: false,
                                itemId: null
                            })
                            if (!accessToken) {
                                props.history.push({
                                    pathname: '/login',
                                    mode: 'ordering'
                                })
                                return;
                            }
                            props.history.push({
                                pathname: "/order/main",
                            });
                        }
                        upsaleContinue ? setUpsaleContinueLoading(false) : setLoadingData(false);
                    }
                } else {
                    // setErrMsg(response.data.message)
                    upsaleContinue ? setUpsaleContinueLoading(false) : setLoadingData(false);
                    if (upsaleContinue) setShowModalUpsale(false);
                }

            }))
            .catch(err => {
                // setErrMsg('homepage.errorMsg')
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                upsaleContinue ? setUpsaleContinueLoading(false) : setLoadingData(false);
                if (upsaleContinue) setShowModalUpsale(false);
            })
    }


    const handleUpsale = () => {

        // if(basketData.upsale?.length!==0){
        //     handleUpsaleFinished();
        //     return;
        // }
        setLoadingData(true);
     
        // handleUpsaleFinished();
        let config = getUpsaleConfig(accessToken ? accessToken : "", i18n.language);
        axios.get(basket_upsales_url + basketData.id + "/upsale", { headers: config.headers })
            .then(response => {
                let products = [];
                if (response?.data?.groups) {
                    for (let item in response.data.groups) {
                        for (let itemProducts in response.data.groups[item].products) {
                            products.push({ ...response.data.groups[item].products[itemProducts] })
                        }
                    }

                }
                if (products.length !== 0) {
                    setShowModalUpsale(true);
                    setFetchedUpsaleData(products);
                    setLoadingData(false);
                } else {
                    handleUpsaleFinished();
                }



            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setLoadingData(false);;
            })
    }


    const handleFinalOrder = () => {
        handleUpsale();
    }

    const handleCloseMenuModal = () => {
        setModalEditing({
            opened: false,
            data: null,
            basketItemDetails: null,
            editingItemId: null
        });
        props.history.goBack();
    }

    let contentUpsale = null;
    if (showModalUpsale && fetchedUpsaleData) {
        contentUpsale = <Auxilary>
            <ModalUpsale
                data={fetchedUpsaleData }
                handleUpsaleClose={() => handleUpsaleFinished(true)}
                handleUpsaleContinue={() => handleUpsaleFinished(true)}
                upsaleContinueLoading={upsaleContinueLoading}
                accessToken={accessToken}
                lang={i18n.language}
                basketId={basketData.id}
                editing={false}
                overaAllEdit={basketData.upsale?.length !== 0}
            />
            <Backdrop address className='backdrop' show={true} backdropClicked={() => { }} />
        </Auxilary>
    }
    let upsaleEditingModal = null;
    if (upsaleEditing.editing) {
        upsaleEditingModal = <Auxilary>
            <ModalUpsale
                data={basketData.upsale.filter(item=>item.id===upsaleEditing.itemId)}
                handleUpsaleClose={() => setUpsaleEditingModal({
                    editing: false,
                    itemId: null
                })}
                handleUpsaleContinue={() => handleUpsaleFinished(true)}
                upsaleContinueLoading={upsaleContinueLoading}
                accessToken={accessToken}
                lang={i18n.language}
                basketId={basketData.id}
                editing={true}
            />
            <Backdrop address className='backdrop' show={true} backdropClicked={() => setUpsaleEditingModal({
                editing: false,
                itemId: null
            })} />
        </Auxilary>
    }
    return (
        show ?
            <div className="basket-button" >
                {openBasket ?
                    <Auxilary>
                        <OpenBasketData
                            handleCloseOpenBasketData={() => setOpenBasket(false)}
                            removeItemHandler={removeItemHandler}
                            editItemHandler={editItemHandler}
                            handleFinalOrder={handleFinalOrder}
                           
                        />
                        {loadingData ?
                            <div className='loading-data-modal-basket' >
                                <Spinner logo button spinnerStyle="SpinnerYellow" />;
                             </div> : null
                        }
                        {modalEditing.opened ?

                            <Auxilary>
                                <Route path={'/:id'}
                                    component={(props) =>
                                        <MenuModalOrder
                                            mode={basketData.order_mode}
                                            data={modalEditing.data}
                                            basketItemDetails={modalEditing.basketItemDetails}
                                            editingItemId={modalEditing.editingItemId}
                                            handleCloseMenuModal={handleCloseMenuModal}
                                            editing={true}
                                        />
                                    } />
                                {/*                               
                                <MenuModalOrder
                                    mode={basketData.order_mode}
                                    data={modalEditing.data}
                                    bßasketItemDetails={modalEditing.basketItemDetails}
                                    handleCloseMenuModal={() => setModalEditing({ opened: false, data: null })}
                                    editingItemId={modalEditing.editingItemId}
                                    editing={true}
                                /> */}
                                <Backdrop address className='backdrop' show={modalEditing} backdropClicked={() => handleCloseMenuModal()} />
                            </Auxilary>
                            : null
                        }

                        <div className={closedRestaurant ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>  {t('homepage.closed_message')} </div>
                    </Auxilary>
                    : null
                }
                <div onClick={() => {
                    if (openBasket) {
                        setShowModalUpsale(null);
                        setFetchedUpsaleData(null);
                        setUpsaleEditingModal({
                            editing: false,
                            itemId: null
                        })
                    }
                    setOpenBasket(!openBasket)
                }}>
                    <img alt='btn-card-badge' src={btnCardBadge} />
                    <span className="basket-item-count">{
                        parseInt(basketData.products.length) + parseInt(basketData.upsale ? basketData.upsale.length : 0)
                    }</span>
                </div>

                {contentUpsale}
                {upsaleEditingModal}
            </div >
            : null


    )
}

export default BasketButton;
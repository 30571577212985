import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/performance";
import ReactPixel from "react-facebook-pixel";
import { firebaseConfig } from "./firebaseConfig";
import "./index.scss";
import "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-smartbanner/dist/main.css";
import { Provider } from "react-redux";
import { createStore, compose, combineReducers } from "redux";
import authReducer from "./store/reducers/authReducer";
import restaurantsDataReducer from "./store/reducers/restaurantsDataReducer";
import basketReducer from "./store/reducers/basketReducer";
import bannerReducer from "./store/reducers/bannerReducer";
import orderItemDataReducer from "./store/reducers/orderItemDataReducer";
import okRedirectReducer from "./store/reducers/okRedirectReducer";
import filterReducer from "./store/reducers/filterReducer";
import App from "./App";
import ScrollToTop from "./UI/ScrollToTop";
import { AppProvider } from "./context/app-context";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  restData: restaurantsDataReducer,
  auth: authReducer,
  basket: basketReducer,
  orderItemData: orderItemDataReducer,
  banner: bannerReducer,
  okPage: okRedirectReducer,
  filters: filterReducer,
});

const store = createStore(rootReducer);
// const store = createStore(rootReducer, composeEnhancers());

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

firebase.initializeApp(firebaseConfig);
firebase.performance();
firebase.analytics();
ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, options);
ReactPixel.pageView();

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<div></div>}>
            <ScrollToTop />
            <App />
          </Suspense>
        </BrowserRouter>
      </Provider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

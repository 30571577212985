import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { Col } from "react-bootstrap";

const NavigationItem = (props) => {
  const { t } = useTranslation();
  return (
    <Col xs="12" className="navigation-item p-0">
      <NavLink to={props.link} exact={props.exact} activeClassName="active">
        {/* <Menuicon hover={hover} imgSrc={props.imgSrc} imgSrcActive={props.imgSrcActive} activeLink={props.link} location={props.location}/> */}
        {props.image ? <img alt='navigation-icon' src={props.image}></img> : null}
        <span className="spanTextStyle">{t(props.children)}</span> 
      </NavLink>
    </Col>
  );
};

export default NavigationItem;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Auxilary from '../hoc/Auxilary';
import ModifierGroup from './ModifierGroup';


const ModifierGroupItem = props => {
    // const [itemSelected, setItemSelected] = useState(props.data.is_default ? true : false);
    const [mounted, setMounted] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        if (mounted) {
            const hasModifierSubgroup=props.data.has_modifier_subgroup===true && props.data.is_default && props.data.modifier_groups.length!==0 
            props.setModifiersForOrderHandler(props.data.size.menu_modifier_ordering_id, props.data.is_default, {}, hasModifierSubgroup);
        } else {
            setMounted(true);
        }
        
    }, [props.data.is_default]);

  
    return (
        <Auxilary>
            <div className='modifier-group-item'>
                <div className='name'>
                    {/* <div>{itemSelected ? <img src={checkImg} onClick={() => setItemSelected(false)} />
                    : <button type='button' className='empty-square' onClick={() => setItemSelected(true)}> </button>}</div>
                <div>}</div> */}
                    <div>
                        <label className={props.radio ? "container-radio" : "container-checkbox"}>
                            {props.radio ?
                                <input type="radio" checked={props.data.is_default} onChange={(event) => {
                                    props.handleItemSelection(props.data.id, event.target.checked, true);
                                }} name={props.groupId} />
                                :
                                <input type="checkbox" checked={props.data.is_default} onChange={(event) => {
                                    props.handleItemSelection(props.data.id, event.target.checked, false);
                                }} />
                            }
                            {props.data.name}
                            <span className={props.radio ? "checkmark-radio" : "checkmark-checkbox"}></span>
                        </label>
                    </div>
                </div>
                <div className='description'>
                    {  props.data.size && props.data.size.price !== 0 ? <span> +{props.data.size.price} {t('post.currency')}</span> : null}
                </div>
            </div>
            {props.data.has_modifier_subgroup===true && props.data.is_default && props.data.modifier_groups.length!==0 ?
                <div className='subgroup-style'>
                    {props.data.modifier_groups.map(item => {
                        return <ModifierGroup
                            data={item}
                            key={item.id}
                            subgroup
                            setModifiersForOrderHandler={props.setModifiersForOrderHandler}
                            parentOrderingId={props.data.size.menu_modifier_ordering_id}
                            editing={props.editing}
                            updatingModifiers={props.updatingModifiers}
                        />
                    })}
                </div> : null
            }

        </Auxilary>

    )
}

export default ModifierGroupItem;
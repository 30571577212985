import React, { useEffect } from 'react';
import axios from '../axios-instance_baseURL';
import { useTranslation } from 'react-i18next';

import Spinner from '../UI/Spinner';
import { basket_url } from '../assets/urls/urls';
import { payment_ok_number_config } from '../assets/configs/config_calls';
import { useSelector,useDispatch } from 'react-redux';
import { set_loading} from '../store/actions/okRedirectActions';
const OkPage = props => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.okPage.loading);
    const successfuly = useSelector(state => state.okPage.successfuly);
    useEffect(() => {
        if (loading === false) {
            if (successfuly) {
                dispatch(set_loading(true, true));
                props.history.push({
                    pathname: '/',
                    mode: "order",
                    orderNumber: props.match.params.id
                });
            } else {
                dispatch(set_loading(true, true));
                props.history.push({
                    pathname: '/',
                });
            }
        }
    }, [loading])
    // const accessToken = useSelector(state => state.auth.accessToken);
    // const { i18n } = useTranslation();
    // useEffect(() => {
    //     let transStarted = localStorage.getItem("payingStarted");
    //     if (transStarted && transStarted === "true") {
    //         let basketId = localStorage.getItem("basketId");
    //         let phone = localStorage.getItem("phone");
    //         let config = payment_ok_number_config(accessToken, i18n.language, phone);
    //         localStorage.removeItem("payingStarted");
    //         localStorage.removeItem("phone");
    //         localStorage.removeItem("basketId");
    //         axios.post(basket_url + basketId + "/payment", { ...config.body }, { params: {}, headers: config.headers })
    //             .then(response => {
    //                 props.history.push({
    //                     pathname: '/',
    //                     mode: "order",
    //                     orderNumber: props.match.params.id
    //                 });
    //             }).catch(err => {
    //                 if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
    //                     props.history.push({
    //                         pathname: '/settings/logout',
    //                         err: "unauthenticated",
    //                     });
    //                     return;
    //                 }
    //                 props.history.push({
    //                     pathname: '/',
    //                     mode: "order",
    //                     orderNumber: props.match.params.id
    //                 });
    //             })


    //     } else {
    //         props.history.push("/");
    //     }

    // }, [])
    return (
        <div className='order-page'>
            <div className='order-page-wrapper'>
                <Spinner logo spinnerStyle="SpinnerYellow" />;
        </div>
        </div>
    )
}

export default OkPage;
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  restaurantsData: null,
  errorMsg: null,
  loading: false,
  noData: false,
  error: null,
  nextPage: 1,
  hasNextPage: false,
  firstLoad: false,
  searchValue: "",
  lat: null,
  long: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_RESTAURANTS_RESPONSE_DATA:
      const isData = action.data.restaurants.length ? false : true;

      let hasNextPage = false;
      let nextPage = state.nextPage;
      if (action.data.current_page < action.data.total_pages) {
        hasNextPage = true;
        nextPage = action.data.current_page + 1;
      }
      if (action.scrolling) {
        const nextRestaurants = [...state.restaurantsData.restaurants, ...action.data.restaurants];
        return {
          ...state,
          restaurantsData: {
            ...action.data,
            restaurants: nextRestaurants,
          },
          errorMsg: null,
          loading: false,
          noData: isData,
          error: null,
          hasNextPage: hasNextPage,
          nextPage: nextPage,
          firstLoad: true,
          lat: action.lat,
          long: action.long
        }
      }
      return {
        ...state,
        restaurantsData: action.data,
        errorMsg: null,
        loading: false,
        noData: isData,
        error: null,
        hasNextPage: hasNextPage,
        nextPage: nextPage,
        firstLoad: true,
        lat: action.lat,
        long: action.long

      }
    case actionTypes.SET_RESTAURANTS_LOADING:
      if (action.clear) {
        return {
          ...state,
          loading: true,
          noData: false,
          error: null,
          restaurantsData:null
        }
      }
      return {
        ...state,
        loading: true,
        noData: false,
        error: null
      }
    case actionTypes.SET_RESTAURANTS_LOADING_FINISHED:
      return {
        ...state,
        loading: false,
        error: null
      }
    case actionTypes.SET_ERROR:
      return {
        ...state,
        restaurantsData: null,
        loading: false,
        error: "Sorry something went wrong",
        noData: false,
        nextPage: false
      }
    case actionTypes.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.value
      }
    case actionTypes.SET_RESTAURANTS_FAV: {
      const isData = action.restaurants.length ? false : true;
      return {
        ...state,
        restaurantsData: {
          ...state.restaurantsData,
          restaurants: action.restaurants
        },
        noData: isData,
      }
    }
    case actionTypes.CLEAR_RESTAURANTS_STATE: {
      return {
        restaurantsData: null,
        errorMsg: null,
        loading: false,
        noData: false,
        error: null,
        nextPage: 1,
        hasNextPage: false,
        firstLoad: false,
        searchValue: "",
        lat: state.lat,
        long: state.long
      }
    }
    default:
      return state;
  }
};

export default reducer;

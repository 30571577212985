
import * as actionTypes from "./actionTypes";

export const set_banner = (hasBanner, value) => {
    return {
      type: actionTypes.SET_BANNER,
      hasBanner,
      value
    }
  }
  
  export const setPromoBanner= ( value) => {
    return {
      type: actionTypes.SET_PROMO_BANNER,
      value
    }
  }
  
  export const set_slider_data = ( data) => {
    return {
      type: actionTypes.SET_SLIDER_DATA,
      data
    }
  }
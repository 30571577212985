import React, { useState, useEffect, useContext } from "react";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import PlacesAutocomplete from "react-places-autocomplete";
// import {
//   geocodeByAddress,
//   geocodeByPlaceId,
//   getLatLng,
// } from 'react-places-autocomplete';

import showEye from "../assets/images/icons/eye.svg";
import hideEye from "../assets/images/icons/hide.svg";
import { validator } from "../assets/configs/config_functions";
import { AppContext } from "../context/app-context";

const Input = (props) => {
  //
  const { handleStreet } = useContext(AppContext);
  //
  const { t } = useTranslation();
  // const [typing, setTyping] = useState(false);
  const [inputData, setInputData] = useState(props.data);
  const [mounted, setMounted] = useState(false);
  const google = window.google ? window.google : null;
  const searchOptions = google
    ? {
        location: new google.maps.LatLng(41.9981, 21.4254),
        radius: 100,
        componentRestrictions: { country: ["MK"] },
      }
    : null;
  const onChange = (event) => {
    const valid = validator(event.target.value, inputData.rules);
    setInputData({
      ...inputData,
      value: event.target.value,
      valid: valid,
    });
  };
  const handleChangePlaces = (address) => {
    handleStreet(address);
    setInputData({
      ...inputData,
      value: address,
      valid: false,
    });
  };

  const handleSelectPlaces = (address, placeId) => {
    handleStreet(address);
    var skopje = new google.maps.LatLng(41.9981, 21.4254);
    const map = new google.maps.Map(document.getElementById("map"), {
      center: skopje,
      zoom: 15,
    });
    var request = {
      placeId: placeId,
      fields: ["name", "rating", "formatted_phone_number", "geometry"],
    };

    const service = new google.maps.places.PlacesService(map);
    service.getDetails(request, callback);

    function callback(result, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        setInputData({
          ...inputData,
          value: address,
          valid: true,
        });
        props.setLatLng({
          lat: result.geometry.location.lat() + "",
          long: result.geometry.location.lng() + "",
        });
      }
    }
  };

  const showHide = () => {
    if (inputData.type === "password") {
      setInputData({
        ...inputData,
        type: "input",
      });
    } else {
      setInputData({
        ...inputData,
        type: "password",
      });
    }
  };

  useEffect(() => {
    if (mounted) {
      props.notifyHandler(inputData.name, inputData.valid, inputData.value);
    } else {
      setMounted(true);
    }
  }, [inputData.value]);

  // function with auto 07 prefix adding
  const phoneInputClicked = () => {
    // if (inputData.name === "phone") {
    //   if (inputData.value === "07") return;
    //   if (inputData.value !== "07" && inputData.value.trim().length === 0) {
    //     setInputData({
    //       ...inputData,
    //       value: "07",
    //     });
    //   }
    // }
  };

  let input = null;
  let notValid = null;
  if (!inputData.valid) {
    if (inputData.value.trim() === "" && inputData.value.length > 0) {
      notValid = true;
    } else if (inputData.value.trim() !== "" && inputData.value.length > 0) {
      notValid = true;
    } else if (inputData.value.trim() === "" && inputData.value.length === 0) {
      notValid = false;
    }
  }
  let applyErrorClass = "";

  if (notValid) applyErrorClass = "border-red";
  if (notValid && props.modalAddress) applyErrorClass = "border-red-modal";
  if (props.match === false && props.formValid) {
    applyErrorClass = "border-red";
  }

  useEffect(() => {
    if (props.reset) {
      setInputData({
        ...inputData,
        value: "",
        valid: false,
      });
    }
  }, [props.reset]);

  if (props.modalAddress) {
    if (props.places) {
      let searchPlaceholder = t("inputs.descriptions.search_places");
      input = (
        <Col
          className={props.className}
          xs={props.xs}
          sm={props.sm}
          md={props.md}
          lg={props.lg}
        >
          <div id="map"></div>
          <div className={"input-modal-desription"}>{t(props.data.desc)}</div>
          <PlacesAutocomplete
            value={inputData.value}
            onChange={handleChangePlaces}
            onSelect={handleSelectPlaces}
            searchOptions={searchOptions}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: searchPlaceholder,
                    className: "input-modal-normal places " + applyErrorClass,
                  })}
                  disabled={inputData.disabled ? true : false}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>{t("inputs.descriptions.loading")}</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#FFC300", cursor: "pointer" }
                      : { backgroundColor: "#fafafa", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {notValid ? (
            <span className="error-msg-modal">
              {t(inputData.validationMessage)}
            </span>
          ) : null}
        </Col>
      );
    } else {
      input = (
        <Col
          className={props.className}
          xs={props.xs}
          sm={props.sm}
          md={props.md}
          lg={props.lg}
        >
          <div className={"input-modal-desription"}>{t(props.data.desc)}</div>
          <input
            className={"input-modal-normal " + applyErrorClass}
            type={inputData.type}
            value={inputData.value}
            placeholder={t(inputData.placeholder)}
            onChange={(event) => onChange(event)}
            disabled={inputData.disabled ? true : false}
            style={inputData.name === "city" ? { fontWeight: "700" } : {}}
          />
          {notValid ? (
            <span className="error-msg-modal">
              {t(inputData.validationMessage)}
            </span>
          ) : null}
        </Col>
      );
    }
  } else {
    switch (inputData.elemType) {
      case "input":
        if (inputData.changeAble) {
          input = (
            <Col
              className={props.className}
              xs={props.xs}
              sm={props.sm}
              md={props.md}
              lg={props.lg}
            >
              <div className="input-desription">{t(props.data.desc)}</div>
              <div className="changable">
                <input
                  className={"input-normal " + applyErrorClass}
                  type={inputData.type}
                  value={inputData.value}
                  placeholder={t(inputData.placeholder)}
                  onChange={(event) => onChange(event)}
                />
                <div className={"button-wrapper " + applyErrorClass}>
                  <img
                    alt="show-hide-img"
                    onClick={showHide}
                    src={inputData.type === "password" ? hideEye : showEye}
                  />
                </div>
              </div>
              {notValid ? (
                <span className="error-msg">
                  {t(inputData.validationMessage)}
                </span>
              ) : null}
            </Col>
          );
        } else {
          input = (
            <Col
              className={props.className}
              xs={props.xs}
              sm={props.sm}
              md={props.md}
              lg={props.lg}
            >
              <div className={"input-desription"}>{t(props.data.desc)}</div>
              <input
                onClick={() => phoneInputClicked()}
                className={"input-normal " + applyErrorClass}
                type={inputData.type}
                value={inputData.value}
                placeholder={t(inputData.placeholder)}
                onChange={(event) => onChange(event)}
              />
              {notValid ? (
                <span className="error-msg">
                  {t(inputData.validationMessage)}
                </span>
              ) : null}
            </Col>
          );
        }
        break;
      case "datePicker":
        input = (
          <Col
            className={props.className}
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
          >
            <div className={"input-desription"}>{t(props.data.desc)}</div>
            <DatePicker
              placeholderText="DD/MM/YY"
              selected={props.date}
              onChange={props.setDateFunc}
              dateFormat="dd MMMM, yyyy"
              peekNextMonth
              showYearDropdown
              dropdownMode="select"
              maxDate={new Date()}
            />
            {notValid ? (
              <span className="error-msg">
                {t(inputData.validationMessage)}
              </span>
            ) : null}
          </Col>
        );
        break;
      default:
        break;
    }
  }

  return input;
};

export default Input;

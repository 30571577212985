import * as actionTypes from "../actions/actionTypes";

const initialState = {
  banner: false,
  bannerText: "",
  sliderData: null,
  bannerPromo:null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BANNER:
      return {
        ...state,
        banner: action.hasBanner,
        bannerText:action.value
      }
    case actionTypes.SET_SLIDER_DATA:
      return {
        ...state,
        sliderData: action.data
      }
      case actionTypes.SET_PROMO_BANNER:
        return {
          ...state,
          bannerPromo: action.value
        }
    default:
      return state;
  }
};

export default reducer;

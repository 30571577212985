import React from 'react'
import { Row } from 'react-bootstrap';

import NavigationItem from '../components/NavigationItem';
import logouticon from '../assets/images/icons/003-login-square-arrow-button-outline.svg';
import settingsIcon from '../assets/images/icons/settings.svg';
import locationIcon from '../assets/images/icons/Location Icon.svg';
import userIcon from '../assets/images/icons/user.png';
import ordersIcon from '../assets/images/icons/001-order.svg';
import heart from '../assets/images/icons/heart_black.svg';
const NavigationItems = (props) => {

   let navigations = (
      <Row className="m-0" >
         <NavigationItem image={props.image ? heart : null} exact link="/favorites">navigation.favorites</NavigationItem>
         <NavigationItem image={props.image ? ordersIcon : null} link="/settings/orders" >navigation.orders</NavigationItem>
         <NavigationItem image={props.image ? locationIcon : null} link="/settings/address" >navigation.addresses</NavigationItem>
         <NavigationItem image={props.image ? settingsIcon : null} link="/settings/additional" >navigation.settings</NavigationItem>
         <NavigationItem image={props.image ? userIcon : null} exact link="/settings/profile">navigation.profile</NavigationItem>
         {props.noLogout ? null : <NavigationItem image={props.image ? logouticon : null} link="/settings/logout" >navigation.logout</NavigationItem>}
      </Row>
   )
   if (props.settingsPage) {
      navigations = (
         <Row className="m-0 mobile-hide" >
            <NavigationItem image={props.image ? userIcon : null} exact link="/settings/profile">navigation.profile</NavigationItem>
            <NavigationItem image={props.image ? settingsIcon : null} link="/settings/additional" >navigation.settings</NavigationItem>
            <NavigationItem image={props.image ? ordersIcon : null} link="/settings/orders" >navigation.orders</NavigationItem>
            <NavigationItem image={props.image ? locationIcon : null} link="/settings/address" >navigation.addresses</NavigationItem>
            {props.noLogout ? null : <NavigationItem image={props.image ? logouticon : null} link="/settings/logout" >navigation.logout</NavigationItem>}
         </Row>
      )
   }
   return (
      navigations
   )
}

export default NavigationItems;
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

import HeaderLogo from "../../UI/HeaderLogo";
import SearchBar from "./SearchBar/SearchBar";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import BackDrop from "../../UI/Backdrop";
import Languages from "../../UI/Languages";
import locationIcon from "../../assets/images/icons/Location Icon black.png";
import Filters from "../Filters";
import heart from "../../assets/images/icons/heart_yellow.svg";
import heartFilled from "../../assets/images/icons/heart_yellow_filled.svg";
import locationIconMobile from "../../assets/images/icons/Location Icon.svg";
import userIconMobile from "../../assets/images/icons/user.png";
import Navigation from "../../containers/NavigationItems";
import Auxilary from "../../hoc/Auxilary";

const Header = (props) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const bannerText = useSelector((state) => state.auth.bannerText);
  const { t } = useTranslation();
  const menuClick = () => {
    setMenuOpened(!menuOpened);
  };
  const userData = useSelector((state) => state.auth.userData);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const authenticated = accessToken && userData.status;
  const [expanded, setExpanded] = useState(false);
  const sideDrawerClasses = menuOpened
    ? "sideDrawer open-sd desktop-hide m-0"
    : "sideDrawer close-sd desktop-hide m-0";
  const userClicked = () => {
    if (!authenticated) {
      props.history.push("/login");
    } else {
      setExpanded(!expanded);
    }
  };
  const locationPath = props.history.location.pathname;
  const detailsSettings =
    locationPath.includes("/restaurant/details/") ||
    locationPath.includes("/settings/") ||
    locationPath.includes("/order/main");
  return (
    <div
      className={
        bannerText === "" ? "header-wrapper" : "header-wrapper banner-padding"
      }
    >
      {!authenticated && !props.headerHomepageOnly ? (
        <Languages classess="languages" />
      ) : (
        <Languages
          headerHomepageOnly={props.headerHomepageOnly}
          infoOnly
          classess="languages"
        />
      )}
      <BackDrop show={menuOpened} backdropClicked={menuClick} />

      <Row
        className={
          props.headerHomepageOnly
            ? "header-display-block m-0"
            : "m-0 header-row align-items-center"
        }
      >
        <div
          className={
            props.headerHomepageOnly
              ? "header-display-inline-block-left"
              : "col-custom-7"
          }
        >
          <Row
            className={
              props.headerHomepageOnly ? "m-0 header-display-block" : "m-0 "
            }
          >
            <Col xs="6" md="2" className="p-0 header-logo">
              <HeaderLogo history={props.history} />
            </Col>
            {props.headerHomepageOnly || detailsSettings ? null : (
              <Filters
                showDesktop
                col="9"
                history={props.history}
                favorites={props.favorites}
              />
            )}
            {
              props.settings ? null : null
              // <Col className='p-0 text-left mobile-hide header-city'>
              //     <img alt="location icon" src={locationIcon} />
              //     <span className="city-span">{t('homepage.skopje')}</span>
              //     {!props.headerHomepageOnly ? <span onClick={accessToken ? () => {
              //         if (props.favorites === false) {
              //             props.history.push('/favorites')
              //         } else {
              //             props.history.push('/')
              //         }
              //     } : () => props.history.push({
              //         pathname: '/login',
              //         redirect: "favorites",
              //     })} className='favorites-button'>
              //         <button onClick={accessToken ? () => {
              //             if (props.favorites === false) {
              //                 props.history.push('/favorites')
              //             } else {
              //                 props.history.push('/')
              //             }

              //         } : () => props.history.push({
              //             pathname: '/login',
              //             redirect: "favorites",
              //         })} >
              //             <img className='img-favorites' onClick={accessToken ? () => {
              //                 if (props.favorites === false) {
              //                     props.history.push('/favorites')
              //                 } else {
              //                     props.history.push('/')
              //                 }
              //             } : () => props.history.push({
              //                 pathname: '/login',
              //                 redirect: "favorites",
              //             })}
              //                 alt='heart-fav' src={props.favorites === true ? heartFilled : heart} />
              //             {t('navigation.favorites')}
              //         </button>
              //     </span>
              //         : null
              //     }

              // </Col>
            }
            {props.headerHomepageOnly ? null : (
              <HamburgerMenu click={menuClick} />
            )}
          </Row>
        </div>

        <SearchBar
          hideMobile
          favorites={props.favorites === true ? true : false}
          headerHomepageOnly={props.headerHomepageOnly}
          settings={props.settings}
          history={props.history}
          authenticated={authenticated}
          name={authenticated ? userData.user.first_name : null}
        />
        {props.headerHomepageOnly ? null : (
          <div className={sideDrawerClasses}>
            <HamburgerMenu click={menuClick} open={menuOpened} />
            <div className="header-city-menu">
              <img
                alt="location icon"
                className="icon-mobile-location"
                src={locationIconMobile}
              />
              <span>{t("homepage.skopje")}</span>
            </div>
            <div
              onClick={() => userClicked()}
              xs="12"
              className={
                authenticated
                  ? "user-logo-name-closed name-authenticated"
                  : "user-logo-name-closed"
              }
            >
              <img
                alt="user icon"
                className="icon-mobile"
                src={userIconMobile}
              />
              <span className="name">
                {authenticated ? userData.user.first_name : t("homepage.login")}
              </span>
            </div>
            {authenticated ? (
              <div className="menu-model-sidedrawer">
                <Navigation image />
              </div>
            ) : null}
          </div>
        )}
        {/* <SearchBar hideDetailsPage favorites={props.favorites === true ? true : false} headerHomepageOnly={props.headerHomepageOnly} settings={props.settings} history={props.history} authenticated={authenticated} name={authenticated ? userData.user.first_name : null} /> */}
        {props.headerHomepageOnly || detailsSettings ? null : (
          <Filters
            col="12"
            hideDesktop
            history={props.history}
            favorites={props.favorites}
          />
        )}
      </Row>
    </div>
  );
};

export default Header;

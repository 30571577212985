import React from 'react';

import Layout from '../hoc/Layout';
import { Switch, Route } from 'react-router';

import Logout from '../components/Logout';
import Profile from '../components/Profile';
import MyOrders from '../components/MyOrders';
import MyAdresses from '../components/MyAdreses';
import Navigator from './NavigationItems';
import SettingsAndPassword from '../components/SettingsAndPassword';
import BasketButton from '../UI/BasketButton';

const SettingsPage = props => {
    return (
        <Layout settings history={props.history}>
            <div className='settings-wrapper'>
                <div className='settings-content'>
                    <div className='settings-navigator'>
                        <Navigator settingsPage  />
                    </div>
                    <div className="settings-page-content">
                        <Switch>
                            <Route path='/settings/profile' component={Profile} />
                            <Route path='/settings/address' component={MyAdresses} />
                            <Route path='/settings/additional' component={SettingsAndPassword} />
                            <Route path='/settings/orders' component={MyOrders} />
                            <Route path='/settings/logout' component={Logout} />
                            <Route path='/settings' render={(props) => props.history.push('/settings/profile')} />
                        </Switch>
                    </div>
                </div>
            </div>
                <BasketButton history={props.history}/>
        </Layout>
    )
}

export default SettingsPage;
import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import axios from "../axios-instance_baseURL";
import { useTranslation } from "react-i18next";

import AddressPost from "./AddressPost";
import {
  get_user_addresses_config,
  delete_address_config,
  set_default_address_config,
} from "../assets/configs/config_calls";
import {
  get_addresses_url,
  set_default_address_url,
} from "../assets/urls/urls";
import { useSelector } from "react-redux";
import Spinner from "../UI/Spinner";
import Auxilary from "../hoc/Auxilary";

const MyAdresses = (props) => {
  const [loading, setLoading] = useState(true);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [responseAddresses, setResponseAddresses] = useState();
  const [errorMsg, setErrMsg] = useState();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const config = get_user_addresses_config(accessToken, i18n.language);
    axios
      .get(get_addresses_url, { params: {}, headers: config.headers })
      .then((response) => {
        if (response.data.status === true) {
          setResponseAddresses(response.data.user_fav_locations);
        } else {
          setErrMsg(response.data.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 401 &&
          err.response.statusText &&
          err.response.status === 401 &&
          err.response.statusText === "UNAUTHORIZED"
        ) {
          props.history.push({
            pathname: "/settings/logout",
            err: "unauthenticated",
          });
          return;
        }
        setLoading(false);
        setErrMsg("homepage.errorMsg");
      });
  }, []);

  const handleRefresh = () => {
    const config = get_user_addresses_config(accessToken, i18n.language);
    setLoading(true);
    axios
      .get(get_addresses_url, { params: {}, headers: config.headers })
      .then((response) => {
        if (response.data.status === true) {
          setResponseAddresses(response.data.user_fav_locations);
        } else {
          setErrMsg(response.data.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 401 &&
          err.response.statusText &&
          err.response.status === 401 &&
          err.response.statusText === "UNAUTHORIZED"
        ) {
          props.history.push({
            pathname: "/settings/logout",
            err: "unauthenticated",
          });
          return;
        }
        setLoading(false);
        setErrMsg("homepage.errorMsg");
      });
  };

  const deleteHandler = (id) => {
    const config = delete_address_config(id, accessToken, i18n.language);
    setLoading(true);
    axios
      .delete(get_addresses_url, {
        data: { ...config.body },
        headers: { ...config.headers },
      })
      .then((response) => {
        if (response.data.status) {
          let copy = [];
          for (let item in { ...responseAddresses }) {
            if (responseAddresses[item].id !== id) {
              copy.push({
                ...responseAddresses[item],
              });
            }
          }
          setResponseAddresses(copy);
        } else {
          setErrMsg(response.data.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 401 &&
          err.response.statusText &&
          err.response.status === 401 &&
          err.response.statusText === "UNAUTHORIZED"
        ) {
          props.history.push({
            pathname: "/settings/logout",
            err: "unauthenticated",
          });
          return;
        }
        setLoading(false);
        setErrMsg("homepage.errorMsg");
      });
  };

  const updateAddressData = (id, name, street, streetNumber, aptNumber) => {
    let copy = [];
    for (let item in { ...responseAddresses }) {
      if (responseAddresses[item].id === id) {
        copy.push({
          ...responseAddresses[item],
          address_apt_no: aptNumber,
          name: name,
          address_street: street,
          address_no: streetNumber,
        });
      } else {
        copy.push({
          ...responseAddresses[item],
        });
      }
    }
    setResponseAddresses(copy);
  };

  const setDefault = (id, value) => {
    const config = set_default_address_config(
      id,
      accessToken,
      value,
      i18n.language
    );
    setLoading(true);
    axios
      .put(
        set_default_address_url,
        { ...config.body },
        { headers: { ...config.headers } }
      )
      .then((response) => {
        if (response.data.status === true) {
          handleRefresh();
        } else {
          setErrMsg(response.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 401 &&
          err.response.statusText &&
          err.response.status === 401 &&
          err.response.statusText === "UNAUTHORIZED"
        ) {
          props.history.push({
            pathname: "/settings/logout",
            err: "unauthenticated",
          });
          return;
        }
        setErrMsg("homepage.errorMsg");
        setLoading(false);
      });
  };

  console.log(responseAddresses);
  const data = responseAddresses
    ? Object.keys(responseAddresses).map((item) => {
        return (
          <AddressPost
            updateAddressData={updateAddressData}
            deleteAddress={deleteHandler}
            key={responseAddresses[item].id}
            data={responseAddresses[item]}
            setDefault={setDefault}
            refreshAddresses={handleRefresh}
            orderMode={props.orderMode}
            createBasketDelivery={() =>
              props.createBasketDelivery(responseAddresses[item])
            }
          />
        );
      })
    : null;

  return loading ? (
    <Spinner logo spinnerStyle="SpinnerYellow" />
  ) : (
    <Row className="m-0">
      {errorMsg ? (
        t(errorMsg)
      ) : (
        <Auxilary>
          {data}
          <AddressPost
            createBasketDelivery={props.createBasketDelivery}
            orderMode={props.orderMode}
            adding
            refreshAddresses={handleRefresh}
          />
        </Auxilary>
      )}
    </Row>
  );
};

export default MyAdresses;

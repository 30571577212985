import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const RecursiveModifiers = props => {
    
    const { t } = useTranslation();
    return <Col xs='12' className='p-0 pl-2 product-details' style={{marginTop:"-5px"}} >
        {props.modifiers.map(item => {

            return (
                <Row className='m-0' key={item.id} >
                    <Col xs='8' className='p-0' >
                        {item.name}
                    </Col>
                    <Col xs='4' className='p-0 text-right' >
                        + {item.total_price} {t('post.currency')}
                    </Col>
                    {item.modifiers.length !== 0
                        ?
                        <RecursiveModifiers modifiers={item.modifiers} />
                        : null
                    }
                </Row>
            )
        })}
    </Col>
}

export default RecursiveModifiers;
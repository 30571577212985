import * as actionTypes from "./actionTypes";

export const set_restaurants = (data, lat, long, scrolling = false) => {
  return {
    type: actionTypes.SET_RESTAURANTS_RESPONSE_DATA,
    data: {
      ...data,
      restaurants: [
        ...data.open,
        ...data.close
      ]
    },
    scrolling: scrolling,
    lat: lat ? lat : "0",
    long: long ? long : "0"
  }
};

export const set_restaurants_favorites = (data, lat, long, scrolling = false) => {
  return {
    type: actionTypes.SET_RESTAURANTS_RESPONSE_DATA,
    data: {
      ...data,
      restaurants: [
        ...data.restaurants
      ]
    },
    scrolling: scrolling,
    lat: lat ? lat : "0",
    long: long ? long : "0"
  }
};
export const set_restaurants_loading = (clear=false) => {
  return {
    type: actionTypes.SET_RESTAURANTS_LOADING,
    clear:clear
  };
};

export const set_search_value = (value) => {
  return {
    type: actionTypes.SET_SEARCH_VALUE,
    value:value
  }
}

export const set_restaurants_loading_finished = () => {
  return {
    type: actionTypes.SET_RESTAURANTS_LOADING_FINISHED,
  };
};


export const set_error = () => {
  return {
    type: actionTypes.SET_ERROR
  }
}
export const set_restaurants_fav = (restaurants) => {
  return {
    type: actionTypes.SET_RESTAURANTS_FAV,
    restaurants:restaurants
  }
}

export const clear_rest_data = () => {
  return {
    type: actionTypes.CLEAR_RESTAURANTS_STATE,
  }
}



import React from 'react';
import { Col } from "react-bootstrap";


const HamburgerMenu = (props) => {

    let openCloseStyle = (props.open ? 'menu-btn-open menu-btn' : 'menu-btn')

    return (
        <Col className='p-0 desktop-hide self-align-right'>
            <div className={openCloseStyle} onClick={props.click}>
                <div  className='menu-btn__burger'></div>
            </div>
        </Col>
    )
}

export default HamburgerMenu;
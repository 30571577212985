import React from "react";
import "../assets/styles/UI/_faq.scss";

export const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="faq">
        <br />
        <h2>ПОЛИТИКА ЗА ПРИВАТНОСТ</h2>
        <br />
        <p>
          Оваа политика за приватност објаснува како Naracaj ги користи личните
          податоци што ги собираме од вас кога ја користите Услугата Naracaj.
          <br />
          <br />
          Теми: <br />• Кои податоци ги собираме? <br />• Како ги собираме
          вашите податоци?
          <br />• Како ќе ги користиме вашите податоци? <br />• Како ги чуваме
          вашите податоци? <br />• Маркетинг <br />• Кои се вашите права за
          заштита на податоците?
          <br />• Што се колачиња? <br />• Како користиме колачиња? <br />• Кои
          видови колачиња ги користиме? <br />• Како да управувате со вашите
          колачиња? <br />• Политика на приватност на други веб-страници <br />•
          Промени во нашата политика за приватност <br />• Како да не
          контактирате? <br />• Како да контактирате со надлежните органи?
        </p>
        <br />
        <h2>Кои податоци ги собираме?</h2>
        <p>
          Naracaj ги собира следниве податоци:
          <br />• Информации за лична идентификација (Име, адреса на е-пошта,
          телефонски број и сл.)
          <br />• Информации што ги давате при рангирање на нашите услуги
          <br />• Други информации што ги обезбедувате или кога креирате
          Корисничка сметка или подоцна при менување на профилот на вашата
          сметка
        </p>
        <br />
        <h2>Како ги собираме вашите податоци?</h2>
        <p>
          Повеќето податоци кои ги собираме ги добиваме директно од Вас.
          Собираме и обработуваме податоци кога вие:
          <br />• Ќе направите кориснички профил или направите нарачка за било
          кој наш производ или услуга
          <br />• Доброволно ќе пополните корисничка анкета или дадете мислење
          на било кој начин
          <br />• Ја користете или ја прегледате Услугата Naracaj преку
          колачињата на прелистувачот
        </p>
        <br />
        <h2>Како ќе ги користиме вашите податоци?</h2>
        <p>
          Naracaj ги собира вашите податоци за да можеме:
          <br />• Да ја спроведеме вашата нарачка и да управуваме со вашите
          податоци
          <br />• Да ви испраќаме е-пошта со специјални понуди за други
          производи и услуги што мислиме дека можеби ќе ви се допаднат
          <br />• За да го подобриме квалитетот на Услугата Naracaj.
          <br />
          <br />
          Ние можеби ќе ги споделиме вашите податоци со нашите Партнери за да
          може да ви ги понудат своите производи и услуги.
        </p>
        <br />
        <h2>Колку долго ги чуваме вашите податоци?</h2>
        <p>
          Naracaj не ги чува вашите лични податоци подолго отколку што е
          законски дозволено и неопходно за целите на обезбедување на Услугата
          Naracaj. Периодот на чување зависи од природата на информациите и
          целите на обработката. Затоа, максималниот период може да варира во
          зависност од употребата.
          <br />
          <br />
          Повеќето лични податоци што се однесуваат на Корисничката сметка на
          Услугата Naracaj, ќе бидат избришани по истекот на период од 90 дена
          откако Корисникот ќе ја избрише својата Kорисничка сметка на Услугата
          Naracaj. Потоа, дел од личните податоци што се однесуваат на
          Корисничката сметка на Услугата Naracaj можат да се чуваат само
          доколку таквата обработка се бара со закон или е разумно неопходна за
          нашите законски обврски или легитимни интереси, како што се наплата на
          побарувања, книговодство, внатрешни извештаи и постапки на медијација.
          Сите лични податоци што се однесуваат на сметката на корисникот со
          Услугата Naracaj, ќе бидат избришани во период од 10 години откако
          корисникот ќе ја избрише својата корисничка сметка на Услугата
          Naracaj, со исклучок на личните податоци што се бараат во одредени
          ретки ситуации, како што се правните постапки.
          <br />
          <br />
          Naracaj ќе ги зачува податоците за аналитика на корисниците кои немаат
          сметка на Услугата Naracaj за период од 90 дена.
        </p>
        <br />
        <h2>Маркетинг</h2>
        <p>
          Со ваша претходна согласност, Ние би сакале да ви испраќаме информации
          за нашите производи и услуги за кои мислиме дека можеби ќе ви се
          допаднат, а исто така и за нашите Партнери.
          <br />
          Доколку сте се согласиле да добивате маркетинг услуги, секогаш ќе
          имате можност да се откажете. Имате право во секое време да побарате
          од Naracaj да престане да ве контактира за маркетинг цели, или да ги
          дава вашите податоци на Партнерите.
          <br />
          Доколку повеќе не сакате да бидете контактирани за маркетинг цели,
          кликнете овде.
        </p>
        <br />
        <h2>Кои се вашите права за заштита на податоците?</h2>
        <p>
          Би сакале да бидеме сигурни дека сте целосно запознаени за сите ваши
          права за заштита на податоците. Секој корисник има право на следново:
          <br />• Право на пристап - Да побарате од Naracaj копија на вашите
          лични податоци, за која услуга можно е да треба да ви наплатиме мал
          надомест
          <br />• Право на исправка – Да побарате од Naracaj да ги исправи сите
          информации кои сметате дека се неточни. Вие исто така имате право да
          побарате од Naracaj да ги комплетира информациите за кои сметате дека
          се нецелосни
          <br />• Право на бришење - Да побарате од Naracaj да ги избрише вашите
          лични податоци, под одредени услови
          <br />• Право на ограничување на обработката - Да побарате од Naracaj
          да ја ограничи обработката на вашите лични податоци, под одредени
          услови
          <br />• Право на приговор на обработка - Да приговарате на обработката
          на вашите лични податоци од Naracaj, под одредени услови
          <br />• Право на преносливост на податоци - Да побарате од Naracaj да
          ги пренесе податоците што ги собравме на друга организација, или
          директно до вас, под одредени услови
          <br />
          Доколку поднесете барање, имаме рок од еден месец да ви одговориме.
          Доколку сакате да искористите било кое од овие права, ве молиме
          контактирајте не на
          <br />
          Нашата е-пошта: contact@naracaj.com
          <br />
          Јавете ни се на: 070 680-414
        </p>
        <br />
        <h2>Што се колачиња (cookies) ?</h2>
        <p>
          Колачиња (cookies) се мали текстуални датотеки, кои се сместуваат во
          Вашиот компјутер или мобилен телефон од страна на веб страниците кои
          ги посетувате. Овие текстуални датотеки можат да бидат прочитани од
          страна на веб страниците кои ги посетувате и да помогнат во Вашата
          идентификација кога повторно ќе ја отворите веб страницата. Овие
          текстуални датотеки можат да бидат „постојани“ или „привремени“.
          Постојаните колачиња (cookies) остануваат на Вашиот компјутер и кога
          ќе го исклучите пребарувачот (ќе преминете во офлајн состојба), додека
          привремените се бришат штом го затворите интернет пребарувачот.
          Колачињата (cookies) се користат за повеќе цели, како на пример за да
          се запомнат Вашите активности и преференции (вклучувања, јазични
          преференции, големина на фонт или како да ви се прикажува страницата),
          за да се обезбеди персонализирана/приспособена содржина на веб
          страницата и да се прикаже навигациската историја на истата.
          <br />
          <br />
          За дополнителни информации, посетете го allaboutcookies.org
        </p>
        <br />
        <h2>Како користиме колачиња?</h2>
        <p>
          Ние користиме колачиња за да ја прилагодиме Услугата Naracaj и
          информациите што ги даваме во согласност со индивидуалните интереси на
          нашите корисници.
        </p>
        <br />
        <h2>Кои видови колачиња ги користиме?</h2>
        <p>
          Постојат различни видови колачиња, Naracaj користи колачиња за:
          <br />• Функционалност - Naracaj ги користи овие колачиња за да ве
          препознаеме на Услугата Naracaj и да ги зачуваме претходно избраните
          преференции. Овие може да вклучуваат кој јазик го сакате и која
          локација ја имате.
          <br />• Рекламирање - Naracaj ги користи овие колачиња за да собере
          информации за вашата посета на Услугата Naracaj, содржината што сте ја
          гледале, линковите што ги следите и информациите за прелистувачот,
          уредот и вашата IP адреса. Naracaj понекогаш споделува некои
          ограничени аспекти на овие податоци со трети страни за рекламни цели.
          Исто така, понекогаш ги споделуваме онлајн податоците собрани преку
          колачиња со нашите Партнери за рекламирање. Ова значи дека кога вие ќе
          посетете друга веб-страница, можеби ќе ви биде прикажано рекламирање
          засновано на вашите модели на прелистување на Услугата Naracaj.
        </p>
        <br />
        <h2>Како да управувате со колачињата?</h2>
        <p>
          Корисниците можат да изберат да го постават веб-прелистувачот да одбие
          колачиња или да предупредува кога се испраќаат колачиња.
        </p>
        <br />
        <h2>Политика на приватност на други веб-страници</h2>
        <p>
          Услугата Naracaj содржи линкови до други веб страни. Нашата политика
          за приватност се однесува само на Услугата Naracaj, ако кликнете на
          линк на друга веб страна, треба да ја прочитате нивната политика за
          приватност.
        </p>
        <br />
        <h2>Промени во нашата политика за приватност</h2>
        <p>
          Naracaj ја одржува својата политика за приватност под редовен преглед
          и става нови ажурирања на Услугата Naracaj. Оваа политика за
          приватност последен пат обновена на 13 Април 2020 година.
        </p>
        <br />
        <h2>Како да не контактирате?</h2>
        <p>
          Доколку имате било какви прашања во врска со политиката за приватност
          на Услугата Naracaj, податоците што ги имаме во врска со вас, или
          доколку сакате да ги искористите вашите права за заштита на
          податоците, Ве молиме не двоумете се да не контактирате.
          <br />
          Нашата е-пошта: contact@naracaj.com
          <br />
          Јавете ни се на: 070 680-414
        </p>
        <br />
        <h2>Како да контактирате со соодветниот орган?</h2>
        <p>
          Доколку сакате да поднесете поплака, или доколку сметате дека нашата
          компанија не постапила на задоволителен начин на вашите забелешки,
          може да се јавите во Дирекција за заштита на личните податоци.
          <br />
          <br />
          Е-пошта: info@privacy.mk
          <br />
          Адреса: бул.„Гоце Делчев“бр. 18, (зградата на Македонска радио
          телевизија МРТВ - кат 14)
          <br />
          Поштенски фах 417 1000 Скопје
        </p>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

import React from 'react';
import { Switch, Route } from 'react-router';

import Settings from './Settings';
import PasswordUpdate from './PasswordUpdate';

const SettingsAndPassword = props => {
    return (
        <Switch>
            <Route path='/settings/additional/password-update' component={PasswordUpdate} />
            <Route path='/settings/additional' component={Settings} />
        </Switch>
    )

}

export default SettingsAndPassword;
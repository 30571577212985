import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { get_orders_url, get_orders_completed_url, basket_url } from '../assets/urls/urls';
import { user_profile_config, } from '../assets/configs/config_calls';
import axios from '../axios-instance_baseURL';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../UI/Spinner';
import OrderActiveItem from './OrderActiveItem';
import { save_basket_data } from '../store/actions/basketActions';
import imgOrdersEmptyState from '../assets/images/icons/imgOrdersEmptyState.svg';

const MyOrders = props => {
    const [activeOrFinished, setActiveOrFinished] = useState(true);
    const { t, i18n } = useTranslation();
    const accessToken = useSelector(state => state.auth.accessToken);
    const [ordersDataActive, setOrdersDataActive] = useState();
    const [ordersDataCompleted, setOrdersDataCompleted] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [errMsgModal, setErrMsgModal] = useState(false);
    const [errMsgReorder, setErrmsgReorder] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setErrMsg();
        setLoading(true);
        const config = user_profile_config(accessToken, i18n.language);
        if (activeOrFinished) {
            setOrdersDataActive();
        } else {
            setOrdersDataCompleted();
        }
        axios.get(activeOrFinished ? get_orders_url : get_orders_completed_url, { params: {}, headers: config.headers })
            .then(response => {
                if (response.data.status) {
                    if (activeOrFinished && response.data.active && response.data.active.length !== 0) {
                        setOrdersDataActive(response.data);
                    } else {
                        if (response.data.completed && response.data.completed.length !== 0)
                            setOrdersDataCompleted(response.data);
                    }
                } else {
                    setErrMsg(response.data.message);
                }
                setLoading(false);

            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setErrMsg('homepage.errorMsg');
                setLoading(false);
            })

    }, [activeOrFinished]);

    const handleReorder = (basketId, restaurantId, type) => {
        setLoadingModal(true);
        setErrMsgModal();
        const config = user_profile_config(accessToken, i18n.language);
        axios.post(basket_url + basketId + '/reorder', { ...config.body }, { params: {}, headers: config.headers })
            .then(response => {
                if (response.data.status) {
                    dispatch(save_basket_data(response.data));
                    props.history.push({
                        pathname: '/restaurant/details/' + restaurantId,
                        id: restaurantId,
                        mode: type,
                    });
                } else {
                    setErrMsgModal(response.data.message);
                    setLoadingModal(false);
                    setErrmsgReorder(true);
                    setTimeout(() => {
                        setErrmsgReorder(false);
                    }, 3000);
                }


            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setErrMsgModal('homepage.errorMsg');
                setLoadingModal(false);
                setErrmsgReorder(true);
                setTimeout(() => {
                    setErrmsgReorder(false);
                }, 3000);
            })
    }



    return (

        <div className='my-orders'>
            <Row className='m-0 tabs-border'>
                <Col className='p-0 tabs-col' xs='6' onClick={() => {
                    if (!loading)
                        setActiveOrFinished(true);
                }}>
                    <div className={activeOrFinished ? 'p-0 tab-style active-tab' : 'p-0 tab-style'}>
                        {t('my-orders.active')}
                    </div>
                </Col>
                <Col className='p-0 tabs-col' xs='6' onClick={() => {
                    if (!loading)
                        setActiveOrFinished(false);
                }}>
                    <div className={!activeOrFinished ? 'p-0 tab-style active-tab' : 'p-0 tab-style'}>
                        {t('my-orders.finished')}
                    </div>
                </Col>
            </Row>
            {activeOrFinished ?
                (loading ? <Spinner logo spinnerStyle="SpinnerYellow" /> :
                    errMsg ? <div style={{ textAlign: "center", marginTop: "20px" }}>{t(errMsg)} </div> :
                        <Row className='m-0 active-orders'>
                            {ordersDataActive && ordersDataActive.active ?
                                ordersDataActive.active.map(item => {
                                    return <OrderActiveItem data={item} key={item.id} />
                                })
                                : <Row >
                                    <Col xs='12' className='p-md-0 image-no-orders'>
                                        <img alt='orders-empty-state-background' src={imgOrdersEmptyState} />
                                    </Col>
                                    <Col xs='12' className='p-md-0 no-orders'>
                                        {(t('my-orders.no-active'))}
                                    </Col>
                                    <Col xs='12' className='p-md-0 make-first'>
                                        {(t('my-orders.make-first'))}
                                    </Col>
                                    <Col xs='12' className='p-md-0 button-no-orders'>
                                        <button onClick={() => props.history.push('/')}>  {(t('my-orders.order'))}   </button>
                                    </Col>
                                </Row>
                            }
                        </Row>
                ) : null}
            {
                !activeOrFinished ?
                    (loading ? <Spinner logo spinnerStyle="SpinnerYellow" /> :
                        errMsg ? <div style={{ textAlign: "center", marginTop: "20px" }}>{t(errMsg)} </div> :
                            <Row className='m-0 completed-orders'>
                                {ordersDataCompleted && ordersDataCompleted.completed ?
                                    ordersDataCompleted.completed.map(item => {
                                        if (item.order_status !== 'CANCELED')
                                            return <OrderActiveItem
                                                completed data={item}
                                                key={item.id}
                                                handleReorder={() => handleReorder(item.id, item.restaurant_id, item.order_mode)}
                                            />
                                        return null;
                                    })
                                    : <Row >
                                        <Col xs='12' className='p-md-0 image-no-orders'>
                                            <img alt='orders-empty-state-background' src={imgOrdersEmptyState} />
                                        </Col>
                                        <Col xs='12' className='p-0 no-orders'>
                                            {(t('my-orders.no-completed'))}
                                        </Col>
                                        <Col xs='12' className='p-0 make-first'>
                                            {(t('my-orders.make-first'))}
                                        </Col>
                                        <Col xs='12' className='p-0 button-no-orders'>
                                            <button onClick={() => props.history.push('/')}>  {(t('my-orders.order'))}   </button>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                    ) : null
            }
            {loadingModal ?
                <div className='loading-data-modal' >
                    <Spinner logo spinnerStyle="SpinnerYellow" />;
                         </div> : null
            }
            <div className={errMsgReorder ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>
                {t(errMsgModal)}
            </div>

        </div >
    )
}

export default MyOrders;
import React, { useState, useEffect } from 'react';
import {  Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {  useDispatch } from 'react-redux';
import {
    setModifiersPrice,
    setModifiersNotValid,
    removeModifiersNotValid,
    setModifiersForOrder
} from '../store/actions/orderDataActions';
import ModifierGroupItem from './ModifierGroupItem';

const ModifierGroup = props => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [modifierGroupData, setModifierGroupData] = useState(props.data);
    const [mounted, setMounted] = useState(false);
    const [maxExceeded, setMaxExceeded] = useState(false);

    // let selectedCount = 0;
    // for (let item in props.data.modifiers) {
    //     if (props.data.modifiers[item].is_default) {
    //         selectedCount += 1;
    //     }
    // }
    // const [selected, setSelected] = useState(selectedCount);

    useEffect(() => {
        return () => {
            if (props.subgroup) {
                dispatch(removeModifiersNotValid(modifierGroupData.id));
                dispatch(setModifiersPrice(props.data.id, 0));
            }
        }
    }, [])

    useEffect(() => {
        if (mounted) {
            if (!props.data.modifiers && props.data.modifiers.length === 0) return;
            let updatedModifierData = { ...props.data };
            let modifierGroupPrice = 0;
            let updatingModifiersIds = [];
            if (props.editing) {
                for (let item of props.updatingModifiers) {
                    updatingModifiersIds.push(item.modifire_id);
                }

            }

            for (let item in updatedModifierData.modifiers) {
                if (props.editing) {

                    //if modifier_id of given modifier listing in the loop is same as the given pre selected modifiers_ids, in corresponding level
                    //we need to set it to its default to true
                    if (updatingModifiersIds.includes(updatedModifierData.modifiers[item].modifier_id + "")) {
                        // if includes that modifier_id it means we need to update the modifiers array data 
                        // in a way that the modifiers[item] will be set to is_default:true, others to false
                        let includesId = updatedModifierData.modifiers[item].modifier_id;
                        let newModifiers = updatedModifierData.modifiers.map(item2 => {
                            if (item2.modifier_id === includesId) {
                                return {
                                    ...item2,
                                    is_default: true
                                }
                            } else {
                                //if item is not the one in its turn but still exist as selected in the pre populated modifiers, leave it as it is
                                if (updatingModifiersIds.includes(item2.modifier_id + "")) {
                                    return {
                                        ...item2,
                                    }
                                } else {
                                    // if item is not selected and not in pre selected data set it to false
                                    return {
                                        ...item2,
                                        is_default: false
                                    }
                                }

                            }
                        });
                        // for (let item2 in updatedModifierData.modifiers) {
                        //     if (updatedModifierData.modifiers[item].modifier_id !== updatedModifierData.modifiers[item2].modifier_id) {
                        //         newModifiers.push({
                        //             ...updatedModifierData.modifiers[item2],
                        //             is_default: false
                        //         })
                        //     } else {
                        //         newModifiers.push({
                        //             ...updatedModifierData.modifiers[item],
                        //             is_default: true
                        //         })
                        //     }
                        // }
                        updatedModifierData = {
                            ...updatedModifierData,
                            modifiers: newModifiers.slice()
                        }
                        modifierGroupPrice += updatedModifierData.modifiers[item].size.price;
                        const hasSubModifiers = updatedModifierData.modifiers[item].has_modifier_subgroup === true && updatedModifierData.modifiers[item].modifier_groups.length.length !== 0;
                        setModifiersForOrderHandler(updatedModifierData.modifiers[item].size.menu_modifier_ordering_id, true, {}, hasSubModifiers);
                    } else {
                        // if modifier_id of given modifier listing in the loop is not in the range of the given pre selected modifiers_ids, in corresponding level
                        // we need to set it to its default to false, leave the others untouched
                        let includesId = updatedModifierData.modifiers[item].modifier_id;
                        let newModifiers = updatedModifierData.modifiers.map(item2 => {
                            if (item2.modifier_id === includesId) {
                                return {
                                    ...item2,
                                    is_default: false
                                }
                            } else {
                                //if item is not the one in its turn but still exist as selected in the pre populated modifiers, leave it as it is
                                if (updatingModifiersIds.includes(item2.modifier_id + "")) {
                                    return {
                                        ...item2,
                                    }
                                } else {
                                    // if item is not selected and not in pre selected data set it to false
                                    return {
                                        ...item2,
                                        is_default: false
                                    }
                                }

                            }
                        });
                        updatedModifierData = {
                            ...updatedModifierData,
                            modifiers: newModifiers.slice()
                        }
                        // modifierGroupPrice += updatedModifierData.modifiers[item].size.price;
                        // const hasSubModifiers = updatedModifierData.modifiers[item].has_modifier_subgroup === true && updatedModifierData.modifiers[item].modifier_groups.length.length !== 0;
                        // setModifiersForOrderHandler(updatedModifierData.modifiers[item].size.menu_modifier_ordering_id, true, {}, hasSubModifiers);
                    }
                } else {
                    if (updatedModifierData.modifiers[item].is_default) {
                        modifierGroupPrice += props.data.modifiers[item].size.price;
                        // if (props.subgroup) {
                        //     setModifiersForOrderHandler(props.data.modifiers[item].size.menu_modifier_ordering_id, true);
                        // } else {
                        //     setModifiersForOrderHandler(props.data.modifiers[item].size.menu_modifier_ordering_id, true);
                        // }
                        const hasSubModifiers = updatedModifierData.modifiers[item].has_modifier_subgroup === true && updatedModifierData.modifiers[item].modifier_groups.length.length !== 0;
                        setModifiersForOrderHandler(updatedModifierData.modifiers[item].size.menu_modifier_ordering_id, true, {}, hasSubModifiers);
                        // if its true and its submodifier

                    }
                }

            }
            const validity = checkModifierGroupValidity(updatedModifierData.maximum_options, updatedModifierData.minimum_options, updatedModifierData.modifiers);
            if (validity === -1) {
                dispatch(setModifiersNotValid(updatedModifierData.id, updatedModifierData.name, updatedModifierData.minimum_options));
            }

            dispatch(setModifiersPrice(updatedModifierData.id, modifierGroupPrice));
            setModifierGroupData({ ...updatedModifierData });
        } else {
            setMounted(true);
        }

    }, [props.data, mounted]);


    const checkModifierGroupValidity = (maxOptions, minOptions, modifiers) => {
        let defaultOptionsCount = 0;
        for (let item in modifiers) {
            if (modifiers[item].is_default) {
                defaultOptionsCount += 1;
            }
        }
        if (defaultOptionsCount === maxOptions) return 0; // if default options selection is excideed
        if (defaultOptionsCount < minOptions || (defaultOptionsCount === 0 && minOptions === 0)) return -1; // if required options min is not satisfied
        return 1; // if there is still space to select, and required options have at least 1
    }

    const handleItemSelection = (itemId, value, radio = false) => {
        let updatedModifiers = [];
        if (radio) {
            const validity = checkModifierGroupValidity(modifierGroupData.maximum_options, modifierGroupData.minimum_options, modifierGroupData.modifiers);
            for (let item in modifierGroupData.modifiers) {
                if (modifierGroupData.modifiers[item].id === itemId) {
                    updatedModifiers.push({
                        ...modifierGroupData.modifiers[item],
                        is_default: value
                    });
                } else {
                    updatedModifiers.push({
                        ...modifierGroupData.modifiers[item],
                        is_default: false
                    })
                }
            }
            let modifierGroupPrice = 0;
            for (let item in updatedModifiers) {
                if (updatedModifiers[item].is_default) {
                    modifierGroupPrice += updatedModifiers[item].size.price;
                }
            }
            const validityUpdated = checkModifierGroupValidity(updatedModifiers.maximum_options, updatedModifiers.minimum_options, updatedModifiers.modifiers);
            if (validity === -1) dispatch(setModifiersNotValid(modifierGroupData.id, modifierGroupData.name, modifierGroupData.minimum_options));
            if (validityUpdated !== -1 && validity === -1) dispatch(removeModifiersNotValid(modifierGroupData.id));
            dispatch(setModifiersPrice(modifierGroupData.id, modifierGroupPrice));
        } else {
            const validity = checkModifierGroupValidity(modifierGroupData.maximum_options, modifierGroupData.minimum_options, modifierGroupData.modifiers);
            if (validity === 0 && value === true) {
                setMaxExceeded(true);
                setTimeout(() => {
                    setMaxExceeded(false);
                }, 3000);
                return;
            }
            setMaxExceeded(false);
            for (let item in modifierGroupData.modifiers) {
                if (modifierGroupData.modifiers[item].id === itemId) {
                    updatedModifiers.push({
                        ...modifierGroupData.modifiers[item],
                        is_default: value
                    });
                } else {
                    updatedModifiers.push({
                        ...modifierGroupData.modifiers[item]
                    })
                }
            }
            let modifierGroupPrice = 0;
            for (let item in updatedModifiers) {
                if (updatedModifiers[item].is_default) {

                    modifierGroupPrice += updatedModifiers[item].size.price;
                }
            }
            const validityUpdated = checkModifierGroupValidity(modifierGroupData.maximum_options, modifierGroupData.minimum_options, updatedModifiers);
            if (validityUpdated === -1) dispatch(setModifiersNotValid(modifierGroupData.id, modifierGroupData.name, modifierGroupData.minimum_options));
            if (validityUpdated !== -1 && validity === -1) dispatch(removeModifiersNotValid(modifierGroupData.id));
            dispatch(setModifiersPrice(modifierGroupData.id, modifierGroupPrice));
        }

        // props.changeModifierGroupData({
        //     ...props.data,
        //     modifiers: updatedModifiers
        // });
        setModifierGroupData({
            ...modifierGroupData,
            modifiers: updatedModifiers
        })
    }

    const setModifiersForOrderHandler = (menu_modifier_ordering_id, value, subgroupData, hasSubModifiers) => {
        if (props.subgroup && hasSubModifiers === false) {
            let updatedSubgroupData = {
                "menu_modifiers_sub": menu_modifier_ordering_id,
                parentOrderingId: props.parentOrderingId,
                value: value,
                subModifiers: {
                    ...subgroupData.subModifiers,
                }
                // "menu_modifiers_sub": menu_modifier_ordering_id,
                // parentOrderingId:props.parentOrderingId,
                // value: value
            }
            // updatedSubgroupData.push({
            //     subModifiers: {
            //         "menu_modifiers_sub": menu_modifier_ordering_id,
            //         parentOrderingId:props.parentOrderingId,
            //         value: value
            //     }

            // })
            props.setModifiersForOrderHandler(props.parentOrderingId, value, updatedSubgroupData, true);
        } else if (props.subgroup && hasSubModifiers === true) {
            let updatedSubgroupData = {
                "menu_modifiers_sub": menu_modifier_ordering_id,
                parentOrderingId: props.parentOrderingId,
                value: value,
                subModifiers: {
                    ...updatedSubgroupData
                }
            };
            props.setModifiersForOrderHandler(props.parentOrderingId, value, updatedSubgroupData, true);
        } else if (!props.subgroup && hasSubModifiers === false) {
            dispatch(setModifiersForOrder(menu_modifier_ordering_id, value));
        } else if (!props.subgroup && hasSubModifiers === true) {
            dispatch(setModifiersForOrder(menu_modifier_ordering_id, value, subgroupData));
        }

    }

    const radio = (modifierGroupData.maximum_options === 1 && modifierGroupData.minimum_options <= 1 && modifierGroupData.mandatory);

    return (
        <Col xs='12' className={'modifier-group-items p-0 ' + props.classname} >
            {!props.subgroup ?
                <div className={props.subgroup ? 'modifier-group-description remove-margin-top' : 'modifier-group-description'}>
                    <div className={props.subgroup ? 'name remove-padding-top' : "name"}>
                        {modifierGroupData.name}
                    </div>
                    <div className={props.subgroup ? 'modification-rule remove-padding-bottom' : "modification-rule"} >
                        {radio ?
                            <span>
                                {t('modifier-group.required')} {t('modifier-group.choose_option')}
                            </span>
                            : <span>{t('modifier-group.choose')} {modifierGroupData.minimum_options} {t("modifier-group.to")} {modifierGroupData.maximum_options}
                            </span>
                        }
                    </div>
                </div> : null}

            {modifierGroupData.modifiers.map(item => {
                let updatingModifiers = [];
                if (props.editing) {
                    for (let item2 in props.updatingModifiers) {
                        if (props.updatingModifiers[item2].modifire_id === item.modifier_id) {
                            updatingModifiers = props.updatingModifiers[item2].modifiers.slice();
                        }
                    }
                }
                return <ModifierGroupItem
                    key={item.id}
                    groupId={modifierGroupData.id}
                    handleItemSelection={handleItemSelection}
                    data={item}
                    radio={radio}
                    setModifiersForOrderHandler={setModifiersForOrderHandler}
                    editing={props.editing && updatingModifiers.length !== 0}
                    updatingModifiers={updatingModifiers}
                />
            })}
            <div className={maxExceeded ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>
                {t('modifier-group.maxExceeded')} "{modifierGroupData.name}" {t('modifier-group.is')} {modifierGroupData.maximum_options}
            </div>
        </Col>
    )
}

export default ModifierGroup;
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import btnArrowUp from '../assets/images/icons/btnArrow@1x.svg';
import btnArrowDown from '../assets/images/icons/btnArrowDown@1x.svg';
import RecursiveModifiers from '../UI/RecursiveModifiers';
import btnRemove from '../assets/images/icons/btnRemove.svg';
import btnEdit from '../assets/images/icons/btnEdit.svg';
const OrderDetailsProductItem = props => {
    const [openDetails, setOpenDetails] = useState(false);
    const { t } = useTranslation();
    // const recursivePrice = (modifiers, currentPrice) => {

    //     for (let item in modifiers) {
    //         currentPrice += modifiers[item].price;
    //         if (modifiers[item].modifiers && modifiers[item].modifiers.length !== 0) {
    //             currentPrice += recursivePrice(modifiers[item].modifiers, 0);
    //         }
    //     }
    //     return currentPrice;
    // }
    // let modifiersPrice = recursivePrice(props.data.modifiers, 0);
   
    return (
        <Row className='m-0'>
            <Col xs='12' className='p-0 name'>
                <Row className='m-0'>
                    <Col xs='8' lg='9' className='p-0'>
                        {props.data.quantity}x {props.data.name}
                    </Col>
                    <Col xs='4' lg='3' className='p-0 text-right' style={{ fontWeight: "400" }}>
                        {(props.data.price) * props.data.quantity} {t('post.currency')}
                    </Col>
                </Row>
            </Col>
            {props.openBasket ?
                <Col xs='12' className='p-0 details' >
                    <Row className='m-0 mt-2 mb-1'>
                        <Col xs='5' className='p-0' onClick={() => setOpenDetails(!openDetails)}>
                            <span> {t('order-page.details')} </span>
                            <img alt='btn-up-down-arrow' src={openDetails ? btnArrowDown : btnArrowUp} />
                        </Col>
                        <Col xs='7' className='p-0 edit-remove-buttons text-right' style={{ cursor: "default" }}>
                            <button onClick={() => props.editItemHandler(props.data.id)}>
                                <img alt='btn-edit' src={btnEdit}></img>
                                <div>{t("open-basket.edit")}</div>
                            </button>
                            <button onClick={() => props.removeItemHandler(props.data.id)}>
                                <img alt='btn-remove' src={btnRemove}></img>
                                <div>{t("open-basket.delete")}</div>
                            </button>
                        </Col>
                    </Row>
                </Col>
                : <Col xs='12' className='p-0 details' onClick={() => setOpenDetails(!openDetails)}>
                    <span> {t('order-page.details')} </span>
                    <img alt='btn-up-down-arrow' src={openDetails ? btnArrowDown : btnArrowUp} />
                </Col>}
            {openDetails ?
                <Col xs='12' className='p-0 product-details'>
                    {props.data.modifiers?.map(item => {

                        return (
                            <Row className='m-0' key={item.id} >
                                <Col xs='8' className='p-0' >
                                    {item.name}
                                </Col>
                                <Col xs='4' className='p-0 text-right' >
                                    {/* {item.modifiers.length !== 0
                                        ? (item.total_price !== 0 ? <span> + {item.total_price} {t('post.currency')}</span> : null)
                                        : <span> + {item.total_price} {t('post.currency')}</span>} */}
                                    + {item.total_price} {t('post.currency')}

                                </Col>
                                {item.modifiers?.length !== 0
                                    ?
                                    <RecursiveModifiers modifiers={item.modifiers} />
                                    : null
                                }
                            </Row>
                        )
                    })}
                </Col>
                : null
            }
            {/* <Col xs='12' className='p-0 total-product-item'>
                <Row className='m-0'>
                    <Col xs='8' className='p-0 text-left'>
                        {t('order-page.total')}
                    </Col>
                    <Col xs='4' className='p-0 text-right'>
                        {(props.data.price + modifiersPrice) * props.data.quantity} {t('post.currency')}
                    </Col>
                </Row>
            </Col> */}
        </Row>

    )
}


export default OrderDetailsProductItem;
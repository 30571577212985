

//Form Validator

export const validator = (value, rules) => {
    let isValid = true;
    if (!rules) { return true; }

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) { isValid = value.trim().length >= rules.minLength && isValid }
    if (rules.maxLength) { isValid = value.trim().length <= rules.maxLength && isValid }
    if (rules.email) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
        
    }
    if (rules.isNumeric) {
        const pattern = /(((\(0\))|0)?\s*7\d{1})[\/\-\s*\.\,]?([\d]{3})[\/\-\s*\.\,]?([\d]{3})/;
        isValid = pattern.test(value) && isValid
       
    }
    if (rules.isNumericCard) {
        const patternVisa = /^4[0-9]{12}(?:[0-9]{3})?$/;
        const patternMasterCard = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/
        const reg = new RegExp('^[0-9]+$');
        isValid = (patternVisa.test(value) || patternMasterCard.test(value) )&& reg.test(value) && isValid
    }
    if (rules.numeric) {
        const reg = new RegExp('^[0-9]+$');
        isValid = reg.test(value) && isValid
    }
    if (rules.cardDate) {
        var reg = new RegExp('(0[1-9]|10|11|12)/2[0-9]{1}$');
        isValid = reg.test(value) && isValid;
    }
    if (rules.passwordTriximi) {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
        isValid = pattern.test(value) && isValid
    }

    return isValid;
}



// export const valueUpdaterConfig = (value, elemType, formData, resetToken = false) => {
//     let copyFormData = { ...formData };
//     let element = { ...formData[elemType] };
//     element.value = value;
//     element.valid = validator(element.value, element.rules) ? true : false;
//     copyFormData[elemType] = element;
//     // option to check and set messages for reset page, if passwords does not match display another message
//     if (resetToken) {
//         let passValid = validator(copyFormData["password"].value, copyFormData["password"].rules);
//         let passConfirmValid = validator(copyFormData["passwordConfirm"].value, copyFormData["passwordConfirm"].rules);
//         if (passValid && passConfirmValid) {
//             if (copyFormData.password.value !== copyFormData.passwordConfirm.value) {
//                 let elementConfirm = { ...copyFormData["passwordConfirm"] };
//                 elementConfirm.valid = false;
//                 elementConfirm.validationMessage ='inputs.errorMessages.passwordNotMatch';
//                 copyFormData["passwordConfirm"] = elementConfirm;
//                 return copyFormData;
//             } else {
//                 element = { ...copyFormData["password"] };
//                 let elementConfirm = { ...copyFormData["passwordConfirm"] };
//                 elementConfirm.valid = element.valid = true;
//                 copyFormData["password"] = element;
//                 copyFormData["passwordConfirm"] = elementConfirm;
//                 return copyFormData;
//             }
//         }
//         if (!passConfirmValid) {
//             let elementConfirm = { ...copyFormData["passwordConfirm"] };
//             elementConfirm.validationMessage = 'inputs.errorMessages.password';
//             copyFormData["passwordConfirm"] = elementConfirm;
//             return copyFormData;
//         }
//     }
//     return copyFormData;
// }
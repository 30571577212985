import * as actionTypes from "../actions/actionTypes";

const initialState = {
  basketData: null,
  orders: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_CREATED_BASKET:
      if (action.remove) {
        return {
          ...state,
          basketData:null
        }
      } else {
        return {
          ...state,
          basketData: { ...action.data.basket }
        }
      }

    default:
      return state;
  }
};

export default reducer;

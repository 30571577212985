import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow,
} from '@react-google-maps/api';
import axios from '../axios-instance_baseURL';


import Spinner from '../UI/Spinner';
import Auxilary from '../hoc/Auxilary';
import { useTranslation } from 'react-i18next';
import { get_user_addresses_config } from '../assets/configs/config_calls';
import { get_address_api_url } from '../assets/urls/urls';
import { useSelector } from 'react-redux';
import SpinerElipsins from '../UI/SpinnerElipsins';


const MapOrderPage = props => {

    const { i18n,t } = useTranslation();
    const accessToken = useSelector(state => state.auth.accessToken);
    const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API });
    const [selectedMarker, setSelectedMarker] = useState(true);
    const [dragging, setDragging] = useState(false);
    const [center, setCenter] = useState({
        lat: props.latitude !== "" ? parseFloat(props.latitude) : 41.9981,
        lng: props.longitude !== "" ? parseFloat(props.longitude) : 21.4254
    })
    const [loadingNewAddress, setLoadingNewAddress] = useState(false);
    const refMap = useRef(null);
    const [mapRef, setMapRef] = useState(null);
    const mapContainerStyle = {
        width: "100%",
        height: props.allowPick ? "65vh" : "200px"
    }
    useEffect(() => {
        setCenter({
            lat: props.latitude !== "" ? parseFloat(props.latitude) : 41.9981,
            lng: props.longitude !== "" ? parseFloat(props.longitude) : 21.4254
        })
    }, [props.latitude, props.longitude])
    const handleMapClick = (event, dragging = false) => {
        let config = get_user_addresses_config(accessToken, i18n.language);
        setLoadingNewAddress(true);
        axios.get(get_address_api_url + "?latitude=" + (dragging ? center.lat : event.latLng.lat()) + "&longitude=" + (dragging ? center.lng : event.latLng.lng()), { headers: config.headers })
            .then(response => {
                setLoadingNewAddress(false);
                if (response.status) {
                    props.setNewMapAddressData(
                        dragging ? center.lat : event.latLng.lat(),
                        dragging ? center.lng : event.latLng.lng(),
                        response.data.address.name,
                        response.data.address.address_no)
                }
            })
            .catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setLoadingNewAddress(false);
            })
    }

    const handleCenterChange = () => {
        if (mapRef && !dragging) {
            setCenter({
                lat: parseFloat(mapRef.center.lat()),
                lng: parseFloat(mapRef.center.lng())
            });
            setDragging(true);
            setSelectedMarker(true);
            if (props.allowPick === true)
                setTimeout(() => {
                    setDragging(false);
                }, 80);
        }
    }
    let content = <Spinner logo spinnerStyle="SpinnerYellow" />;
    if (isLoaded) {
        content = <Auxilary>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                onLoad={map => setMapRef(map)}
                zoom={props.allowPick ? 18 : 15}
                center={center}
                options={{ disableDefaultUI: true, zoomControl: props.allowPick ? true : false }}
                draggable={true}
                // onClick={(event) => {
                //     setSelectedMarker(true);
                //     if (props.allowPick === true)
                //         handleMapClick(event);
                // }}
                onCenterChanged={handleCenterChange}
                onDragEnd={() => {
                    setSelectedMarker(true);
                    if (props.allowPick === true)
                        handleMapClick({}, true);
                }}
                
            >
                <Marker
                    position={center} onClick={() => setSelectedMarker(true)}
                // draggable={true}
                // onDragEnd={(event) => {
                //     setSelectedMarker(true);
                //     if (props.allowPick === true)
                //         handleMapClick(event);
                // }}
                >
                    {selectedMarker && props.addressName && props.latitude && props.longitude && props.allowPick ? <InfoWindow onCloseClick={() => { setSelectedMarker(false) }}>
                        <div>
                            <div>
                                {(props.addressName + ", " + props.addressNumber)}
                            </div>
                            <a href={"https://www.google.com/maps/search/?api=1&query=" + center.lat + "," + center.lng} target="_blank" rel="noopener noreferrer" >View on google maps</a>
                        </div>
                    </InfoWindow> : null}
                    {props.allowPick ?
                        <div className='submit-map-modal'>
                            <button onClick={() => {
                                if (!loadingNewAddress)
                                    props.handleSubmitingMap();
                            }} > {loadingNewAddress ? <SpinerElipsins /> : t('order-page.submit')}  </button>

                        </div> :
                        <div className='open-map'>
                            {/* <button onClick={() => props.openMapModalFunc()} >Edit </button> */}
                        </div>}
                </Marker>

            </GoogleMap>

        </Auxilary>

    }
    if (loadError) content = <div>Something went wrong</div>

    return (
        content
    )
}

export default MapOrderPage;

/*

import React, { useState } from 'react';
// import {
//     GoogleMap,
//     useLoadScript,
//     Marker,
//     InfoWindow,
// } from '@react-google-maps/api';
import axios from '../axios-instance_baseURL';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';



import { Spinner } from 'react-bootstrap';
import Auxilary from '../hoc/Auxilary';
import { useTranslation } from 'react-i18next';
import { get_user_addresses_config } from '../assets/configs/config_calls';
import { get_address_api_url } from '../assets/urls/urls';
import { useSelector } from 'react-redux';

const MapOrderPage = props => {

    const { i18n } = useTranslation();
    const accessToken = useSelector(state => state.auth.accessToken);
    // const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API });
    const [selectedMarker, setSelectedMarker] = useState(true);
    // const [loadingNewAddress, setLoadingNewAddress] = useState(false);

    const mapContainerStyle = {
        width: "100%",
        height: "200px"
    }
    const center = {
        lat: parseFloat(props.latitude),
        lng: parseFloat(props.longitude)
    }


    const handleMapClick = (event) => {
        let config = get_user_addresses_config(accessToken, i18n.language);
        // setLoadingNewAddress(true);
        axios.get(get_address_api_url + "?latitude=" + event.center.lat+ "&longitude=" + event.center.long, { headers: config.headers })
            .then(response => {
                // setLoadingNewAddress(false);
                if (response.status) {
                    props.setNewMapAddressData(
                        event.latLng.lat(),
                        event.latLng.lng(),
                        response.data.address.name,
                        response.data.address.address_no)
                }
            })
            .catch(err => {
                //    setLoadingNewAddress(false);
            })


    }

    let content = <Spinner spinnerStyle="SpinnerYellow" />;
    // if (isLoaded) {
        content = <Auxilary>
            <Map
                google={props.google}
                containerStyle={mapContainerStyle}
                zoom={15}
                center={center}
                options={{ disableDefaultUI: true, zoomControl: true }}
                draggable={true}
                onClick={(event) => {
                    setSelectedMarker(true);
                    if (props.allowPick === true)
                        handleMapClick(event);
                }}

            >
                <Marker
                    position={{ lat: parseFloat(props.latitude), lng: parseFloat(props.longitude) }} onClick={() => setSelectedMarker(true)}
                    onDragEnd={(evt) => {
                    }}
                >
                    {selectedMarker && props.addressName && props.latitude && props.longitude ? <InfoWindow onCloseClick={() => { setSelectedMarker(false) }}>
                        <div>
                            {(props.addressName + ", " + props.addressNumber)}
                        </div>
                    </InfoWindow> : null}
                </Marker>

            </Map>

        </Auxilary>

    // }
    // if (loadError) content = <div>Something went wrong</div>

    return (
        content
    )
}

export default GoogleApiWrapper({
    apiKey:  process.env.REACT_APP_GOOGLE_MAPS_API
})(MapOrderPage);

/*
import React, { useState } from 'react';
// import {
//     GoogleMap,
//     useLoadScript,
//     Marker,
//     InfoWindow,
// } from '@react-google-maps/api';
import axios from '../axios-instance_baseURL';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';



import { Spinner } from 'react-bootstrap';
import Auxilary from '../hoc/Auxilary';
import { useTranslation } from 'react-i18next';
import { get_user_addresses_config } from '../assets/configs/config_calls';
import { get_address_api_url } from '../assets/urls/urls';
import { useSelector } from 'react-redux';

const MapOrderPage = props => {

    const { i18n } = useTranslation();
    const accessToken = useSelector(state => state.auth.accessToken);
    // const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API });
    const [selectedMarker, setSelectedMarker] = useState(true);
    // const [loadingNewAddress, setLoadingNewAddress] = useState(false);

    const mapContainerStyle = {
        width: "100%",
        height: "200px"
    }
    const center = {
        lat: parseFloat(props.latitude),
        lng: parseFloat(props.longitude)
    }


    const handleMapClick = (event) => {
        let config = get_user_addresses_config(accessToken, i18n.language);
        // setLoadingNewAddress(true);
        axios.get(get_address_api_url + "?latitude=" + event.center.lat+ "&longitude=" + event.center.long, { headers: config.headers })
            .then(response => {
                // setLoadingNewAddress(false);
                if (response.status) {
                    props.setNewMapAddressData(
                        event.center.lat,
                        event.center.long,
                        response.data.address.name,
                        response.data.address.address_no)
                }
            })
            .catch(err => {
                //    setLoadingNewAddress(false);
            })


    }

    let content = <Spinner spinnerStyle="SpinnerYellow" />;
    // if (isLoaded) {
        content = <Auxilary>
            <Map
                google={props.google}
                containerStyle={mapContainerStyle}
                zoom={15}
                initialCenter={center}
                // options={{ disableDefaultUI: true, zoomControl: true }}
                draggable={true}
                onClick={(event) => {
                    setSelectedMarker(true);
                    if (props.allowPick === true)
                        handleMapClick(event);
                }}
                onDragend={(evt) => {
                }}
            >
                <Marker
                    position={{ lat: parseFloat(props.latitude), lng: parseFloat(props.longitude) }} onClick={() => setSelectedMarker(true)}
                    draggable={true}
                    onDragend={(evt) => {
                    }}
                >
                    {selectedMarker && props.addressName && props.latitude && props.longitude ? <InfoWindow onCloseClick={() => { setSelectedMarker(false) }}>
                        <div>
                            {(props.addressName + ", " + props.addressNumber)}
                        </div>
                    </InfoWindow> : null}
                </Marker>

            </Map>

        </Auxilary>

    // }
    // if (loadError) content = <div>Something went wrong</div>

    return (
        content
    )
}

export default GoogleApiWrapper({
    apiKey:  process.env.REACT_APP_GOOGLE_MAPS_API
})(MapOrderPage);
*/

import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "../axios-instance_baseURL";
import {
  get_addresses_url,
  get_times_url,
  get_address_api_url,
} from "../assets/urls/urls";
import {
  get_user_addresses_config,
  get_times_confing,
} from "../assets/configs/config_calls";
import locationIcon from "../assets/images/icons/iconLocation.svg";
import Auxilary from "../hoc/Auxilary";
import iconCart from "../assets/images/icons/iconCart.svg";
import iconTime from "../assets/images/icons/iconTime.svg";
import AddressRadioCheck from "../UI/AddressRadioCheck";
import { useSelector, useDispatch } from "react-redux";
// import AddressAdd from './AddressAdd';
import Backdrop from "../UI/Backdrop";
import { save_basket_data } from "../store/actions/basketActions";
import MapOrderPage from "./MapOrderPage";
import DeliveryTimesModal from "./DeliveryTimesModal";
import Spinner from "../UI/Spinner";
import CloseIcon from "../assets/images/icons/close.svg";
import InputOrdering from "../UI/InputOrdering";
import { validator } from "../assets/configs/config_functions";
import { AppContext } from "../context/app-context";

function toISOLocal(d) {
  var z = (n) => ("0" + n).slice(-2);
  var zz = (n) => ("00" + n).slice(-3);
  var off = d.getTimezoneOffset();
  var sign = off < 0 ? "+" : "-";
  off = Math.abs(off);

  return (
    d.getFullYear() +
    "-" +
    z(d.getMonth() + 1) +
    "-" +
    z(d.getDate()) +
    "T" +
    z(d.getHours()) +
    ":" +
    z(d.getMinutes()) +
    ":" +
    z(d.getSeconds()) +
    "." +
    zz(d.getMilliseconds()) +
    sign +
    z((off / 60) | 0) +
    ":" +
    z(off % 60)
  );
}

const OrderPageDetails = (props) => {
  //
  const { street, city, handleStreet, handleCity } = useContext(AppContext);
  //
  const { t, i18n } = useTranslation();
  const [checkedAddress, setCheckedAddress] = useState();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [responseAddresses, setResponseAddresses] = useState(null);
  const [addressCheckedOnce, setAddressCheckedOnce] = useState(false);

  // const [newAddressInfo, setNewAddressInfo] = useState();
  const [modalBasketChange, setModalBasketChange] = useState(false);
  const [latlng, setLatLng] = useState({
    latitude: "41.9981",
    longitude: "21.4254",
  });
  const [orderingType, setOrderingType] = useState();
  const [openModalTimePick, setOpenModalTimePick] = useState(false);
  const [availableTimes, setAvailableTimes] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingDataModal, setLoadingDataModal] = useState(false);
  const [errMessageTimes, setErrMessageTimes] = useState();
  const [timesNotValid, setTimesNotValid] = useState(false);
  const [existingAddress, setExistingAddress] = useState();
  const lat = useSelector((state) => state.auth.lat);
  const long = useSelector((state) => state.auth.long);
  const [openModalLatLng, setOpenModalLaLong] = useState(false);
  const [currentNotValid, setCurrentNotValid] = useState(false);
  const [errCurentAddress, seterrCurentAddress] = useState();
  const [openMapModal, setOpenMapModal] = useState(false);
  const [mapAddressNotValid, setMapAddressNotValid] = useState(false);
  const [addressForm, setAddressForm] = useState({
    street: {
      elemType: "input",
      placeholder: "inputs.placeholders.street",
      type: "input",
      name: "street",
      valid: false,
      value: "",
      typing: false,
      rules: {
        required: true,
      },
    },
    streetNumber: {
      elemType: "input",
      placeholder: "inputs.placeholders.street_number",
      type: "input",
      name: "streetNumber",
      valid: false,
      value: "",
      typing: false,
      rules: {
        required: true,
        minLength: 1,
      },
    },
    apptNumber: {
      elemType: "input",
      placeholder: "inputs.placeholders.appt_number",
      type: "input",
      name: "apptNumber",
      valid: false,
      value: "",
      typing: false,
      rules: {
        required: true,
      },
    },
    city: {
      elemType: "input",
      placeholder: "inputs.placeholders.city",
      type: "input",
      name: "city",
      valid: false,
      value: "",
      typing: false,
      rules: {
        required: true,
        minLength: 2,
      },
    },
    additionalInfo: {
      elemType: "input",
      placeholder: "inputs.placeholders.additional_info",
      type: "input",
      name: "additionalInfo",
      valid: false,
      value: "",
      typing: false,
      rules: {
        required: false,
      },
    },
  });
  const [mapTempInfo, setMapTempInfo] = useState({
    street: {
      elemType: "input",
      placeholder: "inputs.placeholders.street",
      type: "input",
      name: "street",
      valid: false,
      value: "",
      typing: false,
      streetNumber: "",
      lat: "41.9981",
      long: "21.4254",
      rules: {
        required: true,
      },
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const config = get_user_addresses_config(accessToken, i18n.language);
    if (accessToken && props.type === "delivery") {
      setLoadingData(true);
      axios
        .get(get_addresses_url, { params: {}, headers: config.headers })
        .then(async (response) => {
          setLoadingData(false);
          if (response.data.status) {
            setResponseAddresses(response.data.user_fav_locations);
            if (response.data.user_fav_locations.length === 0) {
              setCheckedAddress("new");
            } else {
              let defaultAddress = false;
              for (let item in response.data.user_fav_locations) {
                if (response.data.user_fav_locations[item].is_default) {
                  defaultAddress = true;
                  let result = await props.selectedAddress(
                    response.data.user_fav_locations[item],
                    response.data.user_fav_locations[item].id,
                    true
                  );
                  if (result === 1) {
                    setExistingAddress(response.data.user_fav_locations[item]);
                    setCheckedAddress(
                      response.data.user_fav_locations[item].id
                    );
                    setLatLng({
                      latitude: response.data.user_fav_locations[item].latitude,
                      longitude:
                        response.data.user_fav_locations[item].longitude,
                    });
                    setMapTempInfo({
                      street: {
                        ...mapTempInfo.street,
                        lat: response.data.user_fav_locations[item].latitude,
                        long: response.data.user_fav_locations[item].longitude,
                        streetNumber:
                          response.data.user_fav_locations[item].address_no,
                        value:
                          response.data.user_fav_locations[item].address_street,
                        valid: true,
                        typing: true,
                      },
                    });
                  }
                }
              }
              if (!defaultAddress) {
                let result = await props.selectedAddress(
                  response.data.user_fav_locations[0],
                  response.data.user_fav_locations[0].id,
                  true
                );
                if (result === 1) {
                  setExistingAddress(response.data.user_fav_locations[0]);
                  setCheckedAddress(response.data.user_fav_locations[0].id);
                  setLatLng({
                    latitude: response.data.user_fav_locations[0].latitude,
                    longitude: response.data.user_fav_locations[0].longitude,
                  });
                  setMapTempInfo({
                    street: {
                      ...mapTempInfo.street,
                      lat: response.data.user_fav_locations[0].latitude,
                      long: response.data.user_fav_locations[0].longitude,
                      streetNumber:
                        response.data.user_fav_locations[0].address_no,
                      value: response.data.user_fav_locations[0].address_street,
                      valid: true,
                      typing: true,
                    },
                  });
                }
              }
            }
          } else {
            // setCheckedAddress('current');
          }
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.status &&
            err.response.status === 401 &&
            err.response.statusText &&
            err.response.status === 401 &&
            err.response.statusText === "UNAUTHORIZED"
          ) {
            props.history.push({
              pathname: "/settings/logout",
              err: "unauthenticated",
            });
            return;
          }
          setLoadingData(false);
          if (accessToken) {
            setCheckedAddress("new");
          }
        });
    } else {
      if (accessToken) {
        setCheckedAddress("new");
      }
    }
  }, []);
  useEffect(() => {
    if (props.type === "delivery") {
      if (!props.leadTime || props.leadTime === -1 || !checkedAddress) return;
    }

    // let refreshFalse = setTimeout(() => {
    //     setRefreshTime(false);
    // }, 60000);
    // let refreshTrue=setTimeout(() => {
    //     setRefreshTime(true);
    // }, 60000);

    const leadTime = props.leadTime;
    let todayDate = toISOLocal(new Date()).slice(0, 10);
    const config = get_times_confing(
      props.data.order_mode,
      props.data.restaurant_id,
      props.data.menu_id,
      accessToken,
      i18n.language
    );
    axios
      .get(
        get_times_url + "?from_date=" + todayDate + "&to_date=" + todayDate,
        { params: {}, headers: config.headers }
      )
      .then((response) => {
        if (response.data.status) {
          setAvailableTimes(response.data);
          props.setAvailableTimes(response.data);
          let values = response.data.times[0].values;
          // let time = values[0].time.split(" ")[1].slice(0, 5);
          // handleSelectedTime({
          //     value: values[0].id,
          //     label: time,
          //     fullTime: values[0].time
          // }, "fastes");
          // let foundTime = false;
          for (let item in values) {
            let date = new Date();
            let time = values[item].time.split(" ")[1].slice(0, 5);
            let hours = date.getHours();
            let minutes = date.getMinutes();

            if (parseInt(minutes) + parseInt(leadTime) >= 60) {
              hours = parseInt(hours) + 1;
              minutes = parseInt(leadTime) - (60 - parseInt(minutes));
            } else {
              minutes = parseInt(leadTime) + parseInt(minutes);
            }

            if (parseInt(hours) <= parseInt(time.split(":")[0])) {
              if (parseInt(hours) === parseInt(time.split(":")[0])) {
                if (parseInt(time.split(":")[1]) > minutes) {
                  handleSelectedTime(
                    {
                      value: values[item].id,
                      label: time,
                      fullTime: values[item].time,
                    },
                    "fastes",
                    true
                  );
                  // foundTime = true;
                  break;
                }
              } else {
                handleSelectedTime(
                  {
                    value: values[item].id,
                    label: time,
                    fullTime: values[item].time,
                  },
                  "fastes",
                  true
                );
                // foundTime = true;
                break;
              }
            }
          }
          // if (!foundTime) {
          //     setTimesNotValid(true);
          //     setTimeout(() => {
          //         setTimesNotValid(false);
          //     }, 4000);
          // } else {
          // if (refreshTime) {
          //     refreshFalse();
          // } else {
          //     refreshTrue();
          // }

          // }
        } else {
          setOrderingType();
          setErrMessageTimes(response.data.message);
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 401 &&
          err.response.statusText &&
          err.response.status === 401 &&
          err.response.statusText === "UNAUTHORIZED"
        ) {
          props.history.push({
            pathname: "/settings/logout",
            err: "unauthenticated",
          });
          return;
        }
        setOrderingType();
      });

    return () => {};
  }, [props.leadTime, checkedAddress]);

  const handleItemSelection = async (event, id) => {
    if (!addressCheckedOnce) setAddressCheckedOnce(true);
    if (event.target.checked) {
      // setCheckedAddress(id);
      if (id === "new" || id === "current") {
        if (id === "current") {
          if (lat + "" !== "" && long + "" !== "0") {
            let config = get_user_addresses_config(accessToken, i18n.language);
            setLoadingDataModal(true);
            //get_address_api_url + "?latitude=" + lat + "&longitude=" + long,
            //get_address_api_url + "?latitude=41.9951345&longitude=21.4115118"
            axios
              .get(
                get_address_api_url +
                  "?latitude=41.9951345&longitude=21.4115118",
                { headers: config.headers }
              )
              .then(async (response) => {
                if (response.data.status) {
                  let result = await props.selectedAddress(
                    {
                      address_apt_no: "",
                      address_no: response.data.address.address_no,
                      address_street: response.data.address.address_street,
                      latitude: response.data.address.latitude,
                      longitude: response.data.address.longitude,
                      additional_info: "",
                      name: "Option",
                      valid: false,
                    },
                    id
                  );
                  if (result === 1) {
                    setCheckedAddress(id);
                    setExistingAddress();
                    setMapTempInfo({
                      street: {
                        ...mapTempInfo.street,
                        value: response.data.address.address_street,
                        valid: true,
                        streetNumber: response.data.address.address_no,
                        typing: true,
                        lat: response.data.address.latitude,
                        long: response.data.address.longitude,
                      },
                    });
                    setAddressForm({
                      ...addressForm,
                      street: {
                        ...addressForm.street,
                        value: response.data.address.address_street,
                        disabled: false,
                        valid: true,
                      },
                      streetNumber: {
                        ...addressForm.streetNumber,
                        value: response.data.address.address_no,
                        disabled: false,
                        typing: true,
                        valid: true,
                      },
                      city: {
                        ...addressForm.city,
                        value: "",
                        disabled: false,
                        typing: true,
                        valid: false,
                      },
                    });
                    setLatLng({
                      latitude: response.data.address.latitude,
                      longitude: response.data.address.longitude,
                    });
                    setLoadingDataModal(false);
                  } else {
                    setLoadingDataModal(false);
                  }
                } else {
                  setLoadingDataModal(false);
                  seterrCurentAddress("homepage.errorMsg");
                  setCurrentNotValid(true);
                  setTimeout(() => {
                    setCurrentNotValid(false);
                  }, 3000);
                }
              })
              .catch((err) => {
                if (
                  err &&
                  err.response &&
                  err.response.status &&
                  err.response.status === 401 &&
                  err.response.statusText &&
                  err.response.status === 401 &&
                  err.response.statusText === "UNAUTHORIZED"
                ) {
                  props.history.push({
                    pathname: "/settings/logout",
                    err: "unauthenticated",
                  });
                  return;
                }
                seterrCurentAddress("homepage.errorMsg");
                setCurrentNotValid(true);
                setTimeout(() => {
                  setCurrentNotValid(false);
                }, 3000);
                setLoadingDataModal(false);
              });
          } else {
            setOpenModalLaLong(true);
          }
        }

        if (id === "new") {
          // setModalOpen(true);
          setCheckedAddress(id);
          setExistingAddress();
          props.setSelectedAddressInfos({}, "new", true);
          setMapTempInfo({
            street: {
              ...mapTempInfo.street,
              value: "",
              valid: false,
              streetNumber: "",
              typing: false,
              lat: 41.9981,
              long: 21.4254,
            },
          });
          setAddressForm({
            street: {
              elemType: "input",
              placeholder: "inputs.placeholders.street",
              type: "input",
              name: "street",
              valid: false,
              value: "",
              typing: false,
              rules: {
                required: true,
              },
            },
            streetNumber: {
              elemType: "input",
              placeholder: "inputs.placeholders.street_number",
              type: "input",
              name: "streetNumber",
              valid: false,
              value: "",
              typing: false,
              rules: {
                required: true,
                minLength: 1,
              },
            },
            apptNumber: {
              elemType: "input",
              placeholder: "inputs.placeholders.appt_number",
              type: "input",
              name: "apptNumber",
              valid: false,
              value: "",
              typing: false,
              rules: {
                required: true,
              },
            },
            city: {
              elemType: "input",
              placeholder: "inputs.placeholders.city",
              type: "input",
              name: "city",
              valid: false,
              value: "",
              typing: false,
              rules: {
                required: true,
                minLength: 2,
              },
            },
            additionalInfo: {
              elemType: "input",
              placeholder: "inputs.placeholders.additional_info",
              type: "input",
              name: "additionalInfo",
              valid: false,
              value: "",
              typing: false,
              rules: {
                required: false,
              },
            },
          });
          setLatLng({
            latitude: "",
            longitude: "",
          });
        }
      } else {
        for (let item in responseAddresses) {
          if (responseAddresses[item].id === id) {
            let result = await props.selectedAddress(
              responseAddresses[item],
              id
            );
            if (result === 1) {
              props.setSaveForFutureUse(false);
              setMapTempInfo({
                street: {
                  ...mapTempInfo.street,
                  value: responseAddresses[item].address_street,
                  valid: true,
                  streetNumber: responseAddresses[item].address_no,
                  typing: true,
                  lat: responseAddresses[item].latitude,
                  long: responseAddresses[item].longitude,
                },
              });
              setCheckedAddress(id);
              setExistingAddress(responseAddresses[item]);
              setLatLng({
                latitude: responseAddresses[item].latitude,
                longitude: responseAddresses[item].longitude,
              });
            }
          }
        }
      }
    }
  };

  const handleOrderingSelection = (event, id) => {
    if (!availableTimes) {
      setTimesNotValid(true);
      setTimeout(() => {
        setTimesNotValid(false);
      }, 4000);
      return;
    }
    if (id === "choose") {
      setOpenModalTimePick(true);
    } else {
      let values = availableTimes.times[0].values;
      let leadTime = 0;
      // let foundTime = false;
      if (props.type === "delivery") leadTime = props.leadTime;
      for (let item in values) {
        let date = new Date();
        let time = values[item].time.split(" ")[1].slice(0, 5);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (parseInt(minutes) + parseInt(leadTime) >= 60) {
          hours = parseInt(hours) + 1;
          minutes = parseInt(leadTime) - (60 - parseInt(minutes));
        } else {
          minutes = parseInt(leadTime) + parseInt(minutes);
        }
        if (parseInt(hours) <= parseInt(time.split(":")[0])) {
          if (parseInt(hours) === parseInt(time.split(":")[0])) {
            if (parseInt(time.split(":")[1]) > minutes) {
              handleSelectedTime(
                {
                  value: values[item].id,
                  label: time,
                  fullTime: values[item].time,
                },
                "fastes",
                false
              );
              // foundTime = true;
              break;
            }
          } else {
            handleSelectedTime(
              {
                value: values[item].id,
                label: time,
                fullTime: values[item].time,
              },
              "fastes",
              false
            );
            break;
          }
        }
      }
    }
  };

  const handleSelectedTime = async (pickedTime, type, onMount = false) => {
    let result = await props.handleSelectedTime(pickedTime, onMount, type);
    if (result === 1) {
      setSelectedTime(pickedTime);
      setOrderingType(type);
      setOpenModalTimePick(false);
    } else {
      if (type === "fastes" && !onMount) {
        setTimesNotValid(true);
        setTimeout(() => {
          setTimesNotValid(false);
        }, 4000);
      }

      setSelectedTime();
      setOpenModalTimePick(false);
      setOrderingType();
    }
  };

  const onChange = async (
    event,
    type,
    addressAutocomplete = false,
    latlngAddress = null,
    addressSelected = false
  ) => {
    if (addressAutocomplete === true) {
      const updatedAddressData = {
        ...addressForm,
        [type]: {
          ...addressForm[type],
          value: event,
          typing: event.trim() !== "",
          valid: addressSelected,
        },
        streetNumber: {
          ...addressForm["streetNumber"],
          typing: event.trim() !== "",
          valid:
            addressSelected &&
            validator(
              addressForm["streetNumber"].value,
              addressForm["streetNumber"].rules
            ),
        },
        city: {
          ...addressForm["city"],
          typing: event.trim() !== "",
          valid:
            addressSelected &&
            validator(addressForm["city"].value, addressForm["city"].rules),
        },
      };

      if (addressSelected) {
        //to do if street number, but new address selectedFtimes
        if (updatedAddressData.streetNumber.valid) {
          const result = await props.selectedAddress(
            {
              address_street: updatedAddressData.street.value,
              latitude: latlngAddress ? latlngAddress.latitude : "0",
              longitude: latlngAddress ? latlngAddress.longitude : "0",
              address_no: updatedAddressData.streetNumber.value,
              city: updatedAddressData.city.value,
              address_apt_no: updatedAddressData.apptNumber.value,
              valid:
                addressSelected &&
                updatedAddressData.streetNumber.valid &&
                updatedAddressData.city.valid,
              additional_info: updatedAddressData.additionalInfo.value,
            },
            checkedAddress
          );
          if (result === 1) {
            props.setSelectedAddressInfos(
              {
                address_apt_no: updatedAddressData.apptNumber.value,
                address_no: updatedAddressData.streetNumber.value,
                address_street: updatedAddressData.street.value,
                latitude: latlngAddress ? latlngAddress.latitude : "",
                longitude: latlngAddress ? latlngAddress.longitude : "",
                valid:
                  addressSelected &&
                  updatedAddressData.streetNumber.valid &&
                  updatedAddressData.city.valid,
                additional_info: updatedAddressData.additionalInfo.value,
                name: "Option",
                typing: true,
                city: updatedAddressData.city.value,
              },
              checkedAddress
            );
            setMapTempInfo({
              street: {
                ...mapTempInfo.street,
                value: updatedAddressData.street.value,
                typing: true,
                valid: true,
                streetNumber: updatedAddressData.streetNumber.value,
                lat: latlngAddress
                  ? latlngAddress.latitude
                  : mapTempInfo.street.lat,
                long: latlngAddress
                  ? latlngAddress.longitude
                  : mapTempInfo.street.long,
              },
            });
            setAddressForm({ ...updatedAddressData });
            setLatLng({
              latitude: latlngAddress ? latlngAddress.latitude : "0",
              longitude: latlngAddress ? latlngAddress.longitude : "0",
            });
            return;
          }
        } else {
          setAddressForm({ ...updatedAddressData });
          setLatLng({
            latitude: latlngAddress ? latlngAddress.latitude : "0",
            longitude: latlngAddress ? latlngAddress.longitude : "0",
          });
          setMapTempInfo({
            street: {
              ...mapTempInfo.street,
              value: updatedAddressData.street.value,
              typing: true,
              valid: true,
              streetNumber: updatedAddressData.streetNumber.value,
              lat: latlngAddress
                ? latlngAddress.latitude
                : mapTempInfo.street.lat,
              long: latlngAddress
                ? latlngAddress.longitude
                : mapTempInfo.street.long,
            },
          });
        }

        return;
      } else {
        setAddressForm({ ...updatedAddressData });
        props.setSelectedAddressInfos(
          {
            address_apt_no: updatedAddressData.apptNumber.value,
            address_no: updatedAddressData.streetNumber.value,
            address_street: updatedAddressData.street.value,
            latitude: latlngAddress ? latlngAddress.latitude : "",
            longitude: latlngAddress ? latlngAddress.longitude : "",
            additional_info: updatedAddressData.additionalInfo.value,
            valid: addressSelected,
            name: "Option",
            typing: true,
            city: updatedAddressData.city.value,
          },
          checkedAddress
        );
        setMapTempInfo({
          street: {
            ...mapTempInfo.street,
            value: updatedAddressData.street.value,
            typing: true,
            valid: false,
            streetNumber: updatedAddressData.streetNumber.value,
            lat: latlngAddress
              ? latlngAddress.latitude
              : mapTempInfo.street.lat,
            long: latlngAddress
              ? latlngAddress.longitude
              : mapTempInfo.street.long,
          },
        });
      }
      return;
    } else {
      let valid = validator(event.target.value, addressForm[type].rules);
      let typing = event.target.value.trim().length !== 0;
      if (type === "streetNumber") {
        if (addressForm.street.valid) typing = true;
        valid = addressForm.street.valid && valid;
        if (valid) {
          const updatedAddressData = {
            ...addressForm,
            [type]: {
              ...addressForm[type],
              value: event.target.value,
              valid: valid,
              typing: typing,
            },
          };
          props.selectedAddress(
            {
              address_street: updatedAddressData.street.value,
              latitude: latlng.latitude ? latlng.latitude : "0",
              longitude: latlng.longitude ? latlng.longitude : "0",
              address_no: updatedAddressData.streetNumber.value,
              city: updatedAddressData.city.value,
              address_apt_no: updatedAddressData.apptNumber.value,
              valid: valid && updatedAddressData.city.valid,
              additional_info: updatedAddressData.additionalInfo.value,
            },
            checkedAddress,
            false,
            true
          );
          props.setSelectedAddressInfos(
            {
              address_apt_no: updatedAddressData.apptNumber.value,
              address_no: updatedAddressData.streetNumber.value,
              address_street: updatedAddressData.street.value,
              latitude: latlng.latitude ? latlng.latitude : "0",
              longitude: latlng.longitude ? latlng.longitude : "0",
              valid: valid && updatedAddressData.city.valid,
              additional_info: updatedAddressData.additionalInfo.value,
              name: "Option",
              typing: true,
              city: updatedAddressData.city.value,
            },
            checkedAddress
          );
          setMapTempInfo({
            street: {
              ...mapTempInfo.street,
              value: updatedAddressData.street.value,
              typing: true,
              valid: true,
              streetNumber: updatedAddressData.streetNumber.value,
              lat: latlngAddress
                ? latlngAddress.latitude
                : mapTempInfo.street.lat,
              long: latlngAddress
                ? latlngAddress.longitude
                : mapTempInfo.street.long,
            },
          });
          setAddressForm({ ...updatedAddressData });
          return;
        }
      }
      if (type === "city") {
        if (addressForm.street.valid) typing = true;
        valid = addressForm.street.valid && valid;
        const updatedAddressData = {
          ...addressForm,
          [type]: {
            ...addressForm[type],
            value: event.target.value,
            valid: valid,
            typing: typing,
          },
        };
        if (valid && addressForm.streetNumber.valid) {
          props.selectedAddress(
            {
              address_street: updatedAddressData.street.value,
              latitude: latlng.latitude,
              longitude: latlng.longitude,
              address_no: updatedAddressData.streetNumber.value,
              city: updatedAddressData.city.value,
              address_apt_no: updatedAddressData.apptNumber.value,
              valid:
                updatedAddressData.street.valid &&
                updatedAddressData.streetNumber.valid &&
                updatedAddressData.city.valid,
              additional_info: updatedAddressData.additionalInfo.value,
            },
            checkedAddress,
            false,
            true
          );
        }

        setAddressForm({ ...updatedAddressData });
        props.setSelectedAddressInfos(
          {
            address_apt_no: updatedAddressData.apptNumber.value,
            address_no: updatedAddressData.streetNumber.value,
            address_street: updatedAddressData.street.value,
            latitude: latlng.latitude,
            longitude: latlng.longitude,
            name: "Option",
            valid: valid && updatedAddressData.streetNumber.valid,
            city: updatedAddressData.city.value,
            additional_info: updatedAddressData.additionalInfo.value,
          },
          checkedAddress
        );

        return;
      }
      if (type === "additionalInfo" && event.target.value.trim().length >= 400)
        return;
      const updatedAddressData = {
        ...addressForm,
        [type]: {
          ...addressForm[type],
          value: event.target.value,
          valid: valid,
          typing: typing,
        },
      };
      if (
        updatedAddressData.street.valid &&
        updatedAddressData.streetNumber.valid
      ) {
        props.selectedAddress(
          {
            address_street: updatedAddressData.street.value,
            latitude: latlng.latitude,
            longitude: latlng.longitude,
            address_no: updatedAddressData.streetNumber.value,
            city: updatedAddressData.city.value,
            address_apt_no: updatedAddressData.apptNumber.value,
            valid:
              updatedAddressData.street.valid &&
              updatedAddressData.streetNumber.valid &&
              updatedAddressData.city.valid,
            additional_info: updatedAddressData.additionalInfo.value,
          },
          checkedAddress,
          false,
          true
        );
      }
      setAddressForm({ ...updatedAddressData });
      props.setSelectedAddressInfos(
        {
          address_apt_no: updatedAddressData.apptNumber.value,
          address_no: updatedAddressData.streetNumber.value,
          address_street: updatedAddressData.street.value,
          latitude: latlng.latitude,
          longitude: latlng.longitude,
          name: "Option",
          valid:
            updatedAddressData.street.valid &&
            updatedAddressData.streetNumber.valid &&
            updatedAddressData.city.valid,
          city: updatedAddressData.city.value,
          additional_info: updatedAddressData.additionalInfo.value,
        },
        checkedAddress
      );
    }
  };
  const onChangeMap = (
    address,
    type,
    addressAutocomplete = false,
    latlngAddress = null,
    addressSelected = false
  ) => {
    setMapTempInfo({
      street: {
        ...mapTempInfo.street,
        value: address,
        valid: addressSelected,
        typing: address.trim().value !== "" ? true : false,
        lat: latlngAddress ? latlngAddress.latitude : "",
        long: latlngAddress ? latlngAddress.longitude : "",
        streetNumber: "",
      },
    });
  };

  const setNewMapAddressDataModal = (lat, long, addressName, addressNumber) => {
    setMapTempInfo({
      street: {
        ...mapTempInfo.street,
        value: addressName,
        valid: true,
        streetNumber: addressNumber,
        typing: true,
        lat: lat,
        long: long,
      },
    });
  };
  const handleSubmitingMap = async () => {
    if (!mapTempInfo.street.valid) {
      setMapAddressNotValid(true);
      setTimeout(() => {
        setMapAddressNotValid(false);
      }, 4000);
    } else {
      if (checkedAddress === "current" || checkedAddress === "new") {
        const result = await props.selectedAddress(
          {
            address_street: mapTempInfo.street.value,
            latitude: mapTempInfo.street.lat,
            longitude: mapTempInfo.street.long,
            address_no:
              mapTempInfo.street.streetNumber !== ""
                ? mapTempInfo.street.streetNumber
                : addressForm.streetNumber.value !== ""
                ? addressForm.streetNumber.value
                : "0",
            city: addressForm.city.value,
            address_apt_no: addressForm.apptNumber.value,
            valid:
              addressForm.city.value.trim().length >= 2 &&
              mapTempInfo.street.streetNumber !== "",
            additional_info: addressForm.additionalInfo.value,
          },
          checkedAddress
        );
        if (result === 1) {
          setAddressForm({
            ...addressForm,
            street: {
              ...addressForm.street,
              value: mapTempInfo.street.value,
              valid: true,
              typing: true,
            },
            streetNumber: {
              ...addressForm.streetNumber,
              value:
                mapTempInfo.street.streetNumber !== ""
                  ? mapTempInfo.street.streetNumber
                  : "",
              valid: mapTempInfo.street.streetNumber !== "",
              typing: true,
            },
            city: {
              ...addressForm.city,
              typing: true,
              valid:
                addressForm.city.value.trim().length >= 2 &&
                mapTempInfo.street.streetNumber !== "",
            },
          });
          setLatLng({
            latitude: mapTempInfo.street.lat,
            longitude: mapTempInfo.street.long,
          });
          setOpenMapModal(false);
        }
      } else {
        let result = await props.selectedAddress(
          {
            ...existingAddress,
            address_street: mapTempInfo.street.value,
            latitude: mapTempInfo.street.lat,
            longitude: mapTempInfo.street.long,
            address_no:
              mapTempInfo.street.streetNumber !== ""
                ? mapTempInfo.street.streetNumber
                : existingAddress.address_no,
            valid: true,
          },
          checkedAddress
        );
        if (result === 1) {
          let newExistingAddresses = [];
          for (let item in responseAddresses) {
            if (responseAddresses[item].id === existingAddress.id) {
              newExistingAddresses.push({
                ...responseAddresses[item],
                address_no:
                  mapTempInfo.street.streetNumber !== ""
                    ? mapTempInfo.street.streetNumber
                    : existingAddress.address_no,
                address_street: mapTempInfo.street.value,
              });
            } else {
              newExistingAddresses.push({
                ...responseAddresses[item],
              });
            }
          }
          setResponseAddresses(newExistingAddresses);
          setExistingAddress({
            ...existingAddress,
            address_no:
              mapTempInfo.street.streetNumber !== ""
                ? mapTempInfo.street.streetNumber
                : existingAddress.address_no,
            address_street: mapTempInfo.street.value,
          });
          setAddressForm({
            street: {
              elemType: "input",
              placeholder: "inputs.placeholders.street",
              type: "input",
              name: "street",
              valid: false,
              value: "",
              typing: false,
              rules: {
                required: true,
              },
            },
            streetNumber: {
              elemType: "input",
              placeholder: "inputs.placeholders.street_number",
              type: "input",
              name: "streetNumber",
              valid: false,
              value: "",
              typing: false,
              rules: {
                required: true,
              },
            },
            apptNumber: {
              elemType: "input",
              placeholder: "inputs.placeholders.appt_number",
              type: "input",
              name: "apptNumber",
              valid: false,
              value: "",
              typing: false,
              rules: {
                required: true,
              },
            },
            city: {
              elemType: "input",
              placeholder: "inputs.placeholders.city",
              type: "input",
              name: "city",
              valid: false,
              value: "",
              typing: false,
              rules: {
                required: true,
                minLength: 2,
              },
            },
            additionalInfo: {
              elemType: "input",
              placeholder: "inputs.placeholders.additional_info",
              type: "input",
              name: "additionalInfo",
              valid: false,
              value: "",
              typing: false,
              rules: {
                required: false,
              },
            },
          });

          setLatLng({
            latitude: mapTempInfo.street.lat,
            longitude: mapTempInfo.street.long,
          });
          setOpenMapModal(false);
        }
      }
    }
  };

  const handleChangeRestaurant = () => {
    localStorage.removeItem("basketId");
    dispatch(save_basket_data({}, true));
    props.history.push("/");
  };
  useEffect(() => {
    if (props.show === true) window.scrollTo(0, 0);
  }, [props.show]);

  // const currentTranslated = t('order-page.currentLocation');
  const newLocationTranslated = t("order-page.newLocation");
  const fastesTimeTranslated = t("order-page.fastes-time");
  const chooseTimeTranslated = t("order-page.choose-time");
  let addressesContent = loadingData ? (
    <Spinner logo spinnerStyle="SpinnerYellow" />
  ) : null;

  if (responseAddresses && props.type === "delivery") {
    addressesContent = (
      <Auxilary>
        {responseAddresses.map((item) => {
          return (
            <Col xs="12" className="p-0 pl-4" key={item.id}>
              <AddressRadioCheck
                checked={checkedAddress === item.id ? true : false}
                description={item.address_street}
                handleItemSelection={handleItemSelection}
                id={item.id}
                groupId="locations"
              />
            </Col>
          );
        })}
        <Col xs="12" className="p-0 pl-4">
          <AddressRadioCheck
            checked={checkedAddress === "new" ? true : false}
            description={newLocationTranslated}
            handleItemSelection={handleItemSelection}
            id="new"
            groupId="locations"
          />
        </Col>
      </Auxilary>
    );
  } else {
    if (props.type === "delivery") {
      addressesContent = (
        <Auxilary>
          <Col xs="12" className="p-0 pl-4">
            <AddressRadioCheck
              checked={checkedAddress === "new" ? true : false}
              description={newLocationTranslated}
              handleItemSelection={handleItemSelection}
              id="new"
              groupId="locations"
            />
          </Col>
        </Auxilary>
      );
    }
  }

  let timePickContent = null;
  if (props.type !== "delivery") {
    timePickContent = (
      <Row className="m-0 address-row margin-order-time-row">
        <Col xs="12" xl="5" className="p-0 mb-2 order-type-pickup-dinein">
          <img
            alt="time-icon"
            style={{ width: "20px", height: "20px" }}
            src={iconTime}
          />{" "}
          <span>{t("order-page.delivery-time")}</span>
        </Col>
        <Col xs="12" xl="7" className="radio-desc-styles">
          <Row className="m-0 ">
            <Col xs="12" sm="6" className="p-0">
              <AddressRadioCheck
                checked={orderingType === "fastes" ? true : false}
                description={fastesTimeTranslated}
                handleItemSelection={handleOrderingSelection}
                id="fastes"
                groupId="orderType"
              />
            </Col>
            <Col xs="12" sm="6" className="p-0 pl-sm-2">
              <AddressRadioCheck
                checked={orderingType === "choose" ? true : false}
                description={chooseTimeTranslated}
                handleItemSelection={handleOrderingSelection}
                id="choose"
                groupId="orderType"
              />
            </Col>
          </Row>
        </Col>
        {selectedTime && orderingType !== "fastes" ? (
          <Col
            xs="12"
            className="selected-time"
            onClick={() => setOpenModalTimePick(true)}
          >
            {t("order-page.today_at")} {selectedTime.label}{" "}
            {t("order-page.hours")}
          </Col>
        ) : null}
        {openModalTimePick ? (
          <Auxilary>
            <DeliveryTimesModal
              data={availableTimes}
              handleCloseTimesModal={() => setOpenModalTimePick(false)}
              setSelected={handleSelectedTime}
              selectedTime={selectedTime}
              leadTime={props.leadTime}
            />
            <Backdrop
              address
              className="backdrop"
              show={openModalTimePick}
              backdropClicked={() => setOpenModalTimePick(false)}
            />
          </Auxilary>
        ) : null}
      </Row>
    );
  } else {
    if (checkedAddress && props.leadTime !== -1) {
      timePickContent = (
        <Row className="m-0 address-row margin-order-time-row">
          <Col xs="12" xl="5" className="p-0 mb-2 order-type-pickup-dinein">
            <img
              alt="time-icon"
              style={{ width: "24px", height: "22px" }}
              src={iconTime}
            />{" "}
            <span> {t("order-page.delivery-time")}</span>
          </Col>
          <Col xs="12" xl="7" className="radio-desc-styles">
            <Row className="m-0 ">
              <Col xs="12" sm="6" className="p-0">
                <AddressRadioCheck
                  checked={orderingType === "fastes" ? true : false}
                  description={fastesTimeTranslated}
                  handleItemSelection={handleOrderingSelection}
                  id="fastes"
                  groupId="orderType"
                />
              </Col>
              <Col xs="12" sm="6" className="p-0 pl-sm-2">
                <AddressRadioCheck
                  checked={orderingType === "choose" ? true : false}
                  description={chooseTimeTranslated}
                  handleItemSelection={handleOrderingSelection}
                  id="choose"
                  groupId="orderType"
                />
              </Col>
            </Row>
          </Col>
          {selectedTime && orderingType !== "fastes" ? (
            <Col
              xs="12"
              className="selected-time"
              onClick={() => setOpenModalTimePick(true)}
            >
              {t("order-page.today_at")} {selectedTime.label}{" "}
              {t("order-page.hours")}
            </Col>
          ) : null}
          {openModalTimePick ? (
            <Auxilary>
              <DeliveryTimesModal
                data={availableTimes}
                handleCloseTimesModal={() => setOpenModalTimePick(false)}
                setSelected={handleSelectedTime}
                selectedTime={selectedTime}
                leadTime={props.leadTime}
              />
              <Backdrop
                address
                className="backdrop"
                show={openModalTimePick}
                backdropClicked={() => setOpenModalTimePick(false)}
              />
            </Auxilary>
          ) : null}
        </Row>
      );
    }
  }

  let typeDescription;
  if (props.type === "pickup") typeDescription = t("post.pickUp");
  if (props.type === "dinein") typeDescription = t("post.dineIn");
  let mapComponent = (
    <MapOrderPage
      latitude={latlng.latitude}
      longitude={latlng.longitude}
      addressName={
        existingAddress
          ? existingAddress.address_street
          : addressForm.street.value
      }
      addressNumber={
        existingAddress
          ? existingAddress.address_no
          : addressForm.streetNumber.value
      }
      allowPick={false}
      openMapModalFunc={() => setOpenMapModal(true)}
    />
  );
  let mapComponentModal = (
    <MapOrderPage
      latitude={mapTempInfo.street.lat}
      longitude={mapTempInfo.street.long}
      addressName={mapTempInfo.street.value}
      addressNumber={mapTempInfo.street.streetNumber}
      setNewMapAddressData={setNewMapAddressDataModal}
      handleSubmitingMap={handleSubmitingMap}
      allowPick={true}
    />
  );
  return props.show ? (
    <Auxilary>
      <Row className="m-0 location-row">
        <Col xs="12" className="p-0">
          <Row className="m-0">
            <Col
              xs="2"
              sm="1"
              className="p-0 pr-3 pr-md-0 text-left"
              style={{ maxWidth: "40px" }}
            >
              <div className="address-location-img">
                <img alt="icon location" src={locationIcon} />
              </div>
            </Col>
            <Col xs="6" sm="8" className="p-0">
              <Row className="m-0">
                <Col xs="12" className="p-0 location">
                  {t("order-page.location")}
                </Col>
                <Col xs="12" className="p-0 address">
                  {props.data.restaurant.address}
                </Col>
              </Row>
            </Col>
            <Col xs="4" sm="3" className="p-0 change">
              <div onClick={() => setModalBasketChange(true)}>
                <span> {t("profile.change")}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {props.type === "delivery" ? (
        <Row className="m-0 address-row">
          <Col xs="12" md="6" className="p-0">
            <Row className="m-0">
              <Col xs="12" className="p-0 delivery-address">
                <img alt="icon-cart" src={iconCart} />
                <span>{t("order-page.delivery-address")}</span>
              </Col>
              {addressesContent}
            </Row>
          </Col>
          {checkedAddress &&
          (checkedAddress === "new" || checkedAddress === "current") ? (
            <Col xs="12" className="p-0 pl-4 mt-3 hide-md">
              <Row className="m-0">
                <Col xs="12" md="5" className="p-0 pr-2">
                  <InputOrdering
                    data={addressForm.street}
                    places
                    onChange={onChange}
                    phoneInputClicked={() => {}}
                  />
                </Col>
                <Col xs="6" sm="4" md="2" className="p-0 pr-2  mt-3 mt-md-0">
                  <InputOrdering
                    data={addressForm.streetNumber}
                    onChange={onChange}
                    phoneInputClicked={() => {}}
                  />
                </Col>
                <Col xs="6" sm="4" md="2" className="p-0 pr-2 mt-3 mt-md-0">
                  <InputOrdering
                    data={addressForm.apptNumber}
                    onChange={onChange}
                    phoneInputClicked={() => {}}
                  />
                </Col>
                <Col xs="9" sm="6" md="3" className="p-0 pr-2 mt-3 mt-md-0">
                  <InputOrdering
                    data={addressForm.city}
                    onChange={onChange}
                    phoneInputClicked={() => {}}
                  />
                </Col>
                <Col xs="12" sm="8" md="6" className="p-0 pr-2 mt-3 mt-md-0">
                  <InputOrdering
                    data={addressForm.additionalInfo}
                    onChange={onChange}
                    phoneInputClicked={() => {}}
                  />
                </Col>
                <Col xs="12" className="p-0 mt-3">
                  <div>
                    <label className="container-checkbox">
                      <input
                        type="checkbox"
                        checked={props.saveForFutureUse}
                        onChange={(event) => {
                          props.setSaveForFutureUse(event.target.checked);
                        }}
                      />

                      {t("address.save_address")}
                      <span className="checkmark-checkbox"></span>
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>
          ) : null}
          <Col xs="12" md="6" className=" pr-0 pl-4 pl-md-1 mt-3 mt-md-0">
            <Row className="m-0">
              <Col
                xs="12"
                className="p-0"
                onClick={() => setOpenMapModal(true)}
              >
                {mapComponent}
              </Col>
              <Col xs="12" className="p-0 text-center mt-2">
                <button
                  onClick={() => setOpenMapModal(true)}
                  className="edit-map-button"
                >
                  {t("open-basket.edit")}
                </button>
              </Col>
            </Row>
          </Col>
          {openMapModal ? (
            <Auxilary>
              <Backdrop
                address
                className="backdrop"
                show={openMapModal}
                backdropClicked={() => setOpenMapModal(false)}
              />
              <div className="open-map-modal">
                <Row className="m-0">
                  <Col xs="12" className="p-0 pb-1">
                    <div className="your-location-desc">
                      {t("order-page.your-location")}
                    </div>
                    <InputOrdering
                      places
                      mapModal
                      data={mapTempInfo.street}
                      onChange={onChangeMap}
                      phoneInputClicked={() => {}}
                    />
                  </Col>
                  <Col xs="12" className="p-0">
                    {mapComponentModal}
                  </Col>
                </Row>
                <button
                  className="close-button"
                  onClick={() => {
                    setOpenMapModal(false);
                  }}
                >
                  <div className="mdiv">
                    <div className="md"></div>
                  </div>
                </button>
              </div>
            </Auxilary>
          ) : null}
          {checkedAddress &&
          (checkedAddress === "new" || checkedAddress === "current") ? (
            <Col xs="12" className="p-0 pl-4 mt-3 show-md">
              <Row className="m-0">
                <Col xs="12" md="5" className="p-0 pr-2">
                  <InputOrdering
                    data={addressForm.street}
                    places
                    onChange={onChange}
                    phoneInputClicked={() => {}}
                  />
                </Col>
                <Col xs="6" sm="4" md="2" className="p-0 pr-2  mt-3 mt-md-0">
                  <InputOrdering
                    data={addressForm.streetNumber}
                    onChange={onChange}
                    phoneInputClicked={() => {}}
                  />
                </Col>
                <Col xs="6" sm="4" md="2" className="p-0 pr-2 mt-3 mt-md-0">
                  <InputOrdering
                    data={addressForm.apptNumber}
                    onChange={onChange}
                    phoneInputClicked={() => {}}
                  />
                </Col>
                <Col xs="9" sm="6" md="3" className="p-0 pr-2 mt-3 mt-md-0">
                  <InputOrdering
                    data={addressForm.city}
                    onChange={onChange}
                    phoneInputClicked={() => {}}
                  />
                </Col>
                <Col xs="12" sm="8" md="6" className="p-0 pr-2 mt-3 ">
                  <InputOrdering
                    data={addressForm.additionalInfo}
                    onChange={onChange}
                    phoneInputClicked={() => {}}
                  />
                </Col>
                <Col xs="12" className="p-0 mt-3">
                  <div>
                    <label className="container-checkbox">
                      <input
                        type="checkbox"
                        checked={props.saveForFutureUse}
                        onChange={(event) => {
                          props.setSaveForFutureUse(event.target.checked);
                        }}
                      />

                      {t("address.save_address")}
                      <span className="checkmark-checkbox"></span>
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
      ) : (
        <Row className="m-0 address-row">
          <Col xs="12" className="p-0 order-type-pickup-dinein">
            <span>{t("order-page.type")}</span>
          </Col>
          <Col xs="12" className="">
            <AddressRadioCheck
              checked={true}
              description={typeDescription}
              id="simple_value"
              groupId="singleItem"
            />
          </Col>
        </Row>
      )}
      {openModalLatLng ? (
        <Auxilary>
          <div
            className={
              openModalLatLng
                ? "noLatLng-modal open-modal-display-latlng"
                : "noLatLng-modal closed-modal-display-latlng"
            }
          >
            {t("address.enable_latlng")}
            <button
              className="close-modal-latlng"
              onClick={() => {
                setOpenModalLaLong(false);
              }}
            >
              <img alt="close-icon" src={CloseIcon}></img>
            </button>
          </div>
          <Backdrop
            address
            className="backdrop no-latlng"
            show={openModalLatLng}
            backdropClicked={() => setOpenModalLaLong(false)}
          />
        </Auxilary>
      ) : null}
      {timePickContent}
      {/* {addModal} */}
      {modalBasketChange ? (
        <div className="modal-basket-change">
          <div className="description">
            {t("restaurant_mode.change_restaurant")}
          </div>
          <div className="buttons">
            <button onClick={() => setModalBasketChange(false)}>
              {" "}
              {t("restaurant_mode.cancel")}{" "}
            </button>
            <button onClick={() => handleChangeRestaurant()}>
              {" "}
              {t("restaurant_mode.ok")}{" "}
            </button>
          </div>
        </div>
      ) : null}
      <div
        className={
          timesNotValid
            ? "password-udpate-modal open-modal-display"
            : "password-udpate-modal closed-modal-display"
        }
      >
        {errMessageTimes ? errMessageTimes : t("restaurant_mode.errorTimes")}
      </div>
      <div
        className={
          currentNotValid
            ? "password-udpate-modal open-modal-display"
            : "password-udpate-modal closed-modal-display"
        }
      >
        {t(errCurentAddress)}
      </div>
      <div
        className={
          mapAddressNotValid
            ? "password-udpate-modal open-modal-display"
            : "password-udpate-modal closed-modal-display"
        }
      >
        Ve molime vnesete validna adresa ili izberete od mapa
      </div>
      {loadingDataModal ? (
        <div className="loading-data-modal">
          <Spinner logo spinnerStyle="SpinnerYellow" />;
        </div>
      ) : null}
    </Auxilary>
  ) : null;
};

export default OrderPageDetails;

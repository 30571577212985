import React, {useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getUpsaleConfig } from '../assets/configs/config_calls';
import { basket_upsales_url } from '../assets/urls/urls';
import axios from '../axios-instance_baseURL';
import { save_basket_data } from '../store/actions/basketActions';

import Spinner from '../UI/Spinner';
import UpsaleItem from '../UI/UpsaleItem';


const ModalUpsale = (props) => {
    const [loadingOnAddition, setLoadingOnAddition] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleItemAddition = async (id, quantity) => {
        setLoadingOnAddition(true);
        let config = getUpsaleConfig(props.accessToken, props.lang);
        return axios({
            method: props.editing ? 'put' : 'post',
            url: basket_upsales_url + props.basketId + "/upsale",
            data: { upsale: [{ id, quantity }] },
            headers: config.headers,
            params: {}
        })
            .then(response => {

                if (response.data) {
                    dispatch(save_basket_data(response.data));
                    setLoadingOnAddition(false);
                    return 1;
                }
                setLoadingOnAddition(false);
                return 0;
            })
            .catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    setLoadingOnAddition(false);
                    return 0;
                }
                setLoadingOnAddition(false);
                return 0;
            })
    }


    
    return (
        <div className='modal-upsale'>
            {props.upsaleContinueLoading || loadingOnAddition ?
                <Spinner logo button spinnerStyle="SpinnerYellow" />
                : null
            }

            <button className='close-upsale-button'
                onClick={() => props.handleUpsaleClose()}>
                <div className='mdiv'>
                    <div className='md'></div>
                </div>
            </button>

            <div className='upsale-header'>
                {t('upsale.addWith')}
            </div>
            <div className='upsale-content'>
                {/* {props.editing ? null : */}
                <div className='upsale-info'>
                    {t('upsale.info')}
                </div>
                {/* } */}
                {/* {props.editing ?
                    <UpsaleItem
                        data={editingItem}
                        addItem={handleItemAddition}
                        quantity={editingItem.id}
                    />
                    : */}
                <div className='upsale-items-wrapper'>
                    {props.data.map((item, index) => {
                        return <UpsaleItem
                            key={item.id}
                            data={item}
                            addItem={handleItemAddition}
                            quantity={props.editing || props.overaAllEdit ? item.quantity : null}
                            includeHr={index !== props.data.length - 1}
                        />
                    })}
                </div>
                {/* } */}
                <button className='continue-button'
                    onClick={() => props.handleUpsaleContinue()}> {t('upsale.continue')}</button>
            </div>
        </div >
    )
}

export default ModalUpsale

import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from "react-device-detect";

import { products_search_config } from '../assets/configs/config_calls';
import { product_search_url } from '../assets/urls/urls';
import userIcon from '../assets/images/icons/user.png';
import searchIcon from '../assets/images/icons/search icon menu.svg';
import searchIconOpened from '../assets/images/icons/Search Glyph Icon.png';
import axios from '../axios-instance_baseURL';
import Backdrop from '../UI/Backdrop';
import Aux from '../hoc/Auxilary';

const SearchMenuItems = (props) => {


    const accessToken = useSelector(state => state.auth.accessToken);
    const [searchValue, setSearchValue] = useState("");
    const [inputFocused, setInputFocused] = useState(false);
    const [searchLeft, setSearchleft] = useState(true);
    const [searchClicked, setSearchClicked] = useState(false);
    const [typing, setTyping] = useState(false);
    const inputRef = useRef();
    const { t, i18n } = useTranslation();
    const [expandMenu, setExpandMenu] = useState(false);
    const long = useSelector(state => state.auth.long);
    const lat = useSelector(state => state.auth.lat);

    useEffect(() => {
        const timer = setTimeout(() => {

            if (searchValue.trim().length >= 3 && searchValue === inputRef.current.value) {
                props.setLoadingSearchProp(true);
                const configs = products_search_config(i18n.language, accessToken ? accessToken : "");
                const callurl = product_search_url + '?order_type=' + props.mode + '&menuId=' + props.menuId + '&search=' + searchValue;
                axios.get(callurl, {
                    params: {}, headers: configs.headers
                })
                    .then(response => {
                        if (response.data.status) {
                            props.setSearchDataProp(response.data.menu_items);
                            props.setSearchErrProp(false);
                        } else {
                            props.setSearchDataProp(null);
                            props.setSearchErrProp(true);
                        }
                        props.setLoadingSearchProp(false);
                        setSearchClicked(false);

                    })
                    .catch(err => {
                        if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                            props.history.push({
                                pathname: '/settings/logout',
                                err: "unauthenticated",
                            });
                            return;
                        }
                        
                        props.setSearchErrProp(true);
                        props.setSearchDataProp(null);
                        props.setLoadingSearchProp(false);
                        setSearchClicked(false);

                    });

            } else if (typing && searchValue.trim().length < 3) {
                props.setLoadingSearchProp(false);
                props.setSearchErrProp(false);
                props.setSearchDataProp(null);
                setSearchClicked(false);
                // dispatch(set_restaurants_loading());
                //     let configs = getAllRestaurantsConfig(i18n.language, accessToken ? accessToken : "");
                //     let baseUrl = props.favorites === true ? get_favorites : get_all_restaurants;
                //     let callurl = baseUrl + currentFilters + '&latitude=' + lat + '&longitude=' + long + '&search=' + searchValue + '&size=6&page=1';
                //     axios.get(callurl, {
                //         params: {}, headers: configs.headers
                //     })
                //         .then(response => {
                //             dispatch(set_restaurants({ ...response.data }, lat, long));

                //         })
                //         .catch(err => {
                //             if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                //                 props.history.push({
                //                     pathname: '/settings/logout',
                //                     err: "unauthenticated",
                //                 });
                //                 return;
                //             }
                //             dispatch(set_error());
                //         });
                // }
            }
        }, 800)


        return () => {
            clearTimeout(timer);
        }
    }, [searchValue]);


    useEffect(() => {
        if (!searchClicked || searchValue.trim() === "") return;
        props.setLoadingSearchProp(true);
        const configs = products_search_config(i18n.language, accessToken ? accessToken : "");
        const callurl = product_search_url + '?order_type=' + props.mode + '&menuId=' + props.menuId + '&search=' + searchValue;
        axios.get(callurl, {
            params: {}, headers: configs.headers
        })
            .then(response => {

                if (response.data.status) {
                    props.setSearchDataProp(response.data.menu_items);
                    props.setSearchErrProp(false);
                } else {
                    props.setSearchDataProp(null);
                    props.setSearchErrProp(true);
                }
                props.setLoadingSearchProp(false);
                setSearchClicked(false);

            })
            .catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                props.setLoadingSearchProp(false);
                props.setSearchErrProp(true);
                props.setSearchDataProp(null);
                props.setSearchClicked(false);

            });

    }, [searchClicked]);


    const handleChange = (event) => {
        setSearchValue(event.target.value);
        // dispatch(set_search_value(event.target.value));
        setTyping(true);
    }

    const searchLeave = () => {
        if (!inputFocused && searchValue.trim() === "") {
            props.setClickedProp(false);
            setTyping(false);
            props.setSearchErrProp(false, true);
        }
        setSearchleft(true);
    }

    const inputFocusedHandler = () => {
        setInputFocused(true);
    }


    const searchBlur = () => {
        if (isMobile && searchValue.trim() === "") {
            props.setClickedProp(false);
            props.setSearchErrProp(false, true);
            setTyping(false);
            return;
        }
        if (searchValue.trim() === "" && searchLeft) {
            props.setClickedProp(false);
            props.setSearchErrProp(false, true);
            props.setSearchDataProp(null,true);
            setTyping(false);
        }
    }

    const closeButtonClicked = () => {
        if (searchValue.trim() === "") {
            props.setClickedProp(false);
            setInputFocused(false);
            setSearchleft(true);
            setTyping(false);
            props.setSearchDataProp(null,true);
            props.setSearchErrProp(false, true);
            return;
        }
        setSearchValue("");
        props.setSearchErrProp(false);
        props.setSearchDataProp(null,true);
        // dispatch(set_search_value(""));
        inputRef.current.focus();
    }

    const searchClickHandler = () => {
        inputRef.current.focus();
        setSearchClicked(true);
    }




    let content = (
        props.clicked ?
            (
                <div>
                    <div onMouseOver={() => setSearchleft(false)} onMouseLeave={searchLeave} onBlur={searchBlur} className='search-input'>
                        <div className="search-items">
                            <div onClick={searchClickHandler} className="search-btn">
                                <img alt="user icon" src={searchIconOpened} />
                            </div>
                            <form className="input-form" onSubmit={(event) => { event.preventDefault(); searchClickHandler() }}>
                                <input ref={inputRef} autoFocus onFocus={inputFocusedHandler} onChange={handleChange} placeholder={t('inputs.descriptions.search_food')} value={searchValue}></input>
                            </form>
                            <div onClick={closeButtonClicked} className='close-btn'>
                                <span>X</span>
                            </div>
                        </div>
                    </div>
                </div >
            )
            :
            (
                <div className={''}>

                    <div onClick={() => { setSearchleft(false); props.setClickedProp(true) }} className="search-closed-menu-items">
                        <div className="search-btn-closed">
                            <img alt="user icon" src={searchIcon} style={{ width: "26px", height: "26px" }} />
                        </div>

                    </div>
                </div>
            )
    )



    return (
        content
    )

}


export default SearchMenuItems;

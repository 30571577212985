import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from "react-bootstrap";

import heart from '../assets/images/icons/heart_yellow.svg';
import heartFilled from '../assets/images/icons/heart_yellow_filled.svg';
import allImg from '../assets/images/icons/nine-squares.svg';
import locationIcon from '../assets/images/icons/Location Icon black.png';
import deliverImg from '../assets/images/icons/pickup-car.png';
import pickupImg from '../assets/images/icons/Icon.png';
import { set_filter, remove_filter, clear_filter_modal, set_expanded } from '../store/actions/filterActions';
import Auxilary from '../hoc/Auxilary';
import FavoriteBtn from '../UI/FavoriteBtn';
import FilterOption from './FilterOption';


const Filters = (props) => {

    const [menuOpened, setMenuOpened] = useState(false);
    const bannerText = useSelector(state => state.auth.bannerText);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userData = useSelector(state => state.auth.userData);
    const accessToken = useSelector(state => state.auth.accessToken);
    const authenticated = (accessToken && userData.status);
    const filters = useSelector(state => state.filters);


    return (
        <Col xs={props.col} className={props.showDesktop ? 'mobile-hide-filters-col p-0 filters': (props.hideDesktop ? 'mobile-show-filters-col p-0 filters' : 'p-0 filters')}>
    <div className='filters-wrapper'>
        {/* <div className='location-favorites'>
                    <span className='city'>
                        <img alt="location icon" src={locationIcon} />
                        <span className="city-span">{t('homepage.skopje')}</span>
                    </span>
                    <FavoriteBtn clicked={() => dispatch(set_filter('favorites'))} remove={() => dispatch(remove_filter('favorites'))} history={props.history} accessToken={accessToken} authenticated={authenticated} favorites={props.favorites} />
                </div> */}

        <span
            // className={filters.all && !filters.filter.filterApplied ? 'active-item' : ''}
            className={filters.all ? 'active-item' : ''}
            onClick={() => {
                // if (filters.filter.filterApplied) {
                //     dispatch(set_expanded(false, true, 'all'))
                // } else {
                //     dispatch(set_filter('all'));
                // }
                dispatch(set_filter('all'));

            }}
        >
            <button className='button'>
                <img src={allImg} />
                {t('filters.all')}
            </button>
        </span>

        <span
            // className={filters.delivery && !filters.filter.filterApplied ? 'mobile-hide-filters active-item' : 'mobile-hide-filters'}
            className={filters.delivery ? 'mobile-hide-filters active-item' : 'mobile-hide-filters'}
            onClick={() => {
                // if (filters.filter.filterApplied) {
                //     dispatch(set_expanded(false, true, 'delivery'))
                // } else {
                //     dispatch(set_filter('delivery'));
                // }
                dispatch(set_filter('delivery'));
            }}
        >
            <button className='button'>
                <img src={deliverImg} />
                {t('filters.delivery')}
            </button>
        </span>
        <span
            // className={filters.pickup && !filters.filter.filterApplied ? 'mobile-hide-filters active-item' : 'mobile-hide-filters'}
            className={filters.pickup ? 'mobile-hide-filters active-item' : 'mobile-hide-filters'}
            onClick={() => {
                // if (filters.filter.filterApplied) {
                //     dispatch(set_expanded(false, true, 'pickup'))
                // } else {
                //     dispatch(set_filter('pickup'))
                // }
                dispatch(set_filter('pickup'))

            }}
        >
            <button className='button'>
                <img src={pickupImg} />
                {t('filters.pickUp')}
            </button>
        </span>
        <FilterOption />
    </div>

        </Col >
    )
}

export default Filters;
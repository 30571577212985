import React, { useState, useEffect, useRef } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Spinner from '../UI/Spinner';
import { clearOrderState } from '../store/actions/orderDataActions';
import MenuItem from './MenuItem';
import Backdrop from '../UI/Backdrop';
import MenuModalOrder from './MenuModalOrder'
import { Route } from 'react-router';
import Auxilary from '../hoc/Auxilary';
import SearchMenuItems from '../UI/SearchMenuItems';


const MenuItems = props => {
    const [menuData, setMenuData] = useState(props.data[0].menu_items);
    const [searchData, setSearchData] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState(props.data[0]);
    const [openItem, setOpenItem] = useState();
    const [clicked, setClicked] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [searchErr, setSearchErr] = useState(false);
    const itemsRef = useRef(null);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    let history = useHistory();
    const menuItems = props.data.map(item => {
        return <a className={item.id === openSubMenu.id ? "open-link-item" : ""} key={item.id}
            onClick={() => {
                scrollToItemsTopView();
                setTimeout(() => {
                    setMenuData(item.menu_items);
                    setOpenSubMenu(item);
                }, 200);
            }} >{item.name}</a>
    });


    const scrollToItemsTopView = () => {
        if (!itemsRef.current) return;
        // itemsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline:"nearest" });

        if (itemsRef.current.getBoundingClientRect().top - 10 <= 0) {
            window.scrollTo({
                top: itemsRef.current.getBoundingClientRect().top + window.pageYOffset,
                behavior: "smooth"
            })
        };

    }

    const handleMenuItemClick = (item) => {
        setOpenModal(true);
        setOpenItem(item);
        if (props.history.location.pathname !== '/restaurant/details/' + props.restaurantId) {
            props.history.replace('/restaurant/details/' + props.restaurantId + '/' + item.id);
        } else {
            props.history.push({
                pathname: '/restaurant/details/' + props.restaurantId + '/' + item.id,
                scrollOff: true
            });
        }

    }

    const handleCloseMenuModal = () => {
        if (openModal) {
            props.history.goBack();
        } else {
            props.history.replace({
                pathname: '/restaurant/details/' + props.restaurantId,
                scrollOff: true
            });
        }


        dispatch(clearOrderState());
        setOpenModal(false);
        setOpenItem(null);

    }

    useEffect(() => {
        if (props.match.isExact === false) {
            let foundItem = false;
            for (let item in props.data) {
                let substring = props.history.location.pathname.replace('/restaurant/details/' + props.restaurantId + "/", "");
                for (let item2 in props.data[item].menu_items) {
                    if (props.data[item].menu_items[item2].id === substring) {
                        setOpenItem(props.data[item].menu_items[item2]);
                        foundItem = true;
                        setMenuData(props.data[item].menu_items);
                        setOpenSubMenu(props.data[item]);
                        return;
                    }
                }
                if (foundItem) break;
            }
            if (!foundItem) props.history.replace('/restaurant/details/' + props.restaurantId);
        }
        for (let item in props.data) {
            if (props.data[item].id === openSubMenu.id) {
                setMenuData(props.data[item].menu_items);
                setOpenSubMenu(props.data[item]);
                return;
            }
        }
        setMenuData(props.data[0].menu_items);
        setOpenSubMenu(props.data[0]);
    }, [props.data[0].menu_items]);


    let searchMenuItem = <SearchMenuItems
        setClickedProp={(value) => setClicked(value)}
        clicked={clicked}
        menuId={props.menuId}
        mode={props.mode}
        setLoadingSearchProp={(value) => {
            scrollToItemsTopView();
            setLoadingSearch(value);
            
        }}
        setSearchDataProp={(data, empty = false) => {
            
            if (!empty) {
                scrollToItemsTopView();
                setTimeout(() => {
                    setSearchData(data);
                }, 200);
                return;
            }
            setSearchData(data);

        }}

        setSearchErrProp={(value, empty = false) => {
           
            if (!empty) {
                scrollToItemsTopView();
                setTimeout(() => {
                    setSearchErr(value);
                }, 200);
                return;
            }

            setSearchErr(value);
        }}
    />;



    let renderData = null;
    if (searchErr === true) {
        renderData = <div style={{ width: "100%", textAlign: "center" }}>{t('homepage.errorMsg')}</div>;
    } else if (searchData) {
        if (loadingSearch) {
            renderData = <Spinner logo spinnerStyle="SpinnerYellow" />;
        } else {
            if (searchData.length === 0 && clicked) {
                renderData =
                    <div style={{ width: "100%", textAlign: "center" }}>
                        {t('homepage.noResult')}
                    </div>
            } else {
                renderData = searchData.map(item => {
                    return <MenuItem deliveryTime={props.deliveryTime} handleMenuItemClick={() => handleMenuItemClick(item)} key={item.id} data={item} />
                });
            }
        }
    }
    const menuDataMap = menuData.map(item => {
        return <MenuItem
            deliveryTime={props.deliveryTime}
            handleMenuItemClick={() => handleMenuItemClick(item)}
            key={item.id} data={item} />
    });

    return (
        <div className='menu-items' ref={itemsRef}>

            <div className='div-nav'>
                {clicked ?
                    searchMenuItem
                    :
                    <div >
                        <div className='nav-items'>
                            <div className='nav-link-items'>
                                {menuItems}
                            </div>

                        </div>
                        <div className='search-items-menu'>
                            {searchMenuItem}
                        </div>
                    </div>
                }

            </div>


            <div
                style={{ paddingLeft: "15px", paddingRight: "15px", minHeight: "300px" }}>
                <Row className='m-0 pt-4 pb-4' >
                    {renderData ? renderData : menuDataMap}
                    {/* {openModal ? <MenuModalOrder mode={props.mode} data={openItem} handleCloseMenuModal={handleCloseMenuModal} /> : null}
                    <Backdrop address className='backdrop' show={openModal} backdropClicked={() => handleCloseMenuModal()} /> */}
                    {openItem && !props.history.location.pathname.includes('/more') ?
                        <Auxilary>
                            <Route path={'/restaurant/details/' + props.restaurantId + '/:id'}
                                component={(props) =>
                                    <MenuModalOrder mode={props.mode} data={openItem} handleCloseMenuModal={handleCloseMenuModal} />} />
                            <Backdrop address className='backdrop' show={true && props.history.location.pathname !== '/restaurant/details/' + props.restaurantId} backdropClicked={() => handleCloseMenuModal()} />
                        </Auxilary> : null}

                </Row>
            </div>

        </div>
    )
}

export default MenuItems;
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from '../axios-instance_baseURL';
import { Route } from 'react-router';

import Auxilary from '../hoc/Auxilary';
import phoneIcon from '../assets/images/icons/iconPhone@1x.svg';
import cardIcon from '../assets/images/icons/iconCard@1x.svg';
import cashIcon from '../assets/images/icons/iconCash@1x.svg';
import infoIcon from '../assets/images/icons/information-button.svg';
import AddressRadioCheck from '../UI/AddressRadioCheck';
import InputOrdering from '../UI/InputOrdering';
import { validator } from '../assets/configs/config_functions';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../UI/Spinner';
import OwnRestModal from '../UI/OwnRestModal';
import { basket_url } from '../assets/urls/urls';
import { promo_code_config } from '../assets/configs/config_calls';
import { save_basket_data } from '../store/actions/basketActions';


const OrderPayment = props => {

    const { t, i18n } = useTranslation();
    const accessToken = useSelector(state => state.auth.accessToken);
    const [cardPayment, setCardPayment] = useState(false);
    const [cashPayment, setCashPayment] = useState(false);
    const userData = useSelector(state => state.auth.userData);
    const basketData = useSelector(state => state.basket.basketData);
    const [discountModal, setDiscountModal] = useState(false);
    const [discountMssg, setDiscountMssg] = useState("");
    const [loadingData, setLoadingData] = useState(false);
    const [ownRestModal, setOwnRestModal] = useState(false);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        phone: {
            elemType: "input",
            placeholder: '07XXXXXXX',
            type: "input",
            name: "phone",
            valid: false,
            value: "",
            typing: false,
            rules: {
                required: true,
                isNumeric: true,
                maxLength: 9,
                minLength: 9
            },
        },
        cardNumber: {
            elemType: "input",
            placeholder: 'inputs.placeholders.card_number',
            type: "input",
            name: "cardNumber",
            valid: false,
            value: "",
            typing: false,
            setFirst: false,
            setSecond: false,
            setThird: false,
            rules: {
                required: true,
                isNumericCard: true,
                maxLength: 16,
                minLength: 16
            },
        },
        cardOwnerName: {
            elemType: "input",
            placeholder: 'inputs.placeholders.card_owner_name',
            type: "input",
            name: "cardOwnerName",
            valid: false,
            value: "",
            typing: false,
            rules: {
                required: true,
                minLength: 2
            },
        },
        cardCVV: {
            elemType: "input",
            placeholder: 'inputs.placeholders.card_cvv',
            type: "input",
            name: "cardCVV",
            valid: false,
            value: "",
            typing: false,
            rules: {
                required: true,
                maxLength: 3,
                minLength: 3,
                numeric: true,
            }
        },
        cardDate: {
            elemType: "input",
            placeholder: 'MM/YY',
            type: "input",
            name: "cardDate",
            valid: false,
            value: "",
            typing: false,
            rules: {
                required: true,
                cardDate: true
            },
        },
        promoCode: {
            elemType: "input",
            placeholder: 'inputs.placeholders.promo_code',
            type: "input",
            name: "promoCode",
            valid: basketData.discount_code !== "" ? true : false,
            value: basketData.discount_code !== "" ? basketData.discount_code : "",
            typing: basketData.discount_code !== "" ? true : false,
            rules: {
                required: true,
            },
            applyed: basketData.discount_code !== "" ? true : false,
            disabled: basketData.discount_code !== "" ? true : false
        },
    });

    useEffect(() => {
        if (props.show === true) {
            window.scrollTo(0, 0);
            if (userData && userData.user && userData.user.phone_number !== "" && formData.phone.value.trim() === "") {
                props.handlePaymentDataChange(userData.user.phone_number, "phone", true);
                setFormData({
                    ...formData,
                    phone: {
                        ...formData.phone,
                        valid: true,
                        value: userData.user.phone_number,
                        typing: true
                    }
                })
            }
        }

    }, [props.show]);
    useEffect(() => {
        let stringCheck = formData.cardNumber.value.split("-");
        let concatString = "";
        for (let item in stringCheck) {
            concatString += stringCheck[item];
        }
        let validCard = validator(concatString, formData.cardNumber.rules);
        setFormData({
            ...formData,
            cardNumber: {
                ...formData.cardNumber,
                valid: validCard,
            }
        });
        if (formData.cardNumber.valid !== validCard) {
            props.handlePaymentDataChange(concatString, "cardNumber", validCard)
        }

    }, [formData.cardNumber.value])

    const handleItemSelection = (event, id) => {
        if (id === 'cash') {
            setCashPayment(true);
            setCardPayment(false)
            props.handleTypeChange(id);
        } else {
            setCashPayment(false);
            setCardPayment(true);
            props.handleTypeChange(id);
        }
    }
    const onChange = (event, type) => {

        let valid = validator(event.target.value, formData[type].rules);
        if (type === 'cardCVV' && event.target.value.trim().length > 4) return
        if (type === 'cardDate' && event.target.value.trim().length > 5) return
        if (type === 'cardDate') {
            if (validator(event.target.value, { minLength: 2, maxLength: 2, numeric: true })) {
                if (formData.cardDate.value.charAt(2) === '/') {
                    setFormData({
                        ...formData,
                        [type]: {
                            ...formData[type],
                            value: event.target.value,
                            valid: valid,
                            typing: event.target.value.trim().length !== 0
                        }
                    });
                } else {
                    setFormData({
                        ...formData,
                        [type]: {
                            ...formData[type],
                            value: event.target.value + "/",
                            valid: valid,
                            typing: event.target.value.trim().length !== 0
                        }
                    });
                }

                return;
            }

        }
        if (type === 'cardNumber') {

            if (event.target.value.length > 19) {
                return;
            }
            if (event.target.value.length > 16) {
                if (event.target.value.charAt(4) !== '-' && event.target.value.charAt(8) !== '-' && event.target.value.charAt(12) !== '-') {
                    setFormData({
                        ...formData,
                        [type]: {
                            ...formData[type],
                            value: event.target.value.slice(0, 4) + "-" + event.target.value.slice(4, 8) + "-" + event.target.value.slice(8, 12) + "-" + event.target.value.slice(12, 16),
                            typing: event.target.value.trim().length !== 0,
                            setSecond: true,
                            setFirst: true,
                            setThird: true
                        }
                    });
                    return;
                }
            }

            if (event.target.value.length > 4 && event.target.value.length < 8 && formData.cardNumber.setFirst === false) {
                setFormData({
                    ...formData,
                    [type]: {
                        ...formData[type],
                        value: event.target.value.slice(0, 4) + "-" + event.target.value.slice(4, event.target.value.length),
                        typing: event.target.value.trim().length !== 0,
                        setFirst: true
                    }
                });
                return
            }

            if (event.target.value.length >= 8 && event.target.value.length < 12 && formData.cardNumber.setSecond === false && formData.cardNumber.setFirst === false) {
                setFormData({
                    ...formData,
                    [type]: {
                        ...formData[type],
                        value: event.target.value.slice(0, 4) + "-" + event.target.value.slice(4, 8) + "-" + event.target.value.slice(8, event.target.value.length),
                        typing: event.target.value.trim().length !== 0,
                        setSecond: true,
                        setFirst: true
                    }
                });
                return
            }

            if (event.target.value.length >= 12 && event.target.value.length <= 16 && event.target.value.charAt(4) !== '-' && event.target.value.charAt(8) !== '-' && event.target.value.charAt(12) !== '-') {
                setFormData({
                    ...formData,
                    [type]: {
                        ...formData[type],
                        value: event.target.value.slice(0, 4) + "-" + event.target.value.slice(4, 8) + "-" + event.target.value.slice(8, 12) + "-" + event.target.value.slice(12, event.target.value.length),
                        typing: event.target.value.trim().length !== 0,
                        setFirst: true,
                        setSecond: true,
                        setThird: true
                    }
                });
                return
            }
            if (event.target.value.length === 4) {

                if (formData.cardNumber.setFirst === true) {
                    setFormData({
                        ...formData,
                        [type]: {
                            ...formData[type],
                            value: event.target.value.slice(0, 3),
                            typing: true,
                            setFirst: false
                        }
                    });
                    return;
                }
                setFormData({
                    ...formData,
                    [type]: {
                        ...formData[type],
                        value: event.target.value + "-",
                        typing: true,
                        setFirst: true
                    }
                });
                return;
            } else if (event.target.value.length < 4) {
                setFormData({
                    ...formData,
                    [type]: {
                        ...formData[type],
                        value: event.target.value,
                        typing: event.target.value.trim().length !== 0,
                        setFirst: false,
                        setSecond: false,
                        setThird: false
                    }
                });
                return
            }


            if (event.target.value.length === 9) {
                if (formData.cardNumber.setSecond === true) {

                    setFormData({
                        ...formData,
                        [type]: {
                            ...formData[type],
                            value: event.target.value.slice(0, 8),
                            typing: true,
                            setSecond: false
                        }
                    });
                    return;
                }

                setFormData({
                    ...formData,
                    [type]: {
                        ...formData[type],
                        value: event.target.value + "-",
                        typing: true,
                        setSecond: true
                    }
                });
                return;
            } else if (event.target.value.length < 9 && event.target.value.length > 4) {
                if (formData.cardNumber.setFirst === true && event.target.value.charAt(4) !== '-') {

                    setFormData({
                        ...formData,
                        [type]: {
                            ...formData[type],
                            value: event.target.value.length === 8 ? event.target.value.slice(0, 4) + "-" + event.target.value.slice(4, event.target.value.length) + "-" : event.target.value.slice(0, 4) + "-" + event.target.value.slice(4, event.target.value.length),
                            typing: event.target.value.trim().length !== 0,
                            setFirst: true,
                            setSecond: event.target.value.length === 8 ? true : false,
                            setThird: false
                        }
                    });
                    return
                }

                setFormData({
                    ...formData,
                    [type]: {
                        ...formData[type],
                        value: event.target.value,
                        typing: event.target.value.trim().length !== 0,
                        setSecond: false,
                        setThird: false
                    }
                });
                return
            }


            //third
            if (event.target.value.length === 14) {
                if (formData.cardNumber.setThird === true) {

                    setFormData({
                        ...formData,
                        [type]: {
                            ...formData[type],
                            value: event.target.value.slice(0, 13),
                            typing: true,
                            setThird: false
                        }
                    });
                    return;
                }

                setFormData({
                    ...formData,
                    [type]: {
                        ...formData[type],
                        value: event.target.value + "-",
                        typing: true,
                        setThird: true
                    }
                });
                return;
            } else if (event.target.value.length < 14 && event.target.value.length > 9) {
                if (formData.cardNumber.setFirst === true && event.target.value.charAt(4) !== '-' && formData.cardNumber.setSecond === true && event.target.value.charAt(8) !== '-') {

                    setFormData({
                        ...formData,
                        [type]: {
                            ...formData[type],
                            value: event.target.value.length === 12 ? event.target.value.slice(0, 4) + "-" + event.target.value.slice(4, 8) + "-" + event.target.value.slice(8, event.target.value.length) + "-" : event.target.value.slice(0, 4) + "-" + event.target.value.slice(4, 8) + "-" + event.target.value.slice(8, event.target.value.length),
                            typing: event.target.value.trim().length !== 0,
                            setFirst: true,
                            setSecond: true,
                            setThird: event.target.value.length === 12 ? true : false
                        }
                    });
                    return
                }

                setFormData({
                    ...formData,
                    [type]: {
                        ...formData[type],
                        value: event.target.value,
                        typing: event.target.value.trim().length !== 0,
                        setThird: false
                    }
                });
                return
            }


        }

        if (valid) {
            props.handlePaymentDataChange(event.target.value, type, true);
        } else if (formData[type].valid === true) {
            props.handlePaymentDataChange(event.target.value, type, false);
        }
        setFormData({
            ...formData,
            [type]: {
                ...formData[type],
                value: event.target.value,
                valid: valid,
                typing: event.target.value.trim().length !== 0
            }
        });
    }

    const promoCodeChange = (event, type) => {
        setFormData({
            ...formData,
            promoCode: {
                ...formData.promoCode,
                value: event.target.value,
                valid: event.target.value.trim() === "" ? false : true,
                typing: event.target.value.trim() === "" ? false : true,
            }
        });
    }
    const phoneInputClicked = (event, type) => {
        // if (type !== 'phone') return;
        // if (event.target.value.slice(0, 2) === "07") return;
        // if (event.target.value.slice(0, 2) !== "07" && event.target.value.trim().length < 3) {
        //     setFormData({
        //         ...formData,
        //         phone: {
        //             ...formData.phone,
        //             value: "07",
        //             valid: false
        //         }
        //     });
        // }
    }
    const handleDiscount = () => {
        if (formData.promoCode.applyed === false && formData.promoCode.value.trim() !== "") {
            const config = promo_code_config(accessToken, i18n.language, formData.promoCode.value);
            setLoadingData(true);
            axios.post(basket_url + basketData.id + "/discount", { ...config.body }, { params: {}, headers: config.headers })
                .then(response => {
                    if (response.data.status) {
                        dispatch(save_basket_data(response.data));
                        setFormData({
                            ...formData,
                            promoCode: {
                                ...formData.promoCode,
                                applyed: true,
                                disabled: true
                            }
                        });
                        setDiscountMssg('order-page.discount-succ');
                        setDiscountModal(true);
                        setTimeout(() => {
                            setDiscountModal(false);
                        }, 3000);
                    } else {
                        setDiscountMssg(response.data.message);
                        setDiscountModal(true);
                        setTimeout(() => {
                            setDiscountModal(false);
                        }, 3000);
                    }
                    setLoadingData(false);
                }).catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }
                    setDiscountMssg('homepage.errorMsg');
                    setDiscountModal(true);
                    setTimeout(() => {
                        setDiscountModal(false);
                    }, 3000);
                    setLoadingData(false);
                });
        } else if (formData.promoCode.applyed === false && formData.promoCode.value.trim() === "") {
            setFormData({
                ...formData,
                promoCode: {
                    ...formData.promoCode,
                    typing: true
                }
            })
        } else if (formData.promoCode.applyed === true) {
            const config = promo_code_config(accessToken, i18n.language, formData.promoCode.value);
            setLoadingData(true);
            axios.delete(basket_url + basketData.id + "/discount", { data: { ...config.body }, headers: { ...config.headers } })
                .then(response => {
                    if (response.data.status) {
                        dispatch(save_basket_data(response.data));
                        setFormData({
                            ...formData,
                            promoCode: {
                                ...formData.promoCode,
                                applyed: false,
                                disabled: false,
                                typing: false,
                                value: "",
                                valid: false
                            }
                        });
                    } else {
                        setDiscountMssg(response.data.message);
                        setDiscountModal(true);
                        setTimeout(() => {
                            setDiscountModal(false);
                        }, 3000);
                    }
                    setLoadingData(false);

                }).catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }
                    setLoadingData(false);
                    setDiscountMssg('homepage.errorMsg');
                    setDiscountModal(true);
                    setTimeout(() => {
                        setDiscountModal(false);
                    }, 3000);
                });
        }
    }
    useEffect(() => {
        if (basketData.discount_code.trim() !== "" && props.show === false) {
            const config = promo_code_config(accessToken, i18n.language, formData.promoCode.value);
            setLoadingData(true);
            axios.delete(basket_url + basketData.id + "/discount", { data: { ...config.body }, headers: { ...config.headers } })
                .then(response => {
                    if (response.data.status) {
                        dispatch(save_basket_data(response.data));
                        setFormData({
                            ...formData,
                            promoCode: {
                                ...formData.promoCode,
                                applyed: false,
                                disabled: false,
                                typing: false,
                                value: "",
                                valid: false
                            }
                        });
                    } else {
                        setDiscountMssg(response.data.message);
                        setDiscountModal(true);
                        setTimeout(() => {
                            setDiscountModal(false);
                        }, 3000);
                    }
                    setLoadingData(false);

                }).catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }
                    setLoadingData(false);
                    setDiscountMssg('homepage.errorMsg');
                    setDiscountModal(true);
                    setTimeout(() => {
                        setDiscountModal(false);
                    }, 3000);
                });
        }
    }, [props.show]);
    const handleCloseOwnRestModal = () => {
        setOwnRestModal(false);
        props.history.goBack(1);

    }

    const creditCardTranslation = t('order-page.credit-card');
    const cashTranslation = t('order-page.cash');
    return (
        props.show ? <Auxilary >
            <Row className='m-0 phone-row promo-code-row' >
                <Col xs='12' className='p-0 phone-input promotion-col'>
                    <div className='promo-code'>
                        <InputOrdering data={formData.promoCode} onChange={promoCodeChange} phoneInputClicked={() => { }} />
                        <button disabled={formData.promoCode.applyed === false && formData.promoCode.value.trim() === ""} onClick={handleDiscount} className={formData.promoCode.applyed ? "applyed" : ""}> {formData.promoCode.applyed ? t('open-basket.delete') : t('order-page.apply')}</button>
                    </div>
                </Col>
            </Row>
            <Row className='m-0 phone-row' >
                <Col xs='12' className='p-0 phone-title'>
                    <img alt='phone-icon' src={phoneIcon} />
                    {t('order-page.phone-title')}
                </Col>
                <Col xs='12' className='p-0 phone-description '>
                    {t('order-page.phone-description')}
                </Col>
                <Col xs='12' className='p-0 phone-input '>
                    <InputOrdering data={formData.phone} onChange={onChange} phoneInputClicked={phoneInputClicked} />
                </Col>
            </Row>

            {props.availablePaymentMethods.card === true
                ?
                <Row className='m-0 phone-row' >
                    <Col xs='12' className='p-0 radio-order-payment' >
                        <Row className='m-0'>
                            <Col xs='10' className='p-0' >
                                <AddressRadioCheck
                                    checked={cardPayment}
                                    description={creditCardTranslation}
                                    id="card"
                                    groupId="payment"
                                    handleItemSelection={handleItemSelection}
                                />
                            </Col>
                            <Col xs='2' className='p-0 text-right' >
                                <img alt='card-icon' src={cardIcon} />
                            </Col>

                            {cardPayment ? <Auxilary>
                                <Col xs='12' className='p-0 pading-left-card pb-2 pr-2 provision'>
                                    {/* + 10 {t('post.currency')} {t('order-page.provision')} */}
                                    + 10 {t('post.currency')}
                                </Col>
                                <Col xs='12' className='p-0 pading-left-card pt-2 pb-2 pr-2'>
                                    <InputOrdering data={formData.cardNumber} cardNumber onChange={onChange} phoneInputClicked={phoneInputClicked} />
                                </Col>
                                <Col xs='12' className='p-0 pading-left-card'>
                                    <Row className='m-0'>
                                        <Col xs='12' md='6' xl='6' className='p-0 pb-2 pb-lg-0 pr-2'>
                                            <InputOrdering data={formData.cardOwnerName} onChange={onChange} phoneInputClicked={phoneInputClicked} />
                                        </Col>
                                        <Col xs='6' md='3' xl='3' className='p-0 pr-2'>
                                            <InputOrdering data={formData.cardDate} onChange={onChange} phoneInputClicked={phoneInputClicked} />
                                        </Col>
                                        <Col xs='6' md='3' xl='3' className='p-0 pr-2'>
                                            <InputOrdering data={formData.cardCVV} onChange={onChange} phoneInputClicked={phoneInputClicked} />
                                        </Col>
                                    </Row>
                                </Col>

                            </Auxilary> : null}
                        </Row>
                    </Col>
                </Row>
                : null}
            {props.availablePaymentMethods.cash === true
                ? <Row className='m-0 phone-row mb-3' >
                    <Col xs='12' className='p-0 radio-order-payment' >
                        <Row className='m-0'>
                            <Col xs='10' className='p-0' >
                                <AddressRadioCheck
                                    checked={cashPayment}
                                    description={cashTranslation}
                                    id="cash"
                                    groupId="payment"
                                    handleItemSelection={handleItemSelection}
                                />
                            </Col>
                            <Col xs='2' className='p-0 text-right' >
                                <img alt='cash-icon' src={cashIcon} />
                            </Col>

                        </Row>

                    </Col>
                </Row>
                : null}
            {basketData && basketData.restaurant && basketData.restaurant.is_own_delivery === true  && basketData.order_mode==="delivery" ?
                <Row className='m-0 phone-row mb-3' >
                    <Col xs='12' className='p-0 radio-order-payment' >
                        <Row className='m-0'>
                            <Col xs='12' className=' own-restaurant' >
                                <span >{t("order-page.restaurant-delivery")}</span>
                                <img src={infoIcon} alt="info-icon" onClick={() => {
                                    setOwnRestModal(true);
                                    props.history.push('/order/main/additional-info');
                                }}></img>
                            </Col>
                            <Col xs='12' >
                                <span className='own-rest-info-desc'>
                                    {t('order-page.restaurant-info-main')}
                                </span>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                : null}


            <div className={discountModal ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>
                {t(discountMssg)}
            </div>
            {loadingData ?
                <div className='loading-data-modal' >
                    <Spinner spinnerStyle="SpinnerYellow" />;
                         </div> : null
            }

            {ownRestModal ?
                <Auxilary>
                    <Route path={'/order/main/additional-info'}
                        component={(props) =>
                            <OwnRestModal closeModal={handleCloseOwnRestModal} />} />
                </Auxilary> : null}
        </Auxilary> : null


    )
}

export default OrderPayment;
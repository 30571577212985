import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from '../axios-instance_baseURL';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom'

import { get_item_config, basket_config } from '../assets/configs/config_calls';
import { get_item_url, basket_url } from '../assets/urls/urls';
import { useSelector, useDispatch } from 'react-redux';
import { setMenuItemOrderingId, clearOrderState } from '../store/actions/orderDataActions';
import { save_basket_data } from '../store/actions/basketActions';
// import defaultImage from '../assets/images/logos/image800x400.png'
// import defaultImage from '../assets/images/logos/Logo_2_1.svg';
import defaultImage from '../assets/images/logos/WebItemDetails.jpg';
import ModifierGroup from './ModifierGroup';
import Spinner from '../UI/Spinner';

const MenuModalOrder = props => {
    const accessToken = useSelector(state => state.auth.accessToken);
    const basketId = useSelector(state => state.basket.basketData.id);
    const mode = useSelector(state => state.basket.basketData.order_mode);
    const [modalPrice, setModalPrice] = useState();
    const [orderSize, setOrderSize] = useState(props.editing ? props.basketItemDetails.quantity : 1);
    const [imgError, setImgError] = useState(false);
    const [modifiers, setModifiers] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    // const [orderDefaultPrice, setOrderDefaultPrice] = useState();
    const [currentSizeId, setCurrentSizeId] = useState();
    const [specialInstructions, setSpecialInstructions] = useState(props.editing ? props.basketItemDetails.special_instructions : "");
    const [orderNotValid, setOrderNotValid] = useState(false);
    const [editingModalSizeChanged, setEditingModalSizeChange] = useState(false);
    const { t, i18n } = useTranslation();
    const menu_item_ordering_id = useSelector(state => state.orderItemData.menu_item_ordering_id);
    const imgRef = useRef();
    const refToImage = useRef();
    const [width, setWidth] = useState();
    const [mounted, setMounted] = useState(false);
    const dispatch = useDispatch();
    const modifiersPrice = useSelector(state => state.orderItemData.modifiersPrice);
    const notValidModifiers = useSelector(state => state.orderItemData.notSatisfiedModifiers);
    const modifiersOrderGroup = useSelector(state => state.orderItemData.modifiers);

    useEffect(() => {
        if (props.editing) {
            dispatch(clearOrderState());
        }
        let defaultIndex = 0
        for (let item in props.data.sizes) {
            if (props.editing) {
                if (props.data.sizes[item].name === props.basketItemDetails.size) {
                    defaultIndex = item;
                    break;
                }
            } else {
                if (props.data.sizes[item].is_default) {
                    defaultIndex = item;
                    break;
                }
            }

        }
        if (props.data.sizes.length !== 0) {
            dispatch(setMenuItemOrderingId(props.data.sizes[defaultIndex].menu_item_ordering_id));
            setCurrentSizeId(props.data.sizes[defaultIndex].menu_size_id);
            setModalPrice(props.data.sizes[defaultIndex].price);
            setLoading(true);
            const config = get_item_config(props.mode ? props.mode : mode, props.data.sizes[defaultIndex].menu_size_id, accessToken, i18n.language);
            axios.get(get_item_url + props.data.id + "/options", { params: {}, headers: config.headers })
                .then(response => {
                    if (response.data.status) {
                        setModifiers(response.data);

                    } else {
                        // setErrMsg(response.data.message);
                    }
                    setLoading(false);
                }).catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }
                    setLoading(false);
                    // setLoading(false);
                    // setErrMsg('homepage.errorMsg');
                })
        }
    }, []);

    const handleScroll = (e) => {
        const elem = refToImage;
        if (width <= 800) {
            setWidth(elem.current.clientWidth);
        }
    }

    useEffect(() => {
        if (mounted) {
            setWidth(refToImage.current.clientWidth);
        } else {
            setMounted(true);
        }
    }, [mounted])


    useEventListener('resize', handleScroll);

    const sizesClickHandler = (item) => {

        const config = get_item_config(props.mode ? props.mode : mode, item.menu_size_id, accessToken, i18n.language);
        setLoadingData(true);
        let currentId = item.menu_size_id;
        setCurrentSizeId(item.menu_size_id);
        setModifiers(null);
        axios.get(get_item_url + props.data.id + "/options", { params: {}, headers: config.headers })
            .then(response => {
                dispatch(clearOrderState());
                if (response.data.status) {
                    setTimeout(() => {
                        setModifiers(response.data);
                        setLoadingData(false);
                    }, 300);
                    setEditingModalSizeChange(true);
                    setModalPrice(item.price);
                    dispatch(setMenuItemOrderingId(item.menu_item_ordering_id));
                } else {
                    if (response.data.code === 1005) {
                        setModalPrice(item.price);
                        setCurrentSizeId(item.menu_size_id);
                        dispatch(setMenuItemOrderingId(item.menu_item_ordering_id));
                        setLoadingData(false);
                    } else {
                        setCurrentSizeId(currentId);
                    }
                }
            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setLoadingData(false);
                setCurrentSizeId(currentId);
            })
    }

    // const changeModifierGroupData = (data) => {
    //     let updatedModifier_groups = [];
    //     for (let item in modifiers.modifier_groups) {
    //         if (modifiers.modifier_groups[item].id === data.id) {
    //             updatedModifier_groups.push({
    //                 ...data
    //             })
    //         } else {
    //             updatedModifier_groups.push({
    //                 ...modifiers.modifier_groups[item]
    //             })
    //         }
    //     }
    //     setModifiers({
    //         ...modifiers,
    //         modifier_groups: updatedModifier_groups
    //     })
    // }

    // useEffect(() => {
    //     window.addEventListener('popstate', (event) => {
    //         window.history.go(1);
    //     },false);
    //     return () => {
    //         window.removeEventListener('popstate', (event) => {
                
    //         },false);
    //     }
    // }, [])

    const handleAddingOrder = () => {
        if (menu_item_ordering_id) {
            // if (modifiers && modifiers.modifier_groups.length > 0) {
            //     for (let i in modifiers.modifier_groups) {
            //         for (let j in modifiers.modifier_groups[i].modifiers) {
            //             if (modifiers.modifier_groups[i].modifiers[j].is_default && modifiers.modifier_groups[i].modifiers[j].is_available) {
            //                 modifiersOrder.push({
            //                     "menu_modifier_ordering_id": modifiers.modifier_groups[i].modifiers[j].size.menu_modifier_ordering_id,
            //                     "quantity": orderSize
            //                 });
            //             }
            //         }
            //     }
            // }
            if (notValidModifiers.length !== 0) {
                setOrderNotValid(true);
                setTimeout(() => {
                    setOrderNotValid(false);
                }, 3000);
            } else {
                const config = basket_config(
                    props.data.id,
                    menu_item_ordering_id,
                    orderSize,
                    modifiersOrderGroup,
                    specialInstructions,
                    i18n.language,
                    accessToken);
                setLoadingData(true);
                if (props.editing) {
                    axios.put(basket_url + basketId + "/products/" + props.editingItemId, { ...config.body }, { params: {}, headers: config.headers })
                        .then(response => {
                            setLoadingData(false);
                            if (response.data.status) {
                                dispatch(save_basket_data(response.data));
                                dispatch(clearOrderState());
                                props.handleCloseMenuModal();
                            }
                        }).catch(err => {
                            if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                                props.history.push({
                                    pathname: '/settings/logout',
                                    err: "unauthenticated",
                                });
                                return;
                            }
                            setLoadingData(false);
                        });
                } else {
                    axios.post(basket_url + basketId + "/products", { ...config.body }, { params: {}, headers: config.headers })
                        .then(response => {
                            setLoadingData(false);
                            if (response.data.status) {
                                dispatch(save_basket_data(response.data));
                                dispatch(clearOrderState());
                                props.handleCloseMenuModal();
                            }
                        }).catch(err => {
                            if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                                props.history.push({
                                    pathname: '/settings/logout',
                                    err: "unauthenticated",
                                });
                                return;
                            }
                            setLoadingData(false);
                        });
                }

            }

        }
    }
    // const history = useHistory()

    // window.onpopstate = function () {
    //     history.go(1)
       
    // };
 

    // const calculatePrice = (data) => {
    //     let sum = 0;
    //     for (let item in data) {
    //         for (let modifierId in data[item].modifiers) {
    //             if (data[item].modifiers[modifierId].is_default) {
    //                 sum += data[item].modifiers[modifierId].size.price;
    //             }
    //         }
    //     }
    //     return sum;
    // }

    const sizeChangeHandler = (type) => {
        if (type === 'decrement' && orderSize > 1 && modalPrice) {
            setOrderSize(orderSize - 1);
        }
        if (type === 'increment' && props.data.maximum_quantity > orderSize && modalPrice) {
            setOrderSize(orderSize + 1);
        }
    }

    const setSpecialInstructionsHandler = (event) => {
        if (event.target.value.length <= props.data.special_characters) {
            setSpecialInstructions(event.target.value);
        }
    }

    const heightStyle = width ? width / 2 : null;
    const textAreaPlaceholder = t('inputs.placeholders.special_instrucitons_textarea');

    // let modifiersPrice = 0;
    let modifiersContent = <Spinner logo spinnerStyle="SpinnerYellow" />;
    if (!loading) {
        if (modifiers && modifiers.modifier_groups) {
            // modifiersPrice = calculatePrice(modifiers.modifier_groups);
            modifiersContent = (modifiers.modifier_groups.map((item, index) => {
                if (item.modifiers.length === 0) return null;
                let lastChild = (!props.data.special_instructions && modifiers.modifier_groups.length === index + 1);
                return <ModifierGroup
                    classname={lastChild ? "add-padding" : ""}
                    data={item} key={item.id}
                    updatingModifiers={props.editing && editingModalSizeChanged === false ? props.basketItemDetails.modifiers : null}
                    editing={props.editing && editingModalSizeChanged === false}
                />
            }))
        } else {
            modifiersContent = null;
        }
    }

    return (
        <div className='menu-modal'>
            <Row className='m-0 menu-modal-scroll'>
                <Col ref={refToImage} xs='12' className='p-0'>
                    <img alt='menu-item-background' ref={imgRef} style={{ display: "none" }} src={props.data.web_image_file} onError={() => setImgError(true)} />
                    <div className='image' style={{ backgroundImage: "url(" + ((imgError || props.data.web_image_file === null) ? defaultImage : props.data.web_image_file) + ")", height: heightStyle }} />
                    <button className='close-button' onClick={() => {
                        dispatch(clearOrderState());
                        props.handleCloseMenuModal();
                    }}>
                        <div className="mdiv">
                            <div className="md"></div>
                        </div>
                    </button>
                </Col>
                {/* <div className='hr-colored'></div> */}
                <Col xs='12' className='p-0'>
                    <Row className={!modifiers && !props.data.special_instructions ? 'm-0 order-basic-description add-padding' : "m-0 order-basic-description"}>
                        <Col xs='8' className='p-0 name'>
                            {props.data.name}
                            {/* {props.data.sizes.map(item => {
                                if (item.is_default && !modalPrice) {
                                    return <div className='size-description' key={item.menu_size_id}>-{item.name.toLowerCase()}-</div>
                                } else if (modalPrice && modalPrice === item.price) {
                                    return <div className='size-description' key={item.menu_size_id}>-{item.name.toLowerCase()}-</div>
                                }
                                return null;
                            })} */}
                        </Col>
                        {modalPrice ?
                            <Col xs='4' className='p-0 text-right price'>
                                {modalPrice} {t('post.currency')}
                            </Col>
                            : null}
                        {props.data.description ?
                            <Col xs='12' className='p-0 description'>
                                {props.data.description}
                            </Col>
                            : null}
                        {props.data.allergens ?
                            <Col xs='12' className='p-0 allergens'>
                                {t('menu_modal_order.allergens')}: {props.data.allergens}
                            </Col>
                            : null}
                        <Col xs='12' className='p-0 buttons text-center'>
                            {props.data.sizes.length !== 1 ? props.data.sizes.map(item => {
                                let active = false;
                                // if (item.is_default && !modalPrice) {
                                //     // setModalPrice(item.price);
                                //     active = true;
                                // }
                                if (item.menu_size_id === currentSizeId) active = true;
                                return <button className={active ? 'active-button' : ''} onClick={() => sizesClickHandler(item)} key={item.menu_size_id}>{item.name} </button>
                            }) : null}
                        </Col>

                    </Row>
                </Col>
                <Col xs='12' className='p-0 modifiers'>
                    {modifiersContent ? modifiersContent : null}
                </Col>
                {props.data.special_instructions ?
                    <Col xs='12' className="p-0 special-instructions add-padding">
                        <div className='modifier-group-description'>
                            <div className='name'>
                                {t('menu_modal_order.special_instructions')}
                            </div>
                            <div className='modification-rule'>
                                {t('menu_modal_order.describe_instructions')}
                            </div>
                        </div>
                        <div className='textarea'>
                            <textarea value={specialInstructions}
                                placeholder={textAreaPlaceholder}
                                onChange={(event) => setSpecialInstructionsHandler(event)}
                            />
                        </div>
                    </Col>
                    : null}

                <Col xs='12' className='p-0 modal-order-buttons'>
                    <Row className='m-0 modal-order-buttons-row text-center'>
                        <Col xs='12' sm='4' className='p-0 incremental-buttons text-center justify-content-center'>
                            <button className='minus-sign' onClick={() => sizeChangeHandler("decrement")}></button>
                            <span>{orderSize}</span>
                            <button className='plus-sign' onClick={() => sizeChangeHandler("increment")} ></button>
                        </Col>
                        <Col xs='12' sm='8' className='pl-0 pr-0 pb-0 pt-3 pt-sm-0 order-button' >
                            <button onClick={handleAddingOrder}>
                                <div>{t('menu_modal_order.add_basket')}</div>
                                <div>{modalPrice || modalPrice === 0 ? (parseFloat(modalPrice) * orderSize + parseInt(modifiersPrice) * orderSize).toFixed(0) : "0"} <span>{t('post.currency')}</span></div>
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {loadingData ?
                <div className='loading-data-modal' >
                    <Spinner logo spinnerStyle="SpinnerYellow" />;
                </div> : null
            }
            {notValidModifiers.length !== 0 ?
                <div className={orderNotValid ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>
                    {t('menu_modal_order.choose_at_least')} {notValidModifiers[0].min} {t('menu_modal_order.choose_from')} {notValidModifiers[0].name}
                </div>
                : null}
            
        </div>
    )
}

export default MenuModalOrder;

function useEventListener(eventName, handler, element = window) {

    const savedHandler = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {

            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            const eventListener = event => savedHandler.current(event);

            element.addEventListener(eventName, eventListener, true);


            return () => {
                element.removeEventListener(eventName, eventListener, true);
            };
        },
        [eventName, element]
    );
};


import * as actionTypes from "../actions/actionTypes";

const initialState = {
    loading: true,
    successfuly:true
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_OK_LOADING:
            return {
                ...state,
                loading: action.value,
                successfuly:action.successfuly
            }
        default:
            return state;
    }
};

export default reducer;

import React, { useRef, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';


const SlickSlider = (props) => {

    const refToImage = useRef();
    const [width, setWidth] = useState();
    const [mounted, setMounted] = useState(false);

    const handleScroll = (e) => {
        const elem = refToImage;
        setWidth(elem.current.clientWidth);
    }

    useEffect(() => {
        if (mounted) {
            setWidth(refToImage.current.clientWidth);
        } else {
            setTimeout(() => {
                setMounted(true);
            }, 2000);
        }
    }, [mounted]);

    useEventListener('resize', handleScroll);
    const heightStyle = width ? parseFloat((width / 4))+"" : parseFloat((window.innerWidth-100)/4)+"";

    return (
        <Row className='pr-2 pl-2 m-0 slick-slider-row' >
            <Col xs='12'
                className='p-0 slick-slider-col'
                ref={refToImage}
                onClick={props.onClickFunc}
            >
                <img src={props.web_image} style={{height:heightStyle, width:"100%"}}/>
            </Col>
        </Row>
    )

}

export default SlickSlider;

function useEventListener(eventName, handler, element = window) {

    const savedHandler = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {

            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            const eventListener = event => savedHandler.current(event);

            element.addEventListener(eventName, eventListener, true);


            return () => {
                element.removeEventListener(eventName, eventListener, true);
            };
        },
        [eventName, element]
    );
};
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import OpenBasketDataItem from './OpenBasketDataItem';
import Auxilary from '../hoc/Auxilary';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const OpenBasketData = props => {
    const { t } = useTranslation();
    const basketData = useSelector(state => state.basket.basketData);
    let contentUpsale= basketData?.upsale?.map(item => {
        return <Auxilary key={item.id+item.name+(new Date().getTime().toString() + Math.floor(Math.random()*1000000))}>
            <OpenBasketDataItem
                data={item}
                removeItemHandler={()=>props.removeItemHandler(item.id,true)}
                editItemHandler={props.editItemHandler}
                upsale={true}
            />
            <Col xs='12' className='open-basket-data-hr'>
            </Col>
        </Auxilary>
    })
    return (
        <div className='open-basket-data'>
            <Row className='m-0'>
                <Col xs='12' className='p-0'>
                    <Row className='m-0'>
                        <Col xs='9' className='p-0 description'>
                            {t('open-basket.my-orders')}
                        </Col>
                        <Col xs='3' className='p-0 text-right '>
                            <button className='close-button' onClick={() => props.handleCloseOpenBasketData()}>
                                <div className="mdiv">
                                    <div className="md"></div>
                                </div>
                            </button>
                        </Col>
                        <Col xs='12' className='p-0 restaurant-name'>
                            {basketData?.restaurant?.name}
                        </Col>
                    </Row>
                </Col>
                <Col xs='12' className='p-0 open-basket-items'>
                    <Row className='m-0' >
                        {basketData?.products.map(item => {
                            return <Auxilary key={item.id}>
                                <OpenBasketDataItem
                                    data={item}
                                    removeItemHandler={props.removeItemHandler}
                                    editItemHandler={props.editItemHandler}
                                />
                                <Col xs='12' className='open-basket-data-hr'>
                                </Col>
                            </Auxilary>
                        })}
                     {contentUpsale}
                    </Row>
                </Col>
                <Col xs='12' className='p-0 pt-3'>
                    <button className='open-basket-order' onClick={() => { props.handleFinalOrder() }}>
                        <div>
                            {t('open-basket.pay')}
                        </div>
                        <div>
                            {basketData?.subtotal?.toFixed(0)} {t('post.currency')}
                        </div>
                    </button>
                </Col>
            </Row>
        </div>
    )

}

export default OpenBasketData;
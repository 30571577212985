import React from "react";
import { useTranslation } from "react-i18next";

import { Row, Col } from "react-bootstrap";
import playStoreLogo from "../assets/images/logos/Google_Play_Store_badge_EN.svg";
import appleStoreLogo from "../assets/images/logos/Download_on_the_App_Store_Badge.svg";
import fbIcon from "../assets/images/icons/1-Facebook.svg";
import twitterIcon from "../assets/images/icons/001-twitter.svg";
import instaIcon from "../assets/images/icons/002-instagram.svg";
// import footerLogo from '../assets/images/logos/naracaj logo white new.png';
import footerLogo from "../assets/images/logos/Logo_naracaj_2-1.svg";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Footer = (props) => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <Row className="m-0 text-left">
        <Col xs="12" md="5" className="p-0 footer-logos">
          <div>
            <a href="/">
              <img alt="naracaj's logo" src={footerLogo} />
            </a>
          </div>
          <div className="stores-logo">
            <a
              href="https://play.google.com/store/apps/details?id=triximi.naracaj.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="android store" src={playStoreLogo} />
            </a>
            <a
              href="https://apps.apple.com/us/app/id1502277612"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="ios store" src={appleStoreLogo} />
            </a>
          </div>
        </Col>
        <Col xs="12" md="7" className="p-0 footer-second-paragraph">
          <Row className="m-0  text-md-right">
            <Col xs="12" sm="8" className="p-0">
              <div className="title">{t("footer.about")} naracaj.com</div>
              <ul>
                <li>
                  <a
                    href="https://info.naracaj.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    {t("homepage.about-us")}{" "}
                  </a>
                </li>
                {/* <li>
                  {" "}
                  <a
                    href="https://info.naracaj.com/contact/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("footer.add_rest")}
                  </a>{" "}
                </li> */}
                {/* <li>
                  {" "}
                  <a
                    href="https://info.naracaj.com/own-delivery/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("footer.reg_delivery")}
                  </a>{" "}
                </li> */}
                <li>
                  {" "}
                  <a href="/register">{t("footer.create")}</a>{" "}
                </li>
              </ul>
            </Col>
            <Col xs="12" sm="4" className="p-0">
              <div className="title">{t("footer.info")}</div>
              <ul>
                <li>
                  {" "}
                  <Link to="/faq">{t("footer.faq")}</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/tos">{t("footer.terms")}</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/privacyPolicy">{t("footer.policy")}</Link>{" "}
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
        <Col xs="12" className="p-0">
          <div className="hr"></div>
        </Col>
        <Col xs="12" className="pl-0 pr-0 footer-last">
          <div>
            <a
              href="https://www.facebook.com/naracaj.com.page/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="facebook icon" src={fbIcon} />
            </a>

            <a
              href="https://twitter.com/naracaj"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="twitter icon" src={twitterIcon} />
            </a>

            <a
              href="https://www.instagram.com/naracaj.official/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="instagram icon" src={instaIcon} />
            </a>
          </div>
          <div>©All rights reserved</div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;

import React, { useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from '../axios-instance_baseURL';
import { Col, Row } from 'react-bootstrap';

import Spinner from '../UI/Spinner';
import Input from '../UI/Input';
import { update_password_config } from '../assets/configs/config_calls';
import { update_password_url } from '../assets/urls/urls';

const VALIDITY_CHANGE = "VALIDITY_CHANGE";
const RESET = 'RESET';
const reducer = (state, action) => {
    if (action.type === VALIDITY_CHANGE) {
        if (state.validElements[action.elemName] === false && action.elemValidity) {
            let copy = { ...state.validElements };
            copy[action.elemName] = action.elemValidity;
            let formValid = true;
            for (let item in { ...copy }) {

                if (copy[item] === false) {
                    formValid = false;
                    break;
                }
            }
            const values = { ...state.elementsValues };
            values[action.elemName] = action.elemValue;
            const match = values.password === values.passwordRepeat;
            return {
                ...state,
                validElements: { ...copy },
                elementsValues: { ...values },
                formValid: formValid,
                match: match
            }
        } else if (state.validElements[action.elemName] && action.elemValidity === false) {
            const copy = { ...state.validElements };
            copy[action.elemName] = action.elemValidity;
            return {
                ...state,
                validElements: { ...copy },
                formValid: false,
            }
        } else if (state.validElements[action.elemName] && action.elemValidity) {
            let copy = { ...state.validElements };
            copy[action.elemName] = action.elemValidity;
            let formValid = true;
            for (let item in { ...copy }) {

                if (copy[item] === false) {
                    formValid = false;
                    break;
                }
            }
            const values = { ...state.elementsValues };
            values[action.elemName] = action.elemValue;
            const match = values.password === values.passwordRepeat;
            return {
                ...state,
                validElements: { ...copy },
                elementsValues: { ...values },
                formValid: formValid,
                match: match
            }
        } else {
            return state;
        }

    } else if (action.type === RESET) {
        return {
            validElements: {
                passwordRepeat: false,
                password: false,
                passwordOld: false
            },
            elementsValues: {
                passwordRepeat: "",
                password: "",
                passwordOld: ""
            },
            formValid: false,
            match: false
        }
    }
}


const PasswordUpdate = props => {
    const accessToken = useSelector(state => state.auth.accessToken);
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [errMessage, setErrMessage] = useState(null);
    const [success, setSuccess] = useState(false);

    const [formData] = useState({
        password: {
            elemType: "input",
            placeholder: 'inputs.placeholders.password',
            desc: 'inputs.descriptions.new_password',
            type: "password",
            name: "password",
            valid: false,
            value: "",
            validationMessage: 'inputs.errorMessages.password',
            succesMessage: 'inputs.inputValidMessage',
            typing: false,
            changeAble: true,
            rules: {
                // passwordTriximi: true,
                minLength: 8,
                required: true
            },
        },
        passwordRepeat: {
            elemType: "input",
            placeholder: 'inputs.placeholders.password',
            desc: 'inputs.descriptions.confirm_password',
            type: "password",
            name: "passwordRepeat",
            valid: false,
            value: "",
            validationMessage: 'inputs.errorMessages.password',
            succesMessage: 'inputs.inputValidMessage',
            typing: false,
            changeAble: true,
            rules: {
                // passwordTriximi: true,
                minLength: 8,
                required: true
            },
        },
        passwordOld: {
            elemType: "input",
            placeholder: 'inputs.placeholders.password',
            desc: 'inputs.descriptions.old_password',
            type: "password",
            name: "passwordOld",
            valid: false,
            value: "",
            validationMessage: 'inputs.errorMessages.password',
            succesMessage: 'inputs.inputValidMessage',
            typing: false,
            changeAble: true,
            rules: {
                // passwordTriximi: true,
                minLength: 8,
                required: true
            },
        }
    })
    const [validations, dispatchValidations] = useReducer(reducer, {
        validElements: {
            passwordRepeat: false,
            password: false,
            passwordOld: false
        },
        elementsValues: {
            passwordRepeat: "",
            password: "",
            passwordOld: ""
        },
        formValid: false,
        match: false
    });



    const handleSubmit = (event) => {
        event.preventDefault();
        if (validations.formValid && validations.match) {
            let config = update_password_config(
                accessToken,
                validations.elementsValues.passwordOld,
                validations.elementsValues.password,
                i18n.language);
            setLoading(true);
            axios.post(update_password_url, { ...config.body }, { params: {}, headers: config.headers })
                .then(response => {
                    if (response.data.status) {
                        // setErrMessage('password-update.success');
                        // setSuccess(true);
                        // dispatchValidations({ type: RESET });
                        props.history.push({ pathname: '/settings/additional', updated: true });
                        // setTimeout(() => {
                        //     // setErrMessage(null);
                        //     // setSuccess(false);
                        //     props.history.goBack();
                        // }, 2000);
                    } else {
                        setErrMessage(response.data.message);
                        setSuccess(false);
                        setLoading(false);
                    }

                })
                .catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }
                    setLoading(false);
                    setErrMessage('homepage.errorMsg');
                    setSuccess(false);
                })
        }
    };

    const validChangeHandler = (elemName, elemValidity, elemValue) => {
        dispatchValidations({ type: VALIDITY_CHANGE, elemName: elemName, elemValidity: elemValidity, elemValue: elemValue });
        if (!success) setErrMessage(null);
    }
    // const encode = (data) => {
    //     var str = data.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
    //     return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
    // }

    // useEffect(() => {
    //     let imageConfing = get_img_config(accessToken, i18n.language);
    //     axios.get(get_img_url + userData.user.profile_picture_url, { params: {}, headers: imageConfing.headers })
    //         .then(response => {
    //             if (response.request && response.request.responseURL) {
    //                   setUriImage(response.request.responseURL)
    //             }
    //             setLoadingoverAll(false);
    //         })
    //         .catch(err => {
    //             setLoadingoverAll(false);
    //         })
    // }, [])


    let disabled = true;
    if (validations.match && validations.formValid) {
        disabled = false;
    }
    let  content = (
            <div className='profile-wrapper'>
                {/* <div className='image-part'>
                    <div style={{marginBottom:"10px"}}>
                        <img style={{ width: "100px", height: "100px", borderRadius: "50%" }} src={uriImage ? uriImage : userImg}></img>
                    </div>
                    <div className='gretings-user'> {userData.user.first_name} {userData.user.last_name}</div>
                    <div className='gretings-user' style={{fontSize:"15px"}}>+389{userData.user.phone_number} </div>
                    <div style={{ height: "35px", width: "150px" }}>
                        {loading ? <Spinner spinnerStyle="SpinnerYellow SpinnerButton SpinnerSettingsButton" /> :
                            <button onClick={handleSubmit} disabled={disabled} className='change-button'>
                                {t('profile.save')}
                            </button>}
                    </div>
                </div> */}
                <div className='profile-settings-part'>
                    <form>
                        <Row className='m-0'>
                            <Input
                                className='p-0 col-height-sm'
                                xs='12'
                                data={formData.passwordOld}
                                notifyHandler={validChangeHandler}
                                reset={success}
                            />
                            <Input
                                className='p-0 col-height-sm'
                                xs='12'
                                data={formData.password}
                                notifyHandler={validChangeHandler}
                                match={validations.match}
                                formValid={validations.validElements.password && validations.validElements.passwordRepeat}
                                reset={success}
                            />
                            <Input
                                className='p-0 col-height-sm'
                                xs='12'
                                data={formData.passwordRepeat}
                                notifyHandler={validChangeHandler}
                                match={validations.match}
                                formValid={validations.validElements.password && validations.validElements.passwordRepeat}
                                reset={success}
                            />


                            <Col xs='12' style={{ height: "24px", marginBottom: "5px", marginTop: "-10px" }} className='password-no-match'>
                                {!validations.match &&
                                    (validations.validElements.password &&
                                        validations.validElements.passwordRepeat) ? t('password-update.no-match') : null
                                }
                            </Col>
                            <Col xs='12' className='text-center'>
                                {/* {loading ? <Spinner spinnerStyle="SpinnerYellow SpinnerButton SpinnerUpdatePassword" /> : */}
                                <button onClick={handleSubmit} disabled={disabled} className='change-button-password-update'>
                                    {t('profile.save')}
                                </button>
                            </Col>


                            <Col xs='12' style={{ height: "24px", marginTop: "10px" }} className={success ? 'succes-change' : 'password-no-match'}>
                                {errMessage ? t(errMessage) : null}
                            </Col>
                            {loading ?
                                <div className='loading-data-modal' >
                                    <Spinner logo spinnerStyle="SpinnerYellow" />;
                         </div> : null
                            }
                        </Row>
                    </form>
                </div>
            </div >
        )
    return (
        content
    )
}
export default PasswordUpdate;
import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import Layout from '../hoc/Layout';
import RestaurantMode from '../components/RestaurantMode';
import RestaurantDetails from '../components/RestaurantDetails';
import BasketButton from '../UI/BasketButton';

const RestaurantContainer = props => {
    return (
        <Layout settings history={props.history}>
            <Switch>
                {/* <Route path='/restaurant/mode/:id' component={RestaurantMode} /> */}
                <Route path='/restaurant/details/:id' component={RestaurantDetails} />
                <Route  path='/restaurant' render={()=><Redirect to='/'/>} />
            </Switch>
            <BasketButton history={props.history}/>
        </Layout>
    )

}

export default RestaurantContainer
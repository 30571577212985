import React, { createContext, useState, useEffect } from "react";
import { cities, citiesEN } from "../components/cities";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");

  const handleCity = (street) => {
    const items = street.split(",");
    return items[items.length - 2];
  };

  const handleStreet = (newStreet) => {
    setStreet(newStreet);
  };

  const handleZip = (city) => {
    const foundCity = cities.find((item) => {
      const cityName = item.split(",")[1];
      return cityName === city;
    });

    if (foundCity) {
      const zipCode = foundCity.split(", ")[0];
      return zipCode;
    }

    return null;
  };

  useEffect(() => {
    const updatedCity = handleCity(street);
    setCity(updatedCity);
  }, [street]);

  useEffect(() => {
    const updatedZip = handleZip(city);
    setZip(updatedZip);
  }, [city]);

  return (
    <AppContext.Provider
      value={{
        street,
        city,
        zip,
        handleStreet,
        handleZip,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

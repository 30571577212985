import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import EditImage from '../assets/images/icons/edit.png';
import { validator } from '../assets/configs/config_functions'


const InputEditable = props => {
    const { t } = useTranslation();
    const [editable, setEditable] = useState(false);
    const [inputData, setInputData] = useState(props.data);
    const [mounted, setMounted] = useState(false);
    const inputRef = useRef();

    const editHandler = (event) => {
        setEditable(!editable);
    }
    
    const onChange = (event) => {
        const valid = validator(event.target.value, inputData.rules);
        setInputData({
            ...inputData,
            value: event.target.value,
            valid: valid,
        });
    };

    useEffect(() => {
        if (mounted) {
            props.notifyHandler(inputData.name, inputData.valid, inputData.value);
        } else {
            setMounted(true);
        }
    }, [inputData.value]);

    const inputBlurHandler = () => {
        setEditable(false);
        // props.notifyHandler(inputData.name, inputData.valid, inputData.value);
   }
    useEffect(() => {
        if (editable && inputRef) {
            inputRef.current.focus();
        }
    }, [editable]);
    let inputPhone = null;
    if (inputData.name === 'phone' && inputData.valid && !editable) {
        inputPhone = "(" + inputData.value.substring(0, 3) + ") " + inputData.value.substring(3, 6) + "-" + inputData.value.substring(6, 9);
    }
    return (
        <div className='editable-input-wrapper' >
            <div className='editable-input-name'>{t(props.data.desc)}</div>
            <input
                ref={inputRef}
                onBlur={inputBlurHandler} 
                className={editable ? 'editable-input border-bottom-editable' : 'editable-input'}
                disabled={!editable}
                onChange={(event) => onChange(event)}
                value={inputPhone ? inputPhone : inputData.value}
                // onClick={handleClick}
            />
            <button  onClick={editHandler} className='editable-input-button'><img alt='edit-icon' src={EditImage}/></button>
        </div>
    )
}

export default InputEditable;
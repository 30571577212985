import * as actionTypes from "../actions/actionTypes";

const initialState = {
    delivery: false,
    pickup: false,
    all: true,
    favorites: false,
    filter: {
        deliveryType: 'all',
        sortType: 'LOCATION',
        appliedTags: [],
        filterApplied: false,
        deliveryTypePreState: "",
        sortTypePreState: "",
        appliedTagsPreState: [],
        filterChanged: false
    },
    apiTags: [],
    foodTypes: null,
    expanded: false,
    filterApplySwitch: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FILTER:

            return {
                ...state,
                delivery: false,
                pickup: false,
                all: false,
                [action.filterType]: true,
                filter: {
                    ...state.filter,
                    deliveryType:action.filterType
                }
            };
            break;
        case actionTypes.REMOVE_FILTER:
            return {
                ...state,
                [action.filterType]: false
            }
            break;
        case actionTypes.SET_FOOD_TYPE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    appliedTags: [{...action.foodTypeObject}],
                    appliedTagsPreState: [{...action.foodTypeObject}],
                },
                // filterApplySwitch: false,
                foodTypes: action.foodType,
            
            }
            // return {
            //     ...state,
            //     foodTypes: state.foodTypes.concat(action.foodType)
            // }
            break;
        case actionTypes.REMOVE_FOOD_TYPE:
            // let copy = state.foodTypes.filter(item => item !== action.foodType);
            return {
                ...state,
                filter: {
                    ...state.filter,
                    appliedTags: [],
                    appliedTagsPreState:[]
                },
                foodTypes: null
            }
            // let copy = state.foodTypes.filter(item => item !== action.foodType);
            // return {
            //     ...state,
            //     foodTypes: copy
            // }
            break;
        case actionTypes.SET_FILTER_TYPES:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.filterGroup]: action.filterType,
                    filterChanged: true
                }
            }
            break;
        case actionTypes.CLEAR_FILTER_MODAL:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    deliveryType: state.delivery ? "delivery" : (state.pickup ? "pickup" : "all"),
                    sortType: 'LOCATION',
                    filterApplied: false,
                    appliedTags: state.foodTypes ? [...state.filter.appliedTags] : [],
                    appliedTagsPreState: state.foodTypes ? [...state.filter.appliedTags] : [],
                    deliveryTypePreState: "",
                    sortTypePreState: "",
                    filterChanged: false
                }
            }
            break;
        case actionTypes.CLEAR_FILTER:
            return {
                ...state,
                delivery: false,
                pickup: false,
                all: true,
                favorites: false,
                filter: {
                    deliveryType: 'all',
                    sortType: 'LOCATION',
                    appliedTags: [],
                    filterApplied: false,
                    deliveryTypePreState: "",
                    sortTypePreState: "",
                    appliedTagsPreState: [],
                    filterChanged: false
                },
                expanded: false,
                filterApplySwitch: false,
                foodTypes: null,
            }
        case actionTypes.FILTER_APPLIED:
            let copyTagsApplied = state.filter.appliedTags.map(item => {
                return {
                    ...item
                }
            });
            let copyTagsApplied2 = state.filter.appliedTags.map(item => {
                return {
                    ...item
                }
            });
            return {
                ...state,
                delivery: false,
                pickup: false,
                all: false,
                [state.filter.deliveryType]: true,
                filter: {
                    ...state.filter,
                    filterApplied: true,
                    deliveryTypePreState: state.filter.deliveryType,
                    sortTypePreState: state.filter.sortType,
                    appliedTags: copyTagsApplied,
                    appliedTagsPreState: copyTagsApplied2,
                    filterChanged: false

                },
                filterApplySwitch: !state.filterApplySwitch,
                foodTypes: null,
                expanded:false
            }
            break;
        case actionTypes.SET_API_TAGS:
            return {
                ...state,
                apiTags: action.tags.splice(0)
            }
            break;
        case actionTypes.SET_TAG_CHECKED:
            const tagsIds = state.filter.appliedTags.map(item => item.tag_id);
            let copyTags = [];
            if (tagsIds.includes(action.tag.tag_id)) {
                for (let index in state.filter.appliedTags) {
                    if (state.filter.appliedTags[index].tag_id !== action.tag.tag_id)
                        copyTags.push({ ...state.filter.appliedTags[index] })
                }
            } else {
                copyTags = state.filter.appliedTags;
                copyTags.push({ ...action.tag })
            }
            return {
                ...state,
                filter: {
                    ...state.filter,
                    appliedTags: copyTags,
                    filterChanged: true
                }
            }
            break;
        case actionTypes.SET_EXPANDED:
            if (action.closeAndClear === true) {
                return {
                    ...state,
                    delivery: false,
                    pickup: false,
                    all: false,
                    favorites: false,
                    [action.typePick]: true,
                    filter: {
                        ...state.filter,
                        deliveryType: 'all',
                        sortType: 'LOCATION',
                        appliedTags: [],
                        filterApplied: false,
                        deliveryTypePreState: "",
                        sortTypePreState: "",
                        appliedTagsPreState: [],
                        filterChanged: false

                    },
                    expanded: false
                }
            }
            if (action.value === false) {
                if (state.filter.filterApplied && state.filter.filterChanged) {
                    let copyTagsAplied3 = state.filter.appliedTagsPreState.map(item => { return { ...item } })
                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            deliveryType: state.filter.deliveryTypePreState,
                            sortType: state.filter.sortTypePreState,
                            appliedTags: copyTagsAplied3,
                            filterChanged: false
                        },
                        expanded: false
                    }
                } else if (!state.filter.filterApplied && state.filter.filterChanged) {
                    return {
                        ...state,
                        filter: {
                            deliveryType: 'all',
                            sortType: 'LOCATION',
                            appliedTags: state.foodTypes ? [...state.filter.appliedTags] : [],
                            filterApplied: false,
                            deliveryTypePreState: "",
                            sortTypePreState: "",
                            appliedTagsPreState: [],
                            filterChanged: false
                        },
                        expanded: false
                    }
                } else {
                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            filterChanged: false
                        },
                        expanded: false
                    }
                }

            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        filterChanged: false
                    },
                    expanded: true
                }
            }

        default:
            return state;
    }
};

export default reducer;

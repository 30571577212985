export const get_all_restaurants = '/v2/restaurants/';
export const login_url = '/v2/user/login';
export const register_url = '/v2/user/signup';
export const facebook_url_api = '/v2/user/facebook_connect';
export const user_profile_url = '/v1/user/profile';
export const logout_url = '/v1/user/logout';
export const forgotPassword_url = '/v2/user/forgot_password';
export const update_profile_url = '/v1/user/profile';
export const get_img_url = '/v1/images/';
export const update_password_url = '/v1/user/update_password'
export const restaurant_details_url = '/v1/restaurants/';
export const add_save_address_url = '/v1/user/my_addresses'; 
export const get_addresses_url = '/v1/user/my_addresses';
export const set_default_address_url = '/v1/user/my_addresses/default';
export const create_basket_url = '/v1/user/basket/create';
export const get_restaurant_menu_url = '/v1/menu/';
export const get_item_url = '/v1/items/';
export const basket_url = '/v1/user/basket/';
export const basket_upsales_url = '/v1/user/basket/';
export const get_address_api_url = '/v1/google/address';
export const get_times_url = '/v1/times/';
export const get_orders_url = '/v1/user/basket/active';
export const get_orders_completed_url = '/v1/user/basket/completed';
export const get_favorites = '/v1/restaurants/favorite';
export const news_url = '/v1/news';
export const gallery_promo_url='/v1/gallery/promo';
export const get_tags_url = '/v2/tags/';
export const product_search_url='/v2/products/search'
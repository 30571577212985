import * as actionTypes from "../actions/actionTypes";

const initialState = {
    menu_item_ordering_id: null,
    modifiers: [],
    notSatisfiedModifiers: [],
    modifiersWithPrice: [],
    modifiersPrice: 0

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MENU_ITEM_ORDERING_ID:            
            return {
                ...state,
                menu_item_ordering_id: action.id,
            }
        case actionTypes.SET_MODIFIERS_PRICE:
            let newPrice = 0;
            let newModifiersWithPrice = [];
            if (state.modifiersWithPrice.length === 0) {
                newPrice = action.totalGroupPrice;
                newModifiersWithPrice.push({
                    id: action.modifierGroupId,
                    price: action.totalGroupPrice
                });
            } else {
                let newItem = true;
                for (let item in state.modifiersWithPrice) {
                    if (state.modifiersWithPrice[item].id === action.modifierGroupId) {
                        newPrice += action.totalGroupPrice;
                        newModifiersWithPrice.push({
                            id: action.modifierGroupId,
                            price: action.totalGroupPrice
                        });
                        newItem = false;
                    } else {
                        newPrice += state.modifiersWithPrice[item].price;
                        newModifiersWithPrice.push({
                            id: state.modifiersWithPrice[item].id,
                            price: state.modifiersWithPrice[item].price
                        })
                    }
                }
                if (newItem) {
                    newPrice += action.totalGroupPrice;
                    newModifiersWithPrice.push({
                        id: action.modifierGroupId,
                        price: action.totalGroupPrice
                    });
                }
            }

            return {
                ...state,
                modifiersWithPrice: newModifiersWithPrice,
                modifiersPrice: newPrice
            }
        case actionTypes.SET_MODIFIERS_NOT_VALID:
            let newNotSatisfiedModifiers = [];
            if (state.notSatisfiedModifiers.length === 0 && (action.min !== 0 || action.required)) {
                newNotSatisfiedModifiers.push({
                    id: action.id,
                    name: action.name,
                    min: action.min
                });
            } else {
                let newItem = true;
                for (let item in state.notSatisfiedModifiers) {
                    if (state.notSatisfiedModifiers[item].id === action.id && (action.min !== 0 || action.required)) {
                        newNotSatisfiedModifiers.push({
                            id: action.id,
                            name: action.name,
                            min: action.min
                        });
                        newItem = false;
                    } else {
                        newNotSatisfiedModifiers.push({
                            id: state.notSatisfiedModifiers[item].id,
                            name: state.notSatisfiedModifiers[item].name,
                            min: state.notSatisfiedModifiers[item].min,
                        });
                    }
                }
                if (newItem && (action.min !== 0 || action.required)) {
                    newNotSatisfiedModifiers.push({
                        id: action.id,
                        name: action.name,
                        min: action.min
                    });
                }
            }

            return {
                ...state,
                notSatisfiedModifiers: newNotSatisfiedModifiers
            }
        case actionTypes.REMOVE_MODIFIERS_NOT_VALID:
            let newNotSatisfiedModifiers2 = [];
            for (let item in state.notSatisfiedModifiers) {
                if (state.notSatisfiedModifiers[item].id !== action.id) {
                    newNotSatisfiedModifiers2.push({
                        ...state.notSatisfiedModifiers[item]
                    })
                }
            }
            return {
                ...state,
                notSatisfiedModifiers: newNotSatisfiedModifiers2
            }
        case actionTypes.SET_MODIFIERS_FOR_ORDER:
            let updatedModifiers = []
            if (state.modifiers.length === 0 && action.value === true) {
                if (action.subgroup && action.subgroup.parentOrderingId) {
                    let actionSubgroup = action.subgroup;
                    let subGroup = {
                        menu_modifier_ordering_id: actionSubgroup.parentOrderingId,
                        menu_modifiers_sub: []
                    }
                    while (true) {
                        if (!actionSubgroup.menu_modifiers_sub) break;
                        let modifierSubArray = subGroup.menu_modifiers_sub;
                        modifierSubArray.push({
                            menu_modifier_ordering_id: actionSubgroup.menu_modifiers_sub,
                            menu_modifiers_sub: []
                        });
                        subGroup = {
                            ...subGroup,
                            menu_modifiers_sub: modifierSubArray 
                        }
                        actionSubgroup = { ...actionSubgroup.subModifiers }
                    }
                    updatedModifiers.push({
                        ...subGroup
                    });
                } else {
                    updatedModifiers.push({
                        menu_modifier_ordering_id: action.id
                    });
                }
            } else {
                let newItem = true;
                for (let item in state.modifiers) {
                    if (state.modifiers[item].menu_modifier_ordering_id === action.id) {
                        newItem = false;
                        if (action.value !== false) {
                            if (action.subgroup && action.subgroup.parentOrderingId) {
                                if (!state.modifiers[item].menu_modifiers_sub) {
                                    let actionSubgroup = action.subgroup;
                                    let modifierSubArray2=[]
                                    modifierSubArray2.push({
                                        menu_modifier_ordering_id: actionSubgroup.menu_modifiers_sub,
                                        menu_modifiers_sub: []
                                    });
                                        let subGroup = {
                                            menu_modifier_ordering_id: actionSubgroup.parentOrderingId,
                                            menu_modifiers_sub: modifierSubArray2
                                        }
                                        updatedModifiers.push({
                                            ...subGroup
                                        });
                                } else {
                                    // nth-level adding
                                    let actionSubgroup = action.subgroup;
                                    let currentSubgroup = state.modifiers[item];

                                    let subGroup = {
                                        menu_modifier_ordering_id: actionSubgroup.parentOrderingId,
                                        menu_modifiers_sub: []
                                    }
                                    while (true) {
                                        if (!actionSubgroup.menu_modifiers_sub) break;
                                        let modifierSubArray = subGroup.menu_modifiers_sub;
                                        let tempCurrentSubgroup;
                                        if (currentSubgroup && currentSubgroup.menu_modifiers_sub) {
                                            let found = false;
                                            for (let item in currentSubgroup.menu_modifiers_sub) {
                                                if (currentSubgroup.menu_modifiers_sub[item].menu_modifier_ordering_id === actionSubgroup.menu_modifiers_sub) {
                                                    found = true;
                                                    tempCurrentSubgroup = currentSubgroup.menu_modifiers_sub[item];
                                                    modifierSubArray.push({
                                                        menu_modifier_ordering_id: actionSubgroup.menu_modifiers_sub,
                                                        menu_modifiers_sub: currentSubgroup.menu_modifiers_sub[item].menu_modifiers_sub ? currentSubgroup.menu_modifiers_sub[item].menu_modifiers_sub.slice(0) : []
                                                    });
                                                } else {
                                                    modifierSubArray.push({
                                                        ...currentSubgroup.menu_modifiers_sub[item]
                                                    })
                                                }
                                                if (found) {
                                                    currentSubgroup = tempCurrentSubgroup;
                                                } else {
                                                    currentSubgroup = null;
                                                    modifierSubArray.push({
                                                        menu_modifier_ordering_id: actionSubgroup.menu_modifiers_sub,
                                                        menu_modifiers_sub:[]
                                                    });
                                                }
                                              
                                            }
                                        } else {
                                            modifierSubArray.push({
                                                menu_modifier_ordering_id: actionSubgroup.menu_modifiers_sub,
                                                menu_modifiers_sub:[]
                                            });
                                        }

                                     
                                     
                                        subGroup = {
                                            ...subGroup,
                                            menu_modifiers_sub: modifierSubArray 
                                        }
                                        actionSubgroup = { ...actionSubgroup.subModifiers }
                                    }
                                    updatedModifiers.push({
                                        ...subGroup
                                    });
                                    
                                }
                            } else {
                                updatedModifiers.push({
                                    ...state.modifiers[item],
                                    menu_modifier_ordering_id: action.id,

                                })
                            }
                         
                        }
                    } else {
                        updatedModifiers.push({
                            ...state.modifiers[item]
                        });
                    }

                }
                if (newItem && action.value === true) {
                    if (action.subgroup && action.subgroup.parentOrderingId) {
                        let actionSubgroup = action.subgroup;
                        let subGroup = {
                            menu_modifier_ordering_id: actionSubgroup.parentOrderingId,
                            menu_modifiers_sub: []
                        }
                        while (true) {
                            if (!actionSubgroup.menu_modifiers_sub) break;
                            let modifierSubArray = subGroup.menu_modifiers_sub;
                            modifierSubArray.push({
                                menu_modifier_ordering_id: actionSubgroup.menu_modifiers_sub,
                                menu_modifiers_sub: []
                            });
                            subGroup = {
                                ...subGroup,
                                menu_modifiers_sub: modifierSubArray 
                            }
                            actionSubgroup = { ...actionSubgroup.subModifiers }
                        }
                        updatedModifiers.push({
                            ...subGroup
                        });
                    } else {
                        updatedModifiers.push({
                            menu_modifier_ordering_id: action.id
                        });
                    }
                }
            }

            return {
                ...state,
                modifiers: updatedModifiers
            }
        case actionTypes.CLEAR_ORDER_STATE:
            return {
                ...state,
                modifiers: {},
                notSatisfiedModifiers: [],
                modifiersWithPrice: [],
                modifiersPrice: 0,
                menu_item_ordering_id: null
            }
        default:
            return state;
    }
};

export default reducer;

import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import axios from '../axios-instance_baseURL';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col } from "react-bootstrap";

import { facebook_login_confing, user_profile_config } from '../assets/configs/config_calls';
import { facebook_url_api, user_profile_url } from '../assets/urls/urls';
import { setUserData } from '../store/actions/authActions';
import { isMobile, osName, osVersion, isAndroid, isWinPhone, isIOS } from "react-device-detect";

const FacebookLoginComp = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [errMsg, setErrMsg] = useState();
    const long = useSelector(state => state.auth.long);
    const lat = useSelector(state => state.auth.lat);
    const responseFacebook = (data) => {
        if (data.accessToken && data.email && data.userID) {
            let osType = 'Web'
            if (isMobile) {
                if (isAndroid) osType = 'Android';
                if (isIOS) osType = 'iPhone';
                if (isWinPhone) osType = 'WinPhone';
            }
            let config = facebook_login_confing(data.email, data.accessToken, data.userID, data.name, i18n.language, parseFloat(lat), parseFloat(long), osType, osName, osVersion);
            axios.post(facebook_url_api, { ...config.body }, {
                params: {}, headers: config.headers
            }).then(response => {
                if (response.data.status) {
                    let configUser = user_profile_config(response.data["x-access-token"], i18n.language);
                    const accesToken = response.data["x-access-token"];
                    axios.get(user_profile_url, {
                        params: {}, headers: configUser.headers
                    }).then(response => {
                        if (response.data.status) {
                            dispatch(setUserData(response.data, accesToken));
                            if (props.location.redirect && props.location.redirect === 'favorites') {
                                props.history.push("/favorites");
                            } else if (props.location && props.location.state && props.location.state.favoriteId) {
                                localStorage.setItem("favoriteLoginId", props.location.state.favoriteId);
                                props.history.push("/favorites");
                            } else {
                                props.history.push("/");
                            }

                        } else {
                            setErrMsg(response.data.message)
                        }
                    }).catch(err => {
                        if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                            props.history.push({
                                pathname: '/settings/logout',
                                err: "unauthenticated",
                            });
                            return;
                        }
                        setErrMsg('homepage.errorMsg');
                    })
                } else {
                    setErrMsg(response.data.message)
                }
            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setErrMsg('homepage.errorMsg');
            })
        }
    }
    const componentClicked = (data) => {
        // if (data.accessToken && data.email && data.userID) {
        //     let config = facebook_login_confing (data.email, data.accessToken, data.userID);
        //     axios.post(facebook_url_api, { ...config.body }, {
        //         params: {}, headers: config.headers
        //     }).then(response => {

        //     }).catch(error => {
        //     })
        // }
    }
    return (
        <div>
            <FacebookLogin
                appId={process.env.REACT_APP_FB_ID}
                autoLoad={false}
                fields="name,email"
                // onClick={componentClicked}
                callback={responseFacebook}
                icon="fa-facebook"
                textButton={t("facebook.login")}
                disableMobileRedirect={true}
            />
            {errMsg ? (
                <Col xs="12">
                    <label className="span-style">{t(errMsg)}</label>
                </Col>
            ) : null}
        </div>

    )
}

export default FacebookLoginComp;
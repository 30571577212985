import React, { useEffect, useState } from 'react';

export const FilterTags = (props) => {
    const [firstInitiate, setFirstInitiate] = useState(false);
    useEffect(() => {
        props.initiateCall(props.params.id);
        return () => {
            props.initiateCall(null);
        }
    }, [props.params.id]);

    return (
        null
    )
}

export default React.memo(FilterTags);

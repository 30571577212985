import React from "react";
import "../assets/styles/UI/_faq.scss";

export const Tos = () => {
  return (
    <div className="container">
      <div className="faq">
        <br />
        <br />
        <h2>УСЛОВИ ЗА КОРИСТЕЊЕ</h2>
        <br />
        <p>
          Користејќи ја Услугата Нарачај, која ја нуди Нарачај ДОО Скопје, вие
          се согласувате дека и се обврзувате да ги почитувате овие Услови за
          користење на корисниците („Услови за користење“)
        </p>
        <br />
        <p>
          Ве молиме, внимателно прочитајте ги следниве Услови за користење пред
          да ја користите Услугата Нарачај. Ако не се согласувате со Условите за
          користење, Ве молиме, не користете ја Услугата Нарачај. Овие Услови за
          користење се применуваат за какво било користење на Услугата Нарачај и
          за Договорите за набавка направени во понатамошниот текст. Подетален
          опис на Услугата Нарачај и информации за системските побарувања се
          достапни на naracaj.com.
        </p>
        <br />
        <h2>1. Дефиниции</h2>
        <br />
        <p>
          „Партнер“ значи ресторан или друг услужен партнер кој има потпишано
          Договор за партнерство со Нарачај и кој ги нуди своите производи и
          услуги за испорака, доколку е применливо, преку услугата Нарачај.
          <br />
          „Договор за набавка“ значи договор за набавка на производи на
          Партнерот и можни услуги за испорака направени по нарачка. Во однос на
          нарачките поставени преку Услугата Нарачај, Договорот за набавка
          претставува обврзувачки договор помеѓу Партнерот и Корисникот.
          <br />
          „Нарачај“ значи:
          <br />
          НАРАЧАЈ ДОО Скопје, адреса ул. Партизански одреди бр.34/1-6, Скопје –
          Центар.
          <br /> e-mail: contact@naracaj.com
          <br />
          „Naracaj App“ значи мобилна апликација наречена Нарачај, обезбедена од
          Нарачај за физички и правни лица да нарачуваат производи од партнерите
          на Нарачај.
          <br />
          „Naracaj App“ значи мобилна апликација наречена Нарачај, обезбедена од
          Нарачај за физички и правни лица да нарачуваат производи од партнерите
          на Нарачај.
          <br />
          „Naracaj WEB“ значи интернет страна наречена Нарачај, обезбедена од
          Нарачај за физички и правни лица да нарачуваат производи од партнерите
          на Нарачај.
          <br />
          „Услуга Naracaj“ значи и мобилната апликација Naracaj App и/или веб
          страната naracaj.com.
          <br />
          „Корисник“ или „вие“ значи физичко лице што ја користи Услугата
          Naracaj.
        </p>
        <br />
        <h2>2. Опис на Услугата Нарачај</h2>
        <br />
        <p>
          2.1. Нарачај обезбедува платформа на која Корисникот може да набави
          прехранбени производи, алкохол и услуги за испорака од Партнерот по
          негов избор.
          <br />
          2.2. Партнерите ги објавуваат информациите за нивните производи и
          услуги во Услугата Нарачај, вклучително и информации за менијата (како
          име на продукти, опис на продукт, додатоци, алергени и фотографии) и
          цените на производите. Информациите за менијата, објавени на мобилната
          апликација или веб страна, во делот мени на Партнерот, се одговорност
          на и само на Партнерот. Нарачај не гарантира за нивната веродостојност
          и истите ги проследува како што му се предадени од Партнерот и
          потврдени од Партнерот пред пуштање на истите во финална употреба пред
          корисниците, Нарачај не подлежи на авторски права, тоа е одговорност
          на Партнерот. Ако имате алергии или други ограничувања во исхраната,
          ве молиме контактирајте го релевантниот Партнер пред да направите
          нарачка. Партнерот ќе ви обезбеди информации за храната, на ваше
          барање.
          <br />
          2.3. Продажбата и купувањето на производи и услуги на Партнерот може
          да подлежат на дополнителни услови на Партнерите, како што е утврдено
          во Услугата Нарачај. При изборот на производите и услугите на
          Партнерот, кои Корисникот сака да ги купи од Партнерот, Корисникот
          донесува обврзувачка наредба да ги набави производите и услугите од
          Партнерот, во склад со правата и обврските претставени на Корисникот
          во Услугата Нарачај пред да ја постави нарачката ("Нарачка"). Откако
          ќе ја прими Нарачката, Нарачај ќе ги пренесе деталите за Нарачката до
          Партнерот. Кога нарачката ќе биде прифатена од Партнерот и кога
          Нарачај ќе му достави на Корисникот потврда за нарачката во име на
          Партнерот, Корисникот и Партнерот го склучуваат Договорот за набавка.
          Нарачај ќе му достави на Корисникот потврда во име на Партнерот.
          <br />
          2.4. Партнерот избран од Корисникот ќе ги подготви (и испорача,
          доколку се нарачани услуги за испорака), производите утврдени со
          Нарачката до Корисникот. Нарачај не е одговорен кон Корисникот за
          правилно извршување на Договорот за набавка од страна на Партнерот.
          <br />
          2.5. Откако Нарачај ќе му достави на Корисникот потврда за нарачката
          во име на Партнерот, Нарачката не може да се откаже. Вие не можете да
          повлечете или откажете налог за производи или услуги за испорака
          откако ќе биде потврден. Пред да поставите Нарачка за производ или
          услуга, треба внимателно да го разгледате вашиот избор.
        </p>
        <br />
        <h2>3. Кориснички сметки</h2>
        <br />
        <p>
          3.1. Вие имате ограничено, не ексклузивно, непреносливо и незаменливо
          право да ја користите Услугата Нарачај во формата која ви ја нудиме
          само за целите утврдени овде.
          <br />
          3.2. За да ја користи Услугата Нарачај, Корисникот може да направи
          Корисничка сметка следејќи ги упатствата за регистрација во Услугата
          Нарачај. Податоците за услугата Нарачај се лични. Корисникот осигурува
          дека сите податоци за корисничката сметка и релевантните информации
          потребни за пристап до корисничката сметка на Корисникот, се чуваат
          доверливи и се користат на безбеден начин што не е достапен од трети
          страни. Корисникот може да има само една лична корисничка сметка.
          <br />
          3.3. Кога се сомнева дека некое неовластено лице се запознало со
          податоците за корисничката сметка на корисникот или има пристап до
          Корисничката сметка на Корисникот, Корисникот веднаш треба да го
          извести Нарачај за тоа. Корисникот е одговорен за каква било употреба
          на Услугата Нарачај и за секоја активност преку сметката на
          корисникот.
          <br />
          3.4. За да ја користите Услугата Нарачај, мора да доставите валидна
          кредитна или дебитна картичка и други информации за начинот на плаќање
          до Нарачај. Нарачај не ги чува информациите за вашиот платен
          инструмент, бидејќи плаќањето се врши од страна на трето лице-давател
          на услугата за плаќање кое го користи Нарачај. Вие сте согласни да
          плаќате за сите Нарачки што произлегуваат од вашата употреба на
          Услугата Нарачај. Вие мора да ги ажурирате податоците за вашиот платен
          инструмент и другите информации за плаќање што сте ги доставиле до
          вашата корисничка сметка.
        </p>
        <br />
        <h2>4. Плаќања</h2>
        <br />
        <p>
          4.1. Корисникот ќе ја плати куповната цена утврдена во Нарачката со
          користење на соодветната функционалност за плаќање на Услугата
          Нарачај. Нарачај ги собира сите исплати од Корисниците, во име на
          Партнерот. Обврската за плаќање на Корисникот ќе се појави по ставање
          нарачка преку Услугата Нарачај.
          <br />
          4.2. Корисникот може да ја исполни оваа обврска за плаќање само со
          користење на релевантниот начин на плаќање преку Интернет, обезбеден
          во Услугата Нарачај. Кога Корисникот ќе ја плати куповната цена на
          Нарачај, тогаш Корисникот ја исполнил својата обврска за плаќање кон
          Партнерот.
          <br />
          4.3. Плаќањето автоматски ќе се наплатува од кредитната картичка на
          Корисникот, по испорака на Нарачката. Нарачај има право да резервира
          средства во име на Партнерот на кредитната или дебитната картичка на
          Корисникот, откако е направена Нарачка преку Услугата Нарачај. Naracaj
          користи трета страна давател на услуги за плаќање со кредитни картички
          и за обработка на плаќањата, т.е. процесор на плаќање, пр. банка кој
          ги обработува плаќањата направени со кредитна картичка. Naracaj не ги
          обработува директно плаќањата со кредитна картичка, ниту пак ги
          складира на своја страна податоците при плаќање со кредитна картичка.
        </p>
        <br />
        <h2>5. Испорака на нарачка</h2>
        <br />
        <p>
          5.1. Ако Корисникот нарача испорака на Нарачка преку Услугата Нарачај,
          Нарачката ќе биде доставена до локацијата потврдена од Корисникот во
          Услугата Нарачај. Корисникот исто така треба да обезбеди улична адреса
          за потврдена локација во Услугата Нарачај. Услугите за испорака ги
          обезбедува Партнерот до Корисникот.
          <br />
          5.2. Корисникот мора да биде достапен за да прима повици на
          телефонскиот број што го доставил до Услугата Нарачај. Ако
          телефонскиот број обезбеден од Корисникот не е достапен, испораката
          може да биде откажана од Нарачај или Партнерот, а Корисникот ќе биде
          должен да ја плати целата цена на Нарачката.
          <br />
          5.3. Корисникот може да постави налог за испорака што е можно поскоро
          (стандарден метод на испорака) или со претходно нарачување на одредено
          време на испорака.
          <br />
          5.4. Стандардна опција за испорака: Корисникот мора да биде присутен
          на потврдената локација дадена во Нарачката од времето на правење на
          Нарачката сè додека не се пристигнат производите наведени во
          Нарачката.
          <br />
          5.5. Опција за испорака „пред нарачка“: Корисникот мора да биде
          присутен на потврдената локација десет минути пред претходно
          нарачаното време на испорака до моментот на испорака за да ја прими
          нарачката.
          <br />
          5.6. Доколку Корисникот не е достапен на локацијата што ја потврдил во
          рок од десет минути од пристигнувањето на Нарачката, а Корисникот не
          реагира по два обиди за контакт од курирот за испорака, корисникот ќе
          може да ја подигне Нарачката од продажното место на Партнерот во
          наредните 60 минути од неуспешниот обид за испорака.
        </p>
        <br />
        <h2>6. Подигање на нарачка во продажното место на Партнерот</h2>
        <br />
        <p>
          6.1. Доколку Корисникот не нарача испорака на производите на Партнерот
          дадени во Нарачката, туку подигање од продажното место на Партнерот,
          производите можат да се подигнат во продажното место на Партнерот што
          Корисникот го избрал во врска со нарачката. Корисникот ќе добие
          посебна електронска потврда кога производите се подготвени за
          подигање. Партнерот или Нарачај можат да постават услови за
          идентификација на Корисникот при подигање на производите утврдени во
          Нарачката.
          <br />
          6.2. Партнерот ќе ги задржи нарачаните производи 60 минути откако ќе
          го извести Корисникот дека нарачката е подготвена за подигнување. Како
          и да е, оваа обврска е ограничена на работното време на продажната
          точка на определениот Партнер и нарачката треба да се наплати пред
          времето за затворање на споменатата продажна точка на Партнерот.
        </p>
        <br />
        <h2>7. Во ресторан Нарачка</h2>
        <br />
        <p>
          7.1. Доколку Корисникот ја одбере опцијата да ги консумира производите
          наведени во Нарачката во местото на продажба на Партнерот, Корисникот
          ќе добие посебна електронска потврда за очекуваното време кога
          производите ќе бидат подготвени за консумирање.
        </p>
        <h2>8. Временски проценки</h2>
        <br />
        <p>
          8.1. Секое време на испорака или време за подигање или друга проценка
          на времето што му е соопштено на Корисникот од страна на Партнерот или
          Нарачај во Услугата Нарачај, се само проценки. Нема гаранција дека
          нарачката ќе биде доставена или достапна за подигнување или
          консумирање во предвиденото време. Времето на испорака на производите
          може да биде под влијание на повеќе фактори како што се сообраќаен
          метеж и временски услови.
        </p>
        <br />
        <h2>9. Права на интелектуална сопственост</h2>
        <br />
        <p>
          9.1. Сите права на интелектуална сопственост содржани во Услугата
          Naracaj, документацијата и сите делови и копии поврзани со истата се
          исклучива сопственост на Нарачај. „Права на интелектуална сопственост“
          се авторски права и сродни права (вклучувајќи база на податоци и права
          на каталози и права на фотографија), патенти, трговски марки, трговски
          тајни, know-how и која било друга форма на регистрирани или
          нерегистрирани права на интелектуална сопственост.
          <br />
          9.2. Овие Услови за користење не му даваат на Корисникот никакви права
          на интелектуална сопственост во Услугата Naracaj и сите права што не
          се изрично дадени тука, се резервирани од Нарачај и неговите
          подизведувачи / даватели на лиценци.
        </p>
        <br />
        <h2>10. Дополнителни одредби за користење на Услугата Нарачај</h2>
        <br />
        <p>
          10.1. Услугата Нарачај е достапна само за лица на возраст од 18 години
          или постари.
          <br />
          10.2. Корисникот треба да ги почитува сите важечки правила и прописи
          при користење на Услугата Нарачај, вклучително и набавка на алкохол.
          На Корисникот може да му биде одбиено испорака на алкохолни пијалоци
          во случај кога тој / таа не е во состојба да обезбеди валидна лична
          карта на курирот што ја доставува Нарачката со која се докажува дека
          Корисникот има најмалку 18 години.
          <br />
          10.3. Нарачај постојано ја развива Услугата Нарачај и може да смени
          или отстрани различни делови на Услугата Нарачај, вклучувајќи
          карактеристики, производи и Партнери кои се достапни во Услугата
          Нарачај делумно или во целина.
          <br />
          10.4. Користејќи ја услугата Нарачај, Корисникот може да наиде на
          содржина или информации што можат да бидат неточни, нецелосни,
          одложени, заведувачки, незаконски, навредливи или на друг начин
          штетни. Нарачај генерално не ја разгледува содржината обезбедена од
          Партнерите. Нарачај не е одговорен за содржината или информацијата на
          трети страни (вклучувајќи ги и Партнерите) или за какви било штети што
          произлегуваат како резултат на употреба или потпирање на истата.
          <br />
          10.5. Корисникот нема: (I) да користи или да се обиде да користи
          Корисничка сметка на Нарачај на друго лице и / или да пристапи до
          лични податоци за плаќање на друго лице преку Услугата Нарачај или да
          користи лични картички за плаќање на друго лице кога ја користи
          Услугата Нарачај, без согласност на тоа друго лице; (II) да копира,
          менува или создава деривативни дела на Услугата Нарачај или која било
          поврзана технологија; (III) да декомпaјлира, дешифрира или на друг
          начин се обиде да го добие изворниот код за Услугата Нарачај, која
          било поврзана технологија, или кој било дел од нив; (IV) да ги
          отстрани сите известувања за авторско право, трговска марка или други
          ознаки за сопственички права содржани во Услугата Нарачај; (V) да
          отстранува, покрива или замаглува рекламирање вклучено во Услугата
          Нарачај; (VI) да собира, употребува, копира или пренесува информации
          добиени од Услугата Нарачај без согласност на Нарачај; (VII) да
          користи ботови или други автоматизирани методи за користење на
          Услугата Нарачај; (VIII) да создава Корисничка сметка на Нарачај
          користејќи лажен идентитет или идентитет на друго лице; или (IX) да
          пристапи до Услугата Нарачај на друг начин освен преку интерфејсите
          што се изрично дадени од страна на Нарачај, како што се Naracaj App и
          веб страната на Нарачај.
          <br />
          10.6. Нарачај има право да отстрани корисник од Услугата Нарачај и /
          или да одбие или откаже Нарачки од Корисник ако: (I) Корисникот ја
          злоупотребува Услугата Нарачај или нанесува штета на употреба на
          Услугата Нарачај, Партнерите или вработените во Нарачај, (II) Нарачај
          има разумно уверување за измамнички акти од страна на Корисникот при
          користење на Услугата Нарачај, (III) Корисникот направи погрешна
          Нарачка (на пример со тоа што не плаќа или не е присутен на испораката
          или локација за наплата за да ја прими Нарачката) или на друг начин не
          ги исполни своите обврски што произлегуваат од овие Услови за
          користење; (IV) постои основано сомневање за точноста или
          автентичноста на Нарачката. Ако Нарачај ја откаже Нарачката која е
          веќе платена, Нарачај ќе го врати паричниот износ на истата корисничка
          сметка од којашто е извршена исплатата од страна на Корисникот.
          <br />
          10.7. Контролорот на собраните лични податоци за Корисниците е
          Нарачај. Нарачај ги обработува личните податоци собрани од Корисникот
          во согласност со Политиката за приватност на Нарачај.
        </p>
        <br />
        <h2>11. Важност и престанок</h2>
        <br />
        <p>
          11.1. Овие Услови за користење се во сила како обврзувачки договор
          помеѓу Нарачај и Корисникот сè додека Корисникот ја користи Услугата
          Нарачај.
          <br />
          11.2. Корисникот може да прекине со употреба на Услугата Нарачај во
          секое време. Нарачај може да престане да ја обезбедува услугата
          Нарачај трајно или привремено во секое време.
        </p>
        <br />
        <h2>12. Поплаки</h2>
        <br />
        <p>
          12.1. Услугата Нарачај може во секое време да биде прекината или
          трајно укината. Иако Нарачај се труди да ви ја обезбеди Услугата
          Нарачај (вклучувајќи нови и / или привремени услуги, како што се
          испораки без контакт, испораки со нова технологија или испораки на
          нови категории на производи) без грешки, Нарачај не ветува ниту
          гарантира ништо во врска со функционалноста, навременоста, квалитетот
          или соодветноста на Услугата Нарачај, неговите карактеристики или која
          било услуга што ја нуди Нарачај. Нарачај не ветува или гарантира ништо
          што не е изрично наведено во овие Услови за користење.
          <br />
          12.2. Партнерот е одговорен да ја подготви Нарачката во согласност со
          карактеристиките нарачани од Корисникот и во времето прикажано на
          Услугата Нарачај. Ако има грешки во нарачката (или испорака),
          Корисникот може да контактира или со услугата за поддршка на корисници
          на Нарачај, постапувајќи во име на Партнер, или на support@naracaj.com
          или со Партнерот директно.
          <br />
          12.3. Партнерот е исклучиво одговорен за било какви грешки во
          содржината и подготовката на нарачката или други недостатоци во
          извршувањето на Договорот за набавка.
          <br />
          12.4. Корисникот кој открил недостатоци во изведбата на Договорот за
          набавка, мора да поднесе поплака до Нарачај или Партнерот без
          одлагање, со јасен опис за наведените недостатоци.
        </p>
        <br />
        <h2>13. Применлив закон и решавање на спорови</h2>
        <br />
        <p>
          13.1. Овие Услови за користење се регулираат и толкуваат во согласност
          со законите на Македонија.
          <br />
          13.2. Споровите што произлегуваат од овие Услови за Користење ќе ги
          решава надлежниот суд во Скопје.
        </p>
        <br />
        <h2>14. Измени и дополнувања</h2>
        <br />
        <p>
          14.1. Овие Услови за користење на корисниците подлежат на измени и
          дополнувања.
          <br />
          14.2. Нарачај ќе ги објави изменетите Услови за користење на веб
          страната на Нарачај и ќе го извести Корисникот дека Условите за
          користење се изменети преку е-пошта на адресата доставена до Услугата
          Нарачај од страна на Корисникот. Ако Корисникот не се согласи со
          изменетите Услови за користење на Нарачај, тој / таа ќе ја прекине
          употребата на услугата Naracaj.
        </p>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

import React, { useRef, useState,useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// import defaultImage from '../assets/images/logos/MobAppHomePageImage.png'
// import defaultImage from '../assets/images/logos/Logo_2_1.svg'
import defaultImage from '../assets/images/logos/WebThumbnail.jpg';
// import heart from '../assets/images/icons/heart.svg';

const MenuItem = props => {

    const imgRef = useRef();
    const [imgError, setImgError] = useState(false);
    const { t } = useTranslation();
    const refToImage = useRef();
    const [width, setWidth] = useState();
    const [mounted, setMounted] = useState(false);
    const handleScroll = (e) => {
        const elem = refToImage;
        setWidth(elem.current.clientWidth);
        // const lastLioffset = elem.offsetTop + elem.clientHeight;
        // const pageOffset = window.pageYOffset + window.innerHeight;
        // var bottomoffset = 300;
        // if (pageOffset > lastLioffset - bottomoffset) {
        //     setScrolling(true);
        // }
    }
    useEffect(() => {
        if (mounted) {
            setWidth(refToImage.current.clientWidth);
        } else {
            setMounted(true);
       }
    }, [mounted])
    useEventListener('resize', handleScroll);
    const heightStyle =  width ? width / 2 : null;
    return (
        <Col xs='12' lg='4' className='post'>
            <Row ref={refToImage} className='m-0 post-row' onClick={() => props.handleMenuItemClick()}>
                <Col xs='12' className='p-0 image' style={{height:heightStyle}}>
                    <img alt='menu-item-img' ref={imgRef} style={{ display: "none" }} src={props.data.web_thumbnail_image} onError={() => setImgError(true)} />
                    <div style={{ backgroundImage: "url(" + (imgError ? defaultImage : props.data.web_thumbnail_image) + ")", height:heightStyle }} />
                    {/* <img alt='heart-fav' onClick={() => { }} className="img-heart" src={heart} /> */}
                </Col>
                <Col xs='12' className='p-0 second-col'>
                    <Row className='m-0'>
                        <Col xs='12' className='p-0'>
                            {/* <Row className='m-0' >
                                <Col xs={props.data.sizes.length !== 0 ? '7' : '12'} className='p-0 name '>
                                    <span>{props.data.name}</span>
                                </Col>
                            </Row> */}
                             <Col xs='12' className='p-0 name '>
                                    <span>{props.data.name}</span>
                                </Col>
                        </Col>
                        <Col xs='9' className='p-0 description-item'>
                          <div>  {props.data.description}</div>
                        </Col>
                        {props.data.sizes.length !== 0 ? 
                                       <Col xs='12' className='p-0  text-right prices'>
                                       <div className="div2">
                                           <div>
                                               {
                                                   props.data.sizes.map((item, index) => {
                                                       return <span key={item.menu_size_id}>
                                                           {item.price}{props.data.sizes.length !== index + 1 ? "/" : ""}
                                                       </span>
                                                   })
                                               }
                                           </div>
                                           <div className="test"> {t('post.currency')} >></div>
                                       </div>
                                   </Col> :null
                                   }
                        {/* {true ?
                            <Col xs='12' className='p-0 delivery-time'>
                                <div> {props.deliveryTime} {t("post.min")}</div>
                            </Col>
                            : null
                        } */}
                        {/* <Col xs='6' className='p-0 status'>
                            <div className={props.status ? "open" : "closed"}>{status}</div>
                        </Col> */}


                    </Row>
                </Col>
            </Row>

        </Col>
    )
}

export default MenuItem;

function useEventListener(eventName, handler, element = window) {

    const savedHandler = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {

            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            const eventListener = event => savedHandler.current(event);

            element.addEventListener(eventName, eventListener, true);


            return () => {
                element.removeEventListener(eventName, eventListener, true);
            };
        },
        [eventName, element]
    );
};
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../axios-instance_baseURL';
import Slider from "react-slick";
import { Route } from 'react-router';
import MapModal from '../components/MapModal';
import { getAllRestaurantsConfig, add_delete_fav_config, create_basket_dinein_pickup_config } from '../assets/configs/config_calls'
import { get_all_restaurants, get_favorites, create_basket_url, restaurant_details_url } from '../assets/urls/urls';
import Layout from '../hoc/Layout';
import {
    set_restaurants,
    set_restaurants_loading,
    set_error,
    set_restaurants_fav,
    clear_rest_data
} from '../store/actions/restaurantsDataActions';
import {
    get_restaurant_details_config,
} from '../assets/configs/config_calls';
import { clear_filter, set_food_type, remove_food_type } from '../store/actions/filterActions';
import { setPromoBanner } from '../store/actions/bannerActions';
import { save_basket_data } from '../store/actions/basketActions';
import Post from '../components/Post';
import Spinner from '../UI/Spinner';
import BasketButton from '../UI/BasketButton';
import Backdrop from '../UI/Backdrop';
import Auxilary from '../hoc/Auxilary';
import logoImg from '../assets/images/logos/naracaj logo grey new.png';
import tickBlue from '../assets/images/icons/tick blue.png';
import SlickSlider from '../components/SlickSlider';
import closeImage from '../assets/images/icons/close_orange.svg';
import FoodTypesImagesSlider from '../UI/FoodTypesImagesSlider';
import FilterTags from '../components/FilterTags';

const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToScroll: 1,
    waitForAnimate: false,
    responsive: [{
        breakpoint: 992,
        settings: {
            slidesToShow: 1,
        }
    }]
};

const HomePage = (props) => {
    const { i18n, t } = useTranslation();
    const long = useSelector(state => state.auth.long);
    const lat = useSelector(state => state.auth.lat);
    const accessToken = useSelector(state => state.auth.accessToken);
    let sliderDataImages = useSelector(state => state.auth.sliderData);
    const banner = useSelector(state => state.auth.bannerPromo);
    const [componentMounted, setComponentMounted] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const [openClicked, setOpenClicked] = useState(false);
    const [showModalSuccOrder, setShowModalSuccOrder] = useState(false);
    const refTolast = useRef();
    const restaurantsData = useSelector(state => state.restData.restaurantsData);
    const loading = useSelector(state => state.restData.loading);
    const noData = useSelector(state => state.restData.noData);
    const error = useSelector(state => state.restData.error);
    const searchValue = useSelector(state => state.restData.searchValue);
    const nextPage = useSelector(state => state.restData.nextPage);
    const setedLat = useSelector(state => state.restData.lat);
    const setedLong = useSelector(state => state.restData.long);
    const hasNextPage = useSelector(state => state.restData.hasNextPage);
    const [updatedLatLng, setUpdatedLatlng] = useState(false);
    const [showUnAuthenticated, setShowUnAuthenticated] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [addingFav, setAddingFav] = useState(false);
    const [addingFavMssg, setAddingFavMssg] = useState();
    const [deletingFav, setDeletingFav] = useState(false);
    const [deletingFavMssg, setDeletingFavMssg] = useState();
    const [modalBasketChange, setModalBasketChange] = useState(false);
    const [restaurantBasketIdChange, setRestaurantBasketIdChange] = useState(null);
    const [restaurantClosedClick, setRestaurantClosedClick] = useState(null);
    const [restaurantClosedClickId, setRestaurantClosedClickId] = useState(null);
    const basketData = useSelector(state => state.basket.basketData);
    const [openModalInfo, setOpenModalInfo] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [restaurantData, setRestaurantData] = useState(null);
    const [loadMoreData, setLoadMoreData] = useState(false);
    const filters = useSelector(state => state.filters);
    const [locationUpdateShow, setLocationUpdateShow] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clear_filter());
        }
    }, []);

    useEffect(() => {
        if (componentMounted) return;
        setComponentMounted(true);
        dispatch(set_restaurants_loading());
        let configs = getAllRestaurantsConfig(i18n.language, accessToken ? accessToken : "");
        let currentFilters = '';
        if (filters.filter.filterApplied === false) {
            let currentStatus = '';
            if (filters.delivery === true) currentStatus = 'DELIVERY';
            if (filters.pickup === true) currentStatus = 'PICKUP';
            if (filters.all === true) currentStatus = 'ALL';

            if (filters.foodTypes) {
                currentFilters += '?type=' + currentStatus;
                // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                // if (item[0])
                // currentFilters += "&tags=" +  item[0].tag_id;;
                if (filters.filter.appliedTags.length !== 0)
                    currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;
            } else {
                currentFilters += '?type=' + currentStatus;
            }
        } else {
            currentFilters = "?orderBy=" + filters.filter.sortType.toUpperCase() + "&type=" + filters.filter.deliveryType.toUpperCase();
            if (filters.foodTypes) {
                // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                // if (item[0])
                // currentFilters += "&tags=" +  item[0].tag_id;;
                if (filters.filter.appliedTags.length !== 0)
                    currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;

            } else if (filters.filter.appliedTags.length !== 0) {
                let tags = "";
                for (let item in filters.filter.appliedTags) {
                    tags += filters.filter.appliedTags[item].tag_id;
                    if (item != filters.filter.appliedTags.length - 1)
                        tags += ",";
                }
                currentFilters += "&tags=" + tags;
            }
        }
        let callurl = get_all_restaurants + currentFilters + '&latitude=' + lat + '&longitude=' + long + '&search=&size=6&page=1';
        axios.get(callurl, {
            params: {}, headers: configs.headers
        })
            .then(response => {

                dispatch(set_restaurants({ ...response.data }, lat, long));
            })
            .catch(err => {

                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                dispatch(set_error());
            })
    }, [i18n.language, componentMounted, dispatch, filters.delivery, filters.pickup, filters.all]);





    useEffect(() => {
        if (!componentMounted) return;
        if (filters.foodTypes) {
            props.history.push('/' + filters.foodTypes);
        } else {
            props.history.push('/');
        }
        initiateFoodTypeFilterCall();

    }, [filters.foodTypes]);

    useEffect(() => {
        if (componentMounted) {
            let value = (searchValue.trim().length >= 0 && searchValue.trim().length < 3) ? "" : searchValue;
            dispatch(set_restaurants_loading());
            let configs = getAllRestaurantsConfig(i18n.language, accessToken ? accessToken : "");
            let currentFilters = '';
            if (filters.filter.filterApplied === false) {
                let currentStatus = '';
                if (filters.delivery === true) currentStatus = 'DELIVERY';
                if (filters.pickup === true) currentStatus = 'PICKUP';
                if (filters.all === true) currentStatus = 'ALL';

                if (filters.foodTypes) {
                    currentFilters += '?type=' + currentStatus;
                    // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                    // if (item[0])
                    // currentFilters += "&tags=" +  item[0].tag_id;;
                    if (filters.filter.appliedTags.length !== 0)
                        currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;
                } else {
                    currentFilters += '?type=' + currentStatus;
                }
            } else {
                currentFilters = "?orderBy=" + filters.filter.sortType.toUpperCase() + "&type=" + filters.filter.deliveryType.toUpperCase();
                if (filters.foodTypes) {
                    // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                    // if (item[0])
                    // currentFilters += "&tags=" +  item[0].tag_id;;
                    currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;

                } else if (filters.filter.appliedTags.length !== 0) {
                    let tags = "";
                    for (let item in filters.filter.appliedTags) {
                        tags += filters.filter.appliedTags[item].tag_id;
                        if (item != filters.filter.appliedTags.length - 1)
                            tags += ",";
                    }
                    currentFilters += "&tags=" + tags;
                }
            }
            let callurl = get_all_restaurants + currentFilters + '&latitude=' + lat + '&longitude=' + long + '&search=' + searchValue + '&size=6&page=1';
            axios.get(callurl, {
                params: {}, headers: configs.headers
            })
                .then(response => {

                    dispatch(set_restaurants({ ...response.data }, lat, long));
                    window.scrollTo(0, 0);
                    if (!locationUpdateShow && (lat != 0 && long != 0)) {
                        setUpdatedLatlng(true);
                        setTimeout(() => {
                            setUpdatedLatlng(false);
                        }, 3000);
                        setLocationUpdateShow(true);
                    }
                    if ((lat != 0 && long != 0)) setLocationUpdateShow(true);
                }).catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }
                    dispatch(set_error());
                })
        }

    }, [lat, long, filters.delivery, filters.pickup, filters.all, filters.filter.filterApplied, filters.filterApplySwitch]);

    useEffect(() => {
        let showModal = props.history && props.history.location && props.history.location.mode && props.history.location.mode === "order" ? true : false;
        let showUnAuthenticated = props.history && props.history.location && props.history.location.err && props.history.location.err === "unauthenticated" ? true : false;
        setShowModalSuccOrder(showModal);
        if (showUnAuthenticated) {
            setShowUnAuthenticated(true);
            setTimeout(() => {
                setShowUnAuthenticated(false);
            }, 5000);
        }


    }, []);

    useEffect(() => {
        return () => {
            dispatch(clear_rest_data());
        }
    }, []);



    useEffect(() => {
        if (scrolling && hasNextPage && componentMounted) {
            let configs = getAllRestaurantsConfig(i18n.language, accessToken ? accessToken : "");
            let value = (searchValue.trim().length >= 0 && searchValue.trim().length < 3) ? "" : searchValue;
            let nextPageCheck = nextPage;
            let scrollingCheck = true;
            if (parseFloat(lat) !== parseFloat(setedLat) && parseFloat(long) !== parseFloat(setedLong)) {
                nextPageCheck = 1;
                scrollingCheck = false;
                window.scrollTo(0, 0);
            }
            let currentFilters = '';
            if (filters.filter.filterApplied === false) {
                let currentStatus = '';
                if (filters.delivery === true) currentStatus = 'DELIVERY';
                if (filters.pickup === true) currentStatus = 'PICKUP';
                if (filters.all === true) currentStatus = 'ALL';

                if (filters.foodTypes) {
                    currentFilters += '?type=' + currentStatus;
                    // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                    // if (item[0])
                    // currentFilters += "&tags=" +  item[0].tag_id;;
                    if (filters.filter.appliedTags.length !== 0)
                        currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;
                } else {
                    currentFilters += '?type=' + currentStatus;
                }
            } else {
                currentFilters = "?orderBy=" + filters.filter.sortType.toUpperCase() + "&type=" + filters.filter.deliveryType.toUpperCase();
                if (filters.foodTypes) {
                    // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                    // if (item[0])
                    // currentFilters += "&tags=" +  item[0].tag_id;;
                    if (filters.filter.appliedTags.length !== 0)
                        currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;

                } else if (filters.filter.appliedTags.length !== 0) {
                    let tags = "";
                    for (let item in filters.filter.appliedTags) {
                        tags += filters.filter.appliedTags[item].tag_id;
                        if (item != filters.filter.appliedTags.length - 1)
                            tags += ",";
                    }
                    currentFilters += "&tags=" + tags;
                }
            }

            let callurl = get_all_restaurants + currentFilters + '&latitude=' + lat + '&longitude=' + long + '&search=' + value + '&size=6&page=' + nextPageCheck;
            setLoadMoreData(true);
            axios.get(callurl, {
                params: {}, headers: configs.headers
            })
                .then(response => {
                    if (response.data.status) {

                        setScrolling(false);
                        dispatch(set_restaurants({ ...response.data }, lat, long, scrollingCheck));
                        setLoadMoreData(false);
                    }
                    setLoadMoreData(false);
                })
                .catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }
                    setLoadMoreData(false);
                    dispatch(set_error());
                })
        }

    }, [scrolling, nextPage]);
    const initiateFoodTypeFilterCall = () => {
        let value = (searchValue.trim().length >= 0 && searchValue.trim().length < 3) ? "" : searchValue;
        dispatch(set_restaurants_loading());
        let configs = getAllRestaurantsConfig(i18n.language, accessToken ? accessToken : "");
        let currentFilters = '';
        if (filters.filter.filterApplied === false) {
            let currentStatus = '';
            if (filters.delivery === true) currentStatus = 'DELIVERY';
            if (filters.pickup === true) currentStatus = 'PICKUP';
            if (filters.all === true) currentStatus = 'ALL';

            if (filters.foodTypes) {
                currentFilters += '?type=' + currentStatus;
                // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                // if (item[0])
                // currentFilters += "&tags=" +  item[0].tag_id;;
                if (filters.filter.appliedTags.length !== 0)
                    currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;
            } else {
                currentFilters += '?type=' + currentStatus;
            }
        } else {
            currentFilters = "?orderBy=" + filters.filter.sortType.toUpperCase() + "&type=" + filters.filter.deliveryType.toUpperCase();
            if (filters.foodTypes) {
                // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                // if (item[0])
                // currentFilters += "&tags=" +  item[0].tag_id;;
                currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;

            } else if (filters.filter.appliedTags.length !== 0) {
                let tags = "";
                for (let item in filters.filter.appliedTags) {
                    tags += filters.filter.appliedTags[item].tag_id;
                    if (item != filters.filter.appliedTags.length - 1)
                        tags += ",";
                }
                currentFilters += "&tags=" + tags;
            }
        }
        let callurl = get_all_restaurants + currentFilters + '&latitude=' + lat + '&longitude=' + long + '&search=' + searchValue + '&size=6&page=1';
        axios.get(callurl, {
            params: {}, headers: configs.headers
        })
            .then(response => {

                dispatch(set_restaurants({ ...response.data }, lat, long));
                window.scrollTo(0, 0);
                if (!locationUpdateShow && (lat != 0 && long != 0)) {
                    setUpdatedLatlng(true);
                    setTimeout(() => {
                        setUpdatedLatlng(false);
                    }, 3000);
                    setLocationUpdateShow(true);
                }
                if ((lat != 0 && long != 0)) setLocationUpdateShow(true);
            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                dispatch(set_error());
            });
    };

    const handleScroll = (e) => {
        if (scrolling) return;
        if (!nextPage) return;
        if (!refTolast.current) return;
        const elem = refTolast.current.childNodes[refTolast.current.childNodes.length - 1];
        const lastLioffset = elem.offsetTop + elem.clientHeight;
        const pageOffset = window.pageYOffset + window.innerHeight;
        var bottomoffset = 300;
        if (pageOffset > lastLioffset - bottomoffset) {
            setScrolling(true);
        }
    }
    useEventListener('scroll', handleScroll);

    const createBasketOnClick = (id) => {
        setLoadingData(true);
        const config = create_basket_dinein_pickup_config("delivery", id, accessToken, i18n.language);
        axios.post(create_basket_url, { ...config.body }, { params: {}, headers: config.headers })
            .then(response => {
                if (response.data.status === true) {
                    dispatch(save_basket_data(response.data));
                    props.history.push({ pathname: '/restaurant/details/' + id, opened: true, mode: "delivery" });

                } else {
                    setAddingFavMssg(response.data.message);
                    setAddingFav(true);
                    setTimeout(() => {
                        setAddingFav(false);
                    }, 3000);
                    setLoadingData(false);
                }

            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setAddingFavMssg('homepage.errorMsg');
                setAddingFav(true);
                setTimeout(() => {
                    setAddingFav(false);
                }, 3000);
                setLoadingData(false);
            })

    }

    const handleInfoClick = (id) => {
        let configs = get_restaurant_details_config("", i18n.language);
        let callurl = restaurant_details_url + id;
        setLoadingInfo(true);
        axios.get(callurl, { params: {}, headers: configs.headers })
            .then(response => {
                if (response.data.status) {
                    setRestaurantData(response.data.restaurant);
                    setLoadingInfo(false);
                    setOpenModalInfo(true);
                    props.history.push("/restaurant-info");
                }
                setLoadingInfo(false);
            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setLoadingInfo(false);
            });
    }

    const handleClickedRestaurant = (item) => {

        if (!item.is_open) {
            if (!item.today_open_hour.open_at) {
                // setOpenClicked(true);
                // setTimeout(() => {
                //     setOpenClicked(false);
                // }, 3000);
                setRestaurantClosedClick(true);
                setRestaurantClosedClickId(item.id);
                return;
            }
            if (item.today_open_hour.open_at === "-") {
                // setOpenClicked(true);
                // setTimeout(() => {
                //     setOpenClicked(false);
                // }, 3000);
                setRestaurantClosedClick(true);
                setRestaurantClosedClickId(item.id);
                return;
            }
            const openHour = parseInt(item.today_open_hour.open_at.split(":")[0]);
            const openMinutes = parseInt(item.today_open_hour.open_at.split(":")[1]);
            let currentHour = new Date().getHours();
            let currentMinutes = new Date().getMinutes();
            if (openHour < currentHour) {
                // setOpenClicked(true);
                // setTimeout(() => {
                //     setOpenClicked(false);
                // }, 3000);
                setRestaurantClosedClick(true);
                setRestaurantClosedClickId(item.id);
            } else if (openHour === currentHour) {
                if (openMinutes <= currentMinutes) {
                    // setOpenClicked(true);
                    // setTimeout(() => {
                    //     setOpenClicked(false);
                    // }, 3000);
                    setRestaurantClosedClick(true);
                    setRestaurantClosedClickId(item.id);
                } else {

                    if (basketData && basketData.restaurant_id !== item.id && basketData.products.length === 0 && basketData.upsale?.length === 0) {
                        createBasketOnClick(item.id);
                    } else if (basketData && basketData.restaurant_id !== item.id && (basketData.products.length > 0 || basketData.upsale?.length > 0)) {
                        setModalBasketChange(true);
                        setRestaurantBasketIdChange(item.id);
                    } else if (basketData && basketData.restaurant_id === item.id) {
                        props.history.push({ pathname: '/restaurant/details/' + item.id, opened: true, mode: basketData.order_mode });
                    } else {
                        createBasketOnClick(item.id);
                    }
                }

            } else {

                if (basketData && basketData.restaurant_id !== item.id &&  basketData.products.length === 0 && basketData.upsale?.length === 0) {
                    createBasketOnClick(item.id);
                } else if (basketData && basketData.restaurant_id !== item.id && (basketData.products.length > 0 || basketData.upsale?.length > 0)) {
                    setModalBasketChange(true);
                    setRestaurantBasketIdChange(item.id);
                } else if (basketData && basketData.restaurant_id === item.id) {
                    props.history.push({ pathname: '/restaurant/details/' + item.id, opened: true, mode: basketData.order_mode });
                } else {
                    createBasketOnClick(item.id);
                }
            }
        } else {

            if (basketData && basketData.restaurant_id !== item.id && basketData.products.length === 0 && basketData.upsale?.length === 0) {
                createBasketOnClick(item.id);
            } else if (basketData && basketData.restaurant_id !== item.id && (basketData.products.length > 0 || basketData.upsale?.length > 0)) {
                setModalBasketChange(true);
                setRestaurantBasketIdChange(item.id);
            } else if (basketData && basketData.restaurant_id === item.id) {
                props.history.push({ pathname: '/restaurant/details/' + item.id, opened: true, mode: basketData.order_mode });
            } else {
                createBasketOnClick(item.id);
            }

        }
    }

    const handleAddFav = (id) => {
        if (!accessToken) {
            props.history.push({
                pathname: "/login",
                state: {
                    favoriteId: id
                }
            });
            return;
        }
        let config = add_delete_fav_config(accessToken, i18n.language, id);
        setLoadingData(true);
        setAddingFav(false);
        axios.post(get_favorites, { ...config.body }, { params: {}, headers: config.headers })
            .then(response => {
                if (response.data.status) {
                    let updatedRestarants = [];
                    for (let item in restaurantsData.restaurants) {
                        if (restaurantsData.restaurants[item].id === id) {
                            updatedRestarants.push({
                                ...restaurantsData.restaurants[item],
                                is_favorite: true
                            });
                        } else {
                            updatedRestarants.push({
                                ...restaurantsData.restaurants[item]
                            })
                        }
                    };
                    dispatch(set_restaurants_fav(updatedRestarants));
                    setAddingFavMssg(response.data.message);
                    setAddingFav(true);
                    setTimeout(() => {
                        setAddingFav(false);
                    }, 3000);

                } else {
                    setAddingFavMssg(response.data.message);
                    setAddingFav(true);
                    setTimeout(() => {
                        setAddingFav(false);
                    }, 3000);
                }
                setLoadingData(false);
                setDeletingFav(false);

            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setDeletingFav(false);
                setLoadingData(false);
                setAddingFavMssg('homepage.errorMsg');
                setAddingFav(true);
                setTimeout(() => {
                    setAddingFav(false);
                }, 3000);
            })
    }
    const handleRemFav = (id) => {
        // if (!accessToken) props.history.push("/login");
        let config = add_delete_fav_config(accessToken, i18n.language, id);
        setLoadingData(true);
        setDeletingFav(false);
        axios.delete(get_favorites, { data: { ...config.body }, headers: { ...config.headers } })
            .then(response => {
                if (response.data.status) {
                    let updatedRestarants = [];
                    for (let item in restaurantsData.restaurants) {
                        if (restaurantsData.restaurants[item].id === id) {
                            updatedRestarants.push({
                                ...restaurantsData.restaurants[item],
                                is_favorite: false
                            });
                        } else {
                            updatedRestarants.push({
                                ...restaurantsData.restaurants[item]
                            })
                        }
                    };
                    dispatch(set_restaurants_fav(updatedRestarants));
                    setDeletingFavMssg(response.data.message);
                    setDeletingFav(true);
                    setTimeout(() => {
                        setDeletingFav(false);
                    }, 3000);

                } else {
                    setDeletingFavMssg(response.data.message);
                    setDeletingFav(true);
                    setTimeout(() => {
                        setDeletingFav(false);
                    }, 3000);
                }
                setLoadingData(false);
                setAddingFav(false);
            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setAddingFav(false);
                setLoadingData(false);
                setDeletingFavMssg('homepage.errorMsg');
                setDeletingFav(true);
                setTimeout(() => {
                    setDeletingFav(false);
                }, 3000);
            })
    }


    const closingPromoBanner = (opened = false) => {
        const now = new Date();
        const promoItem = {
            promoId: banner.restaurant_id,
            promoImage: banner.image.web_image,
            expiry: now.getTime() + 60000 * 60 * 24,
        }
        localStorage.setItem("promoClosed", "true");
        localStorage.setItem("promoItem", JSON.stringify(promoItem));
        dispatch(setPromoBanner(null));
        if (opened)
            props.history.push({ pathname: '/restaurant/details/' + banner.restaurant_id });
    }

    let renderData = null;
    let sliderData = null;

    if (sliderDataImages) {
        let slidesToShow = sliderDataImages.gallery.length < 3 ? sliderDataImages.gallery.length : 3;
        sliderData = (
            <Slider
                {...sliderSettings}
                slidesToShow={1}
                autoplaySpeed={(sliderDataImages.web_duration + sliderDataImages.web_transition) * 1000}
                speed={sliderDataImages.web_transition * 1000}
            >
                {sliderDataImages.gallery.map(item => {
                    return (
                        <SlickSlider
                            key={item.restaurant_id}
                            web_image={item.image.web_image}
                            onClickFunc={() => props.history.push({ pathname: '/restaurant/details/' + item.restaurant_id })}
                        />
                    )
                })}
            </Slider >
        )
    }

    if (restaurantsData) {
        if (noData) {
            renderData = <Col className="error-messages" style={{ marginTop: "40px" }}>{t('homepage.noResult')}</Col>
        } else {
            renderData = restaurantsData.restaurants.map((item, index) => {
                // if (item.is_available === true) {
                return <Post
                    key={item.id}
                    restaurantImage={item.image_file_name}
                    restaurantName={item.name}
                    restaurantTypes={item.type_tags}
                    avrgDeliveryTime={(item.is_delivery && item.average_delivery_time > 0) ? item.average_delivery_time : null}
                    status={item.is_open}
                    distance={item.distance}
                    delivery={item.is_delivery}
                    pickUp={item.is_pickup}
                    dineIn={item.is_dinein}
                    isFav={item.is_favorite}
                    clickHandler={() => handleClickedRestaurant(item)}
                    handleAddFav={() => handleAddFav(item.id)}
                    handleRemFav={() => handleRemFav(item.id)}
                    id={item.id}
                    history={props.history}
                    handleInfoClick={handleInfoClick}
                />
                // }
                // return null
            });

            {/* sliderData = (
                <Slider {...sliderSettings}>
                    {
                        restaurantsData.restaurants.map((item, index) => {
                            // if (item.is_available === true) {
                            return (
                                <Row className='m-0' key={item.id} >
                                    <Post
                                        restaurantImage={item.image_file_name}
                                        restaurantName={item.name}
                                        restaurantTypes={item.type_tags}
                                        avrgDeliveryTime={(item.is_delivery && item.average_delivery_time > 0) ? item.average_delivery_time : null}
                                        status={item.is_open}
                                        distance={item.distance}
                                        delivery={item.is_delivery}
                                        pickUp={item.is_pickup}
                                        dineIn={item.is_dinein}
                                        isFav={item.is_favorite}
                                        clickHandler={() => handleClickedRestaurant(item)}
                                        handleAddFav={() => handleAddFav(item.id)}
                                        handleRemFav={() => handleRemFav(item.id)}
                                        id={item.id}
                                        history={props.history}
                                        slider={true}
                                        handleInfoClick={handleInfoClick}
                                    />
                                </Row>
                            )
                        })
                    }
                  
                </Slider >
            ) */}
        }

    } else if (error) {
        renderData = <Col className="error-messages">{t('homepage.errorMsg')}</Col>
    }

    return (
        <Layout history={props.history}>
            <div className="home-page-title">
                <div className="posts-list">
                    <Row ref={refTolast} className='m-0'>
                        {loading ? <Spinner logo spinnerStyle="SpinnerYellow" /> :
                            <Auxilary>
                                <Col xs='12' className=' pl-2 pr-2 pl-lg-0 pr-lg-0 mb-3 slider-promo'>
                                    {sliderData}
                                </Col>
                                <Col xs='12' className='food-types pl-1 pr-1'>
                                    <FoodTypesImagesSlider />
                                </Col>

                                <Col xs='12' className='p-0'>
                                    <Row className='m-0'>
                                        {renderData}
                                        {loadMoreData ?
                                            <Col xs='12' className='p-0 pb-4'>
                                                <Spinner logo loadMore />
                                            </Col> : null
                                        }
                                    </Row>

                                </Col>
                            </Auxilary>}
                    </Row>
                </div>
            </div>
            <div className={openClicked ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>  {t('homepage.closed_message')} </div>
            <BasketButton history={props.history} />
            {showModalSuccOrder ?
                <Auxilary>
                    <div className='modal-succesfull-order'>
                        <Row className='first-row pb-4'>
                            <Col xs='12' className='p-0 logo-img text-center'><img alt='logo-img' src={logoImg}></img></Col>
                            <Col xs='12' className='p-0 mt-3 text-center'><img alt='tick-blue' src={tickBlue}></img></Col>
                            <Col xs='12' className='p-0 mt-3 text-center thank-you'>{t('homepage.thanks')}</Col>
                            <Col xs='12' className='p-0 mt-3 your-order text-center' ><span>{t('homepage.processing')}</span></Col>
                            <Col xs='12' className='p-0 mt-3 text-center' >{t('homepage.order_number')} {props.history.location.orderNumber}</Col>
                            <button className='close-button' onClick={() => { setShowModalSuccOrder(false) }}>
                                <div className="mdiv">
                                    <div className="md"></div>
                                </div>
                            </button>
                        </Row>
                        <Row className='second-row m-0'>
                            <Col xs='12' className='p-0 mt-4 button text-center see-orders'>
                                <button onClick={() => props.history.push("/settings/orders")}>{t('homepage.my_orders')}</button>
                            </Col>
                        </Row>

                    </div>
                    <Backdrop address className='backdrop' show={showModalSuccOrder} backdropClicked={() => setShowModalSuccOrder(false)} />
                </Auxilary>
                : null}
            <div className={updatedLatLng ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>
                {t('homepage.latlng-updated')}
            </div>
            <div className={showUnAuthenticated ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>
                {t('homepage.errorMsg-unauthenticated')}
            </div>
            {loadingData ?
                <div className='loading-data-modal' >
                    <Spinner logo spinnerStyle="SpinnerYellow" />;
                         </div> : null
            }
            <div className={addingFav ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>
                {t(addingFavMssg)}
            </div>
            <div className={deletingFav ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>
                {t(deletingFavMssg)}
            </div>
            <Backdrop address className='backdrop menu-backdrop' show={modalBasketChange} backdropClicked={() => setModalBasketChange(false)} />
            {modalBasketChange ?
                <Auxilary>
                    <div className='modal-basket-change'>
                        <div className="description">
                            {t('restaurant_mode.change_basket')}
                        </div>
                        <div className='buttons'>
                            <button onClick={() => setModalBasketChange(false)}> {t('restaurant_mode.cancel')} </button>
                            <button onClick={() => createBasketOnClick(restaurantBasketIdChange)}> {t('restaurant_mode.ok')} </button>
                        </div>

                    </div>
                    <Backdrop address className='backdrop' show={modalBasketChange} backdropClicked={() => { setModalBasketChange(false); }} />
                </Auxilary>

                : null
            }
            {restaurantClosedClick ?
                <Auxilary>
                    <div className='modal-basket-change'>
                        <div className="description">
                            {t('restaurant_mode.closed_restaurant')}
                        </div>
                        <div className='buttons'>
                            <button onClick={() => {
                                setRestaurantClosedClickId(null);
                                setRestaurantClosedClick(null);
                            }}> {t('restaurant_mode.cancel')} </button>
                            <button onClick={() => {
                                if (basketData && basketData.restaurant_id !== restaurantClosedClickId && basketData.products.length === 0 && basketData.upsale?.length === 0) {
                                    createBasketOnClick(restaurantClosedClickId);
                                } else if (basketData && basketData.restaurant_id !== restaurantClosedClickId && (basketData.products.length > 0 || basketData.upsale?.length > 0)) {
                                    setModalBasketChange(true);
                                    setRestaurantBasketIdChange(restaurantClosedClickId);
                                } else if (basketData && basketData.restaurant_id === restaurantClosedClickId) {
                                    props.history.push({ pathname: '/restaurant/details/' + restaurantClosedClickId, opened: true, mode: basketData.order_mode });
                                } else {
                                    createBasketOnClick(restaurantClosedClickId);
                                }
                                setRestaurantClosedClickId(null);
                                setRestaurantClosedClick(null);


                            }}> {t('restaurant_mode.ok')} </button>
                        </div>

                    </div>
                    <Backdrop address className='backdrop' show={restaurantClosedClick} backdropClicked={() => {
                        setRestaurantClosedClickId(null);
                        setRestaurantClosedClick(null);

                    }} />
                </Auxilary>

                : null
            }
            {openModalInfo ?
                <Auxilary>
                    <Route path={"/restaurant-info"}
                        component={(props) =>
                            <MapModal data={restaurantData} handleCloseMenuModal={() => {
                                setOpenModalInfo(false);
                                props.history.goBack(1);
                            }}
                                homePage={true}
                            />} />

                </Auxilary>
                : null
            }

            {loadingInfo ?
                <div className='loading-data-modal' >
                    <Spinner logo spinnerStyle="SpinnerYellow" />;
                         </div> : null
            }
            {banner ?
                <div className='banner-promo'>
                    <div className='banner-image'>
                        <div onClick={() => closingPromoBanner(true)}>
                            <img src={banner.image.web_image} ></img>
                        </div>
                        <div className='banner-close-icon' onClick={() => closingPromoBanner()}>
                            <img src={closeImage}></img>
                        </div>
                    </div>
                    <Backdrop address className='backdrop banner-pointer' show={banner} backdropClicked={() => closingPromoBanner()} />
                </div> : null
            }
            {filters.apiTags.length !== 0 ?
                <Route path={"/:id"}
                    render={(props) =>
                        <FilterTags params={props.match.params} foodTypes={filters.foodTypes} initiateCall={(foodType) => {

                            let items = filters.apiTags.filter(item =>
                                item.name === (t("foodTypes." + foodType)) ||
                                item.name.toLowerCase() === (t("foodTypes." + foodType)) ||
                                item.name.toUpperCase() === (t("foodTypes." + foodType)));
                            if (items[0]) {
                                dispatch(set_food_type(foodType, { ...items[0] }));
                            }
                            if (foodType === null) {
                                dispatch(remove_food_type(filters.foodTypes))
                            }

                        }} />}
                />
                : null}
        </Layout >
    )

}

export default HomePage;


function useEventListener(eventName, handler, element = window) {

    const savedHandler = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {

            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            const eventListener = event => savedHandler.current(event);

            element.addEventListener(eventName, eventListener, true);


            return () => {
                element.removeEventListener(eventName, eventListener, true);
            };
        },
        [eventName, element]
    );
};
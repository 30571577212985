const cities = [
  "1000, Скопје",
  "1200, Тетово",
  "1230, Гостивар",
  "1250, Дебар",
  "1300, Куманово",
  "1330, Крива Паланка",
  "1360, Кратово",
  "1400, Велес",
  "1430, Кавадарци",
  "1440, Неготино",
  "1442, Демир Капија",
  "1480, Гевгелија",
  "2000, Штип",
  "2210, Пробиштип",
  "2220, Свети Николе",
  "2300, Кочани",
  "2310, Виница",
  "2320, Делчево",
  "2304, Македонска Каменица",
  "2326, Пехчево",
  "2330, Берово",
  "2400, Струмица",
  "2420, Радовиш",
  "2460, Валандово",
  "6000, Охрид",
  "6250, Кичево",
  "6330, Струга",
  "6530, Македонски Брод",
  "7000, Битола",
  "7240, Демир Хисар",
  "7310, Ресен",
  "7500, Прилеп",
  "7550, Крушево",
  "1000, Skopje",
  "1200, Tetovo",
  "1230, Gostivar",
  "1250, Debar",
  "1300, Kumanovo",
  "1330, Kriva Palanka",
  "1360, Kratovo",
  "1400, Veles",
  "1430, Kavadarci",
  "1440, Negotino",
  "1442, Demir Kapija",
  "1480, Gevgelija",
  "2000, Shtip",
  "2210, Probishtip",
  "2220, Sveti Nikole",
  "2300, Kochani",
  "2310, Vinica",
  "2320, Delchevo",
  "2304, Makedonska Kamenica",
  "2326, Pehchevo",
  "2330, Berovo",
  "2400, Strumica",
  "2420, Radovish",
  "2460, Valandovo",
  "6000, Ohrid",
  "6250, Kichevo",
  "6330, Struga",
  "6530, Makedonski Brod",
  "7000, Bitola",
  "7240, Demir Hisar",
  "7310, Resen",
  "7500, Prilep",
  "7550, Krushevo",
];

module.exports = { cities };

import React, { useEffect, useCallback, useState } from "react";
import { Route, Switch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "./axios-instance_baseURL";
import Ticker from "react-ticker";
import SmartBanner from "react-smartbanner";
import "./App.scss";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import Spinner from "./UI/Spinner";
import HomePage from "./containers/HomePage";
import Login from "./containers/Login";
import Register from "./containers/Register";
import ForgotPage from "./containers/ForgotPage";
import SettingsPage from "./containers/SettingsPage";
import RestaurantContainer from "./containers/RestaurantContainer";
import OrderContainer from "./containers/OrderContainer";
import { set_coord } from "./store/actions/authActions";
import {
  user_profile_url,
  basket_url,
  news_url,
  gallery_promo_url,
  get_tags_url,
} from "./assets/urls/urls";
import {
  user_profile_config,
  get_basket_config,
  news_config,
  banner_config,
  payment_ok_number_config,
  get_tags_config,
} from "./assets/configs/config_calls";
import { set_api_tags } from "./store/actions/filterActions";
import { setUserData } from "./store/actions/authActions";
import {
  setPromoBanner,
  set_banner,
  set_slider_data,
} from "./store/actions/bannerActions";
import { set_loading } from "./store/actions/okRedirectActions";
import { save_basket_data } from "./store/actions/basketActions";
import Aux from "./hoc/Auxilary";
import { Row, Col } from "react-bootstrap";
import OkPage from "./components/OkPage";
import FailPage from "./components/FailPage";
import FavoriteRestaurantsPage from "./containers/FavoriteRestaurantsPage";
import Auxilary from "./hoc/Auxilary";
import Backdrop from "./UI/Backdrop";
import closeImage from "./assets/images/icons/close_orange.svg";
import { Faq } from "./components/Faq";
import { Tos } from "./components/Tos";
import { PrivacyPolicy } from "./components/PrivacyPolicy";

const App = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const bannerText = useSelector((state) => state.auth.bannerText);
  const [showCookies, setShowCookies] = useState(
    localStorage.getItem("cookies-accepted") ? false : true
  );
  const { i18n, t } = useTranslation();
  const [finished, setFinished] = useState(false);
  const [waitingBasket, setWaitingBasket] = useState(true);
  const [waitingBanner, setWaitingBanner] = useState(true);
  const localStorageToken = localStorage.getItem("accessToken");
  // const [banner, setBanner] = useState(false);

  useEffect(() => {
    if (!accessToken && localStorageToken) {
      let configUser = user_profile_config(localStorageToken, i18n.language);
      axios
        .get(user_profile_url, {
          params: {},
          headers: configUser.headers,
        })
        .then((response) => {
          if (response.data.status) {
            dispatch(setUserData(response.data, localStorageToken));
            setFinished(true);
            get_gallery(localStorageToken);
          } else {
            setFinished(true);
            get_gallery();
          }
        })
        .catch((error) => {
          setFinished(true);
          get_gallery();
        });
    } else {
      setFinished(true);
      get_gallery();
    }
  }, [accessToken]);

  useEffect(() => {
    let configs = get_tags_config(
      accessToken ? accessToken : "",
      i18n.language
    );
    axios
      .get(get_tags_url, {
        params: {},
        headers: configs.headers,
      })
      .then((response) => {
        if (response.data.status) {
          if (response.data.tags) {
            dispatch(set_api_tags(response.data.tags));
          }
        }
      })
      .catch((err) => {});
  }, [i18n.language]);

  const checkPaymentOkFunc = (localStoragePayment, removeBasket = true) => {
    if (
      localStoragePayment &&
      history.location &&
      history.location.pathname.substring(0, 12) === "/payment/ok/"
    ) {
      let basketId = localStorage.getItem("basketId");
      let phone = localStorage.getItem("phone");
      let localStorageAccessToken = localStorage.getItem("accessToken");
      let config = payment_ok_number_config(
        localStorageAccessToken,
        i18n.language,
        phone
      );
      localStorage.removeItem("payingStarted");
      localStorage.removeItem("phone");
      if (removeBasket) localStorage.removeItem("basketId");
      axios
        .post(
          basket_url + basketId + "/payment",
          { ...config.body },
          { params: {}, headers: config.headers }
        )
        .then((response) => {
          dispatch(set_loading(false, true));
        })
        .catch((err) => {
          dispatch(set_loading(false, true));
        });
      setWaitingBasket(false);
      return;
    } else if (
      !localStoragePayment &&
      history.location &&
      history.location.pathname.substring(0, 12) === "/payment/ok/"
    ) {
      if (removeBasket) localStorage.removeItem("basketId");
      dispatch(set_loading(false, false));
      setWaitingBasket(false);
    }
    if (removeBasket) localStorage.removeItem("basketId");
    setWaitingBasket(false);
  };

  useEffect(() => {
    const localStorageBasket = localStorage.getItem("basketId");
    const localStoragePayment = localStorage.getItem("payingStarted");
    if (localStorageBasket) {
      // if (localStoragePayment && localStoragePayment === "true") {
      //   const paymentProceed = localStorage.getItem("payingProceed");
      //   if (paymentProceed && paymentProceed === "true") {
      //     localStorage.removeItem("payingStarted");
      //     localStorage.removeItem("phone");
      //   } else {
      //     localStorage.setItem("payingProceed", "true");
      //     setWaitingBasket(false);
      //     return;
      //   }
      // } else {
      //   localStorage.removeItem("payingProceed");
      // };
      let config = get_basket_config(accessToken, i18n.language);
      axios
        .get(basket_url + localStorageBasket, {
          params: {},
          headers: config.headers,
        })
        .then((response) => {
          if (response.data.status) {
            localStorage.removeItem("phone");
            localStorage.removeItem("payingStarted");
            if (
              history.location &&
              history.location.pathname.substring(0, 12) === "/payment/ok/"
            ) {
              dispatch(set_loading(false, false));
            }
            dispatch(save_basket_data(response.data));
            setWaitingBasket(false);
          } else {
            if (response.data.code === 1056) {
              checkPaymentOkFunc(localStoragePayment);
              setWaitingBasket(false);
            } else {
              checkPaymentOkFunc(localStoragePayment);
              setWaitingBasket(false);
            }
          }
        })
        .catch((err) => {
          checkPaymentOkFunc(localStoragePayment, false);
          setWaitingBasket(false);
        });
    } else {
      setWaitingBasket(false);
      dispatch(set_loading(false, false));
    }
  }, []);

  // useEffect(() => {
  //   const localStorageBasket = localStorage.getItem("basket");
  //   if (localStorageBasket) {
  //     dispatch(save_basket_data(JSON.parse(localStorageBasket)));
  //   }

  // }, [])
  useEffect(() => {
    const GOOGLE_PLACES_API_URL = process.env.REACT_APP_GOOGLE_MAPS_API;
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=" +
      GOOGLE_PLACES_API_URL +
      "&language=MK";
    document.head.append(script);
  }, []);

  const [navigatorFinished, setNavigatorFinished] = useState(false);
  const getCoordinates = useCallback(
    (position, timeOut) => {
      // const lat = localStorage.getItem('lat');
      // const long = localStorage.getItem('long');
      // if (lat+"" !== position.coords.latitude+"" || long+"" != position.coords.longitude+"") {
      dispatch(
        set_coord({
          lat: position.coords.latitude + "",
          long: position.coords.longitude + "",
        })
      );
      // }
      clearTimeout(timeOut);
      setNavigatorFinished(true);
    },
    [dispatch]
  );

  const errorHandler = (err) => {
    dispatch(set_coord({ lat: 0, long: 0 }));
  };

  useEffect(() => {
    //   const lat = localStorage.getItem('lat');
    //   const long=localStorage.getItem('long')
    //   if ( lat &&  long) {
    //     dispatch(set_coord({ lat: lat+"", long: long+"" }));
    //  }
    let timeOut = setTimeout(() => {
      setNavigatorFinished(true);
    }, 400);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => getCoordinates(position, timeOut),
        (err) => errorHandler()
      );
    } else {
      clearTimeout(timeOut);
      setNavigatorFinished(true);
    }
  }, [getCoordinates]);

  useEffect(() => {
    let config = news_config(accessToken ? accessToken : "", i18n.language);
    axios
      .get(news_url, { params: {}, headers: config.headers })
      .then((response) => {
        if (response.data.status) {
          dispatch(set_banner(true, response.data.item.message));
        } else {
          dispatch(set_banner(false, ""));
        }
      })
      .catch((err) => {
        dispatch(set_banner(false, ""));
      });
  }, [i18n.language]);

  const get_gallery = (token = null) => {
    let configGallery = banner_config(token ? token : "", i18n.language);
    axios
      .get(gallery_promo_url, { params: {}, headers: configGallery.headers })
      .then((response) => {
        if (response.data.status) {
          //status ok

          //response have banner
          if (
            response.data.banner &&
            response.data.banner.restaurant_id &&
            response.data.banner.image &&
            response.data.banner.image.web_image
          ) {
            let promoClosed = localStorage.getItem("promoClosed");

            if (promoClosed) {
              let promoItem = localStorage.getItem("promoItem");
              if (!promoItem) {
                dispatch(setPromoBanner(response.data.banner));
                setWaitingBanner(false);
              } else {
                const now = new Date();
                promoItem = JSON.parse(promoItem);
                if (
                  promoItem.promoId !== response.data.banner.restaurant_id &&
                  promoItem.promoImage !== response.data.banner.image.web_image
                ) {
                  localStorage.removeItem("promoClosed");
                  localStorage.removeItem("promoItem");
                  dispatch(setPromoBanner(response.data.banner));
                  setWaitingBanner(false);
                } else if (now.getTime() >= promoItem.expiry) {
                  localStorage.removeItem("promoClosed");
                  localStorage.removeItem("promoItem");
                  dispatch(setPromoBanner(response.data.banner));
                  setWaitingBanner(false);
                } else {
                  dispatch(setPromoBanner(null));
                  setWaitingBanner(false);
                }
              }
            } else {
              dispatch(setPromoBanner(response.data.banner));
              setWaitingBanner(false);
            }
          }

          if (response.data.gallery) {
            dispatch(set_slider_data(response.data.gallery));
            setWaitingBanner(false);
          }
          setWaitingBanner(false);
        } else {
          setWaitingBanner(false);
          dispatch(setPromoBanner(null));
          dispatch(set_slider_data(null));
        }
      })
      .catch((err) => {
        setWaitingBanner(false);
        dispatch(setPromoBanner(null));
        dispatch(set_slider_data(null));
      });
  };

  let routes = (
    <Switch>
      <Route path="/restaurant" component={RestaurantContainer} />
      <Route path="/settings" component={SettingsPage} />
      <Route path="/order" component={OrderContainer} />
      <Route path="/payment/ok/:id" component={OkPage} />
      <Route path="/payment/fail" component={FailPage} />
      <Route path="/favorites" component={FavoriteRestaurantsPage} />
      <Route path="/faq" component={Faq} />
      <Route path="/tos" component={Tos} />
      <Route path="/privacyPolicy" component={PrivacyPolicy} />
      <Route path="/" component={HomePage} />
    </Switch>
  );
  if (!accessToken) {
    routes = (
      <Switch>
        <Route path="/privacyPolicy" component={PrivacyPolicy} />
        <Route path="/tos" component={Tos} />
        <Route path="/faq" component={Faq} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/forgot" component={ForgotPage} />
        <Route path="/restaurant" component={RestaurantContainer} />
        <Route path="/order" component={OrderContainer} />
        <Route path="/" component={HomePage} />
      </Switch>
    );
  }

  return navigatorFinished ? (
    <Aux>
      {bannerText !== "" ? (
        <div className="banner-section">
          <Ticker mode="smooth" offset={150}>
            {({ index }) => (
              <>
                <span>{bannerText}</span>
              </>
            )}
          </Ticker>
        </div>
      ) : null}
      {finished && !waitingBasket && !waitingBanner ? (
        <Auxilary>
          <SmartBanner
            daysHidden={0}
            daysReminder={0}
            position={"bottom"}
            ignoreIosVersion={true}
            button={"OPEN"}
            title={"Use the Naracaj.com APP!"}
          />
          {routes}
        </Auxilary>
      ) : (
        <div className="loading-data-modal">
          <Spinner logo spinnerStyle="SpinnerYellow" />;
        </div>
      )}
      {showCookies ? (
        <div className="cookies">
          <Row className="m-0">
            <Col xs="9" md="10">
              <div className="cookies-description">
                <div>
                  {" "}
                  <span>
                    {t("homepage.cookies-description")}
                  </span> &nbsp;&nbsp;{" "}
                  <span>
                    <a
                      href="https://info.naracaj.com/privacy_policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("homepage.read-more")}
                    </a>
                  </span>
                </div>
              </div>
            </Col>
            <Col xs="3" md="2" className="text-right pl-0">
              <div
                className="button-class"
                onClick={() => {
                  localStorage.setItem("cookies-accepted", "true");
                  setShowCookies(false);
                }}
              >
                <button>OK</button>
              </div>
            </Col>
          </Row>
        </div>
      ) : null}
      {/* {banner ?
          <div className='banner-promo'>
            <div className='banner-image'>
              <div onClick={() => {
                history.push({ pathname: '/restaurant/details/' + banner.restaurant_id });
                setBanner(null);
              }}>
                <img src={banner.image.web_image} ></img>
              </div>
              <div className='banner-close-icon' onClick={()=> setBanner(null)}>
                <img src={closeImage}></img>
              </div>
            </div>
            <Backdrop address className='backdrop banner-pointer' show={banner} backdropClicked={() =>{}} />
          </div> : null} */}
    </Aux>
  ) : null;
};

export default App;

const client_secret = process.env.REACT_APP_APPKEY;
const client_secret_v2 = process.env.REACT_APP_APPKEY_V2;
const tags_client_secret = process.env.REACT_APP_TAGS_APPKEY;
const upsale_client_secret = process.env.REACT_APP_APPKEY_UPSALE;
const localization = "mk";

export const getUpsaleConfig = (accesstoken = "", locale = localization) => {
  return {
    headers: {
      locale: locale,
      appkey: upsale_client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
  };
};

export const getAllRestaurantsConfig = (
  locale = localization,
  accesstoken = ""
) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
  };
};

export const login_confing = (
  email,
  password,
  locale,
  lat,
  long,
  osType,
  osName,
  osVersion,
  accesstoken = ""
) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
    body: {
      email: email,
      password: password,
      last_signin_lat: lat,
      last_signin_long: long,
      signin_device_type: osType,
      signin_device_os: osName + " " + osVersion,
    },
  };
};

export const logout_confing = (accesstoken) => {
  return {
    headers: {
      appkey: client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
  };
};

export const forgotPassword_confing = (email, locale) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      Accept: "application/json",
    },
    body: {
      email: email,
    },
  };
};

export const register_confing = (
  email,
  password,
  name,
  surname,
  phone,
  day,
  month,
  year,
  marketing,
  locale,
  lat,
  long,
  osType,
  osName,
  osVersion,
  accesstoken = ""
) => {
  if (day !== null || month !== null || year !== null) {
    return {
      headers: {
        locale: locale,
        appkey: client_secret,
        "x-access-token": accesstoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: {
        first_name: name,
        last_name: surname,
        phone_number: phone,
        email: email,
        password: password,
        dob_day: day,
        dob_month: month,
        dob_year: year,
        signup_device_type: osType,
        signup_device_model: "",
        signup_device_os: osName + " " + osVersion,
        last_signin_lat: lat,
        last_signin_long: long,
        marketing: marketing,
        android_id: "",
        device_token: "",
        keychain_id: "",
      },
    };
  } else {
    return {
      headers: {
        locale: locale,
        appkey: client_secret,
        "x-access-token": accesstoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: {
        first_name: name,
        last_name: surname,
        phone_number: phone,
        email: email,
        password: password,
        signup_device_type: osType,
        signup_device_model: "",
        signup_device_os: osName + " " + osVersion,
        last_signin_lat: lat,
        last_signin_long: long,
        marketing: marketing,
        android_id: "",
        device_token: "",
        keychain_id: "",
      },
    };
  }
};

export const facebook_login_confing = (
  email,
  facebook_access_token,
  fb_user_id,
  name,
  locale,
  lat,
  long,
  osType,
  osName,
  osVersion
) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      Accept: "application/json",
    },
    body: {
      email: email,
      facebook_access_token: facebook_access_token,
      fb_user_id: fb_user_id,
      first_name: name,
      signup_device_type: osType,
      signup_device_model: "",
      signup_device_os: osName + " " + osVersion,
      last_signin_lat: lat,
      last_signin_long: long,
      android_id: "",
      device_token: "",
      keychain_id: "",
    },
  };
};

export const user_profile_config = (accesstoken, locale = localization) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
  };
};
export const news_config = (accesstoken, locale = localization) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
  };
};

export const banner_config = (accesstoken, locale = localization) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret_v2,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
  };
};
export const products_search_config = (accesstoken, locale = localization) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret_v2,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
  };
};

export const get_tags_config = (accesstoken, locale = localization) => {
  return {
    headers: {
      locale: locale,
      appkey: tags_client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
  };
};

export const payment_config = (
  accesstoken,
  locale = localization,
  basket_phone_number,
  basket_payment_type
) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
    body: {
      basket_phone_number,
      basket_payment_type,
    },
  };
};

export const payment_config_card = () =>
  // amount, clientid, storetype, hash, trantype, currency, instalment, oid, okUrl, failUrl, callbackUrl,
  // lang,
  // rnd,
  // encoding,
  // userId,
  // refreshtime,
  // fismi,
  // pan,
  // Ecom_Payment_Card_ExpDate_Month,
  // Ecom_Payment_Card_ExpDate_Year,
  // cv2
  {
    return {
      headers: {},
      body: {
        // amount,
        // clientid,
        // storetype,
        // hash,
        // trantype,
        // currency,
        // instalment,
        // oid,
        // okUrl,
        // failUrl,
        // callbackUrl,
        // lang,
        // rnd,
        // encoding,
        // userId,
        // refreshtime,
        // fismi,
        // pan,
        // Ecom_Payment_Card_ExpDate_Month,
        // Ecom_Payment_Card_ExpDate_Year,
        // cv2
      },
    };
  };

export const update_profile_config = (accesstoken, locale) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      "x-access-token": accesstoken,
      "Content-Type": "multipart/form-data",
      Accept: "multipart/form-data",
    },
  };
};

export const get_img_config = (accesstoken, locale) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      "x-access-token": accesstoken,
      "Content-Type": "multipart/form-data",
      Accept: "multipart/form-data",
      responseType: "blob",
    },
  };
};
export const payment_ok_number_config = (accesstoken, locale, phoneNumber) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
    body: {
      basket_phone_number: phoneNumber,
      basket_payment_type: "CC_HalkBank",
    },
  };
};
export const promo_code_config = (accesstoken, locale, promoCode) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
    body: {
      code: promoCode,
    },
  };
};
export const add_delete_fav_config = (accesstoken, locale, id) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
    body: {
      restaurant_id: id,
    },
  };
};

export const add_save_address_confing = (
  name,
  address_street,
  address_no,
  address_apt_no,
  lat,
  long,
  accesstoken,
  is_default,
  locale,
  city,
  zip = 1000,
  additional_info = "",
) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      Accept: "application/json",
      "x-access-token": accesstoken,
    },
    body: {
      name: name,
      address_street: address_street,
      address_no: address_no,
      address_apt_no: address_apt_no,
      is_default: is_default,
      latitude: lat,
      longitude: long,
      state: "Macedonia",
      city: city,
      zip: zip,
      additional_info: additional_info,
    },
  };
};

export const update_address_config = (
  user_fav_location_id,
  name,
  address_street,
  address_no,
  address_apt_no,
  lat,
  long,
  accesstoken,
  is_default,
  locale,
  city,
  zip = 1000
) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      Accept: "application/json",
      "x-access-token": accesstoken,
    },
    body: {
      user_fav_location_id: user_fav_location_id,
      name: name,
      address_street: address_street,
      address_no: address_no,
      address_apt_no: address_apt_no,
      is_default: is_default,
      latitude: lat,
      longitude: long,
      state: "Macedonia",
      city: city,
      zip: zip,
    },
  };
};

export const set_order_address = (
  address_apt_no,
  address_no,
  address_street,
  lat,
  long,
  name,
  locale,
  accesstoken,
  city,
  additional_info = ""
) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      Accept: "application/json",
      "x-access-token": accesstoken,
    },
    body: {
      delivery_address: {
        address_apt_no: address_apt_no,
        address_no: address_no,
        address_street: address_street,
        latitude: lat,
        longitude: long,
        name: name,
        city: city,
        additional_info: additional_info,
      },
    },
  };
};

export const set_default_address_config = (
  user_fav_location_id,
  accesstoken,
  is_default,
  locale
) => {
  return {
    headers: {
      appkey: client_secret,
      "x-access-token": accesstoken,
      locale: locale,
      Accept: "application/json",
    },
    body: {
      user_fav_location_id: user_fav_location_id,
      is_default: is_default,
    },
  };
};

export const get_user_addresses_config = (accesstoken = "", locale) => {
  return {
    headers: {
      appkey: client_secret,
      "x-access-token": accesstoken,
      locale: locale,
      Accept: "application/json",
    },
  };
};

export const create_basket_config = (
  orderMode,
  restaurant_id,
  delivery_address,
  accesstoken = "",
  locale
) => {
  return {
    headers: {
      appkey: client_secret,
      "x-access-token": accesstoken,
      locale: locale,
      Accept: "application/json",
    },
    body: {
      order_mode: orderMode,
      restaurant_id: restaurant_id,
      delivery_address: delivery_address,
    },
  };
};

export const get_basket_config = (accesstoken = "", locale) => {
  return {
    headers: {
      appkey: client_secret,
      "x-access-token": accesstoken,
      locale: locale,
      Accept: "application/json",
    },
  };
};

export const create_basket_dinein_pickup_config = (
  orderMode,
  restaurant_id,
  accesstoken = "",
  locale
) => {
  return {
    headers: {
      appkey: client_secret,
      "x-access-token": accesstoken,
      locale: locale,
      Accept: "application/json",
    },
    body: {
      order_mode: orderMode,
      restaurant_id: restaurant_id,
    },
  };
};

export const get_item_config = (
  order_type,
  menu_item_size_id,
  accesstoken = "",
  locale
) => {
  return {
    headers: {
      appkey: client_secret,
      "x-access-token": accesstoken,
      locale: locale,
      Accept: "application/json",
      menu_item_size_id: menu_item_size_id,
      order_type: order_type,
    },
  };
};

export const get_restaurant_menu_config = (
  restaurant_id,
  order_type,
  locale,
  accesstoken = "",
  chain_id = null
) => {
  return {
    headers: {
      appkey: client_secret,
      "x-access-token": accesstoken,
      locale: locale,
      Accept: "application/json",
      order_type: order_type,
      restaurantId: restaurant_id,
    },
  };
};
export const basket_config = (
  item_id,
  size_id,
  quantity,
  modifiers,
  specialinstruction,
  locale,
  accesstoken = ""
) => {
  return {
    headers: {
      appkey: client_secret,
      "x-access-token": accesstoken,
      locale: locale,
      Accept: "application/json",
    },
    body: {
      item_id: item_id,
      menu_item_ordering_id: size_id,
      quantity: quantity,
      modifiers: modifiers,
      special_instructions: specialinstruction,
    },
  };
};

export const get_restaurant_details_config = (
  accesstoken = "",
  locale = localization
) => {
  return {
    headers: {
      appkey: client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
      locale: locale,
    },
  };
};

export const get_times_confing = (
  order_type,
  restaurant_id,
  menu_id,
  accesstoken = "",
  locale = localization
) => {
  return {
    headers: {
      appkey: client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
      locale: locale,
      order_type: order_type,
      restaurant_id: restaurant_id,
      menu_id: menu_id,
    },
  };
};

export const delete_address_config = (
  user_fav_location_id,
  accesstoken,
  locale
) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
    body: {
      user_fav_location_id: user_fav_location_id,
    },
  };
};

export const update_password_config = (
  accesstoken,
  oldPassword,
  newPassword,
  locale
) => {
  return {
    headers: {
      locale: locale,
      appkey: client_secret,
      "x-access-token": accesstoken,
      Accept: "application/json",
    },
    body: {
      password_old: oldPassword,
      password_new: newPassword,
    },
  };
};

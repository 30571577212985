import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// import btnRemove from '../assets/images/icons/btnRemove.svg';
// import btnEdit from '../assets/images/icons/btnEdit.svg';
import OrderPageProductItem from './OrderPageProductItem';

const recursivePrice = (modifiers, currentPrice) => {
    for (let item in modifiers) {

        currentPrice += modifiers[item].price;
        if (modifiers[item].modifiers && modifiers[item].modifiers.length !== 0) {
            currentPrice += recursivePrice(modifiers[item].modifiers, 0);
        }
    }
    return currentPrice;
}

const OpenBasketDataItem = props => {
    const { t } = useTranslation();
    let modifiersPrice = 0;
    if (props.data.modifiers) {
        modifiersPrice = recursivePrice(props.data.modifiers, 0) * props.data.quantity;
    }
    return (
        <Col xs='12' className='p-0 open-basket-data-item'>
            <Row className='m-0'>

                <Col xs='12' className='p-0 open-basket-item-details' >
                    <OrderPageProductItem
                        data={props.data}
                        openBasket={true}
                        editItemHandler={() => props.editItemHandler(props.data.id, props.upsale ? true : false)}
                        removeItemHandler={() => props.removeItemHandler(props.data.id, props.upsale ? true : false)}
                    />
                </Col>

                {/* <Col xs='12' className='item-title p-0'>
                    <Row className='m-0'>
                        <Col xs='9' className='p-0'>
                            <span>{props.data.quantity} x {props.data.name}</span>
                        </Col>
                        <Col xs='3' className='p-0 text-right'>
                            <span>{(props.data.total_price + modifiersPrice).toFixed(0)} {t('post.currency')}</span>
                        </Col>
                    </Row>
                </Col>
                <Col xs='12' className='item-modifiers p-0'>
                    {props.data.modifiers.length !== 0 ?
                        props.data.modifiers.map((item, index) => {
                            return <span key={item.id}>{item.name}
                                {index !== props.data.modifiers.length - 1 ? ", " : ""}
                            </span>
                        })
                        : null}
                </Col> */}
                {/* <Col xs='12' className='p-0 edit-remove-buttons'>
                    <button onClick={() => props.editItemHandler(props.data.id)}>
                        <img alt='btn-edit' src={btnEdit}></img>
                        <div>{t("open-basket.edit")}</div>
                    </button>
                    <button onClick={() => props.removeItemHandler(props.data.id)}>
                        <img alt='btn-remove' src={btnRemove}></img>
                        <div>{t("open-basket.delete")}</div>
                    </button>
                </Col> */}
            </Row>
            <div>
            </div>
        </Col>
    )

}

export default OpenBasketDataItem;
export const SET_COORDINATES='SET_COORDINATES';
export const SET_RESTAURANTS_RESPONSE_DATA='SET_RESTAURANTS_RESPONSE_DATA';
export const SET_RESTAURANTS_LOADING = 'SET_RESTAURANTS_LOADING';
export const SET_RESTAURANTS_LOADING_FINISHED = 'SET_RESTAURANTS_LOADING_FINISHED';
export const SET_ERROR = "SET_ERROR";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_USER_DATA = "SET_USER_DATA";
export const LOGOUT = "LOGOUT";
export const UPDATE_NAME_SURNAME = 'UPDATE_NAME_SURNAME';
export const SAVE_CREATED_BASKET = 'SAVE_CREATED_BASKET';
export const SET_MENU_ITEM_ORDERING_ID = 'SET_MENU_ITEM_ORDERING_ID';
export const SET_MODIFIERS_PRICE = 'SET_MODIFIERS_PRICE';
export const CLEAR_ORDER_STATE = 'CLEAR_ORDER_STATE';
export const SET_MODIFIERS_NOT_VALID = 'SET_MODIFIERS_NOT_VALID';
export const REMOVE_MODIFIERS_NOT_VALID = 'REMOVE_MODIFIERS_NOT_VALID';
export const SET_MODIFIERS_FOR_ORDER = 'SET_MODIFIERS_FOR_ORDER';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_RESTAURANTS_FAV = 'SET_RESTAURANTS_FAV';
export const CLEAR_RESTAURANTS_STATE = 'CLEAR_RESTAURANTS_STATE';
export const SET_BANNER = 'SET_BANNER';
export const SET_SLIDER_DATA = 'SET_SLIDER_DATA';
export const SET_PROMO_BANNER = 'SET_PROMO_BANNER';
export const SET_OK_LOADING = 'SET_OK_LOADING';
export const SET_FILTER = 'SET_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const SET_FOOD_TYPE = 'SET_FOOD_TYPE';
export const REMOVE_FOOD_TYPE = 'REMOVE_FOOD_TYPE';
export const SET_FILTER_TYPES = 'SET_FILTER_TYPES';
export const CLEAR_FILTER_MODAL = 'CLEAR_FILTER_MODAL';
export const FILTER_APPLIED = 'FILTER_APPLIED';
export const SET_API_TAGS = 'SET_API_TAGS';
export const SET_TAG_CHECKED = 'SET_TAG_CHECKED';
export const SET_TAG_UNCHECK = 'SET_TAG_UNCHECK';
export const SET_EXPANDED = 'SET_EXPANDED';
export const CLEAR_FILTER = 'CLEAR_FILTER';


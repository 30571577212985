import React, { useRef, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import axios from '../axios-instance_baseURL';
// import defaultImage from '../assets/images/logos/MobAppHomePageImage.png'
// import defaultImage from '../assets/images/logos/Logo_2_1.svg'
import defaultImage from '../assets/images/logos/WebThumbnail.jpg'
// import heart from '../assets/images/icons/heart.svg';
import heart from '../assets/images/icons/heart_yellow.svg';
import heartFilled from '../assets/images/icons/heart_yellow_filled.svg';
import timeIcon from '../assets/images/icons/XMLID_806_.png';
import approxIcon from '../assets/images/icons/is-approximately-equal-to.png';
import pickupIcon from '../assets/images/icons/Icon.png';
import dineinIcon from '../assets/images/icons/Group 4887.png';
import deliveryIcon from '../assets/images/icons/pickup-car.png';
import info from '../assets/images/icons/info.svg';
import {
    restaurant_details_url,
} from '../assets/urls/urls';
import {
    get_restaurant_details_config,
} from '../assets/configs/config_calls';
import Spinner from '../UI/Spinner';
import Auxilary from '../hoc/Auxilary';
import { Route } from 'react-router';
import MapModal from './MapModal';
const Post = props => {

    const imgRef = useRef();
    const [imgError, setImgError] = useState(false);
    const refToImage = useRef();
    const { t, i18n } = useTranslation();
    const status = props.status ? t("post.open") : t("post.closed");
    const delivery = t("post.delivery");
    const pickUp = t("post.pickUp");
    const dineIn = t("post.dineIn");
    const [width, setWidth] = useState();
    const [mounted, setMounted] = useState(false);
    const [restaurantData, setRestaurantData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openModalInfo, setOpenModalInfo] = useState(false);

    const handleScroll = (e) => {
        const elem = refToImage;
        setWidth(elem.current.clientWidth);
    }

    useEffect(() => {
        if (mounted) {
            setWidth(refToImage.current.clientWidth);
        } else {
            setMounted(true);
        }
    }, [mounted]);

    // const handleInfoClick = () => {
    //     let configs = get_restaurant_details_config("", i18n.language);
    //     let callurl = restaurant_details_url + props.id;
    //     setLoading(true);
    //     axios.get(callurl, { params: {}, headers: configs.headers })
    //         .then(response => {
    //             if (response.data.status) {
    //                 setRestaurantData(response.data.restaurant);
    //                 setLoading(false);
    //                 setOpenModalInfo(true);
    //                 props.history.push("/restaurant-info");
    //             }
    //             setLoading(false);
    //         }).catch(err => {
    //             if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
    //                 props.history.push({
    //                     pathname: '/settings/logout',
    //                     err: "unauthenticated",
    //                 });
    //                 return;
    //             }
    //             setLoading(false);
    //         });
    // }
    useEventListener('resize', handleScroll);
    const heightStyle = width ? (width / 2.12) : null;
    const favImage = props.isFav ? heartFilled : heart;
    return (
        <Col ref={refToImage} xs='12' lg={props.slider ? "12" : '4'} className={props.slider ? "slick-image post slick-padding" : 'post'}>
            {/* <Row ref={refToImage} onClick={props.clickHandler} className='m-0 post-row'>
                <Col xs='12' className='p-0 image' style={{ height: heightStyle }}>
                    <img alt='heart-fav' ref={imgRef} style={{ display: "none" }} src={props.restaurantImage} onError={() => setImgError(true)} />
                    <div style={{ backgroundImage: "url(" + (imgError ? defaultImage : props.restaurantImage) + ")", height: heightStyle }} />
                    <img alt='heart-fav' onClick={() => { }} className="img-heart" src={heart} />
                </Col>
                <Col xs='12' className='p-0 second-col'> */}
            <Row className='m-0 post-row'>
                <Col xs='12' className='p-0 image' style={{ height: heightStyle }}>
                    <img alt='heart-fav' ref={imgRef} style={{ display: "none" }} src={props.restaurantImage} onError={() => setImgError(true)} />
                    <div onClick={props.clickHandler} style={{ backgroundImage: "url(" + (imgError ? defaultImage : props.restaurantImage) + ")", height: heightStyle }} />
                    <span className="img-heart" onClick={() => {
                        if (props.isFav === true) {
                            props.handleRemFav();
                        } else {
                            props.handleAddFav();
                        }
                    }}>
                        <img alt='heart-fav' src={ favImage} />
                    </span>
                </Col>
                <Col xs='12' className='p-0 second-col' >
                    <Row className='m-0'>
                        <Col xs='12' className='p-0'>
                            <Row className='m-0' >
                                <Col xs='10' className='p-0 name ' onClick={props.clickHandler}>
                                    <span>{props.restaurantName}</span>

                                </Col>
                                <Col xs='2' className='p-0  '>
                                    <span className="image-info" onClick={() => props.handleInfoClick(props.id)}>
                                        <img src={info} alt="more-info"></img>
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs='12' className='p-0 types' onClick={props.clickHandler}>
                            <span>
                                {
                                    props.restaurantTypes.split(',').map((item, index) => {
                                        return <div className="types-style" key={item}>{item} {
                                            props.restaurantTypes.split(',').length !== index + 1 ?
                                                <span className='dot'></span>
                                                : null
                                        } </div>
                                    })
                                }

                            </span>
                        </Col>
                        {/* {props.avrgDeliveryTime ?
                            <Col xs='12' className='p-0 delivery-time'>
                                <div>
                                    <img alt='time-icon' src={timeIcon} />
                                    {t("post.avrg-time")} <img alt='approximate-icon' src={approxIcon}></img>  {props.avrgDeliveryTime} {t("post.min")}
                                </div>
                        </Col> */}
                        {/* <Col xs='12' className='p-0 types'>
                            <span>{props.restaurantTypes}</span>
                        </Col> */}
                        {props.avrgDeliveryTime ?
                            <Col xs='12' className='p-0 delivery-time' onClick={props.clickHandler}>

                                <div>  <img alt='time-icon' src={timeIcon} /> {t("post.avrg-time")} <img alt='approximate-icon' src={approxIcon}></img> {props.avrgDeliveryTime} {t("post.min")}</div>
                            </Col>
                            : null
                        }
                        {/* <Col xs='6' className='p-0 status'>
                        <div className={props.status ? "open" : "closed"}>{status}</div>
                    </Col> */}
                        <Col xs='12' className='p-0' onClick={props.clickHandler}>
                            <Row className='m-0'>
                                <Col xs='9' className="p-0 methods">
                                    <div>
                                        {props.pickUp ? <span> <img alt='pickup-icon' src={pickupIcon} />{pickUp} </span> : null}
                                        {props.delivery ? <span>  <img alt='delivery-icon' src={deliveryIcon} />{delivery}</span> : null}
                                        {props.dineIn ? <span><img alt='dinein-icon' src={dineinIcon} />{dineIn}</span> : null}
                                    </div>
                                </Col>

                                <Col xs='3' className='p-0 status'>
                                    <div className={props.status ? "open" : "closed"}>{status}</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {loading ?
                <div className='loading-data-modal' >
                    <Spinner spinnerStyle="SpinnerYellow" />;
                         </div> : null
            }
            {/* {openModalInfo ?
                <Auxilary>
                    <Route path={"/restaurant-info"}
                        component={(props) =>
                            <MapModal data={restaurantData} handleCloseMenuModal={() => {
                                setOpenModalInfo(false);
                                props.history.goBack(1);
                            }}
                                homePage={true}
                            />} />

                </Auxilary>
                : null
            } */}
        </Col >
    )
}

export default Post;

function useEventListener(eventName, handler, element = window) {

    const savedHandler = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {

            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            const eventListener = event => savedHandler.current(event);

            element.addEventListener(eventName, eventListener, true);


            return () => {
                element.removeEventListener(eventName, eventListener, true);
            };
        },
        [eventName, element]
    );
};
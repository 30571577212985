import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../axios-instance_baseURL';
import { useTranslation } from 'react-i18next';
import { Redirect, Route } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import axiosWithoutBase from 'axios';
import Spinner from '../UI/Spinner';
import { basket_url, add_save_address_url, basket_upsales_url } from '../assets/urls/urls';
import {
    set_order_address,
    user_profile_config,
    add_save_address_confing,
    payment_config,
    payment_config_card,
    get_basket_config,
    getUpsaleConfig
} from '../assets/configs/config_calls';
import OrderPageProductItem from './OrderPageProductItem';
import defaultImage from '../assets/images/logos/Logo_naracaj_2-1.svg';
import leftArrow from '../assets/images/icons/left-arrow.svg';
import Auxilary from '../hoc/Auxilary';
import OrderPageDetails from './OrderPageDetails';
import OrderPayment from './OrderPayment';
import { save_basket_data } from '../store/actions/basketActions';
import MenuModalOrder from '../components/MenuModalOrder';
import Backdrop from '../UI/Backdrop';
import ModalUpsale from './ModalUpsale';

const OrderPage = props => {

    const basketData = useSelector(state => state.basket.basketData);
    const accessToken = useSelector(state => state.auth.accessToken);
    const [orderPaymentActiveTab, setOrderPaymentActiveTab] = useState(true);
    const [selectedAddressData, setSelectedAddressData] = useState();
    const [selectedTime, setSelectedTime] = useState();
    const [loadingData, setLoadingData] = useState(false);
    const [errorMsg, setErrMsg] = useState();
    const [mounted, setMounted] = useState(false);
    const [orderNotValid, setOrderNotValid] = useState(false);
    const [addressValidated, setAddressValidated] = useState(false);
    const [orderOverAllValidation, setOrderOverAllValidation] = useState(false);
    const [orderOverAllMssg, setOrderOverAllMssg] = useState();
    const [paymentMethod, setPaymentMethod] = useState();
    const [saveForFutureUse, setSaveForFutureuse] = useState(false);
    const [leadTime, setLeadTime] = useState();
    const [orderNumber, setOrderNumber] = useState("");
    const [availableTimes, setAvailableTimes] = useState();
    const [selectedTimeType, setSelectedTimeType] = useState();
    const [hasAccessToken, setHasAccessToken] = useState(true);
    const [paymentDataInputs, setPaymentDataInputs] = useState(null);
    const refToPayment = useRef();
    const refToIframe = useRef();
    const [redirect, setRedirect] = useState(false);
    const [showTransErr, setShowTransErr] = useState(false);
    const [availablePaymentMethods, setAvailablePaymentMethods] = useState({
        cash: false,
        card: false
    });
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [paymentData, setPaymentData] = useState({
        phone: {
            valid: false,
            value: "",
        },
        cardNumber: {
            valid: false,
            value: "",
        },
        cardOwnerName: {
            valid: false,
            value: "",
        },
        cardCVV: {
            valid: false,
            value: "",
        },
        cardDate: {
            valid: false,
            value: "",
        },
    });

    const [modalEditing, setModalEditing] = useState({
        opened: false,
        data: null,
        basketItemDetails: null,
        editingItemId: null,
        closed: false
    });

    const [modalEditingUpsale, setModalEditingUpsale] = useState({
        opened: false,
        data: null,
    });

    useEffect(() => {
        if (paymentDataInputs) {
            localStorage.setItem("payingStarted", "true");
            localStorage.setItem("phone", paymentData.phone.value + "");
            localStorage.setItem("basketId", basketData.id);
            if (accessToken) localStorage.setItem("accessToken", accessToken);
            refToPayment.current.click();
            setLoadingData(true);
        }
    }, [paymentDataInputs]);

    useEffect(() => {
        let showModal = props.history && props.history.location && props.history.location.mode && props.history.location.mode === "failedOrder" ? true : false;
        if (showModal) {
            setShowTransErr(true);
            setTimeout(() => {
                setShowTransErr(false);
            }, 3000);
        }
    }, []);

    // useEffect(() => {
    // }, [refToIframe.current]);
    // if (refToIframe.current && refToIframe.current.contentWindow  ) {
    // }

    useEffect(() => {
        if (orderPaymentActiveTab === false) {
            const config = user_profile_config(accessToken, i18n.language);
            axios.get(basket_url + "" + basketData.id + "/payment_methods", { params: {}, headers: config.headers })
                .then(response => {
                    if (response.data.status) {
                        if (response.data.payment_methods.length !== 0) {
                            let cash = false;
                            let card = false;
                            let cardOption = ""
                            if (response.data.payment_methods[0].type) {
                                if (response.data.payment_methods[0].type === 'CashPayment') {
                                    cash = true;
                                } else {
                                    card = true
                                    cardOption = response.data.payment_methods[0].type;
                                }
                            }
                            if (response.data.payment_methods.length === 2) {
                                if (response.data.payment_methods[1].type === 'CashPayment') {
                                    cash = true;
                                } else {
                                    card = true;
                                    cardOption = response.data.payment_methods[1].type;
                                }
                            }
                            setAvailablePaymentMethods({
                                cash,
                                card,
                                cardOption
                            })

                        }
                    } else {
                        setAvailablePaymentMethods({
                            cash: false,
                            card: false
                        })
                    }

                }).catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }
                    setAvailablePaymentMethods({
                        cash: false,
                        card: false
                    })
                })
        }
    }, [orderPaymentActiveTab]);

    useEffect(() => {
        if (basketData && basketData.order_mode !== 'delivery') setLeadTime(basketData.lead_time)
    }, [basketData]);

    useEffect(() => {
        if (!accessToken) {
            setTimeout(() => {
                setHasAccessToken(false);
            }, 2000);
        }
    }, [accessToken]);

    const selectedAddress = async (addressData, id, onMount = false, streetNumChange = false) => {

        const config = set_order_address(
            addressData.address_apt_no,
            addressData.address_no,
            addressData.address_street,
            // 41.997354,
            // 21.426147,
            addressData.latitude === "" ? "0.0" : addressData.latitude,
            addressData.longitude === "" ? "0.0" : addressData.longitude,
            addressData.name,
            i18n.language,
            accessToken,
            addressData.city,
            addressData.additional_info
        );
        if (!streetNumChange)
            setLoadingData(true);
        let result = await axios.put(basket_url + basketData.id + "/delivery_address", { ...config.body }, {
            params: {}, headers: config.headers
        }).then(response => {
            if (response.data.status === true) {
                setLeadTime(response.data.basket.lead_time)
                if (id === 'current' || id === 'new') {
                    setSelectedAddressData({
                        addressData: {
                            ...addressData,
                        },
                        id: id
                    });
                } else {
                    setSelectedAddressData({
                        addressData: {
                            ...addressData,
                            valid: true
                        },
                        id: id
                    });
                }

                dispatch(save_basket_data(response.data));
                setAddressValidated(true);
                setLoadingData(false);
                return 1;
            } else {
                if (!onMount) {
                    setOrderNotValid(true);
                    setErrMsg(response.data.message);
                    setTimeout(() => {
                        setOrderNotValid(false);
                    }, 3000);
                }
                setLeadTime()
                setLoadingData(false);
                return 0;

            }
        }).catch(err => {
            if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                props.history.push({
                    pathname: '/settings/logout',
                    err: "unauthenticated",
                });
                return;
            }
            setLoadingData(false);
            setLeadTime()
            if (!onMount) {
                setErrMsg('homepage.errorMsg');
                setOrderNotValid(true);
                setTimeout(() => {
                    setOrderNotValid(false);
                }, 3000);
            }


            return 0;
        })
        return result;
    }

    const handleSelectedTime = async (selectedTime, onMount, type) => {
        const config = user_profile_config(accessToken, i18n.language);
        setLoadingData(true);
        let result = await axios.post(basket_url + basketData.id + "/time?time=" + selectedTime.fullTime,
            {}, { params: {}, headers: config.headers })
            .then(response => {
                if (response.data.status) {
                    setLoadingData(false);
                    setSelectedTime(selectedTime);
                    setSelectedTimeType(type);
                    if (!onMount && type !== 'fastes') {
                        setErrMsg(response.data.message);
                        setOrderNotValid(true);
                        setTimeout(() => {
                            setOrderNotValid(false);
                        }, 3000);
                    }
                    return 1;
                } else {
                    setLoadingData(false);
                    if (!onMount && type !== 'fastes') {
                        setErrMsg(response.data.message);
                        setOrderNotValid(true);
                        setSelectedTime();
                        setTimeout(() => {
                            setOrderNotValid(false);
                        }, 3000);
                        setLoadingData(false);
                    }
                    return 0;
                }
            })
            .catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setAddressValidated(false);
                setLoadingData(false);
                if (!onMount && type !== 'fastes') {
                    setErrMsg('homepage.errorMsg');
                    setOrderNotValid(true);
                    setSelectedTime();
                    setTimeout(() => {
                        setOrderNotValid(false);
                    }, 3000);
                }

                return 0
            });
        return result;
    }
    const handleOrderingDetails = async () => {
        if (orderPaymentActiveTab) {
            if (basketData.order_mode === 'delivery') {
                if (!addressValidated) {
                    setOrderNotValid(true);
                    setErrMsg('order-page.address_missing');
                    setTimeout(() => {
                        setOrderNotValid(false);
                    }, 3000);
                    return;
                } else if (addressValidated && !selectedAddressData) {
                    setOrderNotValid(true);
                    setErrMsg('order-page.address_missing');
                    setTimeout(() => {
                        setOrderNotValid(false);
                    }, 3000);
                    return;
                } else if (addressValidated && !selectedAddressData.id) {
                    setOrderNotValid(true);
                    setErrMsg('order-page.address_missing');
                    setTimeout(() => {
                        setOrderNotValid(false);
                    }, 3000);
                    return;

                } else if (addressValidated && selectedAddressData && (selectedAddressData.id === 'new' || selectedAddressData.id === 'current')) {
                    if (selectedAddressData.addressData.valid === false
                        || selectedAddressData.addressData.address_street.trim() === ''
                        || selectedAddressData.addressData.address_no.trim() === ''
                        // || selectedAddressData.addressDatalatitude === ''
                        // || selectedAddressData.addressData.longitude === ''
                        // || selectedAddressData.addressData.latitude === null
                        // || selectedAddressData.addressData.longitude === null
                    ) {
                        setOrderNotValid(true);
                        setErrMsg('order-page.address_missing');
                        setTimeout(() => {
                            setOrderNotValid(false);
                        }, 3000);
                        return;
                    } else {
                        let result = await selectedAddress(selectedAddressData.addressData, selectedAddressData.id);
                        if (result === 1) {
                            if (!selectedTime) {
                                setOrderNotValid(true);
                                setErrMsg('order-page.time_missing');
                                setTimeout(() => {
                                    setOrderNotValid(false);
                                }, 3000);
                                return;
                            }
                        } else {
                            return;
                        }

                    }

                } else if (!selectedTime) {
                    setOrderNotValid(true);
                    setErrMsg('order-page.time_missing');
                    setTimeout(() => {
                        setOrderNotValid(false);
                    }, 3000);
                    return;
                }
            } else {
                if (!selectedTime) {
                    setOrderNotValid(true);
                    setErrMsg('order-page.time_missing');
                    setTimeout(() => {
                        setOrderNotValid(false);
                    }, 3000);
                    return;
                }

            }



            // if (!accessToken && !hasAccessToken) {
            //     props.history.push({
            //         pathname: '/login',
            //         mode: 'ordering'
            //     });
            //     return;
            // }
            let timeValidity = false;
            let date = new Date();
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let time = selectedTime.label;
            if (parseInt(minutes) + parseInt(leadTime) >= 60) {
                hours = parseInt(hours) + 1;
                minutes = parseInt(leadTime) - (60 - parseInt(minutes));
            } else {
                minutes = parseInt(leadTime) + parseInt(minutes);
            }

            if (parseInt(hours) <= parseInt(time.split(":")[0])) {
                if (parseInt(hours) === parseInt(time.split(":")[0])) {
                    if (parseInt(time.split(":")[1]) > minutes) {
                        timeValidity = true;
                    }
                } else {
                    timeValidity = true;
                }
            }
            if (!timeValidity && selectedTimeType === 'choose') {
                setOrderOverAllValidation(true);
                setOrderOverAllMssg("Selected time expired, please select another time");
                setTimeout(() => {
                    setOrderOverAllValidation(false);
                }, 3500);
                return;
            } else if (!timeValidity && selectedTimeType === 'fastes') {
                let foundTime = false;
                let nextFoundTime = null;
                let values = availableTimes.times[0].values;
                for (let item in values) {
                    let date = new Date();
                    let time = values[item].time.split(" ")[1].slice(0, 5);
                    let hours = date.getHours();
                    let minutes = date.getMinutes();

                    if (parseInt(minutes) + parseInt(leadTime) >= 60) {
                        hours = parseInt(hours) + 1;
                        minutes = parseInt(leadTime) - (60 - parseInt(minutes));
                    } else {
                        minutes = parseInt(leadTime) + parseInt(minutes);
                    }

                    if (parseInt(hours) <= parseInt(time.split(":")[0])) {

                        if (parseInt(hours) === parseInt(time.split(":")[0])) {
                            if (parseInt(time.split(":")[1]) > minutes) {
                                nextFoundTime = {
                                    value: values[item].id,
                                    label: time,
                                    fullTime: values[item].time
                                };
                                foundTime = true;
                                break;
                            }
                        } else {
                            nextFoundTime = {
                                value: values[item].id,
                                label: time,
                                fullTime: values[item].time
                            };
                            foundTime = true;
                            break;
                        }
                    }
                }
                if (foundTime) {
                    const result = await handleSelectedTime(nextFoundTime, false, "fastes");
                    if (result !== 1) {
                        return
                    }
                } else {
                    setOrderOverAllValidation(true);
                    setOrderOverAllMssg("restaurant_mode.errorTimes");
                    setTimeout(() => {
                        setOrderOverAllValidation(false);
                    }, 3500);
                    return;
                }


            }

            const config = user_profile_config(accessToken, i18n.language);
            setLoadingData(true);
            axios.post(basket_url + basketData.id + "/validate",
                {}, { params: {}, headers: config.headers })
                .then(response => {
                    if (response.data.status) {
                        setOrderPaymentActiveTab(false);
                        dispatch(save_basket_data(response.data));
                        setLoadingData(false);
                        setOrderNumber(response.data.basket.order_number);

                    } else {
                        setLoadingData(false);
                        setOrderOverAllValidation(true);
                        setOrderOverAllMssg(response.data.message);
                        setTimeout(() => {
                            setOrderOverAllValidation(false);
                        }, 3500);
                    }
                }).catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }
                    setLoadingData(false);
                    setOrderOverAllValidation(true);
                    setOrderOverAllMssg('homepage.errorMsg');
                    setTimeout(() => {
                        setOrderOverAllValidation(false);
                    }, 3500);
                });
        } else {
            // order payment
            let timeValidity = false;
            let date = new Date();
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let time = selectedTime.label;
            if (parseInt(minutes) + parseInt(leadTime) >= 60) {
                hours = parseInt(hours) + 1;
                minutes = parseInt(leadTime) - (60 - parseInt(minutes));
            } else {
                minutes = parseInt(leadTime) + parseInt(minutes);
            }

            if (parseInt(hours) <= parseInt(time.split(":")[0])) {
                if (parseInt(hours) === parseInt(time.split(":")[0])) {
                    if (parseInt(time.split(":")[1]) > minutes) {
                        timeValidity = true;
                    }
                } else {
                    timeValidity = true;
                }
            }
            if (!timeValidity && selectedTimeType === 'choose') {
                setOrderOverAllValidation(true);
                setOrderOverAllMssg("order-page.expired");
                setTimeout(() => {
                    setOrderOverAllValidation(false);
                }, 3500);
                return;
            } else if (!timeValidity && selectedTimeType === 'fastes') {
                let foundTime = false;
                let nextFoundTime = null;
                let values = availableTimes.times[0].values;
                for (let item in values) {
                    let date = new Date();
                    let time = values[item].time.split(" ")[1].slice(0, 5);
                    let hours = date.getHours();
                    let minutes = date.getMinutes();

                    if (parseInt(minutes) + parseInt(leadTime) >= 60) {
                        hours = parseInt(hours) + 1;
                        minutes = parseInt(leadTime) - (60 - parseInt(minutes));
                    } else {
                        minutes = parseInt(leadTime) + parseInt(minutes);
                    }

                    if (parseInt(hours) <= parseInt(time.split(":")[0])) {

                        if (parseInt(hours) === parseInt(time.split(":")[0])) {
                            if (parseInt(time.split(":")[1]) > minutes) {
                                nextFoundTime = {
                                    value: values[item].id,
                                    label: time,
                                    fullTime: values[item].time
                                };
                                foundTime = true;
                                break;
                            }
                        } else {
                            nextFoundTime = {
                                value: values[item].id,
                                label: time,
                                fullTime: values[item].time
                            };
                            foundTime = true;
                            break;
                        }
                    }
                }
                if (foundTime) {
                    const result = await handleSelectedTime(nextFoundTime, false, "fastes");
                    if (result !== 1) {
                        return
                    }
                } else {
                    setOrderOverAllValidation(true);
                    setOrderOverAllMssg("restaurant_mode.errorTimes");
                    setTimeout(() => {
                        setOrderOverAllValidation(false);
                    }, 3500);
                    return;
                }


            }
            if (paymentMethod === 'cash') {
                const config = payment_config(accessToken, i18n.language, paymentData.phone.value, "CashPayment");
                setLoadingData(true);
                axios.post(basket_url + basketData.id + "/payment", { ...config.body }, { params: {}, headers: config.headers })
                    .then(response => {
                        if (response.data.status) {
                            if (saveForFutureUse === true) {
                                const config2 = add_save_address_confing(
                                    selectedAddressData.addressData.name ? selectedAddressData.addressData.name : "Saved address",
                                    selectedAddressData.addressData.address_street,
                                    selectedAddressData.addressData.address_no,
                                    selectedAddressData.addressData.address_apt_no,
                                    parseFloat(selectedAddressData.addressData.latitude),
                                    parseFloat(selectedAddressData.addressData.longitude),
                                    accessToken,
                                    false,
                                    i18n.language,
                                    selectedAddressData.addressData.city,
                                    selectedAddressData.addressData.additional_info
                                );
                                axios.post(add_save_address_url, { ...config2.body }, { params: {}, headers: config2.headers })
                                    .then(response => {
                                        dispatch(save_basket_data({}, true));
                                        props.history.push({
                                            pathname: '/',
                                            mode: "order",
                                            orderNumber: orderNumber
                                        });
                                    }).catch(err => {
                                        if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                                            props.history.push({
                                                pathname: '/settings/logout',
                                                err: "unauthenticated",
                                            });
                                            return;
                                        }
                                        dispatch(save_basket_data({}, true));
                                        props.history.push({
                                            pathname: '/',
                                            mode: "order",
                                            orderNumber: orderNumber
                                        });
                                    });
                            } else {
                                dispatch(save_basket_data({}, true));
                                props.history.push({
                                    pathname: '/',
                                    mode: "order",
                                    orderNumber: orderNumber
                                });
                            }

                        } else {
                            setLoadingData(false);
                            setOrderNotValid(true);
                            setTimeout(() => {
                                setOrderNotValid(false);
                            }, 3000);
                            setErrMsg(response.data.message)
                        }

                    }).catch(err => {
                        if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                            props.history.push({
                                pathname: '/settings/logout',
                                err: "unauthenticated",
                            });
                            return;
                        }
                        setLoadingData(false);
                        setOrderNotValid(true);
                        setTimeout(() => {
                            setOrderNotValid(false);
                        }, 3000);
                        setErrMsg('homepage.errorMsg');
                    })

            } else {
                //order card

                setLoadingData(true);
                const config = user_profile_config(accessToken, i18n.language);
                axios.get(basket_url + basketData.id + "/initialize?type=web", { params: {}, headers: config.headers })
                    .then(response => {
                        if (response.data.status === true) {
                            const payment_data = response.data.payment_data;
                            setPaymentDataInputs(payment_data);
                            if (saveForFutureUse === true) {
                                const config2 = add_save_address_confing(
                                    selectedAddressData.addressData.name ? selectedAddressData.addressData.name : "Saved address",
                                    selectedAddressData.addressData.address_street,
                                    selectedAddressData.addressData.address_no,
                                    selectedAddressData.addressData.address_apt_no,
                                    parseFloat(selectedAddressData.addressData.latitude),
                                    parseFloat(selectedAddressData.addressData.longitude),
                                    accessToken,
                                    false,
                                    i18n.language,
                                    selectedAddressData.addressData.city,
                                    selectedAddressData.addressData.additional_info
                                );
                                axios.post(add_save_address_url, { ...config2.body }, { params: {}, headers: config2.headers })
                                    .then(response => {
                                        if (response.data.status === true) {
                                            dispatch(save_basket_data({}, true));
                                        }
                                        // props.history.push({
                                        //     pathname: '/',
                                        //     mode: "order",
                                        //     orderNumber: orderNumber
                                        // });
                                    }).catch(err => {
                                        if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                                            props.history.push({
                                                pathname: '/settings/logout',
                                                err: "unauthenticated",
                                            });
                                            return;
                                        }
                                        // dispatch(save_basket_data({}, true));
                                        // props.history.push({
                                        //     pathname: '/',
                                        //     mode: "order",
                                        //     orderNumber: orderNumber
                                        // });
                                    });


                            }


                            // let formData = new URLSearchParams();
                            // formData.append('amount', payment_data.amount);
                            // formData.append('clientid', payment_data.clientid);
                            // formData.append('storetype', payment_data.storetype);
                            // formData.append('hash', payment_data.hash);
                            // formData.append('trantype', payment_data.trantype);
                            // formData.append('currency', payment_data.currency);
                            // // formData.append('instalment',payment_data.instalment);
                            // formData.append('oid', payment_data.oid);
                            // formData.append('okUrl', payment_data.okUrl);
                            // formData.append('failUrl', payment_data.failUrl);
                            // formData.append('callbackUrl', payment_data.callbackUrl);
                            // formData.append('lang', payment_data.lang);
                            // formData.append('rnd', payment_data.rnd);
                            // formData.append('encoding', payment_data.encoding);
                            // formData.append('userId', payment_data.userId);
                            // formData.append('refreshtime', payment_data.refreshtime);
                            // formData.append('fismi', paymentData.cardOwnerName.value);
                            // formData.append('pan', paymentData.cardNumber.value);
                            // formData.append('Ecom_Payment_Card_ExpDate_Month', paymentData.cardDate.value.split("/")[0]);
                            // formData.append('Ecom_Payment_Card_ExpDate_Year', paymentData.cardDate.value.split("/")[1]);
                            // formData.append('cv2', paymentData.cardCVV.value);

                            // const config_payment = payment_config_card(
                            //     payment_data.amount,
                            //     payment_data.clientid,
                            //     payment_data.storetype,
                            //     payment_data.hash,
                            //     payment_data.trantype,
                            //     payment_data.currency,
                            //     payment_data.instalment,
                            //     payment_data.oid,
                            //     payment_data.okUrl,
                            //     payment_data.failUrl,
                            //     payment_data.callbackUrl,
                            //     payment_data.lang,
                            //     payment_data.rnd,
                            //     payment_data.encoding,
                            //     payment_data.userId,
                            //     payment_data.refreshtime,
                            //     paymentData.cardOwnerName.value,
                            //     paymentData.cardNumber.value,
                            //     paymentData.cardDate.value.split("/")[0],
                            //     paymentData.cardDate.value.split("/")[1],
                            //     paymentData.cardCVV.value
                            // );
                            // fetch.post(process.env.REACT_APP_BANK_API_CALL, formData, {
                            //     headers: {
                            //         "Content-Type": 'application/x-www-form-urlencoded',

                            //     }
                            // })
                            // fetch(process.env.REACT_APP_BANK_API_CALL, {
                            //     method: "POST",
                            //     headers: {
                            //         "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                            //         "Sec-Fetch-Mode": "navigate"
                            //     },
                            //     body: formData

                            // }).then(response => {
                            //     if (response.data.status) {
                            //         if (saveForFutureUse === true) {
                            //             const config2 = add_save_address_confing(
                            //                 selectedAddressData.addressData.name ? selectedAddressData.addressData.name : "Saved address",
                            //                 selectedAddressData.addressData.address_street,
                            //                 selectedAddressData.addressData.address_no,
                            //                 selectedAddressData.addressData.address_apt_no,
                            //                 parseFloat(selectedAddressData.addressData.latitude),
                            //                 parseFloat(selectedAddressData.addressData.longitude),
                            //                 accessToken,
                            //                 false,
                            //                 i18n.language,
                            //                 selectedAddressData.addressData.city,
                            //                 selectedAddressData.addressData.additional_info
                            //             );
                            //             axios.post(add_save_address_url, { ...config2.body }, { params: {}, headers: config2.headers })
                            //                 .then(response => {
                            //                     dispatch(save_basket_data({}, true));
                            //                     props.history.push({
                            //                         pathname: '/',
                            //                         mode: "order",
                            //                         orderNumber: orderNumber
                            //                     });
                            //                 }).catch(err => {
                            //                     dispatch(save_basket_data({}, true));
                            //                     props.history.push({
                            //                         pathname: '/',
                            //                         mode: "order",
                            //                         orderNumber: orderNumber
                            //                     });
                            //                 });
                            //         } else {
                            //             dispatch(save_basket_data({}, true));
                            //             props.history.push({
                            //                 pathname: '/',
                            //                 mode: "order",
                            //                 orderNumber: orderNumber
                            //             });
                            //         }

                            //     } else {
                            //         setLoadingData(false);
                            //         setOrderNotValid(true);
                            //         setTimeout(() => {
                            //             setOrderNotValid(false);
                            //         }, 3000);
                            //         setErrMsg(response.data.message)
                            //     }

                            // }).catch(err => {
                            //     setOrderNotValid(true);
                            //     setTimeout(() => {
                            //         setOrderNotValid(false);
                            //     }, 3000);
                            //     setErrMsg('homepage.errorMsg');
                            // });
                        } else {
                            setOrderNotValid(true);
                            setTimeout(() => {
                                setOrderNotValid(false);
                            }, 3000);
                            setErrMsg(response.data.message);
                        }
                        setLoadingData(false);
                    }).catch(err => {
                        if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                            props.history.push({
                                pathname: '/settings/logout',
                                err: "unauthenticated",
                            });
                            return;
                        }
                        setLoadingData(false);
                        setOrderNotValid(true);
                        setTimeout(() => {
                            setOrderNotValid(false);
                        }, 3000);
                        setErrMsg('homepage.errorMsg');
                    })
            }

        }


    }
    const removeItemHandler = (itemId) => {

        let config = get_basket_config(accessToken, i18n.language);
        setLoadingData(true);
        axios.delete(basket_url + basketData.id + "/products/" + itemId, { headers: config.headers })
            .then(response => {
                if (response.data.status) {
                    dispatch(save_basket_data(response.data));
                }
                setLoadingData(false);
            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setLoadingData(false);
            })
    }

    const removeItemHandlerUpsale = (itemId) => {
        let config = getUpsaleConfig(accessToken, i18n.language);
        setLoadingData(true);
        axios.delete(basket_upsales_url + basketData.id + "/upsale",
            {
                params: {},
                headers: { ...config.headers },
                data: { upsale: [{ id: itemId, quantity: basketData.upsale?.filter(item => item.id = itemId)[0].quantity }] }
            })
            .then(response => {
                if (response.data.status) {
                    dispatch(save_basket_data(response.data));
                }
                setLoadingData(false);
            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setLoadingData(false);
            })
    }

    const editItemHandler = (itemId) => {
        let basketItemDetails;
        for (let item in basketData.products) {
            if (basketData.products[item].id === itemId) {
                basketItemDetails = basketData.products[item];
            }
        }
        let config = get_basket_config(accessToken, i18n.language);
        setLoadingData(true);
        axios.get(basket_url + basketData.id + "/products/" + itemId + "/menu_item", { headers: config.headers })
            .then(response => {
                if (response.data.status) {
                    setModalEditing({
                        opened: true,
                        data: response.data.menu_item,
                        basketItemDetails: { ...basketItemDetails },
                        editingItemId: itemId,
                        closed: false
                    });
                    props.history.push({
                        pathname: '/order/main/' + itemId,
                        scrollOff: true
                    });
                }
                setLoadingData(false);


            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setLoadingData(false);
            })
    }
    const editItemHandlerUpsale = (itemId) => {
        setModalEditingUpsale({
            opened: true,
            data: basketData.upsale,
            itemId
        });
        // props.history.push({
        //     pathname: '/order/main/' + itemId,
        //     scrollOff: true
        // });
    }

    // useEffect(() => {
    //     if (modalEditing.opened === true) {
    //         props.history.replace({
    //             pathname: '/order/main/' + modalEditing.editingItemId,
    //             scrollOff: true
    //         });
    //     } else if (modalEditing.closed === true) {
    //         props.history.replace({
    //             pathname: '/order/main',
    //             scrollOff: true
    //         });
    //     }
    // }, [modalEditing.opened]);

    const setSelectedAddressInfos = (addressData, id, clear = false) => {

        if (clear) {
            setSelectedAddressData();
            setAddressValidated(false);
            setLeadTime();
            return;
        }
        setSelectedAddressData({
            ...selectedAddressData,
            addressData: { ...addressData },
            id: id
        });
        setAddressValidated(true);
    }

    const handleTypeChange = (type) => {
        setPaymentMethod(type);
    }
    const handlePaymentDataChange = (value, type, valid) => {

        setPaymentData({
            ...paymentData,
            [type]: {
                ...paymentData[type],
                value: value,
                valid: valid,
            }
        });
    }
    const handleSubmitingForm = (event) => {
        event.preventDefault(event);
        let xhr = new XMLHttpRequest();

        xhr.open('POST', event.target.action);
        xhr.setRequestHeader('Sec-Fetch-Dest', 'document')
        xhr.send(new URLSearchParams(new FormData(event.target)));
        xhr.onload = function () {
            alert('ee');
        };
        xhr.onerror = function () {
            alert("Request failed");
        };
        // fetch(event.target.action, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //         'Sec-Fetch-Dest': 'document',
        //         'Accept':"text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9"

        //       },
        //     body: new URLSearchParams(new FormData(event.target)) // event.target is the form
        // }).then((resp) => {
        //     return resp.json(); // or resp.text() or whatever the server sends
        // }).then((body) => {
        //     // TODO handle body
        // }).catch((error) => {
        //     // TODO handle error
        // });
    }


    const handleCloseMenuModal = () => {
        setModalEditing({ opened: false, data: null, closed: true });
        props.history.goBack();
    }

    let validPayment = false;
    if (paymentMethod === 'cash' && paymentData.phone.valid) validPayment = true;
    if (paymentMethod === 'card'
        && paymentData.phone.valid
        && paymentData.cardNumber.valid
        && paymentData.cardOwnerName.valid
        && paymentData.cardNumber.valid
        && paymentData.cardDate) validPayment = true;

    useEffect(() => {
        setMounted(true);
    }, [])

    let content = <div className='order-page'>
        <div className='order-page-wrapper'>
            <Spinner logo spinnerStyle="SpinnerYellow" />;
        </div>
    </div>

    if (!accessToken && mounted) {
        props.history.push({
            pathname: '/login',
            mode: 'ordering'
        })
    } else if (basketData && (basketData.products.length !== 0 || basketData.upsale?.length !== 0)) {
        content = (
            <div className='order-page'>
                <div className='order-page-wrapper'>
                    <Row className='m-0'>
                        <Col className='p-0 order-main' xs='12' sm='12' lg='7'>
                            <Row className='m-0 tabs-border'>
                                <Col className='p-0 tabs-col' xs='6' onClick={() => setOrderPaymentActiveTab(true)}>
                                    <div className={orderPaymentActiveTab ? 'p-0 tab-style active-tab' : 'p-0 tab-style'}>
                                        {t('order-page.order')}
                                    </div>
                                </Col>
                                <Col className='p-0 tabs-col' xs='6' onClick={() => handleOrderingDetails()}>
                                    <div className={!orderPaymentActiveTab ? 'p-0 tab-style active-tab' : 'p-0 tab-style'}>
                                        {t('order-page.payment')}
                                    </div>
                                </Col>
                            </Row>
                            <OrderPageDetails
                                show={orderPaymentActiveTab}
                                history={props.history}
                                type={basketData.order_mode}
                                data={basketData}
                                saveForFutureUse={saveForFutureUse}
                                setSaveForFutureUse={(value) => setSaveForFutureuse(value)}
                                selectedAddress={selectedAddress}
                                handleSelectedTime={handleSelectedTime}
                                setSelectedAddressInfos={setSelectedAddressInfos}
                                leadTime={leadTime ? leadTime : -1}
                                setAvailableTimes={(times) => setAvailableTimes(times)}
                            />

                            <OrderPayment
                                show={!orderPaymentActiveTab}
                                handlePaymentDataChange={handlePaymentDataChange}
                                handleTypeChange={handleTypeChange}
                                availablePaymentMethods={availablePaymentMethods}
                                history={props.history}
                            />
                            {loadingData ?
                                <div className='loading-data-modal' >
                                    <Spinner logo spinnerStyle="SpinnerYellow" />;
                         </div> : null
                            }
                            <Row className='m-0'>
                                <Col className='p-0 hide-lg mt-4' xs='12' >
                                    <Row className='m-0 order-page-buttons'>
                                        <Col xs='12' sm='7' className='p-0 text-left'>
                                            <div>
                                                <img alt='left-arrow' src={leftArrow} />
                                                {
                                                    orderPaymentActiveTab ?
                                                        <span onClick={() => {
                                                            props.history.push({
                                                                pathname: '/restaurant/details/' + basketData.restaurant_id,
                                                                id: basketData.restaurant_id,
                                                                mode: basketData.order_mode,
                                                            });
                                                        }}>{t('order-page.add-more')}  </span>
                                                        :
                                                        <span onClick={() => {
                                                            setOrderPaymentActiveTab(true)
                                                        }}>{t('order-page.back-order-info')} </span>
                                                }
                                            </div>
                                        </Col>
                                        <Col xs='12' sm='5' className='p-0  text-sm-right'>
                                            <button disabled={orderPaymentActiveTab === false && validPayment === false} onClick={() => handleOrderingDetails()}>
                                                {orderPaymentActiveTab ?
                                                    t('order-page.to-payment') :
                                                    t('order-page.finish-payment')
                                                }
                                            </button>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col className='p-0 order-products' xs='12' sm='12' lg='5'>
                            <Row className='m-0'>
                                <Col xs='12' className='p-0 your-order'>
                                    {t('open-basket.my-orders')}
                                </Col>
                                {basketData ? basketData.products.map(item => {
                                    return <Auxilary key={item.id}>
                                        <Col xs='4' sm='2' lg='3' xl='2' className='p-0'>
                                            <img alt='default-img' src={defaultImage} style={{ maxWidth: "90px", height: "55px" , width:"inherit", paddingRight:"8px"}} />
                                        </Col>
                                        <Col xs='8' sm='10' lg='9' xl='10' className='p-0 pl-1 order-item-container'  >
                                            {orderPaymentActiveTab ?
                                                <OrderPageProductItem
                                                    data={item}
                                                    openBasket={true}
                                                    removeItemHandler={() => removeItemHandler(item.id)}
                                                    editItemHandler={() => editItemHandler(item.id)}
                                                    history={props.history}
                                                    match={props.match}
                                                /> :
                                                <OrderPageProductItem
                                                    data={item}
                                                />
                                            }

                                        </Col>
                                    </Auxilary>
                                })
                                    : null}

                                {basketData ? basketData.upsale?.map(item => {
                                    return <Auxilary key={item.id+item.name+(new Date().getTime().toString() + Math.floor(Math.random()*1000000))}>
                                        <Col xs='4' sm='2' lg='3' xl='2' className='p-0'>
                                            <img alt='default-img' src={defaultImage}  style={{ maxWidth: "90px", height: "55px" , width:"inherit", paddingRight:"8px"}} />
                                        </Col>
                                        <Col xs='8' sm='10' lg='9' xl='10' className='p-0 pl-1 order-item-container'  >
                                            {orderPaymentActiveTab ?
                                                <OrderPageProductItem
                                                    data={item}
                                                    openBasket={true}
                                                    removeItemHandler={() => removeItemHandlerUpsale(item.id)}
                                                    editItemHandler={() => editItemHandlerUpsale(item.id)}
                                                    history={props.history}
                                                    match={props.match}
                                                /> :
                                                <OrderPageProductItem
                                                    data={item}
                                                />
                                            }

                                        </Col>
                                    </Auxilary>
                                })
                                    : null}
                                {basketData.discount !== 0 && orderPaymentActiveTab === false ?
                                    <Col xs='12' className='p-0 subtotal-info'>
                                        <Row className='m-0 '>
                                            <Col xs='8' className='p-0 text-left'>
                                                {t('order-page.discount')}
                                            </Col>
                                            <Col xs='4' className='p-0 text-right'>
                                                - {basketData.discount.toFixed(0)} {t('post.currency')}
                                            </Col>
                                        </Row>
                                    </Col> : null}
                                {basketData.order_mode === 'delivery' ?
                                    <Col xs='12' className='p-0 delivery-info'>
                                        <Row className='m-0' >
                                            <Col xs='8' className='p-0 text-left'>
                                                {t('order-page.delivery')}
                                            </Col>
                                            <Col xs='4' className='p-0 text-right'>
                                                {basketData.delivery_fee} {t('post.currency')}
                                            </Col>
                                        </Row>
                                    </Col> : null}
                                <Col xs='12' className='p-0 subtotal-info'>
                                    <Row className='m-0 '>
                                        <Col xs='8' className='p-0 text-left'>
                                            {t('order-page.order-subtotal')}
                                        </Col>
                                        <Col xs='4' className='p-0 text-right'>
                                            {(basketData.subtotal - basketData.delivery_fee).toFixed(0)} {t('post.currency')}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs='12' className='p-0 total-info'>
                                    <Row className='m-0'>
                                        <Col xs='8' className='p-0 text-left'>
                                            {t('order-page.total')}
                                        </Col>
                                        <Col xs='4' className='p-0 text-right'>
                                            {paymentMethod && paymentMethod === 'card'
                                                ? <span>{(basketData.subtotal + 10).toFixed(0)} {t('post.currency')}</span>
                                                : <span> {(basketData.subtotal).toFixed(0)} {t('post.currency')} </span>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col className='p-0 show-lg ' lg='7' >
                            <Row className='m-0 order-page-buttons'>
                                <Col xs='12' sm='7' className='p-0 text-left'>
                                    <div>
                                        <img alt='left-arrrow' src={leftArrow} />
                                        {
                                            orderPaymentActiveTab ?
                                                <span onClick={() => {
                                                    props.history.push({
                                                        pathname: '/restaurant/details/' + basketData.restaurant_id,
                                                        id: basketData.restaurant_id,
                                                        mode: basketData.order_mode,
                                                    });
                                                }}>{t('order-page.add-more')}  </span>
                                                :
                                                <span onClick={() => {
                                                    setOrderPaymentActiveTab(true)
                                                }}>{t('order-page.back-order-info')} </span>
                                        }
                                    </div>
                                </Col>
                                <Col xs='12' sm='5' className='p-0  text-sm-right'>
                                    <button disabled={orderPaymentActiveTab === false && validPayment === false} onClick={() => handleOrderingDetails()}>
                                        {orderPaymentActiveTab ?
                                            t('order-page.to-payment') :
                                            t('order-page.finish-payment')
                                        }
                                    </button>
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                </div>
                <div className={orderNotValid ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>
                    {t(errorMsg)}
                </div>
                <div className={orderOverAllValidation ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>
                    {t(orderOverAllMssg)}
                </div>
                <div className={showTransErr ? 'password-udpate-modal open-modal-display' : 'password-udpate-modal closed-modal-display'}>
                    {t('order-page.trans-err')}
                </div>
                {paymentDataInputs ?
                    <Auxilary>
                        <form style={{ display: "none" }} action={process.env.REACT_APP_BANK_API_CALL} method="post" >
                            <input type="hidden" name="amount" value={paymentDataInputs.amount} />
                            <input type="hidden" name="clientid" value={paymentDataInputs.clientid} />
                            <input type="hidden" name="storetype" value={paymentDataInputs.storetype} />
                            <input type="hidden" name="hash" value={paymentDataInputs.hash} />
                            <input type="hidden" name="trantype" value={paymentDataInputs.trantype} />
                            {/* <input type="hidden" name="currency" value={paymentDataInputs.currency} /> */}
                            <input type="hidden" name="oid" value={paymentDataInputs.oid} />
                            <input type="hidden" name="okUrl" value={paymentDataInputs.okUrl} />
                            <input type="hidden" name="failUrl" value={paymentDataInputs.failUrl} />
                            {/* <input type="hidden" name="okUrl" value="https://tapp.naracaj.com/api/v1/payment/ok?type=web" />
                            <input type="hidden" name="failUrl" value="https://tapp.naracaj.com/api/v1/payment/fail?type=web" /> */}
                            {/* <input type="hidden" name="callbackUrl" value={paymentDataInputs.callbackUrl} /> */}
                            <input type="hidden" name="lang" value={paymentDataInputs.lang} />
                            <input type="hidden" name="rnd" value={paymentDataInputs.rnd} />
                            {/* <input type="hidden" name="encoding" value={paymentDataInputs.encoding} /> */}
                            {/* <input type="hidden" name="userId" value={paymentDataInputs.userId} /> */}
                            <input type="hidden" name="refreshtime" value={paymentDataInputs.refreshtime} />
                            {/* <input type="hidden" name="storekey" value={paymentDataInputs.storekey} /> */}
                            <input type="hidden" name="fismi" value={paymentData.cardOwnerName.value} />
                            <input type="hidden" name="pan" value={paymentData.cardNumber.value} />
                            <input type="hidden" name="Ecom_Payment_Card_ExpDate_Month" value={paymentData.cardDate.value.split("/")[0]} />
                            <input type="hidden" name="Ecom_Payment_Card_ExpDate_Year" value={"20" + (paymentData.cardDate.value.split("/")[1])} />
                            <input type="hidden" name="cv2" value={paymentData.cardCVV.value} />
                            <button ref={refToPayment} type="submit"></button>
                        </form>

                    </Auxilary>
                    : null}
                {/* <iframe ref={refToIframe} name="output_frame" src="" id="output_frame" >
                </iframe> */}
                {modalEditing.opened ?

                    <Route path={'/order/main' + '/:id'}
                        component={(props) =>
                            <Auxilary>
                                <Backdrop address className='backdrop' show={modalEditing.opened} backdropClicked={() => handleCloseMenuModal()} />
                                <MenuModalOrder
                                    mode={basketData.order_mode}
                                    data={modalEditing.data}
                                    basketItemDetails={modalEditing.basketItemDetails}
                                    // handleCloseMenuModal={() => setModalEditing({ opened: false, data: null })}
                                    editingItemId={modalEditing.editingItemId}
                                    editing={true}
                                    handleCloseMenuModal={handleCloseMenuModal} />
                            </Auxilary>
                        } />
                    /* <MenuModalOrder
                        mode={basketData.order_mode}
                        data={modalEditing.data}
                        basketItemDetails={modalEditing.basketItemDetails}
                        handleCloseMenuModal={() => setModalEditing({ opened: false, data: null })}
                        editingItemId={modalEditing.editingItemId}
                        editing={true}
                    /> */
                    : null
                }
                {modalEditingUpsale.opened ?

                    // <Route path={'/order/main' + '/:id'}
                    //     component={(props) =>
                            <Auxilary>
                                <Backdrop address className='backdrop' show={modalEditingUpsale.opened} backdropClicked={() => setModalEditingUpsale({ data: null, opened: false, itemId: null })} />
                                <ModalUpsale
                                    data={basketData.upsale?.filter(item => item.id === modalEditingUpsale.itemId)}
                                    editing={true}
                                    basketId={basketData.id}
                                    accessToken={accessToken}
                                    lang={i18n.language}
                                    handleUpsaleContinue={() => setModalEditingUpsale({ data: null, opened: false, itemId: null })}
                                    handleUpsaleClose={() => setModalEditingUpsale({ data: null, opened: false, itemId: null })}
                                />
                            </Auxilary>
                        // } />
                    /* <MenuModalOrder
                        mode={basketData.order_mode}
                        data={modalEditing.data}
                        basketItemDetails={modalEditing.basketItemDetails}
                        handleCloseMenuModal={() => setModalEditing({ opened: false, data: null })}
                        editingItemId={modalEditing.editingItemId}
                        editing={true}
                    /> */
                    : null
                }
            </div>
        )
    } else {
        content = (mounted && basketData && basketData.products.length === 0 && basketData.upsale?.length === 0) ? <Redirect to='/'></Redirect> : content
    }
    return (
        content
    )
}

export default OrderPage;

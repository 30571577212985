import React from 'react';


const AddressRadioCheck = props => {
    

    return (
        <div>
            <label className="container-radio-order-page" >
                <input type="radio"  checked={props.checked} onChange={(event) => { props.handleItemSelection(event,props.id) }} name={props.groupId} />
                {props.description}
                <span className="checkmark-radio"></span>
            </label>
        </div>
    )

}

export default AddressRadioCheck;
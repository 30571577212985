import * as actionTypes from "./actionTypes";

export const set_filter = (filterType) => {
  return {
    type: actionTypes.SET_FILTER,
    filterType: filterType,
  };
};


export const remove_filter = (filterType) => {
  return {
    type: actionTypes.REMOVE_FILTER,
    filterType: filterType,
  };
};

export const set_food_type = (foodType,foodTypeObject) => {
  return {
    type: actionTypes.SET_FOOD_TYPE,
    foodType: foodType,
    foodTypeObject:foodTypeObject
  };
};

export const remove_food_type = (foodType) => {
  return {
    type: actionTypes.REMOVE_FOOD_TYPE,
    foodType: foodType,
  };
};


export const set_filter_types = (filterGroup, filterType) => {
  return {
    type: actionTypes.SET_FILTER_TYPES,
    filterGroup,
    filterType
  };
};

export const clear_filter_modal = () => {
  return {
    type: actionTypes.CLEAR_FILTER_MODAL,
  };
};

export const filter_applied = () => {
  return {
    type: actionTypes.FILTER_APPLIED,
  };
};

export const set_api_tags = (tags) => {
  return {
    type: actionTypes.SET_API_TAGS,
    tags
  };
};

export const set_tag_checked = (tag) => {
  return {
    type: actionTypes.SET_TAG_CHECKED,
    tag
  };
};
export const set_tag_uncheck = (tag) => {
  return {
    type: actionTypes.SET_TAG_UNCHECK,
    tag
  };
};

export const set_expanded = (value, closeAndClear = false, typePick = false) => {
  return {
    type: actionTypes.SET_EXPANDED,
    value,
    closeAndClear,
    typePick
  }
}
export const clear_filter = () => {
  return {
    type: actionTypes.CLEAR_FILTER,
  
  }
}




import React from "react";
// import logoImg from '../assets/images/logos/naracaj logo grey new.png';
import logoImg from '../assets/images/logos/Logo_naracaj_2-1.svg'
const HeaderLogo = (props) => {
  return (
    <div className="header-logo-anchor">
      <img onClick={()=>props.history.push('/')} className="header-logo" alt="naracaj logo" src={logoImg}></img>
    </div>
  );
};

export default HeaderLogo;

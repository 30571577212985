import * as actionTypes from "../actions/actionTypes";

const initialState = {
  long: 0.0,
  lat: 0.0,
  coordSet: false,
  userData: {},
  accessToken: false,
  banner: false,
  bannerText: "",
  sliderData: null,
  bannerPromo:null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COORDINATES:
      return {
        ...state,
        long: action.data.long,
        lat: action.data.lat,
        coordSet:true
      }
    case actionTypes.SET_USER_DATA:
      return {
        ...state,
        userData: {
          ...action.data,
          user: { ...action.data.user }
        },
        accessToken:action.token
      }
    case actionTypes.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken:action.token
      }
      case actionTypes.UPDATE_NAME_SURNAME:
        return {
          ...state,
          userData: {
            ...state.userData,
            user: {
              ...state.userData.user,
              first_name: action.name,
              last_name:action.surname
            }

          }
        }
    case actionTypes.LOGOUT:
      return {
        ...state,
        accessToken: null,
        userData:{}
      }
    case actionTypes.SET_BANNER:
      return {
        ...state,
        banner: action.hasBanner,
        bannerText:action.value
      }
    case actionTypes.SET_SLIDER_DATA:
      return {
        ...state,
        sliderData: action.data
      }
      case actionTypes.SET_PROMO_BANNER:
        return {
          ...state,
          bannerPromo: action.value
        }
    default:
      return state;
  }
};

export default reducer;

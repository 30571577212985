import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { history } from "react-router";
export default function ScrollToTop() {
  const { pathname } = useLocation();
  const  history  = useHistory();

  useEffect(() => {

    if (history.location.scrollOff) return;
    if(history.action==="POP" && history.location.pathname.includes('/restaurant/details/')) return
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
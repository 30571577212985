import * as actionTypes from "./actionTypes";

export const set_coord = (data) => {
  localStorage.setItem('long', data.long);
  localStorage.setItem('lat', data.lat);
  return {
    type: actionTypes.SET_COORDINATES,
    data: data,
  };
};

export const setAccessToken = (token) => {
  return {
    type: actionTypes.SET_ACCESS_TOKEN,
    token: token,
  };
};

export const setUserData = (data, token) => {
  localStorage.setItem("accessToken", token);
  return {
    type: actionTypes.SET_USER_DATA,
    data: data,
    token: token
  };
};

export const setUserNameSurname = (name, surname) => {
  return {
    type: actionTypes.UPDATE_NAME_SURNAME,
    name: name,
    surname: surname
  };
}

export const logout_call = () => {
  localStorage.removeItem("accessToken");
  return {
    type: actionTypes.LOGOUT
  }
}

import React from "react";
import "../assets/styles/UI/_faq.scss";

export const Faq = () => {
  return (
    <div className="container">
      <div className="faq">
        <br />
        <br />
        <h2>Што претставува Naracaj.com?</h2>
        <br />
        <p>
          Naracaj.com е платформа за онлајн нарачување која го олеснува
          значително процесот на нарачување храна од вашиот омилен ресторан.
          <br />
          <br />
          Платформата ве поврзува со различни типови на ресторани и храна, од
          каде што вие може да нарачате кога ќе посакате од менито на вашиот
          омилен ресторан.
        </p>
        <br />
        <h2>Како работи Naracaj.com?</h2>
        <br />
        <p>
          Платформата Naracaj.com ве поврзува со различен број на локални
          ресторани, каде вие може да го изберете вашиот омилен ресторан, вашиот
          омилен тип на нарачување и вашата омилена храна. Може да уживате во
          вашата омилена храна доставена до вашиот дом, можете сами да си ја
          подигнете храната од ресторанот или пак може да јадете и уживате во
          самиот ресторан. Ова зависи од самиот ресторан, какви опции на тип на
          нарачка ќе понуди ресторанот, и се разбира, од вашите желби.
        </p>
        <br />
        <h2>Каде и кога е Naracaj.com достапен?</h2>
        <br />
        <p>
          Платформата Naracaj.com е достапна на целата територија на Република
          Северна Македонија.
          <br />
          <br />
          Дали ресторанот ќе биде прикажан во платформата, зависи од самиот
          ресторан. Рестораните сами одредуваат кога ќе бидат достапни и
          прикажани на платформата и кое ќе биде нивното работно време прикажано
          на Naracaj.com. За да видите кои ресторани се достапни во ваша близина
          и дали се отворени во саканиот момент, упатете се до почетната страна
          во апликацијата.
        </p>
        <br />
        <h2>Како да нарачате преку Naracaj.com?</h2>
        <br />
        <p>
          Во овој момент нарачувањето преку Naracaj.com може да биде направено
          само од мобилната апликација.
          <br />
          Симнете ја и инсталирајте ја апликацијата Naracaj.com за да почнете со
          нарачувањето
        </p>
        <br />
        <ol>
          <li>
            <p>Најавете се (или регистрирајте се)</p>
          </li>
          <li>
            <p>Одберете го ресторанот од кој сакате да нарачате</p>
          </li>
          <li>
            <p>Одберете го начинот на испорака од понудените</p>
          </li>
          <li>
            <p>Ако одберeте достава, внесете ја вашата адреса за достава</p>
          </li>
          <li>
            <p>Одберете ги вашите продукти и кликнете “Во кошничка“</p>
          </li>
          <li>
            <p>Проверете ја вашата нарачка и одберете Време на нарачка</p>
          </li>
          <li>
            <p>Кликнете “Кон наплата“</p>
          </li>
          <li>
            <p>Одберете го методот на плаќање и кликнете “Плати“</p>
          </li>
          <li>
            <p>
              За плаќање со картичка внесете ги податоците од картичката и
              кликнете “Потврди купување“
            </p>
          </li>
        </ol>
        <br />
        <h2>Како е пресметано времето за достава?</h2>
        <br />
        <p>
          Времето кое вие како корисници го гледате во селекторот на време,
          претставува комбинација од просечното време на подготовка на храната,
          кое ресторанот го одредува, и од времето потребно храната да биде
          доставена на вашата сакана адреса.
          <br />
          <br />
          Ве молиме имајте предвид дека ова е наша естимација. Сепак, актуелното
          време кога храната ќе биде доставена, може да варира во различни
          моменти во зависност од тоа колку е зафатен ресторанот, колку е голема
          и комплексна вашата нарачка, условите во сообраќајот, временските
          услови како и други услови.
        </p>
        <br />
        <h2>
          Дали се плаќа за достава како услуга и дали постои минимална сума на
          нарачка за да биде доставена?
        </h2>
        <br />
        <p>
          Да, во зависност од тоа кој ја доставува нарачката, може да има
          различна цена за услугата достава и различна минимална сума на
          нарачката за секој ресторан независно. Рестораните имаат право на
          избор да користат услуга доставување од Naracaj.com или пак да
          користат нивни доставувачи, па поради тоа цените може да бидат
          различни во зависност од изборот на ресторанот. Постои можност, за
          еден ист ресторан, цена на услуга за достава, како и минималната сума
          на нарачка за достава, да биде различна во зависност од одалеченоста
          на адресата за достава од ресторанот. Во такви случаи, на основната
          цена за услуга за достава, може да се додаде дополнителна цена од 10
          ден/км.
        </p>
        <br />
        <h2>
          Како да нарачам иста нарачка која и претходно сум ја нарачал преку
          платформата?
        </h2>
        <br />
        <p>
          На главното мени, кое е сместено најдоле хоризонтално во апликацијата,
          ќе ја забележите иконката налик на сметка, кликнете ја. Во овој модул
          може да ги видите сите ваши претходно направени нарачки, а до нив има
          копче ПОВТОРИ. Со кликање на ПОВТОРИ, истата нарачка ќе биде додадена
          во вашата кошничка.
        </p>
        <br />
        <h2>Каде да ја пречекам храната што ми се доставува?</h2>
        <br />
        <p>
          Во повеќето случаи храната ќе ви биде доставена на вашата врата.
          Сепак, доколку вашата адреса е тешко да се пронајде, поедноставно и
          полесно би било доколку излезете некаде во близина и го пресретнете
          доставувачот.
        </p>
        <br />
        <h2>
          Зошто гледам различни ресторани во различни период на платформата?
        </h2>
        <br />
        <p>
          Достапноста на рестораните во Naracaj.com зависи од самите ресторани.
          Кога побарувачката е многу голема, некои ресторани може да не се
          појавуваат како достапни во вашата област. Ова го правиме или ние или
          ресторанот, се со цел да спречиме откажување на нарачките или подолго
          време на испорака поради презафатеност на самите ресторани.
          <br />
          <br />
          Оваа промена обично се прави на краток временски период. Ако не можете
          да пронајдете одреден ресторан што го барате, секогаш може да се
          обидете повторно подоцна.
          <br /> <br />
          Во спротивно, можете да пробате друг ресторан што се појавува во
          вашата апликација.
        </p>
        <br />
        <h2>Имам прашање за Naracaj.com?</h2>
        <br />
        <p>
          Во менито на апликацијата може да ја пронајдете секцијата Контактирај
          не. Овде може да ги испратите сите ваши прашања, забелешки, проблеми…
          Агент ќе ви одговори најбрзо што може.
        </p>
        <br />
        <h2>Тип на нарачка “Превземи сам“ или “Во ресторан“</h2>
        <br />
        <p>
          {" "}
          <b>ПРАШАЊЕ:</b> Што претставува “Превземи сам“?
          <br />
          <br />
          “Превземи сам“ е кога ќе нарачате нарачка преку платформата
          Naracaj.com и одите сами во ресторанот за да ја подигнете.
          <br />
          <br />
          <b>ЗАБЕЛЕШКА: </b> Достапноста на “Превземи сам“ опцијата зависи од
          рестораните, т.е. дали рестораните сакаат да ја овозможат опцијата.
          <br />
          <br />
          <b>ПРАШАЊЕ: </b>Што претставува Во ресторан?
          <br />
          <br />
          “Во ресторан“ е кога ќе нарачате нарачка преку апликацијата
          Naracaj.com и ќе одите во ресторанот да седнете и уживате во вашата
          храна.
          <br />
          <br />
          <b>ЗАБЕЛЕШКА: </b> Достапноста на “Во ресторан“ опцијата зависи од
          рестораните, т.е. дали рестораните сакаат да ја овозможат опцијата.
          <br />
          <br />
          <b>ПРАШАЊЕ: Што ако не можам да го пронајдам ресторанот?</b>
          <br />
          <br />
          Во платформата Naracaj.com, има избор на преглед на рестораните во
          мапа каде што за секој ресторан е достапна опција “Насока“ која со
          помош на мапите на Google / Apple ќе ве однесе до локацијата на
          ресторанот. Меѓутоа, доколку сè уште имате проблеми со лоцирање на
          ресторанот од кој сте нарачале, можете да се јавите директно во
          ресторанот за понатамошна помош преку опцијата “Јави се“.
          <br />
          <br />
          <b>ПРАШАЊЕ: Како да направам “Превземи сам“ нарачка?</b>
          <br />
          <br />
          <ol>
            <li>
              <p>Отворете ја апликацијата</p>
            </li>
            <li>
              <p>Најавете се (или регистрирајте се)</p>
            </li>
            <li>
              <p>Одберете го ресторанот од кој сакате да нарачате</p>
            </li>
            <li>
              <p>
                Одберете го “Превземи сам“ начинот на испорака од понудените
              </p>
            </li>
            <li>
              <p>Одберете ги вашите продукти и кликнете “Во кошничка“</p>
            </li>
            <li>
              <p>Проверете ја вашата нарачка и одберете Време на нарачка</p>
            </li>
            <li>
              <p>Кликнете “Кон наплата“</p>
            </li>
            <li>
              <p>Одберете го методот на плаќање и кликнете “Плати“</p>
            </li>
            <li>
              <p>
                За плаќање со картичка внесете ги податоците од картичката и
                кликнете “Потврди купување“
              </p>
            </li>
          </ol>
          <br />
          <br />
          <b>ПРАШАЊЕ: Како да направам “Во ресторан“ нарачка?</b>
          <br />
          <br />
          <ol>
            <li>
              <p>Отворете ја апликацијата</p>
            </li>
            <li>
              <p>Најавете се (или регистрирајте се)</p>
            </li>
            <li>
              <p>Одберете го ресторанот од кој сакате да нарачате</p>
            </li>
            <li>
              <p>Одберете го “Во ресторан“ начинот на испорака од понудените</p>
            </li>
            <li>
              <p>Одберете ги вашите продукти и кликнете “Во кошничка“</p>
            </li>
            <li>
              <p>Проверете ја вашата нарачка и одберете Време на нарачка</p>
            </li>
            <li>
              <p>Кликнете “Кон наплата“</p>
            </li>
            <li>
              <p>Одберете го методот на плаќање и кликнете “Плати“</p>
            </li>
            <li>
              <p>
                За плаќање со картичка внесете ги податоците од картичката и
                кликнете “Потврди купување“
              </p>
            </li>
          </ol>
        </p>

        <br />
        <h2>
          Дали има минимален износ за нарачка за “Превземи сам“ и “Во ресторан“?
        </h2>
        <br />
        <p>
          Минималниот износ кој треба една нарачка направена преку платформата
          од тип “Превземи сам“ и “Во ресторан“ да го има зависи од самиот
          ресторан. Секој ресторан може да избере и да одлучи дали ќе одреди
          минимален износ за нарачки направени преку “Превземи сам“ и “Во
          ресторан“.
        </p>

        <br />
        <h2>Кои се опциите за плаќање во Naracaj.com?</h2>
        <br />
        <p>
          Naracaj.com како платформа за нарачки преку интернет, поддржува
          плаќање преку кредитна картичка и исто така и плаќање во готово. Кој
          од методите на плаќање ќе се користи при плаќање на нарачки од тип
          “Превземи сам“ и “Достава“ направени преку платформата, зависи од
          самиот ресторанот. За нарачки направени од типот “Во ресторан“,
          платформата поддржува само плаќање со кредитна картичка.
        </p>
      </div>
      <div className="faq">
        <br />
        <br />
        <h2>Çfarë përfaqëson Naracaj.com?</h2>
        <br />
        <p>
          Naracaj.com është platformë për porosi online e cila në mënyrë të
          konsiderueshme e lehtëson procesin e porosisë së ushqimit nga
          restaurant juaj i preferuar.
          <br />
          <br />
          Platforma ju lidh me lloje të ndryshme të restauranteve dhe ushqimeve,
          prej nga ju mund të bëni porositë tuaja në kohën që e dëshironi dhe
          nga restaurant i juaj I preferuar.
        </p>
        <br />
        <h2>Në çfarë mënyre funksionon Naracaj.com?</h2>
        <br />
        <p>
          Platforma Naracaj.com ju lidh me një numër të ndryshëm të
          restauranteve lokale, ku ju mund të zgjedhni restaurantin tuaj të
          preferuar, mënyrën e preferuar për porosi dhe ushqimin tuaj të
          preferuar. Mund të shijoni ushqimin tuaj të preferuar të arritur tek
          shtëpia juaj, mund të merrni ushqimin tuaj nga restauranti ose mund të
          hani dhe shijoni atë në vend, tek restauranti juaj i pëlqyer. Kjo
          varet nga vetë restauranti, se çfarë opcione të porosive do të ofron
          dhe sigurisht, nga vetë dëshira juaj.
        </p>
        <br />
        <h2>Ku dhe kur Naracaj.com është në dispozicion?</h2>
        <br />
        <p>
          Platforma Naracaj.com është në dispozicion në tërë territorin e
          Republikës të Maqedonisë së Veriut.
          <br />
          <br />
          Nëse do të shfaqet restauranti në platformë ose jo, varet nga vetë
          restauranti. Restaurantet vetë përcaktojnë në cilin rast do të jenë të
          disponueshëm dhe të shfaqur në platformë si dhe cili do të jetë orari
          i tyre i punës që do të shfaqet në Naracaj.com. Për të pare se cilat
          restaurante janë në dispozicion në rrethinën tuaj dhe se a janë të
          hapura në kohën e dëshiruar, ju lutemi drejtohuni tek faqja kryesore e
          aplikacionit.
        </p>
        <br />
        <h2>Si të kryeni porosi përmes Naracaj.com?</h2>
        <br />
        <p>
          Në këtë moment porosia nëpërmjet Naracaj.com mund të bëhet vetëm nga
          aplikacioni në celular. Shkarkoni dhe instaloni aplikacionin
          Naracaj.com për të filluar me porosi. .
        </p>
        <br />
        <ol>
          <li>
            <p>Identifikohuni (ose regjistrohuni)</p>
          </li>
          <li>
            <p>Zgjedhni restaurantin nga i cili dëshironi të porosisni</p>
          </li>
          <li>
            <p>Zgjedhni mënyrën e dërgesës nga ato të ofruara</p>
          </li>
          <li>
            <p>
              Nëse zgjedhni dërgesë, shkruani adresën tuaj të pranimit të
              dërgesës
            </p>
          </li>
          <li>
            <p>Zgjedhni produktet tuaja dhe klikoni "Në shportë"</p>
          </li>
          <li>
            <p>Kontrolloni porosinë tuaj dhe zgjedhni kohën e porositjes</p>
          </li>
          <li>
            <p>Klikoni “Drejt pagesës“</p>
          </li>
          <li>
            <p>Zgjedhni mënyrën e pagesës dhe klikoni “Paguaj”</p>
          </li>
          <li>
            <p>
              Për të paguar me kartelë, shtypni të dhënat e kartelës dhe klikoni
              “Konfirmo blerjen”
            </p>
          </li>
        </ol>
        <br />
        <h2>Si llogaritet koha e dorëzimit?</h2>
        <br />
        <p>
          Koha të cilën ju si përdorues e shikoni tek zgjedhësi i kohës, paraqet
          kombinim nga koha mesatare e përgatitjes së ushqimit të cilën e cakton
          restauranti dhe koha e nevojshme që ushqimi të dorëzohet në adresën
          tuaj të dëshiruar.
          <br />
          <br />
          Ju lutemi vini re se ky është vlerësimi ynë. Sidoqoftë, koha aktuale
          se kur do të dorëzohet ushqimi mund të ndryshojë në momente të
          ndryshme në varësi nga ajo se sa është i zënë restauranti, sa e madhe
          dhe komplekse është porosia juaj, kushtet e trafikut, kushtet
          meteorologjike dhe kushte të tjera.
        </p>
        <br />
        <h2>
          A duhet paguar dërgesa si shërbim dhe a ka një shumë minimale për
          porosinë që duhet të dorëzohet?
        </h2>
        <br />
        <p>
          Po, në varësi nga ajo se kush e kryen dërgesën, mund të ketë çmim të
          ndryshëm për shërbimin e dorëzimit dhe një shumë minimale të ndryshme
          për secilin restorant në mënyrë të pavarur. Restaurantet kanë të
          drejtë të zgjedhin shërbimin e dërgesave nga Naracaj.com ose ose të
          përdorin furnizuesit e tyre, kështu që çmimet mund të ndryshojnë në
          varësi të zgjedhjes së restorantit. Ekziston mundësia që për të
          njëjtin restorant, çmimi i shërbimit të dërgesës, si dhe shuma
          minimale e porosisë së dërgesës, të jenë të ndryshme në varësi të
          distancës nga adresa e dorëzimit nga restoranti. Në raste të tilla,
          një çmim shtesë prej 10 denarë / km mund të shtohet në çmimin bazë të
          shërbimit të dërgesës.
        </p>
        <br />
        <h2>
          Si të porosis të njejtën porosi të cilën e kam porositur edhe më pare
          në platformë?
        </h2>
        <br />
        <p>
          Në menunë kryesore, e cila ndodhet në pjesën e poshtme horizontale të
          aplikacionit, do të vini re ikonën që thotë e ngjashme me llogarinë,
          klikoni mbi të.Në këtë modul mund ti shihni të gjitha porositë tuaja
          të mëparshme, ndërsa pranë tyre ka një buton PËRSËRIT. Duke klikuar
          butonin PËRSËRIT, e njejta porosi do të shtohet në shportën tuaj.
        </p>
        <br />
        <h2>Ku mund ta pres ushqimin i cili më dërgohet?</h2>
        <br />
        <p>
          Në të shumtën e rasteve ushqimi do të ju dorëzohet në derën tuaj.
          Sidoqoftë, në rast se adresa e juaj është e vështirë për ta gjetur, do
          të ishte më e lehtë dhe më e thjeshtë nëse dilni diku dhe takoni
          furnizuesin.
        </p>
        <br />
        <h2>
          Përse shoh restaurante të ndryshme në periudha të ndryshme në
          platformë?
        </h2>
        <br />
        <p>
          Disponueshmëria e restauranteve në Naracaj.com varet nga vetë
          restaurantet. Kur kërkesa është shumë e lartë, disa restorante mund të
          mos shfaqen në dispozicion në zonën tuaj. Këtë e bëjmë ose ne ose
          restoranti, në mënyrë që të parandalohet anulimi i porosive ose
          dërgesë e porosive më e gjatë për shkak të jodisponueshmërisë së
          restauranteve.
          <br />
          <br />
          Ky ndryshim bëhet zakonisht në një periudhë të shkurtër kohore. Nëse
          nuk mund ta gjeni restaurantin që po kërkoni, gjithmonë mund të
          provoni më vonë.
          <br /> <br />
          Në të kundërtën, mund të provoni një restaurant tjetër që shfaqet në
          aplikacionin tuaj.
        </p>
        <br />
        <h2>Kam pyetje për Naracaj.com?</h2>
        <br />
        <p>
          Në menunë e aplikacionit mund të gjeni seksionin Na kontaktoni. Këtu
          mund të dërgoni të gjitha pyetjet, vërejtjet, problemet tuaja…Agjenti
          do t'ju përgjigjet sa më shpejt që të jetë e mundur.
        </p>
        <br />
        <h2>Lloji i porosisë "Merr vetë" ose "Në restaurant"</h2>
        <br />
        <p>
          {" "}
          <b>PYETJE:</b> Çfarë do të thotë “Merr vetë“??
          <br />
          <br />
          “Merr vetë“ është kur krijoni një porosi përmes platformës Naracaj.com
          dhe shkoni vetëm në restorant për ta marrë atë.
          <br />
          <br />
          <b>SHËNIM: </b> Disponueshmëria e opsionit "Merr vetë" varet nga
          restaurantet, do të thotë nëse restaurantet duan ta aktivizojnë
          opcionin.
          <br />
          <br />
          <b>PYETJE: </b>Çfarë paraqet “Në restaurant”?
          <br />
          <br />
          “Në restaurant“ paraqet opcionin kur krijoni një porosi përmes
          aplikacionit Naracaj.com dhe shkoni në restaurant për t'u ulur dhe për
          ta shijuar ushqimin tuaj.
          <br />
          <br />
          <b>SHËNIM: </b> Disponueshmëria e opsionit "Në restaurant" varet nga
          restaurantet, do të thotë nëse restaurantet duan ta aktivizojnë
          opcionin.
          <br />
          <br />
          <b>PYETJE: </b>Po sikur të mos e gjej restaurantin?
          <br />
          <br />
          Në platformën Naracaj.com, ekziston mundësia e elaborimit të
          restauranteve në hartë ku secili restaurant ka në dispozicion një
          opsion "Drejtime" i cili do t'ju çojë në vendndodhjen e restaurantit
          me ndihmën e hartave të Google / Apple. Sidoqoftë, nëse akoma keni
          probleme në gjetjen e restorantit nga i cili keni porositur, mund të
          kontaktoni direkt me restorantin për ndihmë të mëtejshme përmes
          opcionit "Lajmërohu".
          <br />
          <br />
          <b>PYETJE: Si të kryej porosi “Merr vetë“?</b>
          <br />
          <br />
          <ol>
            <li>
              <p>Hapeni aplikacionin</p>
            </li>
            <li>
              <p>Identifikohuni (ose regjistrohuni)</p>
            </li>
            <li>
              <p>Zgjedheni restaurantin nga i cili dëshironi të bëni porosi</p>
            </li>
            <li>
              <p>Zgjedheni opcionin për porosi “Merr vetë” nga të ofruarat</p>
            </li>
            <li>
              <p>Zgjedhini produktet tuaja dhe klikoni “Në shportë”</p>
            </li>
            <li>
              <p>Kontrolloni porosinë tuaj dhe zgjedhni kohën e porosisë</p>
            </li>
            <li>
              <p>Klikoni “Drejt pagesës“</p>
            </li>
            <li>
              <p>Zgjedheni mënyrën e pagesës dhe klikoni “Paguaj”</p>
            </li>
            <li>
              <p>
                Për të paguar me kartelë, shtypni të dhënat e kartelës dhe
                klikoni “Konfirmo pagesën”
              </p>
            </li>
          </ol>
          <br />
          <br />
          <b>PYETJE: Si të kryej porosi “Në restaurant“?</b>
          <br />
          <br />
          <ol>
            <li>
              <p>Hapeni aplikacionin</p>
            </li>
            <li>
              <p>Identifikohuni (ose regjistrohuni)</p>
            </li>
            <li>
              <p>Zgjedheni restaurantin nga i cili dëshironi të bëni porosi</p>
            </li>
            <li>
              <p>
                Zgjedheni opcionin për porosi “Në restaurant” nga të ofruarat
              </p>
            </li>
            <li>
              <p>Zgjedhini produktet tuaja dhe klikoni “Në shportë”</p>
            </li>
            <li>
              <p>Kontrolloni porosinë tuaj dhe zgjedhni kohën e porosisë</p>
            </li>
            <li>
              <p>Klikoni “Drejt pagesës“</p>
            </li>
            <li>
              <p>Zgjedheni mënyrën e pagesës dhe klikoni “Paguaj”</p>
            </li>
            <li>
              <p>
                Për të paguar me kartelë, shtypni të dhënat e kartelës dhe
                klikoni “Konfirmo pagesën”
              </p>
            </li>
          </ol>
        </p>

        <br />
        <h2>
          A ka një shumë minimale për porosi të llojeve “Merr vetë” dhe “Në
          restaurant?
        </h2>
        <br />
        <p>
          Shuma minimale që një porosi e bërë përmes platformave të llojit “Merr
          vetë” dhe “Në restaurant” duhet ta ketë, varet nga vetë restauranti.
          Secili restaurant mund të zgjedhë dhe të vendosë se a do të caktojë
          një shumë minimale për porosi të bëra nëpërmjet mundësive “Merr vetë”
          dhe “Në restaurant”.
        </p>

        <br />
        <h2>Cilat janë mundësitë për pagesë në Naracaj.com?</h2>
        <br />
        <p>
          Naracaj.com si platformë e porosive online, mbështetë pagesa nëpërmjet
          kartelave të kreditit si dhe pagesa në kesh. Cila prej metodave të
          pagesës do të përdoret gjatë pagesës së porosive të llojit “Merr vetë
          dhe “Në restaurant” të kryera përmes platformës, varet nga vetë
          restauranti. Për pagesa të llojit “Në restaurant”, platforma mbështet
          vetëm pagesa me kartela të kreditit.
        </p>
      </div>
      <div className="faq">
        <br />
        <br />
        <h2>What is Naracaj.com?</h2>
        <br />
        <p>
          Naracaj.com is an online ordering platform that makes ordering great
          food from your favorite local restaurants as easy as requesting a
          ride.
          <br />
          <br />
          The Naracaj.com app connects you with a broad range of local
          restaurants and food, so you can order from the available menus of
          your local restaurants whenever you want.
        </p>
        <br />
        <h2>How does Naracaj.com work?</h2>
        <br />
        <p>
          Naracaj.com connects you with a broad range of local restaurants, so
          you can select your favorite restaurant, your favorite order type, and
          order your favorite food. You can enjoy your favorite food either
          delivered to your doorstep, you can pick it up yourself from the
          restaurant or you can dine into the restaurant. It all depends on the
          provided options from the restaurant and your desires.
        </p>
        <br />
        <h2>When and where is Naracaj.com available?</h2>
        <br />
        <p>
          Naracaj.com is a platform available on the complete territory of the
          Republic of North Macedonia.
          <br />
          <br />
          If the restaurant is listed and the operation hours of the restaurant
          are dependent on individual restaurants. To see what restaurants are
          available near you and if they are open at the moment, just head to
          the home tab on your app.
        </p>
        <br />
        <h2>How to place an order on Naracaj.com?</h2>
        <br />
        <p>
          Orders on Naracaj.com at this moment can be done in one way:
          <br />
          - On the mobile app: Download the Naracaj.com app to get started
          <br />
          <br />
          Participants can only add food items.
        </p>
        <br />
        <br />
        <p>TO PLACE AN ORDER:</p>
        <br />
        <ol>
          <li>
            <p>Sign in (or create an account)</p>
          </li>
          <li>
            <p> Choose the restaurant you want to order from</p>
          </li>
          <li>
            <p>
              Choose your preferred order mode from the available ones that your
              restaurant offers
            </p>
          </li>
          <li>
            <p>If ordering Delivery, set your delivery address</p>
          </li>
          <li>
            <p>Select your items and tap "Add to Basket"</p>
          </li>
          <li>
            <p>
              To place your order, review the order and select your preferred
              time from the time picker
            </p>
          </li>
          <li>
            <p> Click on "Check out"</p>
          </li>
          <li>
            <p>Select your payment option and tap "Pay".</p>
          </li>
          <li>
            <p>
              For credit card payments enter your Credit Card details and
              “Confirm Pay”
            </p>
          </li>
        </ol>
        <br />
        <h2>How is the delivery time calculated?</h2>
        <br />
        <p>
          The time estimate you see combines how much time it typically takes
          the restaurant to accept and prepare an average order, and how much
          time it should take for your food to be delivered to your address.
          <br />
          <br />
          Please note that this is our best estimate. Actual delivery times may
          fluctuate depending on how busy the restaurant is, how large your
          order is, traffic conditions, and other factors.
        </p>
        <br />
        <h2>
          Is there a delivery fee or delivery minimum amount for an order?
        </h2>
        <br />
        <p>
          Yes, depending on the delivery provider, there can be a different
          delivery fee and a different minimum delivery amount for every
          restaurant. The restaurants have the option to use the Naracaj.com
          delivery service or to use their own delivery, therefore the prices
          can vary depending on the preferences. Also, there is a possibility
          for different delivery fees and minimum amounts for the same
          restaurant but depending on the restaurant distance to the final
          delivery point. In such cases on the basic delivery fee, an additional
          fee of 10 den/km may be applied.
        </p>
        <br />
        <h2>How can I reorder the food that I've ordered before?</h2>
        <br />
        <p>
          Tap the receipt icon at the bottom of the screen to see a list of your
          past orders. Find the order you'd like to have again and tap REORDER.
          This will add the same list of items to your cart, so you can do a
          final review before placing your order again.
        </p>
        <br />
        <h2>Where should I meet my delivery food?</h2>
        <br />
        <p>
          In most cases, your food will be delivered right to your door. If your
          address is difficult to get to, it may be faster and easier for
          everyone if you meet your delivery outside by the street.
        </p>
        <br />
        <h2>Why do I see different restaurants at different times?</h2>
        <br />
        <p>
          The availability of the restaurants in Naracaj.com depends solely on
          the restaurants itself. When demand is very high, some restaurants may
          not appear as available in your area. This is either done by us or the
          restaurant, to prevent order cancellations or longer delivery times.
          <br />
          <br />
          This change is usually made for a short period. If you can't see a
          specific restaurant you're looking for, you can always try again
          later.
          <br /> <br />
          Otherwise, you could try another restaurant which appears in your app.
        </p>
        <br />
        <h2>I have a question for Naracaj.com?</h2>
        <br />
        <p>
          Into the menu section of the app, there is a Contact us form on which
          you can type all of your questions, remarks, and problems. An agent
          will respond to you as soon as possible.
        </p>
        <br />
        <h2>Dine-in and Pick-up Order</h2>
        <br />
        <p>
          {" "}
          <b>QUESTION:</b>What is a Pick-up order?
          <br />
          <br />
          A Pick-up order is when you place an order through the Naracaj.com
          platform and you go to the restaurant to take it.
          <br />
          <br />
          <b>NOTE: </b> The availability of pick-up depends on the restaurant
          offers if they offer it as an option.
          <br />
          <br />
          <b>QUESTION: </b>What is a Dine-in order?
          <br />
          <br />
          A Dine-in order is when you place an order through the Naracaj.com app
          and you go to the restaurant to collect it and sit in the restaurant
          to eat your food.
          <br />
          <br />
          <b>NOTE: </b> The availability of Dine-in depends on the restaurant
          offers if they offer it as an option.
          <br />
          <br />
          <b>QUESTION: </b>What if I can't find the restaurant?
          <br />
          <br />
          In the platform, there is a map view selection where every restaurant
          is available Directions option which will take you in Google/Apple
          maps to the restaurant location. However, if you're still having
          issues locating the restaurant you ordered from, reach out to the
          restaurant directly for further assistance.
          <br />
          <br />
          <b>QUESTION: How do I order Pick-up?</b>
          <br />
          <br />
          <ol>
            <li>
              <p>Open the Naracaj.com app</p>
            </li>
            <li>
              <p>Choose the restaurant you want to order from</p>
            </li>
            <li>
              <p>Choose the ordering mode "Pick up"</p>
            </li>
            <li>
              <p>Select your items and tap "Add to Basket"</p>
            </li>
            <li>
              <p>
                To place your order, review the order and select your preferred
                time from the time picker
              </p>
            </li>
            <li>
              <p> Click on "Check out"</p>
            </li>
            <li>
              <p>Select your payment option and tap "Pay".</p>
            </li>
            <li>
              <p>
                {" "}
                For credit card payments enter your Credit Card details and
                “Confirm Pay”
              </p>
            </li>
          </ol>
          <br />
          <br />
          <b>QUESTION: How do I order Dine-in?</b>
          <br />
          <br />
          <ol>
            <li>
              <p>Open the Naracaj.com app</p>
            </li>
            <li>
              <p>Choose the restaurant you want to order from</p>
            </li>
            <li>
              <p> Choose the dining mode "Dine-in"</p>
            </li>
            <li>
              <p>Select your items and tap "Add to Basket"</p>
            </li>
            <li>
              <p>
                To place your order, review the order and select your preferred
                time from the time picker
              </p>
            </li>
            <li>
              <p> Click on "Check out"</p>
            </li>
            <li>
              <p>Select your payment option and tap "Pay".</p>
            </li>
            <li>
              <p>
                {" "}
                For credit card payments enter your Credit Card details and
                “Confirm Pay”
              </p>
            </li>
          </ol>
        </p>

        <br />
        <h2>Is there a minimum order amount for Pick up and Dine-in?</h2>
        <br />
        <p>
          The minimum order amount for pick up and dine-in for orders made
          through the platform depends on the restaurant preference. Every
          restaurant can choose and decide if they are going to set a minimum
          amount for pick up and dine-in orders.
        </p>

        <br />
        <h2>What are the payment options in Naracaj.com?</h2>
        <br />
        <p>
          Naracaj.com as an online ordering platform supports payment via credit
          card and also cash payment. Which of the methods will be used for
          payment for pick up and delivery orders made through the platform,
          depends on the restaurant preference. For dine-in orders, the platform
          supports only credit card payment.
        </p>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

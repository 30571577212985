import React, { useEffect } from 'react';
import Spinner from '../UI/Spinner';

const FailPage = props => {
    useEffect(() => {
        let transStarted = localStorage.getItem("payingStarted");
        if (transStarted && transStarted === "true") {
            localStorage.removeItem("payingStarted");
            setTimeout(() => {
                props.history.push({
                    pathname: '/order/main',
                    mode: 'failedOrder'
                });
            }, 2000);

        } else {
            props.history.push("/");
        }

    }, [])
    return (
        <div className='order-page'>
            <div className='order-page-wrapper'>
                <Spinner logo spinnerStyle="SpinnerYellow" />;
        </div>
        </div>
    )
}

export default FailPage;
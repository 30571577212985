import React from 'react'
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete from 'react-places-autocomplete';
// import {
//     geocodeByAddress,
//     geocodeByPlaceId,
//     getLatLng,
// } from 'react-places-autocomplete';

import Auxilary from '../hoc/Auxilary';

const InputOrdering = props => {
    const { t } = useTranslation();
   
    /*global google*/
    const searchOptions = {
        location: new google.maps.LatLng(41.9981, 21.4254),
        radius: 100,
        componentRestrictions: { country: ['MK'] }
    }
    const handleSelectPlaces = (address, placeId) => {
        var skopje = new google.maps.LatLng(41.9981, 21.4254);


        const map = new google.maps.Map(
            document.getElementById('map'), { center: skopje, zoom: 15 });
        var request = {
            placeId: placeId,
            fields: ['name', 'rating', 'formatted_phone_number', 'geometry']
        };

        const service = new google.maps.places.PlacesService(map);
        service.getDetails(request, callback);

        function callback(result, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                result.geometry.location.lat();
                props.onChange(
                    address,
                    props.data.name,
                    true,
                    { latitude: result.geometry.location.lat()+"", longitude: result.geometry.location.lng()+"" },
                    true
                )
            } else {
                props.onChange(
                    address,
                    props.data.name,
                    true,
                    { latitude: '', longitude: '' },
                    true
                )
            }
        }
        // geocodeByAddress(address)
        //     .then(results => getLatLng(results[0]))
        //     .then(latLng => {

        //     })
        //     .catch(error => {
        //         props.onChange(
        //             address,
        //             props.data.name,
        //             true,
        //             { latitude: result.geometry.location.lat(), longitude: result.geometry.location.long() },
        //             true
        //         )
        //     });
    }
    let input = null
    let classNamesInput;
    if (props.data.typing === true) {
        if (props.data.valid === false) {
            classNamesInput = 'input-ordering input-ordering-not-valid';
        } else {
            classNamesInput = 'input-ordering input-ordering-normal';
        }
    } else {
        classNamesInput = 'input-ordering input-ordering-normal';
    }

    if (props.places) {
        input = (
            <Auxilary>
                <div id="map"></div>
                <PlacesAutocomplete
                    value={props.mapModal ? props.data.value+(props.data.streetNumber!=="" ? ", "+props.data.streetNumber :"") : props.data.value}
                    onChange={(address) => props.onChange(address, props.data.name, true)}
                    onSelect={handleSelectPlaces}
                    // searchOptions={searchOptions}
                    searchOptions={searchOptions}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <input
                                {...getInputProps({
                                    placeholder: t(props.data.placeholder),
                                    className: classNamesInput,
                                })}
                                disabled={props.data.disabled ? true : false}
                            />
                            <div className="autocomplete-dropdown-container-ordering">
                                {loading && <div>{t('inputs.descriptions.loading')}</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#FFC300', cursor: 'pointer', }
                                        : { backgroundColor: '#fafafa', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </Auxilary>

        )
    } else {
        switch (props.data.elemType) {
            case 'input':
                input =
                    <input
                        className={classNamesInput}
                        type={props.data.type}
                        value={props.data.value}
                        placeholder={t(props.data.placeholder)}
                        onChange={(event) => props.onChange(event, props.data.name)}
                        onClick={(event) => props.phoneInputClicked(event, props.data.name)}
                        disabled={props.data.disabled ? true : false}
                    />
                break;
            default:
                input =
                    <input
                        className={classNamesInput}
                        type={props.data.type}
                        value={props.data.value}
                        placeholder={t(props.data.placeholder)}
                        onChange={(event) => props.onChange(event, props.data.name)}
                        onClick={(event) => props.phoneInputClicked(event, props.data.name)}
                    />
                break;
        }
    }


    return (input)
}

export default InputOrdering

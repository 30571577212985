import React, { useState, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import axios from '../axios-instance_baseURL';

import Header from '../components/Header/Header';
import Spinner from '../UI/Spinner';
import Input from '../UI/Input';
import Footer from '../components/Footer';
import { forgotPassword_url } from '../assets/urls/urls';
import { forgotPassword_confing } from '../assets/configs/config_calls';
import BasketButton from '../UI/BasketButton';

const VALIDITY_CHANGE = "VALIDITY_CHANGE"

const reducer = (state, action) => {
    if (action.type === VALIDITY_CHANGE) {
        if (state.validElements[action.elemName] === false && action.elemValidity) {
            const copy = { ...state.validElements };
            copy[action.elemName] = action.elemValidity;
            let formValid = true;
            for (let item in { ...copy }) {

                if (copy[item] === false) {
                    formValid = false;
                    break;
                }
            }
            const values = { ...state.elementsValues };
            values[action.elemName] = action.elemValue;
            return {
                ...state,
                validElements: { ...copy },
                elementsValues: { ...values },
                formValid: formValid
            }
        } else if (state.validElements[action.elemName] && action.elemValidity === false) {
            const copy = { ...state.validElements };
            copy[action.elemName] = action.elemValidity;
            return {
                ...state,
                validElements: { ...copy },
                formValid: false
            }
        } else if (state.validElements[action.elemName] && action.elemValidity) {
            const copy = { ...state.validElements };
            copy[action.elemName] = action.elemValidity;
            let formValid = true;
            for (let item in { ...copy }) {

                if (copy[item] === false) {
                    formValid = false;
                    break;
                }
            }
            const values = { ...state.elementsValues };
            values[action.elemName] = action.elemValue;
            return {
                ...state,
                validElements: { ...copy },
                elementsValues: { ...values },
                formValid: formValid
            }
        } else {
            return state;
        }
    }
}

const ForgotPage = (props) => {
    const { t, i18n } = useTranslation();
    const [formData] = useState({
        companyEmail: {
            elemType: "input",
            placeholder: 'inputs.placeholders.email',
            desc: 'inputs.descriptions.email',
            type: "email",
            name: "companyEmail",
            valid: false,
            value: "",
            validationMessage: 'inputs.errorMessages.email',
            succesMessage: 'inputs.inputValidMessage',
            typing: false,
            rules: {
                email: true,
                required: true
            },
        }
    });
    const [loading, setLoadig] = useState(false);
    const [validations, dispatchValidations] = useReducer(reducer, {
        validElements: {
            companyEmail: false,
        },
        elementsValues: {
            companyEmail: "",
        },
        formValid: false
    })

    const [mssg, setMssg] = useState();
    const [errMsg, setErrMsg] = useState();
    const handleSubmit = (event) => {
        event.preventDefault();
        if (validations.formValid) {
            let config = forgotPassword_confing(validations.elementsValues.companyEmail, i18n.language);
            setLoadig(true);
            axios.post(forgotPassword_url, { ...config.body }, {
                params: {}, headers: config.headers
            }).then(response => {
                setLoadig(false);
                if (response.data.status) {
                    setMssg(response.data.message);
                } else {
                    setErrMsg(response.data.message);
                }
            }).catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                setLoadig(false);
                setErrMsg('homepage.errorMsg');
            })
        }
    };

    const validChangeHandler = (elemName, elemValidity, elemValue) => {
        dispatchValidations({ type: VALIDITY_CHANGE, elemName: elemName, elemValidity: elemValidity, elemValue: elemValue })
    }

    let formValid = validations.formValid;
    return (
        <div className='wrapper'>
            <Row className="justify-content-center align-content-center text-center m-0 formStyle" >
                {/* <Col xs="12" className='p-0 '> <Logo classNames='logo' /> </Col> */}
                <Col xs="12" className='p-0 mb-5'>   <Header settings history={props.history} headerHomepageOnly />   </Col>
                <Col xs="12 " className='login-form p-md-0' style={{ minHeight: "500px" }}>
                    {mssg ? null : <div className='title'>{t('forgot.description')}</div>}
                    {mssg ?
                        <div>
                            <div>{mssg}</div>
                            <div className='forgot-login-back' onClick={() => props.history.push("/login")}>{t('forgot.back')}</div>
                        </div>
                        : <form onSubmit={() => handleSubmit}>
                            <Row className="justify-content-center align-content-center text-center m-0">
                                <Input
                                    className='p-0 col-height'
                                    sm="12"
                                    data={formData.companyEmail}
                                    notifyHandler={validChangeHandler}
                                />

                            </Row>
                            <button className="submit-button button-forgot-page" disabled={!formValid} onClick={handleSubmit} >{t('forgot.forgot_button')}</button>
                            {errMsg ? (
                                <Col xs="12">
                                    <label className="span-style">{t(errMsg)}</label>
                                </Col>
                            ) : null}
                            <div className='new-create forgot-page-links '>
                                <span className="forgot-page-span">{t('login.new')} Naracaj.com?</span>
                            </div>
                            <div className='create-profile-login'>
                                <button onClick={() => props.history.push("/register")}>{t('login.create')}</button>
                            </div>
                        </form>
                    }
                </Col>
                {loading ?
                    <div className='loading-data-modal' >
                        <Spinner logo spinnerStyle="SpinnerYellow" />;
                         </div> : null
                }
            </Row>
            <BasketButton history={props.history} />
            <Footer />
        </div>
    );
};

export default ForgotPage;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Auxilary from "../hoc/Auxilary";
import Backdrop from '../UI/Backdrop';
import locationOff from '../assets/images/icons/location_off.svg';


const OwnRestModal = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <Auxilary>
            <Backdrop address className='backdrop' show={true} backdropClicked={() =>{}} />

            <div className='own-rest-modal'>

                <Row>
                    <Col xs='12' className='image text-center'>
                        <img src={locationOff} alt="location-off"></img>
                    </Col>
                    <Col xs='12' className='own-rest'>
                        {t("order-page.restaurant-delivery")}
                    </Col>
                    <Col xs='12' className='own-rest-info'>
                        {t("order-page.restaurant-own-info")}
                    </Col>
                </Row>
                <div className='hr'></div>
                <Row>
                    <Col xs='12' className='phone-near'>
                        {t("order-page.phone-near")}
                    </Col >
                    <Col xs='12' className='phone-near-info'>
                        {t("order-page.phone-near-info")}
                    </Col>
                </Row>
                <Row>
                    <Col xs='12' className='call-owner'>
                        {t("order-page.call-owner")}
                    </Col>
                    <Col xs='12' className='call-owner-info'>
                        {t("order-page.call-owner-info")}
                    </Col>
                </Row>
                <Row>
                    <Col xs='12' className='text-center'>
                        <button onClick={() => props.closeModal()}>{t('restaurant_mode.ok')}</button>
                    </Col>
                </Row>
            </div>
        </Auxilary>

    )
}

export default OwnRestModal;

import React, { useRef } from 'react';
import {
    GoogleMap,
    useLoadScript,
    Marker
} from '@react-google-maps/api';
import { Spinner, Row, Col } from 'react-bootstrap';
import Auxilary from '../hoc/Auxilary';
import { useTranslation } from 'react-i18next';
import Backdrop from '../UI/Backdrop';

const MapModal = props => {

    const directionsRef = useRef();
    const { t } = useTranslation();
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API
    });

    const mapContainerStyle = {
        width: "100%",
        height: "300px"
    }
    const center = {
        lat: parseFloat(props.data.latitude),
        lng: parseFloat(props.data.longitude)
    }


    let content = <Spinner logo spinnerStyle="SpinnerYellow" />;
    if (isLoaded) {
        content = <Auxilary>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={18}
                center={center}
                options={{ disableDefaultUI: true, zoomControl: true }}
            >
                <Marker position={{ lat: props.data.latitude, lng: props.data.longitude }} />
                <button className='close-button-map' onClick={() => props.handleCloseMenuModal()}>
                    <div className="mdiv">
                        <div className="md"></div>
                    </div>
                </button>
            </GoogleMap>

        </Auxilary>

    }
    if (loadError) content = <div>Something went wrong</div>

    return (
        <Auxilary>
            <div className='map-modal'>
                {content}
                <Row className='m-0 modal-description'>
                    <Col xs='9' className='p-0 name'>
                        {props.data.address}
                    </Col>
                    <Col xs='3' className='p-0 direction text-right'>
                        <button className='map-directions' onClick={() => directionsRef.current.click()} >
                            <a ref={directionsRef} href={"https://www.google.com/maps/search/?api=1&query=" + props.data.latitude + "," + props.data.longitude} target="_blank" rel="noopener noreferrer"> </a>
                            {t('map_modal.directions')}
                        </button>
                    </Col>
                    {props.data.min_subtotal_for_delivery ?
                        <Col xs='12' className='p-0 delivery-min'>
                            <div>
                                {t('map_modal.min_delivery')}
                            </div>
                            <div>
                                {props.data.min_subtotal_for_delivery} {t('post.currency')}
                            </div>
                        </Col>
                        : null
                    }
                    {props.data.delivery_fee ?
                        <Col xs='12' className='p-0 delivery-basic'>
                            <div>
                                {t('map_modal.basic_fee')}
                            </div>
                            <div>
                                {props.data.delivery_fee} {t('post.currency')}
                            </div>
                        </Col>
                        : null
                    }
                    {props.data.operating_hours.length !== 0 ?
                        <Auxilary>
                            <Col xs='12' className='p-0 available-orders'>
                                {t('map_modal.available-orders')}
                            </Col>
                            {props.data.operating_hours.map(item => {
                                return <Col xs='12' className='p-0 working-days' key={item.day_of_week} >
                                    <Row className='m-0' >
                                        <Col xs='6' sm='5' className='p-0'>
                                            {t('map_modal.' + item.day_of_week)}:
                                </Col>
                                        <Col xs='6' sm='7' className='p-0'>
                                            {item.open_at} - {item.close_at}
                                        </Col>
                                    </Row>
                                </Col>
                            })}
                        </Auxilary>

                        : null}
                </Row>
            </div>
            {props.homePage ?
                <Backdrop address className='backdrop' show={true} backdropClicked={() => props.handleCloseMenuModal()} />
                : null}
        </Auxilary>

    )
}

export default MapModal;
import React from "react";


import Header from '../components/Header/Header';
import Footer from '../components/Footer';

const Layout = (props) => {

  return (
    <div className="layout">
      <Header settings={props.settings} history={props.history} favorites={props.favorites ? true : false} />
      {props.children}
      <Footer/>
    </div>
  );
};

export default Layout;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Spinner from "../UI/Spinner";
import Backdrop from "../UI/Backdrop";

// const VALIDITY_CHANGE = "VALIDITY_CHANGE";

// const reducer = (state, action) => {
//     if (action.type === VALIDITY_CHANGE) {
//         if (state.validElements[action.elemName] === false && action.elemValidity) {
//             const copy = { ...state.validElements };
//             copy[action.elemName] = action.elemValidity;
//             let formValid = true;
//             for (let item in { ...copy }) {

//                 if (copy[item] === false) {
//                     formValid = false;
//                     break;
//                 }
//             }
//             const values = { ...state.elementsValues };
//             values[action.elemName] = action.elemValue;
//             return {
//                 ...state,
//                 validElements: { ...copy },
//                 elementsValues: { ...values },
//                 formValid: formValid
//             }
//         } else if (state.validElements[action.elemName] && action.elemValidity === false) {
//             const copy = { ...state.validElements };
//             copy[action.elemName] = action.elemValidity;
//             return {
//                 ...state,
//                 validElements: { ...copy },
//                 formValid: false
//             }
//         } else if (state.validElements[action.elemName] && action.elemValidity) {
//             const copy = { ...state.validElements };
//             copy[action.elemName] = action.elemValidity;
//             let formValid = true;
//             for (let item in { ...copy }) {

//                 if (copy[item] === false) {
//                     formValid = false;
//                     break;
//                 }
//             }
//             const values = { ...state.elementsValues };
//             values[action.elemName] = action.elemValue;
//             return {
//                 ...state,
//                 validElements: { ...copy },
//                 elementsValues: { ...values },
//                 formValid: formValid
//             }
//         } else {
//             return state;
//         }

//     }
// }

const Settings = (props) => {
  const { t, i18n } = useTranslation();
  // const [loadingOverAll, setLoadingoverAll] = useState(false);
  const [loadingOverAll] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [updatedPassword, setUpdatedPassword] = useState(
    props.history.location.updated ? true : false
  );

  // const handleSubmit = (event) => {
  //     event.preventDefault();

  // };

  const handleLangChange = (language) => {
    setOpenModal(false);
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
  };

  // useEffect(() => {
  //     let imageConfing = get_img_config(accessToken, i18n.language);
  //     axios.get(get_img_url + userData.user.profile_picture_url, { params: {}, headers: imageConfing.headers })
  //         .then(response => {
  //             if (response.request && response.request.responseURL) {
  //                 setUriImage(response.request.responseURL);
  //             }
  //             setLoadingoverAll(false);
  //         })
  //         .catch(err => {
  //             setLoadingoverAll(false);
  //         })
  // }, [])
  useEffect(() => {
    if (props.history.location.updated) {
      setTimeout(() => {
        setUpdatedPassword(false);
      }, 2500);
    }
  }, []);
  let languageText = "English";
  if (i18n.language === "mk") languageText = "Македонски";
  if (i18n.language === "sq") languageText = "Shqip";

  let content = <Spinner logo spinnerStyle="SpinnerYellow" />;
  if (!loadingOverAll) {
    content = (
      <div className="profile-wrapper">
        {/* <div className='image-part'>
                <div style={{marginBottom:"10px"}}>
                    <img style={{ width: "100px", height: "100px", borderRadius: "50%" }} src={uriImage ? uriImage : userImg}></img>
                </div>
                <div className='gretings-user'> {userData.user.first_name} {userData.user.last_name}</div>
                <div className='gretings-user' style={{fontSize:}}>+389{userData.user.phone_number} </div>
                {/* <div style={{ height: "35px", width: "150px" }}>
                    {loading ? <Spinner spinnerStyle="SpinnerYellow SpinnerButton SpinnerSettingsButton" /> :
                        <button onClick={handleSubmit} disabled={!validations.formValid} className='change-button'>
                            {t('profile.save')}
                        </button>}
                </div> */}
        {/* </div> */}
        <div className="profile-settings-part">
          <hr className="hr-remove-margins"></hr>
          <div className="user-settings-item">
            <div>
              <span> {t("settings.changePass")}</span>
              <button
                className="change-password-button"
                onClick={() =>
                  props.history.push("/settings/additional/password-update")
                }
              >
                {" "}
                <span>{t("settings.change")}</span>
              </button>
            </div>
          </div>
          <hr className="hr-remove-margins"></hr>
          <div className="user-settings-item">
            <div>
              <span>{t("settings.privacy")}</span>
              <span>
                <a
                  href="https://naracaj.com/privacy_policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("settings.view")}
                </a>
              </span>
            </div>
          </div>
          <hr className="hr-remove-margins"></hr>
          <div className="user-settings-item">
            <div>
              <span>{t("settings.terms")}</span>
              <span>
                <a
                  href="https://naracaj.com/terms_of_use/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("settings.view")}
                </a>
              </span>
            </div>
          </div>
          <hr className="hr-remove-margins"></hr>
          <div className="user-settings-item">
            <div>
              <span>{t("settings.language")}</span>
              <span>
                <button
                  className="modal-button-open"
                  onClick={() => setOpenModal(!openModal)}
                >
                  {languageText}
                </button>
              </span>
              <Backdrop
                className="modal-backdrop"
                show={openModal}
                backdropClicked={() => setOpenModal(false)}
                menu
              ></Backdrop>
              {openModal ? (
                <div className="modal-languages">
                  <div>{t("settings.select")}</div>
                  <button onClick={() => handleLangChange("en")}>
                    English
                  </button>
                  <button onClick={() => handleLangChange("mk")}>
                    Македонски
                  </button>
                  <button onClick={() => handleLangChange("sq")}>Sqip</button>
                </div>
              ) : null}
            </div>
          </div>
          <hr className="hr-remove-margins"></hr>
          <div className="user-settings-item">
            <div>
              <span> {t("settings.questions")}</span>
              <span>
                <a
                  href="https://naracaj.com/faq/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("settings.view")}
                </a>
              </span>
            </div>
          </div>
          <div
            className={
              updatedPassword
                ? "password-udpate-modal open-modal-display"
                : "password-udpate-modal closed-modal-display"
            }
          >
            {" "}
            {t("password-update.success")}{" "}
          </div>
        </div>
      </div>
    );
  }
  return content;
};

export default Settings;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import iconNotChecked from '../assets/images/icons/iconNotChecked.svg';
import iconChecked from '../assets/images/icons/iconChecked-2.svg';
import iconLocation from '../assets/images/icons/iconLocation.svg';
import iconTime from '../assets/images/icons/iconTime.svg';


const OrderActiveItem = props => {
    let contentStatus = null;
    const { t } = useTranslation();
    if (!props.completed) {
        if (props.data.order_mode === 'delivery') {
            let imgStatusClass = 'order-placed';
            if (props.data.order_status === 'PROCESSED') imgStatusClass = 'order-processsed';
            if (props.data.order_status === 'COMPLETED') imgStatusClass = 'order-completed';

            contentStatus = (
                <Row className={'m-0 order-item-status padding-bottom- ' + imgStatusClass}>
                    {/* <div className='style-status'>
                    <img className='first-img'  src={props.data.order_status === 'PLACED' || props.data.order_status === 'PROCESSED' || props.data.order_status === 'COMPLETED' ? iconChecked : iconNotChecked} />
                    <img  className='second-img' src={(props.data.order_status === 'COMPLETED' || props.data.order_status === 'PROCESSED') ? iconChecked : iconNotChecked} />
                    <img  className='last-img' src={props.data.order_status === 'COMPLETED' ? iconChecked : iconNotChecked} />
                    </div> */}
                    <Col xs='12' className='p-0'>
                        <Row className='m-0 status-descriptions '>
                            <Col xs='12' className='p-0 mb-3'>
                                <img alt='icon-checking' className='first-middle-child' src={props.data.order_status === 'PLACED' || props.data.order_status === 'PROCESSED' || props.data.order_status === 'COMPLETED' ? iconChecked : iconNotChecked} />
                                <span className={props.data.order_status === 'PLACED' ? "active-status" : ""}>{t('my-orders.waiting-approval')}</span>
                            </Col>
                            <Col xs='12' className='p-0 mb-3'>
                                <img  alt='icon-checking' className='first-middle-child' src={(props.data.order_status === 'COMPLETED' || props.data.order_status === 'PROCESSED') ? iconChecked : iconNotChecked} />
                                <span className={props.data.order_status === 'PROCESSED' ? "active-status" : ""} >{t('my-orders.preparing')}</span>
                            </Col>
                            <Col xs='12' className='p-0 '>
                                <Row className='m-0 pb-3'>
                                    <img alt='icon-checking' className='last-child' src={props.data.order_status === 'COMPLETED' ? iconChecked : iconNotChecked} />
                                    <span className={props.data.order_status === 'COMPLETED' ? "active-status" : ""}>{t('my-orders.delivering')}</span>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            )
        } else {
            let imgStatusClass = 'order-placed';
            if (props.data.order_status === 'PROCESSED') imgStatusClass = 'order-processsed';
            contentStatus = (
                <Row className={'m-0 order-item-status ' + imgStatusClass}>
                    <Col xs='12' className='p-0'>
                        <Row className='m-0 status-descriptions '>
                            <Col xs='12' className='p-0 mb-3'>
                                <img alt='icon-checking' className='first-child' src={props.data.order_status === 'PLACED' || props.data.order_status === 'PROCESSED' || props.data.order_status === 'COMPLETED' ? iconChecked : iconNotChecked} />
                                <span className={props.data.order_status === 'PLACED' ? "active-status" : ""} >{t('my-orders.waiting-approval')}</span>
                            </Col>
                            <Col xs='12' className='p-0 '>
                                <img alt='icon-checking' className='last-child' src={(props.data.order_status === 'COMPLETED' || props.data.order_status === 'PROCESSED') ? iconChecked : iconNotChecked} />
                                <span className={props.data.order_status === 'PROCESSED' ? "active-status" : ""}>{t('my-orders.preparing')}</span>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            )
        }
    } else {
        contentStatus = (
            <Row className={'m-0 order-item-status remove-height padding-bottom'}>
                <Col xs='12' md='8' lg='10' className='thanks-order'>
                  {t('my-orders.thanks_order')}
                </Col>
                <Col xs='12'  md='8' lg='10' className='repeat-order'>
                {t('my-orders.repeat')}
                </Col>
                <Col xs='12'  md='4' lg='2' className='button-repeat p-md-0 mt-2 mt-md-0 text-md-right'>
                    <button onClick={()=>props.handleReorder()}>   {t('my-orders.repeat_button')}</button>
                </Col>
            </Row>
        )
    }
    const dateDay = props.data.time.split(" ")[0].split("-")[2];
    const year = props.data.time.split(" ")[0].split("-")[0];
    const hour = props.data.time.split(" ")[1].slice(0, 5);
    const month = t("my-orders."+props.data.time.split(" ")[0].split("-")[1]);

    return (
        <Col xs='12' className='p-0 order-active-item'>
            {contentStatus}
            <Row className='m-0 order-item-details-row'>
                <Col xs='12' md='6' lg='7' className='p-0 mb-4 mb-md-0 pr-md-3 pr-lg-5' >
                    <Row className='m-0'>
                        <Col xs='12' className='p-0 your-order'>
                            {t('my-orders.your-order')}
                        </Col>
                        <Col xs='12' className='p-0 order-number'>
                            {t('my-orders.order_number')}: {props.data.order_number}
                        </Col>
                    </Row>
                    {props.data?.products?.map(item => {
                        return <Row className='m-0 item-infos  mt-1' key={item.id}>
                            <Col xs='12' className='p-0 restaurant-name'>
                               {item.quantity}x {item.name}
                            </Col>
                            {/* <Col xs='12' className='p-0 item-modifiers'>
                                {item.modifiers.map((item2, index) => {
                                    return <span key={item2.id}>
                                        {item2.name}
                                        {index !== item.modifiers.length - 1 ? ", " : ""}
                                    </span>
                                })}
                            </Col> */}
                        </Row>
                    })}
                          {props.data?.upsale?.map(item => {
                        return <Row className='m-0 item-infos  mt-1' key={item.id}>
                            <Col xs='12' className='p-0 restaurant-name'>
                               {item.quantity}x {item.name}
                            </Col>
                            {/* <Col xs='12' className='p-0 item-modifiers'>
                                {item.modifiers.map((item2, index) => {
                                    return <span key={item2.id}>
                                        {item2.name}
                                        {index !== item.modifiers.length - 1 ? ", " : ""}
                                    </span>
                                })}
                            </Col> */}
                        </Row>
                    })}
                    <Row className='m-0 total  mt-2'>
                        <Col xs='6' lg='5' className='p-0 text-left '>
                            {t('order-page.total')}
                        </Col>
                        <Col xs='6' lg='5' className='p-0 text-right '>
                            {props.data.subtotal} {t('post.currency')}
                        </Col>
                    </Row>
                </Col>
                <Col xs='12' md='6' lg='5' className='p-0 pl-md-3 mt-2' >
                    <Row className='m-0 mb-2'>
                        <Col xs='12' className='p-0 order-items-descr-titles'>
                            <div> <img alt='icon-location' src={iconLocation}></img> </div><div> {t('my-orders.restaurant')}</div>
                        </Col>
                        <Col xs='12' className='p-0 order-items-descr-infos'>
                            {props.data.restaurant.name}
                        </Col>
                    </Row>
                    {props.data.delivery_address ?
                        <Row className='m-0 mb-2'>
                            <Col xs='12' className='p-0 order-items-descr-titles'>
                                <div> <img alt='icon-location' src={iconLocation}></img></div> <div>{t('my-orders.location')}</div>
                            </Col>
                            <Col xs='12' className='p-0 order-items-descr-infos'>
                                {props.data.delivery_address.address_street}{props.data.delivery_address.address_no ? ", "+ props.data.delivery_address.address_no : ""}{props.data.delivery_address.address_apt_no ? ", "+ props.data.delivery_address.address_apt_no : ""}
                            </Col>
                        </Row>
                        : null}
                    <Row className='m-0'>
                        <Col xs='12' className='p-0 order-items-descr-titles'>
                            <div><img alt='icon-time' src={iconTime}></img></div> <div>{t('my-orders.time')}</div>
                        </Col>
                        <Col xs='12' className='p-0 order-items-descr-infos'>
                          {dateDay} {month} {year}, {hour}{t('my-orders.hour_short')}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}

export default OrderActiveItem;
import React, { useState, useReducer, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "../axios-instance_baseURL";
import cities from "./cities";
import CloseIcon from "../assets/images/icons/close.svg";
import checkImg from "../assets/images/icons/Check On.svg";
import Backdrop from "../UI/Backdrop";
import Aux from "../hoc/Auxilary";
import Spinner from "../UI/Spinner";
import Input from "../UI/Input";
import {
  add_save_address_confing,
  update_address_config,
} from "../assets/configs/config_calls";
import { add_save_address_url } from "../assets/urls/urls";
import { useSelector } from "react-redux";
import { AppContext } from "../context/app-context";
const VALIDITY_CHANGE = "VALIDITY_CHANGE";
const RESET_STATE = "RESET_STATE";

const reducer = (state, action) => {
  if (action.type === VALIDITY_CHANGE) {
    if (state.validElements[action.elemName] === false && action.elemValidity) {
      const copy = { ...state.validElements };
      copy[action.elemName] = action.elemValidity;
      let formValid = true;
      for (let item in { ...copy }) {
        if (copy[item] === false) {
          formValid = false;
          break;
        }
      }
      const values = { ...state.elementsValues };
      values[action.elemName] = action.elemValue;
      return {
        ...state,
        validElements: { ...copy },
        elementsValues: { ...values },
        formValid: formValid,
      };
    } else if (
      state.validElements[action.elemName] &&
      action.elemValidity === false
    ) {
      const copy = { ...state.validElements };
      copy[action.elemName] = action.elemValidity;
      return {
        ...state,
        validElements: { ...copy },
        formValid: false,
      };
    } else if (state.validElements[action.elemName] && action.elemValidity) {
      const copy = { ...state.validElements };
      copy[action.elemName] = action.elemValidity;
      let formValid = true;
      for (let item in { ...copy }) {
        if (copy[item] === false) {
          formValid = false;
          break;
        }
      }
      const values = { ...state.elementsValues };
      values[action.elemName] = action.elemValue;
      return {
        ...state,
        validElements: { ...copy },
        elementsValues: { ...values },
        formValid: formValid,
      };
    } else {
      return state;
    }
  } else if (action.type === RESET_STATE) {
    return {
      validElements: {
        name: false,
        street: false,
        streetNumber: false,
        aptNumber: false,
      },
      elementsValues: {
        name: "",
        street: "",
        streetNumber: "",
        aptNumber: "",
      },
      formValid: false,
    };
  }
};

const AddressAdd = (props) => {
  const { street, city, zip } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const [loading] = useState(false);
  const [errMsg] = useState();

  const [adding, setAdding] = useState(props.adding ? true : false);
  const [defaultAddress, setDefaultAddress] = useState(
    props.is_default ? true : false
  );
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [openModalLatLng, setOpenModalLaLong] = useState(false);
  const [loadingData] = useState(false);

  let nameValue = "";
  let streetValue = street;
  let streetNumberValue = "";
  let aptNumberValue = "";
  let latData = "0";
  let longData = "0";
  if (props.data) {
    nameValue = props.name;
    streetValue = props.address_street;
    streetNumberValue = props.address_no;
    aptNumberValue = props.address_apt_no;
    latData = props.lat;
    longData = props.long;
  }

  const [latlong, setLatLongState] = useState({ lat: latData, long: longData });
  const [formData, setFormData] = useState({
    name: {
      elemType: "input",
      placeholder: "inputs.placeholders.nameModal",
      desc: "inputs.descriptions.nameModal",
      type: "input",
      name: "name",
      valid: props.data ? true : false,
      value: nameValue,
      validationMessage: "inputs.errorMessages.name",
      succesMessage: "inputs.inputValidMessage",
      typing: false,
      rules: {
        required: true,
        minLength: 2,
      },
    },
    street: {
      elemType: "input",
      placeholder: "",
      desc: "inputs.descriptions.street",
      type: "input",
      name: "street",
      valid: props.data ? true : false,
      value: streetValue,
      validationMessage: "inputs.errorMessages.street",
      succesMessage: "inputs.inputValidMessage",
      typing: false,
      lat: null,
      long: null,
      rules: {
        required: true,
        minLength: 2,
      },
    },
    streetNumber: {
      elemType: "input",
      placeholder: "",
      desc: "inputs.descriptions.number",
      type: "number",
      name: "streetNumber",
      valid: props.data ? true : false,
      value: streetNumberValue,
      validationMessage: "inputs.errorMessages.email",
      succesMessage: "inputs.inputValidMessage",
      typing: false,
      rules: {
        required: true,
        minLength: 1,
      },
    },
    aptNumber: {
      elemType: "input",
      placeholder: "",
      desc: "inputs.descriptions.apartment",
      type: "input",
      name: "aptNumber",
      valid: props.data ? true : false,
      value: aptNumberValue,
      validationMessage: "inputs.errorMessages.email",
      succesMessage: "inputs.inputValidMessage",
      typing: false,
      rules: {
        required: true,
        minLength: 1,
      },
    },
    city: {
      elemType: "input",
      placeholder: "",
      desc: "",
      type: "input",
      name: "",
      valid: true,
      value: "",
      validationMessage: "inputs.errorMessages.email",
      succesMessage: "inputs.inputValidMessage",
      typing: false,
      disabled: true,
      rules: {
        required: true,
        minLength: 1,
      },
    },
  });

  const [validations, dispatchValidations] = useReducer(reducer, {
    validElements: props.data
      ? {
          name: true,
          street: true,
          streetNumber: true,
          aptNumber: true,
        }
      : {
          name: false,
          street: false,
          streetNumber: false,
          aptNumber: false,
        },
    elementsValues: {
      name: nameValue,
      street: streetValue,
      streetNumber: streetNumberValue,
      aptNumber: aptNumberValue,
    },
    formValid: props.data ? true : false,
  });

  const validChangeHandler = (elemName, elemValidity, elemValue) => {
    dispatchValidations({
      type: VALIDITY_CHANGE,
      elemName: elemName,
      elemValidity: elemValidity,
      elemValue: elemValue,
    });
  };

  const setLatLng = (latlng) => {
    setLatLongState({ lat: latlng.lat, long: latlng.long });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validations.formValid) {
      if (!props.data && !props.orderMode) {
        const config = add_save_address_confing(
          validations.elementsValues.name,
          validations.elementsValues.street,
          validations.elementsValues.streetNumber,
          validations.elementsValues.aptNumber,
          parseFloat(latlong.lat),
          parseFloat(latlong.long),
          accessToken,
          defaultAddress,
          i18n.language,
          city,
          zip
        );
        axios
          .post(
            add_save_address_url,
            { ...config.body },
            { params: {}, headers: config.headers }
          )
          .then((response) => {
            props.refreshAddresses();
            props.setModalOpen(false);
          })
          .catch((err) => {
            if (
              err &&
              err.response &&
              err.response.status &&
              err.response.status === 401 &&
              err.response.statusText &&
              err.response.status === 401 &&
              err.response.statusText === "UNAUTHORIZED"
            ) {
              props.history.push({
                pathname: "/settings/logout",
                err: "unauthenticated",
              });
              return;
            }
            props.refreshAddresses();
            props.setModalOpen(false);
          });
      } else if (props.orderMode) {
        const addressData = {
          address_apt_no: validations.elementsValues.aptNumber,
          address_no: validations.elementsValues.streetNumber,
          address_street: validations.elementsValues.street,
          latitude: parseFloat(latlong.lat),
          longitude: parseFloat(latlong.long),
          name: validations.elementsValues.name,
          save: defaultAddress,
        };
        props.saveNewOrCurrentAddress(addressData, props.type);
        hanadleClosing();
      } else {
        const config = update_address_config(
          props.user_fav_location_id,
          validations.elementsValues.name,
          validations.elementsValues.street,
          validations.elementsValues.streetNumber,
          validations.elementsValues.aptNumber,
          parseFloat(latlong.lat),
          parseFloat(latlong.long),
          accessToken,
          defaultAddress,
          i18n.language,
          city,
          zip
        );
        axios
          .put(
            add_save_address_url,
            { ...config.body },
            {
              params: {},
              headers: config.headers,
            }
          )
          .then((response) => {
            props.setModalOpen(false);
            props.refreshAddresses();
          })
          .catch((err) => {
            if (
              err &&
              err.response &&
              err.response.status &&
              err.response.status === 401 &&
              err.response.statusText &&
              err.response.status === 401 &&
              err.response.statusText === "UNAUTHORIZED"
            ) {
              props.history.push({
                pathname: "/settings/logout",
                err: "unauthenticated",
              });
              return;
            }
            props.refreshAddresses();
            props.setModalOpen(false);
          });
      }
    }
  };

  const handleAddingNew = () => {
    setFormData({
      ...formData,
      name: {
        ...formData.name,
        value: "",
        valid: false,
      },
      street: {
        ...formData.street,
        value: "",
        valid: false,
        disabled: false,
      },
      streetNumber: {
        ...formData.streetNumber,
        value: "",
        valid: false,
      },
      aptNumber: {
        ...formData.aptNumber,
        value: "",
        valid: false,
      },
    });
    setAdding(false);
    dispatchValidations({ type: RESET_STATE });
    setDefaultAddress(false);
  };

  const hanadleClosing = () => {
    setLatLongState({ lat: latData, long: longData });
    if (props.adding) setAdding(true);
    if (props.is_default === false) {
      setDefaultAddress(false);
    } else {
      setDefaultAddress(true);
    }
    props.setModalOpen(false);
  };

  return (
    <Aux>
      {props.modalOpen ? (
        <div className="modal-address">
          {adding ? (
            !props.orderMode ? (
              <div className="buttons-modal">
                {/* <button onClick={() => addingCurrent()}>{t('address.current')}</button> */}
                <button onClick={handleAddingNew()}>
                  {t("address.new_address")}
                </button>

                {loadingData ? (
                  <div className="loading-data-modal">
                    <Spinner logo spinnerStyle="SpinnerYellow" />;
                  </div>
                ) : null}
              </div>
            ) : null
          ) : (
            <Row className="m-0">
              <form onSubmit={handleSubmit}>
                <Row className="justify-content-center align-content-center text-center m-0">
                  <Input
                    className="p-0 col-height-modal"
                    sm="12"
                    data={formData.name}
                    notifyHandler={validChangeHandler}
                    modalAddress
                  />
                  <Input
                    className="p-0 col-height-modal "
                    sm="12"
                    data={formData.street}
                    notifyHandler={validChangeHandler}
                    modalAddress
                    places
                    setLatLng={setLatLng}
                  />
                  <Input
                    className="p-0 col-height-modal"
                    sm="12"
                    data={formData.streetNumber}
                    notifyHandler={validChangeHandler}
                    modalAddress
                  />
                  <Input
                    className="p-0 col-height-modal"
                    sm="12"
                    data={formData.aptNumber}
                    notifyHandler={validChangeHandler}
                    modalAddress
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "320px",
                      border: 0,
                      borderBottom: "1px solid #8E8E93",
                      marginBottom: "0.5rem",
                    }}
                    className="p-0 col-height-xs"
                    sm="12"
                  >
                    <p
                      style={{
                        marginLeft: "1.3rem",
                        marginTop: "1.5rem",
                        paddingBottom: "0.5rem",
                      }}
                    >
                      {city}
                    </p>
                  </div>
                  <Col xs="12" style={{ maxWidth: "320px" }}>
                    {props.orderMode ? (
                      accessToken ? (
                        <div className="policy-rules">
                          {defaultAddress ? (
                            <img
                              alt="check-img"
                              src={checkImg}
                              onClick={() => setDefaultAddress(false)}
                            />
                          ) : (
                            <button
                              className="empty-square"
                              onClick={() => setDefaultAddress(true)}
                            >
                              {" "}
                            </button>
                          )}
                          <div className="text">
                            {t("address.save_address")}
                          </div>
                        </div>
                      ) : null
                    ) : (
                      <div className="policy-rules">
                        {defaultAddress ? (
                          <img
                            alt="check-img"
                            src={checkImg}
                            onClick={() => setDefaultAddress(false)}
                          />
                        ) : (
                          <button
                            className="empty-square"
                            onClick={() => setDefaultAddress(true)}
                          >
                            {" "}
                          </button>
                        )}
                        <div className="text">{t("address.set_primary")}</div>
                      </div>
                    )}
                  </Col>
                </Row>

                {loading ? (
                  <div style={{ height: "45px" }}>
                    {" "}
                    <Spinner spinnerStyle="SpinnerYellow SpinnerButton" />
                  </div>
                ) : (
                  <div className="submit-modal">
                    <button
                      onClick={handleSubmit}
                      className="submit-button-modal"
                      disabled={!validations.formValid}
                    >
                      {props.orderMode
                        ? t("address.continue")
                        : t("profile.save")}
                    </button>
                  </div>
                )}
                {errMsg ? (
                  <Col xs="12">
                    <label className="span-style">{t(errMsg)}</label>
                  </Col>
                ) : null}
              </form>
            </Row>
          )}
          <button
            className="close-modal-adding"
            onClick={() => {
              hanadleClosing();
              if (props.orderMode) {
                props.closingModal();
              }
            }}
          >
            <img alt="close-icon" src={CloseIcon}></img>
          </button>
          <Backdrop
            address
            className="backdrop no-latlng"
            show={openModalLatLng}
            backdropClicked={() => {
              setOpenModalLaLong(false);
              if (props.orderMode) {
                hanadleClosing();
                props.closingModal();
              }
            }}
          />
          {openModalLatLng ? (
            <div
              className={
                openModalLatLng
                  ? "noLatLng-modal open-modal-display-latlng"
                  : "noLatLng-modal closed-modal-display-latlng"
              }
            >
              {t("address.enable_latlng")}
              <button
                className="close-modal-latlng"
                onClick={() => {
                  setOpenModalLaLong(false);
                  if (props.orderMode) {
                    hanadleClosing();
                    props.closingModal();
                  }
                }}
              >
                <img alt="close-icon" src={CloseIcon}></img>
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
      <Backdrop
        address
        className="backdrop"
        show={props.modalOpen}
        backdropClicked={hanadleClosing}
      />
    </Aux>
  );
};
export default AddressAdd;

import * as actionTypes from "./actionTypes";

export const save_basket_data = (data, remove = false) => {
  if(!remove)  localStorage.setItem("basketId", data.basket.id);

  return {
    type: actionTypes.SAVE_CREATED_BASKET,
    data: data,
    remove: remove
  }
};



import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from "react-device-detect";

import userIcon from '../../../assets/images/icons/user.png';
import searchIcon from '../../../assets/images/icons/Search Glyph Icon.png';
import axios from '../../../axios-instance_baseURL';
import { getAllRestaurantsConfig } from '../../../assets/configs/config_calls'
import { get_all_restaurants, get_favorites } from '../../../assets/urls/urls';
import {
    set_restaurants,
    set_restaurants_loading,
    set_error,
    set_search_value,
    set_restaurants_favorites
} from '../../../store/actions/restaurantsDataActions';
import Navigation from '../../../containers/NavigationItems';
import Backdrop from '../../../UI/Backdrop';
import Aux from '../../../hoc/Auxilary';

const SearchBar = (props) => {

    const [clicked, setClicked] = useState(false);
    const accessToken = useSelector(state => state.auth.accessToken);
    const [searchValue, setSearchValue] = useState("");
    const [inputFocused, setInputFocused] = useState(false);
    const [searchLeft, setSearchleft] = useState(true);
    const [searchClicked, setSearchClicked] = useState(false);
    const [typing, setTyping] = useState(false);
    const [componentMounted, setComponentMounted] = useState(false);
    const inputRef = useRef();
    const { t, i18n } = useTranslation();
    const [expandMenu, setExpandMenu] = useState(false);
    const long = useSelector(state => state.auth.long);
    const lat = useSelector(state => state.auth.lat);
    const filters = useSelector(state => state.filters);
    const dispatch = useDispatch();
    useEffect(() => {
        const timer = setTimeout(() => {

            let currentFilters = '';
            if (filters.filter.filterApplied === false) {
                let currentStatus = '';
                if (filters.delivery === true) currentStatus = 'DELIVERY';
                if (filters.pickup === true) currentStatus = 'PICKUP';
                if (filters.all === true) currentStatus = 'ALL';

                if (filters.foodTypes) {
                    currentFilters += '?type=' + currentStatus;
                    // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                    // if (item[0])
                    // currentFilters += "&tags=" +  item[0].tag_id;;
                    if (filters.filter.appliedTags.length !== 0)
                    currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;
                } else {
                    currentFilters += '?type=' + currentStatus;
                }
            } else {
                currentFilters = "?orderBy=" + filters.filter.sortType.toUpperCase() + "&type=" + filters.filter.deliveryType.toUpperCase();
                if (filters.foodTypes) {
                    // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                    // if (item[0])
                    // currentFilters += "&tags=" +  item[0].tag_id;;
                    if (filters.filter.appliedTags.length !== 0)
                    currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;

                } else if (filters.filter.appliedTags.length !== 0) {
                    let tags = "";
                    for (let item in filters.filter.appliedTags) {
                        tags += filters.filter.appliedTags[item].tag_id;
                        if (item != filters.filter.appliedTags.length - 1)
                            tags += ",";
                    }
                    currentFilters += "&tags=" + tags;
                }
            }


            if (searchValue.trim().length >= 3 && searchValue === inputRef.current.value) {
                dispatch(set_restaurants_loading());
                let configs = getAllRestaurantsConfig(i18n.language, accessToken ? accessToken : "");
                let baseUrl = props.favorites === true ? get_favorites : get_all_restaurants;

                let callurl = baseUrl + currentFilters + '&latitude=' + lat + '&longitude=' + long + '&search=' + searchValue + '&size=6&page=1';
                axios.get(callurl, {
                    params: {}, headers: configs.headers
                })
                    .then(response => {
                        if (props.favorites) {
                            dispatch(set_restaurants_favorites({ ...response.data }, lat, long));
                        } else {
                            dispatch(set_restaurants({ ...response.data }, lat, long));
                        }
                    })
                    .catch(err => {
                        if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                            props.history.push({
                                pathname: '/settings/logout',
                                err: "unauthenticated",
                            });
                            return;
                        }
                        dispatch(set_error());
                    });
            } else if (typing && searchValue.trim().length < 3) {
                dispatch(set_restaurants_loading());
                let configs = getAllRestaurantsConfig(i18n.language, accessToken ? accessToken : "");
                let baseUrl = props.favorites === true ? get_favorites : get_all_restaurants;
                let callurl = baseUrl + currentFilters + '&latitude=' + lat + '&longitude=' + long + '&search=' + searchValue + '&size=6&page=1';
                axios.get(callurl, {
                    params: {}, headers: configs.headers
                })
                    .then(response => {
                        if (props.favorites) {
                            dispatch(set_restaurants_favorites({ ...response.data }, lat, long));
                        } else {
                            dispatch(set_restaurants({ ...response.data }, lat, long));
                        }
                    

                    })
                    .catch(err => {
                        if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                            props.history.push({
                                pathname: '/settings/logout',
                                err: "unauthenticated",
                            });
                            return;
                        }
                        dispatch(set_error());
                    });
            }
        }, 800);

        return () => {
            clearTimeout(timer);
        }
    }, [dispatch, searchValue]);

    useEffect(() => {
        if (componentMounted) {

            let currentFilters = '';
            if (filters.filter.filterApplied === false) {
                let currentStatus = '';
                if (filters.delivery === true) currentStatus = 'DELIVERY';
                if (filters.pickup === true) currentStatus = 'PICKUP';
                if (filters.all === true) currentStatus = 'ALL';

                if (filters.foodTypes) {
                    currentFilters += '?type=' + currentStatus;
                    // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                    // if (item[0])
                    // currentFilters += "&tags=" +  item[0].tag_id;;
                    if (filters.filter.appliedTags.length !== 0)
                    currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;
                } else {
                    currentFilters += '?type=' + currentStatus;
                }
            } else {
                currentFilters = "?orderBy=" + filters.filter.sortType.toUpperCase() + "&type=" + filters.filter.deliveryType.toUpperCase();
                if (filters.foodTypes) {
                    // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                    // if (item[0])
                    // currentFilters += "&tags=" +  item[0].tag_id;;
                    if (filters.filter.appliedTags.length !== 0)
                    currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;

                } else if (filters.filter.appliedTags.length !== 0) {
                    let tags = "";
                    for (let item in filters.filter.appliedTags) {
                        tags += filters.filter.appliedTags[item].tag_id;
                        if (item != filters.filter.appliedTags.length - 1)
                            tags += ",";
                    }
                    currentFilters += "&tags=" + tags;
                }
            }


            dispatch(set_restaurants_loading());
            let value = (searchValue.trim().length >= 0 && searchValue.trim().length < 3) ? "" : searchValue;
            let configs = getAllRestaurantsConfig(i18n.language, accessToken ? accessToken : "");
            let baseUrl = props.favorites === true ? get_favorites : get_all_restaurants;
            let callurl = baseUrl + currentFilters + '&latitude=' + lat + '&longitude=' + long + '&search=' + value + '&size=6&page=1';
            axios.get(callurl, {
                params: {}, headers: configs.headers
            })
                .then(response => {
                    if (props.favorites) {
                        dispatch(set_restaurants_favorites({ ...response.data }, lat, long));
                    } else {
                        dispatch(set_restaurants({ ...response.data }, lat, long));
                    }
                })
                .catch(err => {
                    if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                        props.history.push({
                            pathname: '/settings/logout',
                            err: "unauthenticated",
                        });
                        return;
                    }
                    dispatch(set_error());
                })
        } else {
            setComponentMounted(true);
        }


    }, [i18n.language]);


    useEffect(() => {
        if (!searchClicked) return;
        let currentFilters = '';
        if (filters.filter.filterApplied === false) {
            let currentStatus = '';
            if (filters.delivery === true) currentStatus = 'DELIVERY';
            if (filters.pickup === true) currentStatus = 'PICKUP';
            if (filters.all === true) currentStatus = 'ALL';

            if (filters.foodTypes) {
                currentFilters += '?type=' + currentStatus;
                // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                // if (item[0])
                // currentFilters += "&tags=" +  item[0].tag_id;;
                if (filters.filter.appliedTags.length !== 0)
                currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;
            } else {
                currentFilters += '?type=' + currentStatus;
            }
        } else {
            currentFilters = "?orderBy=" + filters.filter.sortType.toUpperCase() + "&type=" + filters.filter.deliveryType.toUpperCase();
            if (filters.foodTypes) {
                // let item = filters.apiTags.filter(item => item.name === (t("foodTypes."+filters.foodTypes)));
                // if (item[0])
                // currentFilters += "&tags=" +  item[0].tag_id;;
                if (filters.filter.appliedTags.length !== 0)
                currentFilters += "&tags=" + filters.filter.appliedTags[0].tag_id;

            } else if (filters.filter.appliedTags.length !== 0) {
                let tags = "";
                for (let item in filters.filter.appliedTags) {
                    tags += filters.filter.appliedTags[item].tag_id;
                    if (item != filters.filter.appliedTags.length - 1)
                        tags += ",";
                }
                currentFilters += "&tags=" + tags;
            }
        }

        setSearchClicked(false);
        dispatch(set_restaurants_loading());
        let value = (searchValue.trim().length >= 0 && searchValue.trim().length < 3) ? "" : searchValue;
        let configs = getAllRestaurantsConfig(i18n.language, accessToken ? accessToken : "");
        let baseUrl = props.favorites === true ? get_favorites : get_all_restaurants;
        let callurl = baseUrl + currentFilters + '&latitude=' + lat + '&longitude=' + long + '&search=' + value + '&size=6&page=1';
        axios.get(callurl, {
            params: {}, headers: configs.headers
        })
            .then(response => {
                if (props.favorites) {
                    dispatch(set_restaurants_favorites({ ...response.data }, lat, long));
                } else {
                    dispatch(set_restaurants({ ...response.data }, lat, long));
                }
            })
            .catch(err => {
                if (err && err.response && err.response.status && err.response.status === 401 && err.response.statusText && err.response.status === 401 && err.response.statusText === "UNAUTHORIZED") {
                    props.history.push({
                        pathname: '/settings/logout',
                        err: "unauthenticated",
                    });
                    return;
                }
                dispatch(set_error());
            })
    }, [searchClicked]);


    const handleChange = (event) => {
        setSearchValue(event.target.value);
        dispatch(set_search_value(event.target.value));
        setTyping(true);
    }

    const searchLeave = () => {
        if (!inputFocused && searchValue.trim() === "") {
            setClicked(false);
            setTyping(false);
        }
        setSearchleft(true);
    }

    const inputFocusedHandler = () => {
        setInputFocused(true);
    }

    const searchBlur = () => {
        if (isMobile && searchValue.trim() === "") {
            setClicked(false);
            setTyping(false);
            return;
        }
        if (searchValue.trim() === "" && searchLeft) {
            setClicked(false);
            setTyping(false);
        }
    }

    const closeButtonClicked = () => {
        if (searchValue.trim() === "") {
            setClicked(false);
            setInputFocused(false);
            setSearchleft(true);
            setTyping(false);
            return;
        }
        setSearchValue("");
        dispatch(set_search_value(""));
        inputRef.current.focus();
    }

    const searchClickHandler = () => {
        inputRef.current.focus();
        setSearchClicked(true);
    }

    const nameClicked = () => {

        if (props.authenticated) {
            setExpandMenu(!expandMenu);

        } else {
            props.history.push('/login');
        }
    }

    const locationPath = props.history.location.pathname;
    const loginRegistForgot = locationPath.includes('/login') || locationPath.includes('/forgot') || locationPath.includes('/register');
    const showLogic = false;
    // (( !locationPath.includes('/restaurant/details/') && locationPath.includes('/setting/')) || (loginRegistForgot))
    // || (props.hideDetailsPage && locationPath.includes('/restaurant/details/')) || (props.hideDetailsPage && (locationPath.includes('/settings/')))
    let content = (

        showLogic ? null :
            <div className={(props.headerHomepageOnly ? 'header-display-inline-block-right' : 'col-custom-5 mobile-hide')} >
                <div className={props.headerHomepageOnly ? "to-homepage" : "to-homepage "}>
                    <div onClick={() => props.history.push('/')}>
                        {t('homepage.to-homepage')}
                    </div>
                </div>
                {
                    props.headerHomepageOnly ? null :
                        <div onClick={() => nameClicked()} className="user-logo-name-closed  settings-user-margin">
                            {expandMenu ?
                                <Aux>
                                    <div className='menu-model'><Navigation image /></div>
                                    <Backdrop className='menu-backdrop'
                                        show={true}
                                        backdropClicked={() => setExpandMenu(false)}
                                        menu
                                    />
                                </Aux>
                                : null}
                            <img alt="user icon" src={userIcon} />
                            <span className="name">
                                {props.authenticated ? props.name : t('homepage.login')}
                            </span>
                        </div>

                }
            </div >
    )
    if (!props.settings) {
        content = (
            clicked ?
                // true ?
                (<div className={'col-custom-5'} >
                    <div onClick={() => nameClicked()} className="user-logo-name mobile-hide">
                        {expandMenu ?
                            <Aux>
                                <div className='menu-model'><Navigation image /></div>
                                <Backdrop className='menu-backdrop'
                                    show={true}
                                    backdropClicked={() => setExpandMenu(false)}
                                    menu
                                />
                            </Aux>
                            : null}
                        <img alt="user icon" src={userIcon} />
                        <span className="name">
                            {props.authenticated ? props.name : t('homepage.login')}</span>
                    </div>
                    {/* {props.favorites === false ? */}
                    <div onMouseOver={() => setSearchleft(false)} onMouseLeave={searchLeave} onBlur={searchBlur} className='search-input'>
                        <div className="search-items">
                            <div onClick={searchClickHandler} className="search-btn">
                                <img alt="user icon" src={searchIcon} />
                            </div>
                            <form className="input-form" onSubmit={(event) => { event.preventDefault(); searchClickHandler() }}>
                                <input ref={inputRef} autoFocus onFocus={inputFocusedHandler} onChange={handleChange} placeholder={t('homepage.restaurantName')} value={searchValue}></input>
                            </form>
                            <div onClick={closeButtonClicked} className='close-btn'>
                                <span>X</span>
                            </div>
                        </div>
                    </div>
                    {/* : null } */}
                </div >
                )
                :
                (
                    <div className={'col-custom-5'}>

                        <div onClick={() => { setSearchleft(false); setClicked(true) }} className="search-closed">
                            <div className="search-btn-closed">
                                <img alt="user icon" src={searchIcon} />
                            </div>
                            <form style={{ display: "inline", height: "100%" }}>
                                <input placeholder={t('homepage.search')}></input>
                            </form>
                        </div>

                        <div onClick={() => nameClicked()} className="user-logo-name-closed mobile-hide">
                            {expandMenu ?
                                <Aux>
                                    <div className='menu-model'><Navigation image /></div>
                                    <Backdrop className='menu-backdrop'
                                        show={true}
                                        backdropClicked={() => setExpandMenu(false)}
                                        menu
                                    />
                                </Aux>
                                : null}
                            <img alt="user icon" src={userIcon} />
                            <span className="name">
                                {props.authenticated ? props.name : t('homepage.login')}
                            </span>
                        </div>
                    </div>
                )
        )
    }


    return (
        content
    )

}


export default SearchBar;

import React from 'react';
import { useEffect } from 'react';
import Slider from 'react-slick';
import { set_food_type, remove_food_type } from '../store/actions/filterActions';

import Auxilary from '../hoc/Auxilary';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

var listOfImages = [];
const sliderSettings = {
    dots: false,
    slidesToShow: 14,
    infinite: false,
    speed: 200,
    responsive: [{
        breakpoint: 1350,
        settings: {
            slidesToShow: 14
        }
    }, {
        breakpoint: 992,
        settings: {
            slidesToShow: 12,

        }
    }, {
        breakpoint: 785,
        settings: {
            slidesToShow: 7,
            slidesToScroll: 3,
        }
    },
    {
        breakpoint: 575,
        settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
        }
    },
    {
        breakpoint: 415,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
        }
    }
    ]
};
const foodTypesArray = ["brza hrana", "gotveno", "zdrava hrana", "skara", "burgeri", "piza", "pijaloci", "deserti", "testenini", "vege", "riba", "pekara", "sendvichi", "salati"];

const FoodTypesImagesSlider = props => {

    const dispatch = useDispatch();
    // const importImages = (images) => {
    //     return images.keys().map(images);
    // }
    // useEffect(() => {
    //     listOfImages = importImages(require.context('../assets/images/foodTypes', false, /\.(png)$/))
    // }, []);

    const foodTypes = useSelector(state => state.filters.foodTypes);
    const filters = useSelector(state => state.filters);
    const { t } = useTranslation();


    const foodTypeClicked = (foodType) => {
        if (foodTypes === foodType) {
            dispatch(remove_food_type(foodType));
        } else {
            let items = filters.apiTags.filter(item =>
                item.name === (t("foodTypes." + foodType)) ||
                item.name.toLowerCase() === (t("foodTypes." + foodType)) ||
                item.name.toUpperCase() === (t("foodTypes." + foodType)));
            if (items[0]) {
                dispatch(set_food_type(foodType, { ...items[0] }));
            }


        }
        // if (foodTypes.indexOf(foodType) > -1) {
        //     dispatch(remove_food_type(foodType));
        // } else {
        //     dispatch(set_food_type(foodType));
        // }
    }

    let foodTagsNames = [];
    if (filters.apiTags) {
        foodTagsNames = filters.apiTags.map(item => {
            return item.name.toLowerCase();
        });
    }
    let initialSlide = 0;
    foodTypesArray.map((image, index) => {
        if (foodTagsNames.indexOf((t("foodTypes." + image))) > -1) {
            if (foodTypes === image) initialSlide = index;
            return null;
        };
    });
    if (initialSlide > 2) {
        initialSlide = initialSlide - 2;
    }

    return (
        // listOfImages ?
        filters.apiTags ?
            <Slider
                {...sliderSettings}
                initialSlide={initialSlide}
            >
                {foodTypesArray.map((image, index) => {
                    // const dotSplited = (image.substring(18, (image.length - 4))).split('.');
                    // let foodName = "";
                    // for (let item in dotSplited) {
                    //     if (item != dotSplited.length - 1)
                    //         foodName += dotSplited[item];
                    // }
                    // const foodName = image.split('.');
                    if (foodTagsNames.indexOf((t("foodTypes." + image))) > -1) {
                        // if (foodTypes === image)
                        return <div key={index}
                            // className={foodTypes.indexOf(image) > -1 ? 'food-type active-food-type' : 'food-type'}
                            className={foodTypes === image ? 'food-type active-food-type' : 'food-type'}
                            onClick={() => foodTypeClicked(image)} >
                            <img style={{ width: "60px", height: "60px" }} src={require('../assets/images/foodTypes/' + image + '.png')} alt="info"></img>
                            <span >{t('foodTypes.' + image)}</span>
                        </div>
                    }

                })}
            </Slider>
            : null

    )
}

export default FoodTypesImagesSlider;
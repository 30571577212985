import * as actionTypes from "./actionTypes";

export const setMenuItemOrderingId = (id) => {
    return {
        id: id,
        type: actionTypes.SET_MENU_ITEM_ORDERING_ID
    }

}

export const setModifiersPrice = (modifierGroupId, totalGroupPrice) => {
    return {
        type: actionTypes.SET_MODIFIERS_PRICE,
        modifierGroupId: modifierGroupId,
        totalGroupPrice: totalGroupPrice
    }
}

export const clearOrderState = () => {
    return {
        type: actionTypes.CLEAR_ORDER_STATE,
    }
}


export const setModifiersNotValid = (id, name, min, required=false) => {
    return {
        type: actionTypes.SET_MODIFIERS_NOT_VALID,
        id,
        name,
        min,
        required
    }
}


export const removeModifiersNotValid = (id) => {
    return {
        type: actionTypes.REMOVE_MODIFIERS_NOT_VALID,
        id
    }
}

export const setModifiersForOrder = (id, value, subgroup = null) => {
    // let reversedSubgroup = null;
    // if (subgroup) {
    //     let reversedSubgroup = subgroup.reverse();
    // }
    return {
        type: actionTypes.SET_MODIFIERS_FOR_ORDER,
        id,
        value,
        subgroup
        // reversedSubgroup
    }
}

import React from "react";
// import logo from '../assets/images/logos/320x100px.png'
import logo from '../assets/images/logos/320x100.png'
const spinner = (props) => {
    if (props.logo) {
        if (props.button) {
            return <div className="logo-rotate-wrapper-button" >
            <img className="rotate-button" src={logo}></img>
        </div>;
        }
        if (props.loadMore) {
            return <div className="logo-rotate-wrapper-load-more" >
            <img className=" rotate-more" src={logo}></img>
        </div>;
        }
        return <div className="logo-rotate-wrapper" >
            <img className=" rotate" src={logo}></img>
        </div>;
    }
    return <div className={props.spinnerStyle}>Loading...</div>;
}
export default spinner;
